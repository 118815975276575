<template>
  <div>
    <loading-spinner :show="loading" v-if="loading" />

    <TableViewLayout
      v-if="!!language"
      :is-empty="language.words.length < 1"
      :title="generateTranslationsForLanguagesHandler(language.code)"
    >
      <template v-slot:header-items-right>
        <SearchInput @input="searchWord = $event" />
        <ButtonsSaveCancel
          @save="submit"
          @close="$router.push({ name: 'languages' })"
        />
      </template>

      <template>
        <records-table v-if="!!defaultLanguage" :rows="language.words">
          <template v-slot:thead>
            <tr>
              <th width="10%">{{ $t("components.table.langWordKey") }}</th>
              <th width="45%" v-if="language.id !== defaultLanguage.id">
                {{
                  generateTranslationsForLanguagesHandler(defaultLanguage.code)
                }}
              </th>
              <th width="45%" v-if="!!language">
                {{ generateTranslationsForLanguagesHandler(language.code) }}
              </th>
              <th></th>
            </tr>
          </template>

          <template #tbody>
            <tr v-for="languageWord of filteredWords">
              <td style="font-weight: 400">{{ languageWord.slug }}</td>
              <td v-if="language.id !== defaultLanguage.id">
                <div class="EditRowLine">
                  <Input :value="defaultValue(languageWord.slug)" disabled />
                </div>
              </td>
              <td>
                <div class="EditRowLine">
                  <Input
                    v-model="languageWord.value"
                    :class="{ 'has-warn': !languageWord.is_confirmed }"
                    @input="setValue(languageWord, $event)"
                  />
                  <SvgIcon
                    v-if="!languageWord.is_confirmed"
                    name="check"
                    @click="confirmWord(languageWord)"
                  />
                </div>
              </td>
            </tr>
          </template>
        </records-table>
      </template>
    </TableViewLayout>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/vuex/modules/languages-module/action-types";
import { Component } from "vue-property-decorator";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import Input from "@/components/Input/Input.vue";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { PageMixin } from "@/mixins/pageMixin";
import LanguageWithWords from "@/vuex/models/language/language-with-words";
import LanguageWord from "@/vuex/models/language/langauge-word";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { generateTranslationsForLanguages } from "@/services/helpers/generateTranslationsForLanguages";

@Component({
  components: { Input, ButtonsSaveCancel },
  computed: mapGetters({
    language: "language",
    defaultLanguage: "languageDefault",
  }),
  methods: mapActions({
    fetchLanguage: ActionTypes.FETCH_LANGUAGE,
    fetchDefaultLanguage: ActionTypes.FETCH_DEFAULT_LANGUAGE,
    updateWords: ActionTypes.UPDATE_WORDS,
  }),
})
export default class LanguageWordsView extends PageMixin {
  protected readonly fetchLanguage!: (languageId: number) => Promise<void>;
  protected readonly fetchDefaultLanguage!: () => Promise<void>;
  protected readonly updateWords!: (words: LanguageWord[]) => Promise<void>;

  protected readonly language!: LanguageWithWords;
  protected readonly defaultLanguage!: LanguageWithWords;

  protected wordsToBeUpdated: LanguageWord[] = [];
  protected searchWord: string = "";

  protected get filteredWords() {
    return this.language.words.filter((word: LanguageWord) => {
      return (
        word.value.includes(this.searchWord) ||
        word.slug.includes(this.searchWord)
      );
    });
  }

  protected async created() {
    this.loading = true;
    await this.fetchLanguage(Number(this.$route.params.id));

    if (!this.defaultLanguage) {
      await this.fetchDefaultLanguage();
    }

    this.loading = false;
  }

  protected defaultValue(slug: string): string {
    const word = this.defaultLanguage.words.find(
      (word: LanguageWord) => word.slug === slug
    );
    return !!word ? word.value : "";
  }

  protected confirmWord(word: LanguageWord): void {
    word.is_confirmed = true;
    const index = this.wordsToBeUpdated.findIndex((w) => w.id === word.id);
    if (index !== -1) {
      this.wordsToBeUpdated[index] = word;
    } else {
      this.wordsToBeUpdated.push(word);
    }
  }

  protected generateTranslationsForLanguagesHandler = (code: string) => {
    return generateTranslationsForLanguages(code);
  };

  protected setValue(word: LanguageWord, value: string): void {
    word.value = value;
    word.is_confirmed = false;
  }

  protected async submit() {
    this.loading = true;

    try {
      await this.updateWords(
        this.wordsToBeUpdated.filter((el) => el.is_confirmed)
      );
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Words have been updated",
        type: NotificationType.SUCCESS,
      });
      this.wordsToBeUpdated = [];
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
.EditRowLine {
  display: flex;
  column-gap: 19px;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #1de591;
  }
}
</style>