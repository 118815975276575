<template>
  <div class="StickyContent">
    <ButtonsSaveCancel
      @close="$router.back()"
      @save="$emit('submit')"
      :without-save="!withSave"
    >
      <template #btn-close>
        <SvgIcon name="list" width="24" height="17" />
        <span>{{ $t("components.return") }}</span>
      </template>
      <template #btn-save>
        <SvgIcon name="pencil" width="24" height="24" />
        <span>
          <slot name="saveBtnLabel" :label="saveLabel">
            {{ saveLabel }}
          </slot>
        </span>
      </template>
    </ButtonsSaveCancel>

    <div class="control_card">
      <template>
        <div class="control_card_block" :class="{ with_users: showUserSelect }">
          <div class="control_card_header">
            {{ $t("app.views.library.templatePermissions") }}
          </div>

          <input-select-access
            :access-level="templateData.access_level"
            class="control_card_block_permission"
            @select="selectAccessLevel"
            :has-error="formErrors.hasErrors('access_level')"
            :error="formErrors.get('access_level')?.toString()"
            :disabled="disabled"
          />

          <div v-if="showOrganisationSelect">
            <p class="control_card_label">
              {{ $tc("app.views.organisation.organisation", 3) }}
            </p>
            <input-select-organisation
              :organisation-id="templateData.organisation_id"
              :organisation-name="organisationName"
              class="control_card_block_organisation"
              @select="templateData.organisation_id = $event"
              @loading="$emit('loading')"
              :has-error="formErrors.hasErrors('organisation_id')"
              :error="formErrors.get('organisation_id')?.toString()"
              :disabled="disabled"
            />
          </div>

          <template v-if="assignedUsers.length > 0">
            <p class="control_card_label">
              {{ $t("app.views.library.authorizedPersons") }}
            </p>
            <div class="control_card_block_users">
              <div
                class="control_card_block_users_user"
                v-for="userOption in assignedUsers"
              >
                <span>{{ userOption.label }}</span>
                <img
                  @click="revokeAccess(userOption)"
                  src="/images/icons/white/times.svg"
                  alt="cross-icon"
                  v-if="!disabled"
                />
              </div>
            </div>
          </template>

          <div
            class="control_card_block_add-users"
            v-if="showUserSelect && !disabled"
          >
            <p class="control_card_label">
              {{ $t("app.views.library.addUsers") }}
            </p>
            <div class="control_card_block_add-users_list">
              <SearchInput v-model="searchUsersValue" @input="searchUsers" />
              <div
                class="control_card_block_add-users_list_user"
                v-for="option in userOptions"
                v-if="!!searchUsersValue"
              >
                <span>{{ option.label }}</span>
                <img
                  @click="addAccess(option)"
                  src="/images/icons/template/plus-green.svg"
                  alt="plus-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="control_card_line"></div>

        <div class="control_card_block">
          <div class="control_card_header">
            {{ $t("app.views.library.form.typeOfScore") }}
          </div>
          <input-select
            :value="templateData.type_of_score"
            :options="typeOfScoreOptions"
            :hasError="formErrors.hasErrors('type_of_score')"
            :error="formErrors.get('type_of_score')?.toString()"
            @select="templateData.type_of_score = $event"
            :disabled="disabled"
          />
        </div>

        <div class="control_card_block">
          <div class="control_card_header">
            {{ $tc("app.views.language.language", 1) }}
          </div>
          <InputSelectLanguage
            class="control_card_block_language"
            :language-id="templateData.language_id"
            :disabled="disabled"
            :has-error="formErrors.hasErrors('language_id')"
            :error="formErrors.get('language_id')?.toString()"
            @loading="$emit('loading')"
            @select="templateData.language_id = $event"
          />
        </div>

        <div
          v-if="
            auth.isSystemAdmin(me) && templateData.access_level === 'PUBLIC'
          "
          class="control_card_block"
        >
          <div class="control_card_header">Category</div>
          <input-select
            :disabled="disabled"
            :options="templateTagsOptions"
            :value="templateData.tags"
            @select="setTagIdsHandler"
          />
        </div>

        <div class="control_card_block">
          <div class="control_card_header">
            {{ $t("app.views.library.logo") }}
          </div>
          <div class="control_card_block_logo">
            <label
              class="control_card_block_logo_button"
              :class="{ HasError: formErrors.hasErrors('logo') }"
            >
              <input
                id="logoInput"
                type="file"
                accept="image/png, image/jpeg"
                @change="addLogo"
                :disabled="disabled"
              />
              <img
                class="Icon"
                src="/images/icons/plus.svg"
                height="30"
                alt="add-icon"
              />
            </label>

            <p class="control_card_block_logo_file" v-if="!!logoName">
              <span>{{ logoName }}</span>
              <img
                style="cursor: pointer"
                v-if="!disabled"
                src="/images/icons/delete.svg"
                @click="removeLogo"
                height="24"
                width="24"
              />
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "@/components/InputCheckbox/InputCheckbox.vue";
import Input from "@/components/Input/Input.vue";
import InputSelectAccess from "@/components/InputSelectAccess/InputSelectAccess.vue";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { TemplateAccessLevel } from "@/vuex/models/template/template-access-level";
import Authorization from "@/services/authorization/authorization";
import { mapGetters } from "vuex";
import { MutationTypes as UserMutations } from "@/vuex/modules/users-module/mutation-types";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as TemplateActions } from "@/vuex/modules/templates-module/action-types";
import { Direction, UsersSortableColumns } from "@/services/helpers/sort";
import { TemplateTypeOfScore } from "@/vuex/models/template/template-type-of-score";

export default {
  name: "RightSideNav",
  emits: ["update:templateData", "submit", "loading"],
  components: {
    ButtonsSaveCancel,
    InputSelectOrganisation,
    InputSelectAccess,
    Input,
    InputCheckbox,
  },
  props: {
    templateData: {
      required: true,
    },
    formErrors: {
      required: true,
    },
    template: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["me", "users", "templateTagsOptions"]),
    accessLevel() {
      return this.templateData.access_level;
    },
    organisationName() {
      return !!this.template ? this.template.organisation?.name : "";
    },
    withSave() {
      return this.canCreate || this.canUpdate || this.canClone;
    },
    canCreate() {
      return (
        (this.auth.isSystemAdmin(this.me) ||
          this.auth.isLocationAdmin(this.me) ||
          this.auth.isOrganisationAdmin(this.me)) &&
        !this.template
      );
    },

    canUpdate() {
      const isSystemAdmin = this.auth.isSystemAdmin(this.me);
      const isOrganisationAdmin = this.auth.isOrganisationAdmin(this.me);
      const isLocationAdmin = this.auth.isLocationAdmin(this.me);
      if (!!this.template) {
        switch (this.template.access_level) {
          case TemplateAccessLevel.PUBLIC:
            return isSystemAdmin;
          case TemplateAccessLevel.ORGANISATION:
            if (isSystemAdmin) {
              return true;
            } else if (
              isOrganisationAdmin &&
              this.template.organisation.id === this.me.organisation?.id
            ) {
              return true;
            } else if (
              isLocationAdmin &&
              this.me.id === this.template.creator_id
            ) {
              return true;
            }
            break;
          case TemplateAccessLevel.PRIVATE:
            return this.me.id === this.template.creator_id;
        }
        return false;
      }
      return false;
    },
    canClone() {
      return (
        !this.canUpdate &&
        !!this.template &&
        this.template.access_level === TemplateAccessLevel.PUBLIC
      );
    },
    saveLabel() {
      if (this.canUpdate) {
        return this.$t("components.edit");
      }
      if (this.canClone) {
        return this.$t("components.editAsOwn");
      }
      return this.$t("components.save");
    },
    typeOfScoreOptions() {
      return [
        {
          value: TemplateTypeOfScore.PERCENTAGES,
          label: this.$t("app.views.library.form.percents"),
        },
        {
          value: TemplateTypeOfScore.POINTS,
          label: this.$t("app.views.library.form.points"),
        },
      ];
    },
  },
  data() {
    return {
      auth: new Authorization(),

      showUserSelect: false,
      showOrganisationSelect: false,

      userOptions: [],
      assignedUsers: [],

      logoName: null,
      searchUsersValue: null,

      usersSearchParams: {
        page: 1,
        per_page: 30,
        search: null,
        direction: Direction.ASC,
        column: UsersSortableColumns.NAME,
        organisation_id: null,
        role: null,
        locations_ids: [],
      },
    };
  },
  created() {
    this.switchSelects(this.templateData.access_level);
    this.setUserOptions();
    this.setAssignedUsers();
    this.setLogo();
    this.$store.dispatch(TemplateActions.FETCH_TEMPLATE_TEGS);
  },
  methods: {
    // Access level section
    selectAccessLevel(accessLevel) {
      if (accessLevel !== "PUBLIC") {
        this.$emit("setTagIds", []);
      }
      this.templateData.access_level = accessLevel;
      this.switchSelects(accessLevel);
    },
    setTagIdsHandler(tagIds) {
      this.$emit("setTagIds", tagIds);
    },
    switchSelects(accessLevel) {
      switch (accessLevel) {
        case TemplateAccessLevel.PRIVATE:
          this.showUserSelect = true;
          this.showOrganisationSelect = false;
          this.dropOrganisation();
          break;
        case TemplateAccessLevel.ORGANISATION:
          if (this.auth.isSystemAdmin(this.me)) {
            this.showOrganisationSelect = true;
          }
          this.showUserSelect = false;
          this.dropAssignedUsers();
          break;
        default:
          this.showOrganisationSelect = false;
          this.showUserSelect = false;
          this.dropOrganisation();
          this.dropAssignedUsers();
      }
    },
    dropAssignedUsers() {
      this.assignedUsers = [];
      this.templateData.assigned_users = [];
    },
    dropOrganisation() {
      this.templateData.organisation_id = null;
    },

    // Users with access section
    addAccess(userOption) {
      this.templateData.assigned_users.push(Number(userOption.value));
      this.assignedUsers.push(userOption);
    },
    revokeAccess(userOption) {
      let index = this.templateData.assigned_users.indexOf(
        Number(userOption.value)
      );
      this.templateData.assigned_users.splice(index, 1);

      index = this.assignedUsers.indexOf(userOption);
      this.assignedUsers.splice(index, 1);
    },
    setAssignedUsers() {
      if (!!this.template) {
        this.assignedUsers = this.template.assigned_users.map((user) => {
          return {
            label: `${user.name} ${user.surname}`,
            value: user.id,
          };
        });
      }
    },
    setUserOptions() {
      const users = this.users.map((user) => {
        return { label: `${user.name} ${user.surname}`, value: user.id };
      });

      this.userOptions = users.filter((option) => {
        return (
          option.value !== this.me.id &&
          this.templateData.assigned_users.indexOf(Number(option.value)) === -1
        );
      });
    },
    async searchUsers(search) {
      this.loading = true;

      if (!!search) {
        this.usersSearchParams.search = search;
        await this.$store.dispatch(
          UserActions.FETCH_USERS,
          this.usersSearchParams
        );
      } else {
        this.$store.commit(UserMutations.SET_USERS, []);
      }

      this.setUserOptions();
      this.loading = false;
    },

    // Logo section
    addLogo(event) {
      const files = event.target.files;

      if (files.length > 0) {
        const file = files[0];

        this.templateData.logo = file;
        this.logoName = file.name;
      }
    },
    removeLogo() {
      this.logoName = null;
      this.templateData.logo = null;

      document.querySelector("#logoInput").value = null;
    },
    setLogo() {
      if (!!this.template && !!this.template.logo) {
        this.logoName = this.template.logo.name;
      } else {
        this.logoName = null;
      }
    },
  },
  watch: {
    assignedUsers: {
      handler() {
        this.setUserOptions();
      },
      deep: true,
    },
    accessLevel: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.switchSelects(newVal);
        }
      },
      deep: true,
    },
    template: {
      handler() {
        this.setLogo();
        this.setAssignedUsers();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.control_card {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .with_users {
    margin-bottom: 55px;
  }

  &_line {
    width: 100%;
    border-top: 1px solid #e6e6e6;
  }

  &_header {
    font: normal normal 600 12px/20px Poppins;
    letter-spacing: 0;
    color: #0d0e34;
  }

  &_label {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: #6d6d6d;
    margin-bottom: 5px;
  }

  &_block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    &_permission,
    &_organisation,
    &_language {
      font-size: 12px;
    }

    &_permission {
      z-index: 4;
    }

    &_users {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &_user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font: normal normal 600 12px/18px Poppins;
          letter-spacing: 0;
          color: #0d0e34;
        }

        img {
          height: 24px;
          width: 24px;
          cursor: pointer;
        }
      }
    }

    &_add-users {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;

      &_list {
        display: flex;
        flex-direction: column;
        background: #ffffff no-repeat padding-box;
        box-shadow: 0 0 16px #0000000f;
        border: 1px solid #e6e6e6;
        border-radius: 24px;
        z-index: 3;

        position: absolute;
        top: 28px;
        width: 100%;

        .SearchInput {
          min-height: 48px !important;
          border: none;
        }

        &_user {
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          span {
            font: normal normal normal 12px/18px Poppins;
            letter-spacing: 0;
            color: #6d6d6d;
          }

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }
    }

    &_logo {
      display: flex;
      column-gap: 20px;

      &.HasError {
        background: #e51d1d;
      }

      &.Disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #1de591;
        border-radius: 50%;
        cursor: pointer;
        width: 48px;
        height: 48px;

        &:hover {
          box-shadow: 0 0 16px #00000029;
        }

        input {
          display: none;
        }
      }

      &_file {
        display: flex;
        align-items: center;
        column-gap: 15px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          cursor: pointer;
          color: #e51d1d;
        }
      }
    }
  }
}
</style>
