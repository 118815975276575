<template>
  <Modal :modal="modal">
    <div class="header" slot="header">
      {{ label }}
    </div>
    <div class="content" slot="content">
      {{ $t('app.views.user.deleteTemplateNotification') }}
    </div>
    <div class="footer" slot="footer">
      <ButtonsSaveCancel @close="$emit('close', false)" :danger="true">
        <span class="delete-button" slot="btn-save" @click="remove">
          {{ $t('app.views.user.deleteTemplate') }}
        </span>
      </ButtonsSaveCancel>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {ActionTypes} from "@/vuex/modules/templates-module/action-types";

export default {
  name: "DeleteTemplateModal",
  emits: ['close', 'loading'],
  components: {ButtonsSaveCancel, Modal},
  props: {
    modal: {
      required: true,
      type: Boolean
    },
    template: {
      required: true,
    }
  },
  computed: {
    label() {
      let label = this.$t('app.views.user.deleteTemplateTitle');

      if (!!this.template) {
        label += ` "${this.template.name}"`
      }

      return label;
    }
  },
  methods: {
    async remove() {
      this.$emit('loading');
      try {
        await this.$store.dispatch(ActionTypes.DELETE_TEMPLATE, this.template.id);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: 'Template has been deleted.',
          type: NotificationType.SUCCESS
        });
        this.$emit('close', true);
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER
        });
      }

      this.$emit('loading');
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #FF5151;
}

.content {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0;
  color: #3D3D3D;
  margin: 0;
}

.footer {
  width: 100%;
  padding: 0;
  background: none;

  .ButtonsSaveCancel {
    justify-content: space-between;
  }

  .delete-button {
    min-width: 220px;
  }
}
</style>
