import {ActionTypes} from "./action-types"
import {ActionContext, ActionTree} from "vuex";
import {RootState} from "../../store";
import {http} from "@/services/http/http"
import {appRoutes} from '@/config'
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import StatisticsState from "./state";
import {MutationTypes} from "@/vuex/modules/statistics-module/mutation-types";

export interface StatisticsShortSearchParams {
    location_id: number | null;
    to_date: string | null;
    from_date: string | null;
}

type StatisticsActionContext = ActionContext<StatisticsState, RootState>

const actions: ActionTree<StatisticsState, RootState> = {
    async [ActionTypes.FETCH_SHORT_STATISTICS](store: StatisticsActionContext, params: StatisticsShortSearchParams): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.statistics.short(), {params});
            store.commit(MutationTypes.SET_SHORT_STATISTICS, response.data);
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    }
}

export default actions;

