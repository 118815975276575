import { render, staticRenderFns } from "./TemplateFormView.vue?vue&type=template&id=b2a8547e&scoped=true"
import script from "./TemplateFormView.ts?vue&type=script&lang=ts&external"
export * from "./TemplateFormView.ts?vue&type=script&lang=ts&external"
import style0 from "./TemplateFormView.scss?vue&type=style&index=0&id=b2a8547e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2a8547e",
  null
  
)

export default component.exports