import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { PageMixin } from "@/mixins/pageMixin";
import { ActionTypes } from "@/vuex/modules/incidents-module/action-types";
import { IncidentsSearchParams } from "@/vuex/modules/incidents-module/actions";
import { Debounce } from "vue-debounce-decorator";
import Incident from "@/vuex/models/incident/incident";
import Meta from "@/vuex/models/meta";
import {
  Direction,
  IncidentsSortableColumns,
  LocationsSortableColumns,
} from "@/services/helpers/sort";
import { TemplateIncidentLevel } from "@/vuex/models/template/template-incident-level";
import DateToString from "@/services/dateToString/dateToString";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { ActionTypes as LocationsActions } from "@/vuex/modules/locations-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";

import FilterPanel from "./components/FilterPanel/FilterPanel.vue";
import PopupPhoto from "./components/PopupPhoto/PopupPhoto.vue";
import ModalForAssignment from "./components/ModalForAssignment/ModalForAssignment.vue";
import ModalIncidentInfo from "./components/ModalIncidentInfo/ModalIncidentInfo.vue";
import CreateIncidentModal from "./components/CreateIncidentModal/CreateIncidentModal.vue";
import AssignUserColumn from "./components/AssignUserColumn/AssignUserColumn.vue";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import i18n from "@/lang/locale";

import { ActiveTabTypes, TIncidentPhotoData } from "./types";
import { generateIncidentSourceType } from "@/services/helpers/generateIncidentSourceType";
import {
  generateHeadersForAll,
  generateHeadersForIncidents,
  generateHeadersForInspections,
} from "./headersForCsv";

@Component({
  components: {
    FilterPanel,
    ModalIncidentInfo,
    ModalForAssignment,
    PopupPhoto,
    ButtonWithIcon,
    CreateIncidentModal,
    AssignUserColumn,
  },
  methods: {
    ...mapActions({
      fetchIncidents: ActionTypes.FETCH_INCIDENTS,
      fetchIncidentsForCsv: ActionTypes.FETCH_INCIDENTS_FOR_CSV,
      solveIncident: ActionTypes.SOLVE_INCIDENT,
    }),
  },
  computed: mapGetters({
    incidents: "incidents",
    meta: "incidentsMeta",
    locationSelectOptions: "locationSelectOptions",
    incidentsForCsv: "incidentsForCsv",
  }),
})
export default class InspectionQuestionsSection extends Mixins(PageMixin) {
  protected readonly fetchIncidents!: (
    params: IncidentsSearchParams
  ) => Promise<void>;
  protected readonly fetchIncidentsForCsv!: (
    params: IncidentsSearchParams
  ) => Promise<void>;

  protected readonly solveIncident!: (incidentId: number) => Promise<void>;

  protected readonly incidents!: Incident[];
  protected readonly incidentsForCsv!: Incident[];
  protected readonly locationSelectOptions!: SelectOption[];
  protected readonly meta!: Meta;
  protected readonly sortableColumns = IncidentsSortableColumns;
  protected readonly incidentLevel = TemplateIncidentLevel;
  protected readonly dateToString: DateToString = new DateToString();
  protected innerWidth = window.innerWidth;

  protected openAssignModalData: Incident | null = null;
  protected incidentPhotoData: TIncidentPhotoData | null = null;
  protected showIncidentInfoModal: number | null = null;
  protected isActionIncidentModal: boolean = false;

  protected activeTab: ActiveTabTypes = "all";

  protected searchParams: IncidentsSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
    solved: null,
    source: null,
    incident_type_id: null,
    location_id: null,
  };

  protected searchLocationsParams = {
    page: 1,
    per_page: 100,
    direction: Direction.ASC,
    column: LocationsSortableColumns.NAME,
    search: null,
  };

  protected async created(): Promise<void> {
    await this.paginate();

    if (!this.auth.isSystemAdmin(this.me)) {
      await this.$store.dispatch(
        LocationsActions.FETCH_LOCATIONS,
        this.searchLocationsParams
      );
    }
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchIncidents(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchIncidents(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected selectStatus(value: number) {
    this.searchParams.solved = value;
  }

  protected selectType(typeId: number | null) {
    this.searchParams.incident_type_id = typeId;
  }

  protected changeTab(tab: ActiveTabTypes) {
    if (tab === "all") {
      this.searchParams.source = null;
    } else if (tab === "inspection") {
      this.searchParams.source = tab;
      this.selectType(null);
    } else {
      this.searchParams.source = tab;
    }
    this.activeTab = tab;
  }

  protected closeActionIncidentModal(submited: boolean) {
    this.isActionIncidentModal = false;

    if (submited) {
      this.paginate();
    }
  }

  protected openAssignModal(incident: Incident) {
    this.openAssignModalData = incident;
  }

  protected navigateReportPage(id: number) {
    this.$router.push({
      name: "report-item",
      params: { id: id.toString() },
    });
  }

  protected openIncidentsPopup(name: string, photoUrl: string) {
    this.incidentPhotoData = {
      report_name: name,
      pictureUrl: photoUrl,
    };
  }

  closeIncidentInfoModal() {
    this.showIncidentInfoModal = null;
  }

  closeAssignModal(isSubmit: boolean) {
    this.openAssignModalData = null;

    if (isSubmit) {
      this.paginate();
    }
  }

  protected openIncidentModal(id: number) {
    this.showIncidentInfoModal = id;
  }

  protected async solve(incident: Incident): Promise<void> {
    this.loading = true;

    try {
      await this.solveIncident(incident.id);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Incident has been solved",
        type: NotificationType.SUCCESS,
      });
      await this.paginate();
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  public handleGenerateIncidentSourceType(
    typeIncident: "inspection" | "reported" | "qr_code"
  ) {
    return generateIncidentSourceType(typeIncident);
  }

  tabConfigs = {
    all: {
      headerGenerator: generateHeadersForAll(),
      fileName: "all_incidents.csv",
    },
    inspection: {
      headerGenerator: generateHeadersForInspections(),
      fileName: "inspection_incidents.csv",
    },
    reported: {
      headerGenerator: generateHeadersForIncidents(),
      fileName: "reported_incidents.csv",
    },
  };

  private mapIncidentToCsvRow(
    incident: Incident,
    type: "all" | "inspection" | "reported"
  ) {
    const baseRow = {
      [i18n.t("components.table.date").toString()]:
        this.dateToString.getLocaleString(new Date(incident.date), [
          this.dateToString.LONG_DATE,
        ]),
      [i18n.t("components.table.reportedBy").toString()]: incident?.reported_by
        ? `${incident.reported_by.name} ${incident.reported_by.surname}`
        : "-",
      [i18n.t("components.table.assignTo").toString()]: incident?.assigned_to
        ? `${incident.assigned_to.name} ${incident.assigned_to.surname}`
        : "-",
      [i18n.t("components.table.status").toString()]: incident.solved
        ? i18n.t("app.views.incident.resolved").toString()
        : i18n.t("app.views.incident.notResolved").toString(),
      [i18n.t("components.table.solved_date").toString()]: incident.solved_date
        ? this.dateToString.getLocaleString(new Date(incident.solved_date), [
            this.dateToString.LONG_DATE,
          ])
        : "-",
      [i18n.t("components.table.solved_by").toString()]: incident.solved_by
        ? incident.solved_by.name + " " + incident.solved_by.surname
        : "-",
    };

    if (type === "all") {
      Object.assign(baseRow, {
        [i18n.t("components.table.allReportName").toString()]:
          incident.source === "inspection"
            ? incident?.report_name
            : incident?.name,
        [i18n.t("components.table.source").toString()]: i18n.t(
          this.handleGenerateIncidentSourceType(incident.source)
        ),
        [i18n.t("components.table.typeIncident").toString()]:
          incident.incident_type
            ? incident.incident_type.name
            : i18n.t("app.views.typeIncident.other").toString(),
      });
    } else if (type === "inspection") {
      Object.assign(baseRow, {
        [i18n.t("app.views.incident.nameIncidentTypeInspection").toString()]:
          incident?.report_name,
        [i18n.t("components.table.question").toString()]:
          incident.question_name,
        [i18n.t("components.table.answer").toString()]:
          incident.answer === "no" || incident.answer === "yes"
            ? i18n.t(`components.${incident.answer}`)
            : incident.answer,
      });
    } else if (type === "reported") {
      Object.assign(baseRow, {
        [i18n.t("app.views.incident.nameIncidentTypeReported").toString()]:
          incident.name,
        [i18n.t("components.table.typeIncident").toString()]:
          incident.incident_type
            ? incident.incident_type.name
            : i18n.t("app.views.typeIncident.other"),
        [i18n.t("components.table.site").toString()]:
          incident.location?.name ?? "-",
      });
    }
    return baseRow;
  }

  public async handleExportCsv() {
    await this.fetchIncidentsForCsv(this.searchParams);
    const config = this.tabConfigs[this.activeTab];

    const csvRows = this.incidentsForCsv.map((incident) =>
      this.mapIncidentToCsvRow(incident, this.activeTab)
    );
    await this.exportCsv.handleExportCsv(
      csvRows,
      config.headerGenerator,
      config.fileName
    );
  }
}
