import UsersState, { UserInspector } from "./state";
import { GetterTree } from "vuex";
import { RootState } from "../../store";
import User from "@/vuex/models/user/user";
import Meta from "@/vuex/models/meta";
import { SelectOption } from "@/components/InputSelect/InputSelect";

const getters: GetterTree<UsersState, RootState> = {
  userInspectors(state: UsersState): UserInspector[] {
    if (state.userInspectors) {
      return state.userInspectors;
    }

    return [];
  },
  users(state: UsersState): Array<User> | [] {
    return state.users;
  },
  usersForCsv(state: UsersState): Array<User> | [] {
    return state.usersForCsv;
  },
  usersMeta(state: UsersState): Meta {
    return state.meta;
  },
  userSelectOptionsForAssign(state: UsersState): SelectOption[] | [] {
    return state.usersForAssign.map((user: User) => {
      return {
        value: user.id,
        label: `${user.surname} ${user.name}`,
        roleUser: user.role,
      };
    });
  },
  userSelectOptions(state: UsersState): SelectOption[] | [] {
    return state.users.map((user: User) => {
      return {
        value: user.id,
        label: `${user.surname} ${user.name}`,
        roleUser: user.role,
      };
    });
  },
};

export default getters;
