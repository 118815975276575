<template>
  <div class="ButtonsSaveCancel">
    <button
      :disabled="disabled"
      class="cancel"
      type="button"
      @click="$emit('close')"
      v-show="!dontShowClose"
    >
      <template v-if="!$slots['btn-close']">
        <SvgIcon :name="cancelIcon" width="24" height="24" />
        <span>{{ cancelLabel ? cancelLabel : $t("components.cancel") }}</span>
      </template>
      <slot v-else name="btn-close" />
    </button>
    <button
      :disabled="disabled"
      type="button"
      @click="$emit('save')"
      v-if="!withoutSave"
      :class="{
        save: !danger,
        danger: danger,
      }"
    >
      <template v-if="!$slots['btn-save']">
        <LoadingSpinner :full-screen="false" :show="loading"></LoadingSpinner>
        <SvgIcon v-if="!loading" :name="saveIcon" width="24" height="24" />
        <span>{{ saveLabel ? saveLabel : $t("components.save") }}</span>
      </template>
      <slot v-else name="btn-save" />
    </button>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner/LoadingSpinner.vue";

export default {
  name: "ButtonsSaveCancel",
  components: {
    LoadingSpinner,
  },
  props: {
    withoutSave: Boolean,
    dontShowClose: Boolean,
    cancelLabel: {
      required: false,
      type: String | null,
      default: null,
    },
    saveLabel: {
      required: false,
      type: String | null,
      default: null,
    },
    saveIcon: {
      required: false,
      type: String,
      default: "save",
    },
    cancelIcon: {
      required: false,
      type: String,
      default: "close",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    danger: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/asesst/styles/mixins";

.ButtonsSaveCancel {
  display: flex;
  justify-content: flex-end;
  column-gap: 19px;
  @include mobile {
    justify-content: space-between;
  }

  & > .cancel,
  & > .save,
  & > .danger {
    padding: 9px 20px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    cursor: pointer;
    min-height: 48px;
    min-width: 125px;

    &:hover {
      box-shadow: 0 0 16px #00000029;
    }

    img {
      width: 30px;
      height: 30px;
    }

    @include mobile {
      padding: 9px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }

    @media (max-width: 400px) {
      span {
        display: none;
      }
      justify-content: center;
      min-width: 48px;
    }
  }

  & > .cancel {
    border: 2px solid #1de591;
    color: #1de591;
    background: transparent;
    border-radius: 122px;

    & > .white-icon {
      display: none;
    }

    &:hover {
      background: #1de591;
      color: #fff;

      img:not(.white-icon) {
        display: none;
      }

      & > .white-icon {
        display: block;
      }
    }
  }

  & > .save {
    background: #1de591;
    color: #ffffff;
    border: 0;
    border-radius: 122px;
  }

  & > .danger {
    background: #ff5151 0 0 no-repeat padding-box;
    border-radius: 122px;
    color: #ffffff;
    border: 0;

    &:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
