<template>
  <LoadingSpinner v-if="loading" :show="loading"/>

  <div v-else class="enterprise-plan">
    <BillingForm
        :save-label="$t('components.send').capitalize()"
        @back="$router.back()"
        @save="sendRequest()"
    >
      <template slot="form">
        <div class="cannot-pay_info">
          <p class="cannot-pay_text">
            <strong>
              {{ $t('app.views.billing.sendCannotPayRequest') }}
            </strong>
          </p>
          <p class="cannot-pay_text">
            {{ $t('app.views.billing.contactAsap') }}
          </p>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import {mapActions, mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";

export default {
  name: "CantPayByCard",
  components: {BillingForm},
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.$parent.title = this.$t('app.views.billing.cantPayByCard');
  },
  computed: {
    ...mapGetters({
      me: 'me'
    })
  },
  methods: {
    ...mapActions({
      cannotPayRequest: ActionTypes.CANNOT_PAY_REQUEST,
      pushNotification: NotificationActions.PUSH_NOTIFICATION,
    }),
    async sendRequest() {
      this.loading = true;

      try {
        await this.cannotPayRequest();
        await this.pushNotification({
          type: NotificationType.SUCCESS,
          text: this.$t('app.views.billing.cannotPayNotification').toString()
        });
        this.$router.back();
      } catch (exception) {
        await this.pushNotification({
          type: NotificationType.DANGER,
          text: exception.response.data.message
        });
      }

      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.cannot-pay {
  &_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_text {
    text-align: left;
    font: normal normal 600 12px/26px Poppins;
    letter-spacing: 0;
    color: #707070;
  }
}
</style>