<template>
  <div class="Modal" v-if="modal" @click="closeModal($event)">
    <div
      class="ModalContent"
      :style="{ 'max-width': isWidth ? `${isWidth}px` : '750px' }"
    >
      <slot name="header"></slot>
      <slot name="content"></slot>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modal: {
      required: true,
      type: Boolean,
    },
    isWidth: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeModal(event) {
      if (event.target.className === "Modal") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  .ModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 30px;

    min-height: 330px;
    padding: 35px 30px 25px 30px;

    background: rgba(255, 255, 255, 1) 0 0 no-repeat padding-box;
    box-shadow: 0 0 16px #0000000f;
    border-radius: 10px;

    margin: 20px;
  }
}
</style>