import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import actions from './actions';
import mutations from "./mutations";
import getters from "./getters";
import {BillingState} from "@/vuex/modules/billing-module/state";

const module: Module<BillingState, RootState> = {
    state: new BillingState(),
    actions,
    mutations,
    getters,
}

export default module;
