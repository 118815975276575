import { Component, Prop } from "vue-property-decorator";
import { PageMixin } from "@/mixins/pageMixin";

@Component({})
export default class TabList extends PageMixin {
  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public showTabList!: boolean;

  protected innerWidth: number = window?.innerWidth;

  handleClickOutside(event: any) {
    const buttonWithIcon = this.$refs.buttonWithIcon;
    if (
      buttonWithIcon instanceof HTMLElement &&
      !buttonWithIcon.contains(event.target)
    ) {
      this.$emit("closeTabList");
    }
  }

  protected async created(): Promise<void> {
    document.addEventListener("click", this.handleClickOutside);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
}
