import { Doughnut } from "vue-chartjs/legacy";

export default {
  name: "ChartDoughnut",
  components: {
    Doughnut,
  },
  props: {
    chartData: {
      type: Object,
      require: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      chartOptions: {
        cutout: 80,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
