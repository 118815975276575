export const common = {
  locale: {
    dateTime: "pl-Pl",
  },
};

// route must not end in "/"
export const appRoutes = {
  api: {
    locations: {
      paginate: (): string => "/api/locations/paginate",
      all: (): string => "/api/locations/all",
      index: (): string => "/api/locations",
      update: (id: number): string => "/api/locations/" + id,
      destroy: (id: number): string => "/api/locations/" + id,
    },
    qrCodes: {
      paginate: (): string => "/api/qr-codes/paginate",
      all: (): string => "/api/qr-codes/all",
      create: (): string => "/api/qr-codes/",
      view: (id: number): string => "/api/qr-codes/" + id,
      update: (id: number): string => "/api/qr-codes/" + id,
      delete: (id: number): string => "/api/qr-codes/" + id,
    },
    users: {
      paginate: (): string => "/api/users/paginate",
      all: (): string => "/api/users/all",
      index: (): string => "/api/users",
      update: (id: number): string => "/api/users/" + id,
      usersInUserLocation: (id: number): string =>
        "/api/users/get-users-in-user-location/" + id,
      destroy: (id: number): string => "/api/users/" + id,
      allow: (id: number): string => "/api/users/allow/" + id,
      deny: (id: number): string => "/api/users/deny/" + id,
    },
    templates: {
      paginate: (): string => "/api/templates/paginate",
      all: (): string => "/api/templates/all",
      view: (id: number): string => "/api/templates/" + id,
      create: (): string => "/api/templates",
      update: (id: number): string => "/api/templates/" + id,
      clone: (id: number): string => "/api/templates/clone/" + id,
      destroy: (id: number): string => "/api/templates/" + id,
      templateTags: (): string => "/api/template-tags",
    },
    inspections: {
      paginate: (): string => "/api/inspections/paginate",
      all: (): string => "/api/inspections/all",
      create: (): string => "/api/inspections",
      update: (id: number): string => "/api/inspections/" + id,
      destroy: (id: number): string => "/api/inspections/" + id,
      startInspection: (id: number): string => "/api/inspections/start/" + id,
      view: (id: number): string => "/api/inspections/" + id,
      cancel: (id: number): string => "/api/inspections/cancel/" + id,
      execute: (id: number): string => "/api/inspections/execute/" + id,
      settings: (): string => "/api/inspection-settings",
      cancelExternalInspection: (
        inspectionId: number,
        qr_token: string,
        pin: null | string
      ): string =>
        `/api/inspections/cancel-external-inspection/${inspectionId}?qr_token=${qr_token}${pin ? `&pin=${pin}` : ''}`,
      executeExternalInspection: (
        inspectionId: number,
        qr_token: string,
        pin: null | string
      ): string =>
        `/api/inspections/execute-external-inspection/${inspectionId}?qr_token=${qr_token}${pin ? `&pin=${pin}` : ''}`,
      startInspectionExternal: (qrToken: string, pin: null | string): string =>
        `/api/inspections/get-from-qr-token?qr_token=${qrToken}${pin ? `&pin=${pin}` : ''}`,
      startInspectionExternalPin: (inspection: string, pin: string): string =>
        `/api/inspections/external-inspection-start/${inspection}?pin=${pin}`,
      sendInspectionExternalEmails: (inspection: number): string =>
        `/api/inspections/send-external-access-to-email/${inspection}`,
    },
    organisations: {
      paginate: (): string => "/api/organisations/paginate",
      all: (): string => "/api/organisations/all",
      index: (): string => "/api/organisations",
      update: (id: number): string => "/api/organisations/" + id,
      delete: (id: number): string => "/api/organisations/" + id,
      join: (id: number): string => "/api/organisations/join/" + id,
    },
    orgRequests: {
      paginate: (): string => "/api/org-requests/paginate",
    },
    reports: {
      paginate: (): string => "/api/reports/paginate",
      all: (): string => "/api/reports/all",
      view: (id: number): string => "/api/reports/" + id,
      pdf: (uuid: string): string => "/api/reports/pdf/" + uuid,
      send: (id: number): string => "/api/reports/send/" + id,
    },
    incidents: {
      paginate: (): string => "/api/incidents/paginate",
      all: (): string => "/api/incidents/all",
      solve: (id: number): string => "/api/incidents/solve/" + id,
      count: (): string => "api/incidents/count",
      getIncidentTypes: (): string => "/api/incident-types",
      getIncidentTypesForFilter: (): string =>
        "/api/incident-types/with-trashed",
      addIncident: (): string => "/api/incidents",
      addIncidentTypeReport: (): string => "/api/incident/create-from-qr-form",
      createIncidentType: (): string => "/api/incident-types/",
      updateIncidentType: (id: number): string => "/api/incident-types/" + id,
      deleteIncidentType: (id: number): string => "/api/incident-types/" + id,
      startIncidentType: (qrToken: string, pin: null | string): string =>
        `/api/incident-types/get-from-qr-token?qr_token=${qrToken}${pin ? `&pin=${pin}` : ''}`,
    },
    logs: {
      paginate: (): string => "/api/logs/paginate",
      all: (): string => "/api/logs/all",
    },
    languages: {
      index: (): string => "/api/languages",
      available: (): string => "/api/languages/available",
      destroy: (id: number): string => "/api/languages/" + id,
      view: (id: number): string => "/api/languages/" + id,
      default: (): string => "/api/languages/default",
      translations: (languageCode: string | number): string =>
        "/api/languages/" + languageCode + "/frontend-translations",
      updateWords: (): string => "/api/languages/translations",

      count: (): string => "/api/admin/languages/count",
      supportedLanguages: (): string =>
        "/api/admin/languages/supportedLanguages",
      update: (id: number): string => "/api/admin/languages/" + id,

      select: (): string => "/api/admin/languages/for-select",
    },
    billing: {
      subscription: () => "/api/stripe/subscriptions",
      customer: () => "/api/stripe/customer",
      paymentMethod: () => "/api/stripe/payment-method",
      address: () => "/api/stripe/address",
      invoices: () => "/api/stripe/invoices/paginate",
      enterprise: () => "/api/stripe/enterprise-request",
      products: () => "/api/stripe/products",
      taxTypes: () => "/api/stripe/tax-types",
      countries: () => "/api/stripe/countries",
      intent: () => "/api/stripe/setup-intent",
      key: () => "/api/stripe/public-key",
    },
    profile: {
      update: (): string => "/api/profile",
      password: (): string => "/api/profile/password",
      settings: (): string => "/api/profile/settings",
    },
    statistics: {
      short: (): string => "api/statistics/short",
      default: (): string => "api/statistics/counters/default",
    },
    externalAccess: {
      paginate: (): string => "/api/external-accesses/paginate",
      create: (): string => "/api/external-accesses/",
      view: (externalAccessId: number): string => "/api/external-accesses/" + externalAccessId,
      all: (): string => "/api/external-accesses/all",
      index: (): string => "/api/external-accesses",
      update: (externalAccessId: number): string => "/api/external-accesses/" + externalAccessId,
      destroy: (externalAccessId: number): string => "/api/external-accesses/" + externalAccessId,
      changeStatus: (externalAccessId: number): string => "/api/external-accesses/change-status/"  + externalAccessId,
      sendEmail: (externalAccessId: number): string => "/api/external-accesses/send-pin/"  + externalAccessId
    },
  },
};
