import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import VueI18n from "vue-i18n";

export enum InputStyles {
  SEARCH = 'search',
  FIELD = 'field',
  AUTH = 'auth',
}

@Component
export default class InputPassword extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  public error!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public value!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public label!: string;

  @Prop({
    required: false,
    default: '',
  })
  public placeholder!: string | VueI18n.TranslateResult;

  @Prop({
    type: String,
    required: false,
    default: InputStyles.FIELD,
  })
  public appearance!: InputStyles;
}



