<style lang="scss" src="./ModalForAssignment.scss" scoped></style>

<template>
  <Modal :modal="!!incident" class="modalDeleteUser">
    <div class="header" slot="header">
      <div>{{ $t("app.views.incident.assignModal.title") }}</div>
      <div>
        {{
          incident.source === "inspection"
            ? incident?.report_name
            : incident?.name
        }}
      </div>
    </div>

    <div class="content" slot="content">
      <form>
        <input-select
          :error="formErrors.get('user_id')"
          :has-error="formErrors.hasErrors('user_id')"
          :value="user_id"
          :options="userOptions"
          @select="user_id = $event"
        />
      </form>
    </div>

    <div class="footer" slot="footer">
      <styles-button
        :isTransparentButton="true"
        @clickHandler="$emit('close', false)"
        :title="$t('app.views.incident.assignModal.goBack')"
      />
      <styles-button
        @clickHandler="assignToUser"
        :title="$t('app.views.incident.assignModal.assignButton')"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import InputSelect from "@/components/InputSelect/InputSelect.vue";
import FormErrors from "@/services/formErrors/FormErrors";
import Field from "@/components/Field/Field.vue";
import StylesButton from "@/components/Buttons/StylesButton.vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { http } from "@/services/http/http";
import Authorization from "@/services/authorization/authorization";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

export default {
  name: "ModalForAssignment",
  components: {
    Field,
    InputSelect,
    Modal,
    StylesButton,
  },
  props: {
    incident: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      formErrors: new FormErrors(),
      auth: new Authorization(),
      user_id: null,
      userSearchParams: {
        page: 1,
        per_page: 25,
        column: null,
        direction: null,
        search: null,
        organisation_id: null,
        role: null,
        locations_ids: [],
      },
    };
  },
  async created() {
    if (!!this.incident.location) {
      if (
        this.auth.isSystemAdmin(this.me) ||
        this.auth.isOrganisationAdmin(this.me)
      ) {
        this.userSearchParams.locations_ids = [this.incident.location.id];
      }
      await this.fetchUser(this.userSearchParams);
    } else {
      if (this.auth.isSystemAdmin(this.me)) {
        this.userSearchParams.organisation_id = this.incident.organisation_id;
      }
      await this.fetchUser(this.userSearchParams);
    }

    if (!!this.incident?.assigned_to) {
      this.user_id = this.incident?.assigned_to.id;
    }
  },
  computed: mapGetters({
    me: "me",
    userOptions: "userSelectOptions",
  }),
  methods: {
    ...mapActions({
      fetchUser: UserActions.FETCH_USERS,
    }),

    async assignToUser() {
      try {
        await http().put(`/api/incidents/assign-user/${this.incident.id}`, {
          user_id: this.user_id,
        });
        this.$emit("close", true);
      } catch (exception) {
        this.formErrors.setErrors(exception.response.data.errors);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    },
  },
};
</script>

