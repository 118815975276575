<template>
  <input-select
    :class="{'filter': isFilter, 'not_filter': !isFilter}"
    :has-error="hasError"
    :error="error"
    :placeholder="placeholder"
    :label="isFilter ? ($tc('app.views.language.language', 0) ?? '').capitalize() : ''"
    :value="languageId"
    :disabled="disabled"
    :options="languages"
    @select="$emit('select', $event)"
  />
</template>

<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/languages-module/action-types";

export default {
  name: "InputSelectLocation",
  props: {
    languageId: {
      required: true
    },
    isFilter: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    error: {
      required: false,
      type: String,
      default: null
    },
    hasError: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'loading'],
  computed: {
    ...mapGetters({
      languages: 'languageSelectOptions'
    }),
    placeholder() {
      return this.isFilter ? this.$t('app.views.language.allLanguages') : this.$t('components.select.selectLanguage')
    }
  },
  async created() {
    if (this.languages.length < 1) {
      await this.$store.dispatch(ActionTypes.FETCH_LANGUAGES);
    }
  },
}
</script>

<style>
/*.filter {*/
/*  max-width: 262px;*/
/*}*/
/*.not_filter {*/
/*  max-width: 310px;*/
/*}*/
</style>
