import {ActionTypes} from "./action-types"
import {ActionContext, ActionTree} from "vuex";
import {RootState} from "../../store";
import LanguagesState from "@/vuex/modules/languages-module/state";
import {http} from "@/services/http/http";
import {appRoutes} from "@/config";
import {MutationTypes} from "@/vuex/modules/languages-module/mutation-types";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import LanguageWord from "@/vuex/models/language/langauge-word";
import i18n from "@/lang/locale";

export interface LanguagesSearchParams {
    // sort: 'name' | 'organisation',
    // direction: 'asc' | 'desc',
    page: number,
    perPage: number,

}

export interface LanguageWordsGetParams {

    language_ids: number[];

}

export interface LanguageGetCountParams {

    language_ids: number[];

}

export interface LanguageSyncParams {
    locale: null | string

}


type LanguagesActionContext = ActionContext<LanguagesState, RootState>

const actions: ActionTree<LanguagesState, RootState> = {
    async [ActionTypes.FETCH_LANGUAGES](store: LanguagesActionContext, params: LanguagesSearchParams): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.languages.index(), {params});
            store.commit(MutationTypes.SET_LANGUAGES, response.data.data);

            return response.data.data
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    },

    async [ActionTypes.FETCH_TRANSLATIONS](store: LanguagesActionContext, langCode: string | number): Promise<any> {
        try {
            if (langCode == 'raw') {
                i18n.locale = langCode;

                return {languageCode: langCode, messages: []}
            }
            const response = await http().get(appRoutes.api.languages.translations(langCode));

            const {messages, languageCode} = response.data;
            i18n.setLocaleMessage(languageCode, messages);
            i18n.locale = languageCode;

            return response.data;
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    },

    async [ActionTypes.FETCH_AVAILABLE_LANGUAGES](store: LanguagesActionContext): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.languages.available());
            store.commit(MutationTypes.SET_AVAILABLE_LANGUAGES, response.data.data);
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    },

    async [ActionTypes.CREATE_LANGUAGE](store: LanguagesActionContext, params: {
        code: string,
        name: string
    }): Promise<void> {
        await http().post(appRoutes.api.languages.index(), {...params})
    },

    async [ActionTypes.DELETE_LANGUAGE](store: LanguagesActionContext, languageId: number): Promise<void> {
        await http().delete(appRoutes.api.languages.destroy(languageId));
    },

    async [ActionTypes.FETCH_LANGUAGE](store: LanguagesActionContext, languageId: number): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.languages.view(languageId));
            store.commit(MutationTypes.SET_LANGUAGE, response.data);
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    },

    async [ActionTypes.FETCH_DEFAULT_LANGUAGE](store: LanguagesActionContext): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.languages.default());
            store.commit(MutationTypes.SET_DEFAULT_LANGUAGE, response.data);
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    },

    async [ActionTypes.UPDATE_WORDS](state: LanguagesActionContext, words: LanguageWord[]): Promise<void> {
        await http().put(appRoutes.api.languages.updateWords(), {words: words});
    }
}

export default actions;

