import { MutationTree } from "vuex";
import InspectionReportsState from "@/vuex/modules/reports-module/state";
import ReportsState from "@/vuex/modules/reports-module/state";
import { MutationTypes } from "@/vuex/modules/reports-module/mutation-types";
import Report from "@/vuex/models/report/report";
import Meta from "@/vuex/models/meta";
import ReportFull from "@/vuex/models/report/report-full";

const mutations: MutationTree<InspectionReportsState> = {
  [MutationTypes.SET_REPORTS](state: ReportsState, reports: Report[]): void {
    state.reports = reports;
  },
  [MutationTypes.SET_REPORTS_FOR_CSV](
    state: ReportsState,
    reports: Report[]
  ): void {
    state.reportsForCsv = reports;
  },
  [MutationTypes.SET_REPORTS_META](state: ReportsState, meta: Meta): void {
    state.meta = meta;
  },
  [MutationTypes.SET_REPORT](
    state: ReportsState,
    report: ReportFull | null
  ): void {
    state.report = report;
  },
  [MutationTypes.SET_PDF](state: ReportsState, pdf: string): void {
    state.pdf = pdf;
  },
};

export default mutations;
