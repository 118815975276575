import AuthState from "./state";
import {GetterTree} from "vuex";
import {RootState} from "../../store";
import UserFull from "@/vuex/models/user/user-full";

const getters: GetterTree<AuthState, RootState> = {
    token(state: AuthState): string | null {
        return state.token;
    },

    me(state: AuthState): UserFull | null {
        return state.me;
    }
}

export default getters;
