import {Component, Vue} from 'vue-property-decorator';
import Meta from "@/vuex/models/meta";
import Authorization from "@/services/authorization/authorization";
import {mapGetters} from "vuex";
import UserFull from "@/vuex/models/user/user-full";
import sort, {SortHelper} from "@/services/helpers/sort";
import search, {SearchHelper} from "@/services/helpers/search";
import { ExportCsv } from '@/services/helpers/exportCsvFunction';

@Component({
    computed: mapGetters(['me']),
})
export class PageMixin extends Vue {
    protected readonly sort: SortHelper = sort;
    protected readonly search: SearchHelper = search;

    protected readonly auth: Authorization = new Authorization();
    protected readonly exportCsv = new ExportCsv();
    protected readonly me!: UserFull;

    protected loading: boolean = false;
    protected showForm: boolean = false;
    protected showDeleteModal: boolean = false;

    public pagination: Meta = {
        current_page: 1,
        last_page: 1,
        per_page: 25,
        from: 1,
        to: 1,
        total: 25
    }
}
