import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import IncidentsState from "@/vuex/modules/incidents-module/state";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { Direction, IncidentsSortableColumns } from "@/services/helpers/sort";
import { ActionTypes } from "@/vuex/modules/incidents-module/action-types";
import { MutationTypes } from "@/vuex/modules/incidents-module/mutation-types";
import { AxiosRequestConfig } from "axios";
import objectToFormData from "@/services/http/objectToFormData";
import IncidentData from "@/vuex/models/incident/incident-data";
import IncidentFormData from "@/vuex/models/incident/incident-form-data";

export interface IncidentsSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: IncidentsSortableColumns | null;
  search: string | null;
  solved: number | null;
  source: string | null;
  incident_type_id: number | null;
  location_id: number | null;
}

type IncidentsActionContext = ActionContext<IncidentsState, RootState>;

const actions: ActionTree<IncidentsState, RootState> = {
  async [ActionTypes.FETCH_INCIDENTS](
    store: IncidentsActionContext,
    params: IncidentsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.incidents.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_INCIDENTS, response.data.data);
      store.commit(MutationTypes.SET_INCIDENTS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_INCIDENTS_FOR_CSV](
    store: IncidentsActionContext,
    params: IncidentsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.incidents.all(), {
        params,
      });
      store.commit(MutationTypes.SET_INCIDENTS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.ADD_INCIDENT](
    store: IncidentsActionContext,
    params: {
      data: IncidentData;
    }
  ) {
    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const formData: FormData = objectToFormData(params.data);
    await http(headers).post(appRoutes.api.incidents.addIncident(), formData);
  },

  async [ActionTypes.ADD_INCIDENT_TYPE_REPORT](
    store: IncidentsActionContext,
    params: {
      data: IncidentFormData;
    }
  ) {
    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const formData: FormData = objectToFormData(params.data);
    await http(headers).post(
      appRoutes.api.incidents.addIncidentTypeReport(),
      formData
    );
  },

  async [ActionTypes.SOLVE_INCIDENT](
    store: IncidentsActionContext,
    incidentId: number
  ): Promise<void> {
    await http().get(appRoutes.api.incidents.solve(incidentId));
  },

  async [ActionTypes.FETCH_INCIDENTS_TYPES](
    store: IncidentsActionContext
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.incidents.getIncidentTypes()
      );
      store.commit(MutationTypes.SET_INCIDENTS_TYPES, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_INCIDENTS_TYPES_FOR_FILTER](
    store: IncidentsActionContext
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.incidents.getIncidentTypesForFilter()
      );
      store.commit(
        MutationTypes.SET_INCIDENTS_TYPES_FOR_FILTER,
        response.data.data
      );
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.COUNT_INCIDENTS](
    store: IncidentsActionContext
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.incidents.count());
      store.commit(MutationTypes.SET_INCIDENTS_COUNT, response.data.count);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_INCIDENT_TYPE](
    store: IncidentsActionContext,
    data
  ): Promise<void> {
    try {
      await http().post(appRoutes.api.incidents.createIncidentType(), data);
      await store.dispatch(ActionTypes.FETCH_INCIDENTS_TYPES);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "The incident type has been created",
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.UPDATE_INCIDENT_TYPE](
    store: IncidentsActionContext,
    data
  ): Promise<void> {
    try {
      await http().put(appRoutes.api.incidents.updateIncidentType(data.id), {
        name: data.value,
      });
      await store.dispatch(ActionTypes.FETCH_INCIDENTS_TYPES);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "The incident type has been updated",
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.DELETE_INCIDENT_TYPE](
    store: IncidentsActionContext,
    typeId: number
  ): Promise<void> {
    try {
      await http().delete(appRoutes.api.incidents.deleteIncidentType(typeId));
      await store.dispatch(ActionTypes.FETCH_INCIDENTS_TYPES);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "The incident type has been deleted",
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },
};

export default actions;
