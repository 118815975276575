<template>
  <div class="billing_address" :class="{'empty': ! address}">
    <AlternativeEmptyTable v-if="! address" :message="$t('app.views.billing.addBillingAddress').toString()"/>

    <div v-if="!!address" class="billing_address_info">
      <div class="billing_address_info_row">
        <span class="billing_address_info_row_title">
          {{ $t('app.views.billing.address.company') }}
        </span>
        <span class="billing_address_info_row_content">
          {{ address.billing_name }}
        </span>
      </div>
      <div class="billing_address_info_row">
        <span class="billing_address_info_row_title">
          {{ $t('app.views.billing.address.address') }}
        </span>
        <span class="billing_address_info_row_content">
          {{ fullAddress }}
        </span>
      </div>
      <div class="billing_address_info_row" v-if="!!customer && !!customer.tax_number">
        <span class="billing_address_info_row_title">
          {{ $t('app.views.billing.address.taxNumber') }}
        </span>
        <span class="billing_address_info_row_content">
          {{ customer.tax_number }}
        </span>
      </div>
      <div class="billing_address_info_row">
        <span class="billing_address_info_row_title">
          {{ $t('app.views.billing.address.email') }}
        </span>
        <span class="billing_address_info_row_content">
          {{ address.billing_email }}
        </span>
      </div>
    </div>
    <div v-if="!!address" class="billing_address_actions">
      <table-row-drop-down-menu>
        <template v-slot:content>
              <span class="controls-dropdown-button" @click="deleteAddress">
                {{ $t('components.delete').capitalize() }}
              </span>
        </template>
      </table-row-drop-down-menu>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import AlternativeEmptyTable from "@/components/RecordsTable/components/AlternativeEmptyTable.vue";

export default {
  name: "BillingAddress",
  emits: ['loading'],
  components: {AlternativeEmptyTable},
  computed: {
    ...mapGetters(['address', 'customer']),
    fullAddress() {
      let fullAddress = ''

      if (!!this.address) {
        if (!!this.address.line1) fullAddress += `${this.address.line1}, `;
        if (!!this.address.line2) fullAddress += `${this.address.line2}, `;
        if (!!this.address.postal_code) fullAddress += `${this.address.postal_code}, `;
        if (!!this.address.state) fullAddress += `${this.address.state}, `;
        if (!!this.address.city) fullAddress += `${this.address.city}, `;
        if (!!this.address.country) fullAddress += `${this.address.country}.`;
      }

      return fullAddress;
    }
  },
  methods: {
    async deleteAddress() {
      this.$emit('loading');
      await this.$store.dispatch(ActionTypes.DELETE_ADDRESS);
      this.$emit('loading');
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  padding: 60px 0 !important;
  display: block !important;
}

.billing_address {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000F;
  border-radius: 10px;

  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &_info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_row {
      display: flex;
      flex-direction: column;
      gap: 14px;

      &_title {
        text-align: left;
        font: normal normal 600 14px/21px Poppins;
        letter-spacing: 0;
        color: #0D0E34;
      }

      &_content {
        text-align: left;
        font: normal normal normal 12px/18px Poppins;
        letter-spacing: 0;
        color: #6D6D6D;
      }
    }
  }

  &_actions {
    width: 20px;
  }
}
</style>