export enum ActionTypes {
  FETCH_EXTERNAL_ACCESSES = "external-access-module/fetch-external-accesses",
  GET_EXTERNAL_ACCESSES = "external-access-module/get-external-accesses",
  CREATE_EXTERNAL_ACCESS = "external-access-module/create-external-access",
  UPDATE_EXTERNAL_ACCESS = "external-access-module/update-external-access",
  DELETE_EXTERNAL_ACCESS = "external-access-module/delete-external-access",
  FETCH_EXTERNAL_ACCESS = "external-access-module/fetch-external-access",
  CHANGE_STATUS_EXTERNAL_ACCESS = "external-access-module/allow-external-access",
  SEND_EMAIL_EXTERNAL_ACCESS = "external-access-module/deny-external-access",
}
