export type SearchHelper = (search: string, searchParams: any) => any | void;

const search: SearchHelper = function (search: string, searchParams: any): any | void {
    if (search.length > 2) {
        searchParams.search = search;

        return searchParams;
    }

    if (search.length < 3 && !!searchParams.search) {
        searchParams.search = null;

        return searchParams;
    }
}

export default search;
