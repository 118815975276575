import { ActionContext, ActionTree } from "vuex";
import OrganisationsState from "@/vuex/modules/organisations-module/state";
import store, { RootState } from "@/vuex/store";
import { ActionTypes } from "@/vuex/modules/organisations-module/action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "@/vuex/modules/organisations-module/mutation-types";
import Organisation from "@/vuex/models/organisation/organisation";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import {
  Direction,
  OrganisationsSortableColumns,
} from "@/services/helpers/sort";
import OrganisationData from "@/vuex/models/organisation/organisation-data";

export interface OrganisationSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: OrganisationsSortableColumns | null;
  search: string | null;
  timezone: string | null;
  allow_to_join: boolean | null;
  manual_payment: boolean | null;
  active: boolean | 1 | 0 | null;
}

type OrganisationsActionContext = ActionContext<OrganisationsState, RootState>;

const actions: ActionTree<OrganisationsState, RootState> = {
  async [ActionTypes.FETCH_ORGANISATIONS](
    state: OrganisationsActionContext,
    params: OrganisationSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.organisations.paginate(),
        { params }
      );
      state.commit(MutationTypes.SET_ORGANISATIONS, response.data.data);
      state.commit(MutationTypes.SET_ORGANISATIONS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_ORGANISATIONS_FOR_CSV](
    state: OrganisationsActionContext,
    params: OrganisationSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.organisations.all(),
        { params }
      );
      state.commit(MutationTypes.SET_ORGANISATIONS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_ORGANISATION](
    state: OrganisationsActionContext,
    params: OrganisationData
  ): Promise<Organisation> {
    const response = await http().post(appRoutes.api.organisations.index(), {
      ...params,
    });

    return response.data;
  },

  async [ActionTypes.UPDATE_ORGANISATION](
    state: OrganisationsActionContext,
    organisationData: OrganisationData
  ): Promise<Organisation> {
    if (!organisationData.id) {
      throw new Error();
    }

    const response = await http().put(
      appRoutes.api.organisations.update(organisationData.id),
      organisationData
    );

    return response.data;
  },

  async [ActionTypes.DELETE_ORGANISATION](
    state: OrganisationsActionContext,
    organisationId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.organisations.delete(organisationId));
  },

  async [ActionTypes.JOIN_ORGANISATION](
    state: OrganisationsActionContext,
    params: {
      organisationId: number;
      host: string;
    }
  ): Promise<void> {
    await http().post(
      appRoutes.api.organisations.join(params.organisationId),
      params
    );
  },
};

export default actions;
