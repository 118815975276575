import { render, staticRenderFns } from "./IncidentsView.vue?vue&type=template&id=4cc7b908&scoped=true"
import script from "./IncidentsView.ts?vue&type=script&lang=ts&external"
export * from "./IncidentsView.ts?vue&type=script&lang=ts&external"
import style0 from "./IncidentsView.scss?vue&type=style&index=0&id=4cc7b908&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc7b908",
  null
  
)

export default component.exports