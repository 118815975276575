import i18n from "@/lang/locale";

export const generateHeadersForAll = () => {
  return [
    i18n.t("components.table.date").toString(),
    i18n.t("components.table.allReportName").toString(),
    i18n.t("components.table.source").toString(),
    i18n.t("components.table.typeIncident").toString(),
    i18n.t("components.table.status").toString(),
    i18n.t("components.table.reportedBy").toString(),
    i18n.t("components.table.solved_date").toString(),
    i18n.t("components.table.solved_by").toString(),
    i18n.t("components.table.assignTo").toString(),
  ];
};

export const generateHeadersForInspections = () => {
  return [
    i18n.t("components.table.date").toString(),
    i18n.t("app.views.incident.nameIncidentTypeInspection").toString(),
    i18n.t("components.table.question").toString(),
    i18n.t("components.table.answer").toString(),
    i18n.t("components.table.status").toString(),
    i18n.t("components.table.reportedBy").toString(),
    i18n.t("components.table.solved_date").toString(),
    i18n.t("components.table.solved_by").toString(),
    i18n.t("components.table.assignTo").toString(),
  ];
};

export const generateHeadersForIncidents = () => {
  return [
    i18n.t("components.table.date").toString(),
    i18n.t("app.views.incident.nameIncidentTypeReported").toString(),
    i18n.t("components.table.typeIncident").toString(),
    i18n.t("components.table.site").toString(),
    i18n.t("components.table.reportedBy").toString(),
    i18n.t("components.table.assignTo").toString(),
    i18n.t("components.table.status").toString(),
    i18n.t("components.table.solved_date").toString(),
    i18n.t("components.table.solved_by").toString(),
  ];
};
