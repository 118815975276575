<template>
  <input-select
    :value="locationId"
    :with-search="auth.isSystemAdmin(me)"
    :options="locationSelectOptions"
    :disabled="!auth.isSystemAdmin(me) && locationSelectOptions.length === 1"
    class="select-locations"
    :class="isLongWidth ? 'long-select' : ''"
    :placeholder="$tc('app.views.selectForLocation.placeholder')"
    :search-placeholder="$tc('app.views.selectForLocation.searchPlaceholder')"
    @select="setLocation"
    @searchOptions="searchLocationOptions"
  ></input-select>
</template>

<script>
import { mapGetters } from "vuex";
import { ActionTypes } from "@/vuex/modules/locations-module/action-types";
import { Direction, LocationsSortableColumns } from "@/services/helpers/sort";
import Authorization from "@/services/authorization/authorization";
import { MutationTypes } from "@/vuex/modules/locations-module/mutation-types";

export default {
  props: {
    isLongWidth: {
      required: false,
      default: false,
    },
  },
  emits: ["select", "loading"],
  data() {
    return {
      auth: new Authorization(),
      locationId: null,
      searchParams: {
        page: 1,
        per_page: 100,
        direction: Direction.ASC,
        column: LocationsSortableColumns.NAME,
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters(["me", "locationSelectOptions"]),
  },
  async created() {
    if (this.locationSelectOptions.length < 1) {
      if (this.auth.isSystemAdmin(this.me)) {
        await this.searchLocationOptions();
      } else {
        await this.$store.dispatch(
          ActionTypes.FETCH_LOCATIONS,
          this.searchParams
        );
      }
    }

    if (
      !this.auth.isSystemAdmin(this.me) &&
      this.locationSelectOptions.length === 1
    ) {
      this.setLocation(this.locationSelectOptions[0].value);
    }
  },
  methods: {
    async searchLocationOptions(search) {
      if (this.auth.isSystemAdmin(this.me)) {
        this.searchParams.search = search;
        await this.$store.dispatch(
          ActionTypes.FETCH_LOCATIONS,
          this.searchParams
        );
      } else {
        await this.$store.commit(MutationTypes.SET_LOCATIONS, []);
      }
    },
    setLocation(locationId) {
      this.$emit("select", locationId);
      this.locationId = locationId;
    },
  },
};
</script>

<style scoped>
.select-locations {
  max-width: 300px;
}

.long-select {
  max-width: 400px;
  width: 100%;
}
</style>
