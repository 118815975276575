import {BillingState} from "./state";
import {GetterTree} from "vuex";
import {RootState} from "../../store";
import StripeCustomer from "@/vuex/models/billing/customer";
import StripeSubscription from "@/vuex/models/billing/subscription";
import StripePaymentMethod from "@/vuex/models/billing/payment-method";
import StripeAddress from "@/vuex/models/billing/address";
import StripeInvoice from "@/vuex/models/billing/invoice";
import Meta from "@/vuex/models/meta";

const getters: GetterTree<BillingState, RootState> = {
    customer(state: BillingState): StripeCustomer | null {
        return state.customer;
    },
    subscription(state: BillingState): StripeSubscription | null {
        return state.subscription;
    },
    paymentMethod(state: BillingState): StripePaymentMethod | null {
        return state.paymentMethod;
    },
    address(state: BillingState): StripeAddress | null {
        return state.address;
    },
    invoices(state: BillingState): StripeInvoice[] | [] | null {
        return state.invoices;
    },
    invoicesMeta(state: BillingState): Meta | null {
        return state.invoicesMeta;
    }
}

export default getters;