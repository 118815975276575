<template>
  <div class="inspection-settings">
    <div class="inspection-settings_info">
      <div class="inspection-settings_info_score">
        <h2>{{ $t("app.views.inspection.settings.checlyScore") }}</h2>
        <div class="inspection-settings_info_score_box">
          <div class="inspection-settings_info_score_switch">
            <input-switch
              :checked="settingsData.incidents_impact"
              v-model="settingsData.incidents_impact"
            />
          </div>
          <div class="inspection-settings_info_score_notifications">
            <span
              ><strong>{{
                $t("app.views.inspection.settings.scoreNotification")
              }}</strong></span
            >
            <span>{{
              $t("app.views.inspection.settings.defaultIncidentImpact")
            }}</span>
            <span>{{
              $t("app.views.inspection.settings.urgentIncidentImpact")
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="inspection-settings_action">
      <ButtonsSaveCancel
        :dont-show-close="true"
        :save-label="$t('components.save')"
        @save="submit"
      ></ButtonsSaveCancel>
    </div>
  </div>
</template>

<script>
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { ActionTypes } from "@/vuex/modules/inspections-module/action-types";
import { mapGetters } from "vuex";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

export default {
  name: "InspectionTab",
  emits: ["loading"],
  components: {
    ButtonsSaveCancel,
  },
  data() {
    return {
      settingsData: {
        incidents_impact: false,
      },
    };
  },
  computed: {
    ...mapGetters(["inspectionSettings"]),
  },
  async created() {
    this.$emit("loading");
    await this.$store.dispatch(ActionTypes.FETCH_INSPECTION_SETTINGS);

    if (!!this.inspectionSettings) {
      this.settingsData.incidents_impact =
        this.inspectionSettings.incidents_impact === 1;
    }
    this.$emit("loading");
  },
  methods: {
    async submit() {
      this.$emit("loading");

      try {
        await this.$store.dispatch(
          ActionTypes.UPDATE_INSPECTION_SETTINGS,
          this.settingsData
        );
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: this.$t("app.views.user.SettingsUpdatedAlert"),
          type: NotificationType.SUCCESS,
        });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.$emit("loading");
    },
  },
};
</script>

<style scoped lang="scss">
.inspection-settings {
  &_info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin-top: 40px;

    &_score {
      display: flex;
      flex-direction: column;
      gap: 30px;

      h2 {
        font: normal normal 600 12px/18px Poppins;
        letter-spacing: 0;
        color: #0d0e34;
      }

      &_box {
        display: grid;
        grid-template-columns: 15% 85%;
      }

      &_switch {
        padding: 6px;
      }

      &_notifications {
        display: flex;
        flex-direction: column;
        gap: 12px;

        span {
          font: normal normal normal 12px/18px Poppins;
          letter-spacing: 0;
          color: #6d6d6d;
        }
      }
    }
  }

  &_action {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
</style>
