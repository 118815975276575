<template>
  <div class="AnswerOptions" v-if="showOptions">
    <div
      v-for="(answerOption, i) of question.answer_options"
      :key="i"
      v-if="answerOption.answer_option !== NOT_APPLICABLE_OPTION()"
    >
      <div class="AnswerOption AnswerOptionWithPosition">
        <div class="Column">
          {{ translator.translateAnswerOption(answerOption.answer_option) }}
        </div>

        <div class="Column">
          <InputCheckbox
            v-model="answerOption.has_incident"
            @input="toggleIncident(answerOption, $event)"
            :disabled="disabled"
          />
        </div>

        <div class="Column">
          <InputSelect
            v-if="!!answerOption.incident_level"
            class="question-select"
            is-minimal
            :isChagneStylesForTemplate="true"
            :disabled="disabled"
            :options="incidentLevels"
            :value="answerOption.incident_level"
            @select="answerOption.incident_level = $event"
          />
        </div>

        <div class="Column">
          <input
            class="score-points-input"
            type="text"
            v-model="answerOption.score_points"
            :disabled="
              disabled || questionType !== TemplateQuestionType.SINGLE_CHOICE
            "
          />

          <SvgIcon
            v-if="
              !disabled && questionType === TemplateQuestionType.SINGLE_CHOICE
            "
            name="trash"
            class="TrashIcon"
            width="24"
            height="24"
            @click="removeOption(i)"
          />
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div v-if="isSingleChoice && !disabled">
      <div class="add_option">
        <img
          src="/images/icons/template/plus.svg"
          alt="plus_icon"
          @click="addOption"
        />
        <input
          type="text"
          class="add-option-input"
          :placeholder="$t('app.views.library.form.addOption')"
          v-model="newAnswerOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "@/components/InputCheckbox/InputCheckbox.vue";
import { TemplateQuestionType } from "@/vuex/models/template/template-question-type";
import { TemplateIncidentLevel } from "@/vuex/models/template/template-incident-level";
import { NOT_APPLICABLE_OPTION } from "@/vuex/models/template/template-answer-option";
import { Translator } from "@/services/translator/translator";
import i18n from "@/lang/locale";

export default {
  name: "AnswerOptions",
  components: { InputCheckbox },
  emits: ["update:question"],
  props: {
    question: {
      required: true,
    },
    formErrors: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    questionDragActive: {
      required: true,
      type: Boolean,
    },
    sectionDragActive: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      incidentLevels: [
        {
          label: i18n
            .t("app.views.library.form.incidentLevels.default")
            .toString(),
          value: TemplateIncidentLevel.DEFAULT,
        },
        {
          label: i18n
            .t("app.views.library.form.incidentLevels.urgent")
            .toString(),
          value: TemplateIncidentLevel.URGENT,
        },
      ],
      newAnswerOption: null,
      translator: new Translator(),
    };
  },
  computed: {
    TemplateQuestionType() {
      return TemplateQuestionType;
    },
    questionType() {
      return this.question.type;
    },
    questionReverseScore() {
      return this.question.reverse_score;
    },
    isSingleChoice() {
      return TemplateQuestionType.SINGLE_CHOICE === this.question.type;
    },
    showOptions() {
      return (
        TemplateQuestionType.YES_NO === this.question.type ||
        TemplateQuestionType.SCALE_1_TO_5 === this.question.type ||
        TemplateQuestionType.SCALE_1_TO_10 === this.question.type ||
        this.isSingleChoice
      );
    },
    yesNoOptions() {
      return [
        {
          answer_option: i18n.t("components.yes").toString(),
          order: 1,
          incident_level: null,
          has_incident: false,
          score_points: 1.0,
        },
        {
          answer_option: i18n.t("components.no").toString(),
          order: 2,
          incident_level: null,
          has_incident: false,
          score_points: 0.0,
        },
      ];
    },
    scaleOneToFiveOptions() {
      let options = [];
      for (let i = 0; i < 5; i++) {
        options.push({
          answer_option: i + 1,
          order: i + 1,
          incident_level: null,
          has_incident: false,
          score_points: (0.2 * (i + 1)).toFixed(1),
        });
      }
      return options;
    },
    scaleOneToTenOptions() {
      let options = [];
      for (let i = 0; i < 10; i++) {
        options.push({
          answer_option: i + 1,
          order: i + 1,
          incident_level: null,
          has_incident: false,
          score_points: (0.1 * (i + 1)).toFixed(1),
        });
      }
      return options;
    },
    questionNotApplicableOption() {
      return this.question.answer_options.find(
        (answerOption) => answerOption.answer_option === NOT_APPLICABLE_OPTION
      );
    },
  },
  created() {
    if (this.question.answer_options.length < 1) {
      this.setAnswerOptions();
    }

    this.question.answer_options.forEach((option) => {
      option.has_incident = null !== option.incident_level;
    });
  },
  methods: {
    TemplateQuestionTypesOptions() {
      return [
        {
          label: i18n.t("app.views.library.questionTypes.yesNo").toString(),
          value: TemplateQuestionType.YES_NO,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.scaleUpTo5")
            .toString(),
          value: TemplateQuestionType.SCALE_1_TO_5,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.scaleUpTo10")
            .toString(),
          value: TemplateQuestionType.SCALE_1_TO_10,
        },
        {
          label: i18n.t("app.views.library.questionTypes.number").toString(),
          value: TemplateQuestionType.NUMBER,
        },
        {
          label: i18n.t("app.views.library.questionTypes.text").toString(),
          value: TemplateQuestionType.TEXT,
        },
        {
          label: i18n.t("app.views.library.questionTypes.textarea").toString(),
          value: TemplateQuestionType.COMMENT,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.singleChoice")
            .toString(),
          value: TemplateQuestionType.SINGLE_CHOICE,
        },
        {
          label: i18n.t("app.views.library.questionTypes.signature").toString(),
          value: TemplateQuestionType.SIGNATURE,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.geolocation")
            .toString(),
          value: TemplateQuestionType.GEOLOCATION,
        },
      ];
    },
    NOT_APPLICABLE_OPTION() {
      return NOT_APPLICABLE_OPTION;
    },
    setAnswerOptions() {
      switch (this.question.type) {
        case TemplateQuestionType.YES_NO:
          this.question.answer_options = this.yesNoOptions;
          break;
        case TemplateQuestionType.SCALE_1_TO_5:
          this.question.answer_options = this.scaleOneToFiveOptions;
          break;
        case TemplateQuestionType.SCALE_1_TO_10:
          this.question.answer_options = this.scaleOneToTenOptions;
          break;
        default:
          this.question.answer_options = [];
      }

      if (this.question.reverse_score) {
        this.reverseScorePoints();
      }
    },
    reverseScorePoints() {
      let notApplicableOptionWasRemoved = false;
      if (!!this.questionNotApplicableOption) {
        const nAIndex = this.question.answer_options.indexOf(
          this.questionNotApplicableOption
        );
        this.question.answer_options.splice(nAIndex, 1);
        notApplicableOptionWasRemoved = true;
      }

      let scorePoints = this.question.answer_options
        .map((option) => option.score_points)
        .reverse();

      this.question.answer_options.forEach((option, i) => {
        option.score_points = scorePoints[i];
      });

      if (notApplicableOptionWasRemoved) {
        this.question.answer_options.push({
          answer_option: NOT_APPLICABLE_OPTION,
          has_incident: false,
          incident_level: null,
          order: this.question.answer_options.length + 1,
          score_points: 0,
        });
      }
    },
    toggleIncident(answerOption, value) {
      answerOption.has_incident = value;

      if (value) {
        answerOption.incident_level = TemplateIncidentLevel.DEFAULT;
        this.question.has_incident = 1;
      } else {
        answerOption.incident_level = null;
        const nullOptions = this.question.answer_options.filter((option) => {
          return option.incident_level === null;
        });
        if (nullOptions.length === this.question.answer_options.length) {
          this.question.has_incident = 0;
        }
      }
    },
    addOption() {
      if (!!this.newAnswerOption) {
        this.question.answer_options.push({
          answer_option: this.newAnswerOption,
          order: this.question.answer_options.length + 1,
          incident_level: null,
          has_incident: false,
          score_points: 0,
        });

        this.newAnswerOption = null;
      }
    },
    removeOption(index) {
      this.question.answer_options.splice(index, 1);
    },
  },
  watch: {
    questionType: {
      handler(newVal, oldVal) {
        if (
          newVal !== oldVal &&
          !this.questionDragActive &&
          !this.sectionDragActive
        ) {
          this.setAnswerOptions();
        }
      },
    },
    questionReverseScore: {
      handler() {
        if (!this.questionDragActive && !this.sectionDragActive) {
          this.reverseScorePoints();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.AnswerOptions {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  color: #6d6d6d;

  .Column:first-child {
    padding-left: 7px;
  }

  & > .Header {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  .AnswerOption {
    width: 100%;
    display: flex;
    align-items: center;

    height: 54px;

    padding: 15px 24px 15px 65px;

    & > .Column:nth-child(1) {
      width: 60%;
    }

    & > .Column:nth-child(2) {
      width: 10%;

      display: flex;
      justify-content: center;
    }

    & > .Column:nth-child(3) {
      width: 10%;
    }

    & > .Column:last-child {
      display: flex;
      justify-content: end;
      width: 20%;
    }
  }

  .question-select {
    width: 100px;
  }
}
.line {
  border: 1px solid #e6e6e6;
  margin: 0 20px 0 65px;
}

.add_option {
  padding: 15px 24px 0 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    cursor: pointer;
  }

  input {
    border: 1px solid transparent;
    border-radius: 3px;
    color: #6d6d6d;
    font: normal normal normal 12px/18px Poppins;

    &:focus::placeholder {
      color: transparent;
    }

    &:hover,
    &:focus {
      border: 1px solid #cecece;
    }
  }
}

.AnswerOptionWithPosition {
  position: relative;

  .TrashIcon {
    position: absolute;

    left: 35px;
  }
}

.score-points-input {
  font-size: 12px;
  color: #6d6d6d;
  border: 1px solid #cecece;
  border-radius: 3px;

  text-align: center;

  width: 51px !important;
  height: 24px;

  &:disabled {
    border: none;
    color: #6d6d6d;
    opacity: 0.5;
  }
}
</style>
