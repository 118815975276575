import { render, staticRenderFns } from "./NotificationAboutAddNewUser.vue?vue&type=template&id=5e061252&scoped=true"
import script from "./NotificationAboutAddNewUser.ts?vue&type=script&lang=ts&external"
export * from "./NotificationAboutAddNewUser.ts?vue&type=script&lang=ts&external"
import style0 from "./NotificationAboutAddNewUser.scss?vue&type=style&index=0&id=5e061252&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e061252",
  null
  
)

export default component.exports