import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { TminMaxValue } from "../ChartDataLayout/types";
import i18n from "@/lang/locale";

dayjs.extend(utc);

@Component
export default class ChartLayoutForFilters extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public title!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  public isDefaultChart!: boolean;

  @Prop({
    type: Number,
    required: false,
  })
  public id!: number;

  @Prop({
    type: Boolean,
    required: false,
  })
  public isDefaultDataOnChart!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  public isDashboardChart!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  public isShowLineTrend!: boolean;

  @Prop({
    type: Number,
    required: false,
  })
  public type!: number;

  @Prop({
    type: String,
    required: false,
  })
  public comparisonState!: "bigger" | "lower" | "default";

  @Prop({
    type: Number,
    required: false,
  })
  public filterNumberValue!: number | null;

  @Prop({
    type: Object,
    required: false,
  })
  public minMaxValue!: TminMaxValue;

  public isOpenSelect: boolean = false;

  private comparisonOptions = [
    {
      label: i18n.t("app.views.statistics.typeDefault").toString(),
      value: "default",
    },
    {
      label: i18n.t("app.views.statistics.typeBigger").toString(),
      value: "bigger",
    },
    {
      label: i18n.t("app.views.statistics.typeLower").toString(),
      value: "lower",
    },
  ];

  setComparison(value: string) {
    this.$emit("setComparison", value);
    this.isOpenSelect = false;
  }

  private datesOptions = [
    {
      title: i18n.t("app.views.chartFilterPanel.today").toString(),
      startDate: dayjs.utc().startOf("day"),
      endDate: dayjs.utc().endOf("day"),
    },
    {
      title: i18n.t("app.views.chartFilterPanel.yesterday").toString(),
      startDate: dayjs.utc().add(-1, "day").startOf("day"),
      endDate: dayjs.utc().add(-1, "day").endOf("day"),
    },
    {
      title: "7D",
      startDate: dayjs().add(-7, "day"),
      endDate: dayjs(),
    },
    {
      title: "30D",
      startDate: dayjs().add(-1, "month"),
      endDate: dayjs(),
    },
    {
      title: "3M",
      startDate: dayjs().add(-3, "month"),
      endDate: dayjs(),
    },
    {
      title: "6M",
      startDate: dayjs().add(-6, "month"),
      endDate: dayjs(),
    },
    {
      title: "12M",
      startDate: dayjs().add(-12, "month"),
      endDate: dayjs(),
    },
  ];

  public activeFilter = this.datesOptions[2];

  private setActiveFilter(value: any) {
    this.activeFilter = value;
    if (this.isDefaultChart) {
      this.$emit("updateDefaultChart", this.activeFilter);
    }
    if (!this.isDefaultChart) {
      this.$emit("updateChart", this.activeFilter);
    }
  }

  closeSelect() {
    this.isOpenSelect = false;
  }

  private handleDocumentClick(event: any) {
    const selectWrapper = this.$refs.selectWrapper as HTMLElement;

    if (selectWrapper && !selectWrapper.contains(event.target)) {
      this.closeSelect();
    }
  }

  @Watch("isDefaultDataOnChart", { immediate: true, deep: true })
  activeDefaultData() {
    if (this.isDefaultDataOnChart) {
      this.activeFilter = this.datesOptions[2];
      this.setComparison("default");
      this.$emit("setIsShowLineTrend", false);
    }
  }

  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  beforeMount() {
    if (this.isDefaultChart) {
      this.$emit("updateDefaultChart", this.activeFilter);
    }
  }
}
