<template>
  <div ref="dropDownBlock" class="wrapper-icon">
    <div class="filter-button-block">
      <img
        @click="handleIsOpenDropDown"
        class="export-csv_icon"
        src="/images/icons/export-csv.svg"
        alt="export csv icon"
      />
      <div v-if="isOpenDropDown">
        <div @click.stop="$emit('exportCsvFile')" class="dropDown-block">
          <span>{{ $t("components.button.exportCsv") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DropDown",
  data() {
    return {
      isOpenDropDown: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.closeDropDown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropDown);
  },
  methods: {
    handleIsOpenDropDown() {
      this.isOpenDropDown = !this.isOpenDropDown;
    },
    closeDropDown(event) {
      if (
        this.$refs.dropDownBlock &&
        !this.$refs.dropDownBlock.contains(event.target)
      ) {
        this.isOpenDropDown = false;
      }
    },
  },
};
</script>
  
<style lang="scss">
.wrapper-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.export-csv_icon {
  cursor: pointer;
}

.filter-button-block {
  position: relative;
  line-height: 0;
}

.dropDown-block {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 30px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000f;
  border: 1px solid #e6e6e6;
  border-radius: 3px;

  display: flex;
  align-items: center;

  width: 141px;
  height: 44px;
  padding: 0px 13px 0px 19px;

  cursor: pointer;

  span {
    font-size: 12px;
    line-height: 15px;
    color: #6d6d6d;
  }
}
</style>
  