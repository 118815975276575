<template>
  <div class="security">
    <h5 class="security_header">{{ $t('auth.password') }}</h5>
    <div class="security_form">
      <div>
        <input-text
            :label="$t('app.views.user.newPassword').capitalize()"
            type="password"
            id="password"
            v-model="passwordForm.password"
            :hasError="formErrors.hasErrors('password')"
            :error="$t(formErrors.get('password')).toString()"
        />
      </div>
      <div>
        <input-text
            :label="$t('app.views.user.repeatNewPassword').capitalize()"
            type="password"
            id="password_confirmation"
            v-model="passwordForm.password_confirmation"
            :hasError="formErrors.hasErrors('password_confirmation')"
            :error="$t(formErrors.get('password_confirmation')).toString()"
        />
      </div>
    </div>
    <div class="security_action">
      <ButtonsSaveCancel
          :dont-show-close="true"
          :save-label="$t('components.save')"
          @save="submit"
      ></ButtonsSaveCancel>
    </div>
  </div>
</template>

<script>
import FormErrors from "@/services/formErrors/FormErrors";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {ActionTypes} from "@/vuex/modules/profile-module/action-types";

export default {
  name: "SecurityTab",
  emits: ['loading'],
  components: {ButtonsSaveCancel},
  data() {
    return {
      formErrors: new FormErrors(),
      passwordForm: {
        password: "",
        password_confirmation: ""
      }
    }
  },
  methods: {
    async submit() {
      this.$emit('loading');
      this.formErrors.clearErrors();

      try {
        await this.$store.dispatch(ActionTypes.UPDATE_PASSWORD, this.passwordForm);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: 'Password has been updated.',
          type: NotificationType.SUCCESS
        });

        this.dropPasswordValues();
      } catch (exception) {
        if (exception.response.status === 422) {
          this.formErrors.setErrors(exception.response.data.errors);
        } else {
          await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
            text: exception.response.data.message,
            type: NotificationType.DANGER
          });
        }
      }

      this.$emit('loading');
    },
    dropPasswordValues() {
      this.passwordForm.password = '';
      this.passwordForm.password_confirmation = '';
    }
  }
}
</script>

<style scoped lang="scss">
.security {
  margin-top: 40px;

  &_header {
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0;
    color: #0D0E34;
    margin-left: 20px;
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
  }

  &_action {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
</style>