export enum ActionTypes {
    FETCH_LANGUAGES = "languages-module/fetch-languages",
    FETCH_TRANSLATIONS = "languages-module/fetch-translations",
    FETCH_AVAILABLE_LANGUAGES = "languages-module/fetch-available-languages",
    CREATE_LANGUAGE = "languages-module/create-languages",
    DELETE_LANGUAGE = "languages-module/delete-language",
    FETCH_LANGUAGE = "languages-module/fetch-language",
    FETCH_DEFAULT_LANGUAGE = "languages-module/fetch-default-language",
    UPDATE_WORDS = "languages-module/update-words",
}
