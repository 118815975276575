import Vue from "vue";
import VueRouter from "vue-router";
import "chart.js/auto";
import router from "./routes";
import Application from "./components/Application/Application.vue";
import store from "./vuex/store";
import InputText from "./components/InputText/InputText.vue";
import InputPassword from "./components/InputPassword/InputPassword.vue";
import InputSelect from "./components/InputSelect/InputSelect.vue";
import InputSelectMultiple from "./components/InputSelectMultiple/InputSelectMultiple.vue";
import InputDate from "./components/InputDate/InputDate.vue";
import VLogo from "./components/Logo.vue";
import InputNumber from "@/components/InputNumber/InputNumber.vue";
import Pagination from "./components/Pagination/Pagination.vue";
import RightSideNav from "./components/RightSideNav/RightSideNav.vue";
import TableRowDropDownMenu from "./components/TableRowDropDownMenu/TableRowDropDownMenu.vue";
import RecordsTable from "./components/RecordsTable/RecordsTable.vue";
import InputSelectLocation from "./components/InputSelectLocation/InputSelectLocation.vue";
import InputSelectLanguage from "@/components/InputSelectLanguage/InputSelectLanguage.vue";
import draggable from "vuedraggable";

import { MutationTypes as AuthMutations } from "./vuex/modules/auth-module/mutation-types";
import { ActionTypes as AuthActions } from "./vuex/modules/auth-module/action-types";
import { ActionTypes as BrowserActions } from "./vuex/modules/browser-module/action-types";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import UserAvatar from "@/components/UserAvatar/UserAvatar.vue";
import AppNavBar from "@/components/AppNavBar/AppNavBar.vue";
import SortableTh from "@/components/SortableTh/SortableTh.vue";
import InputRadio from "@/components/InputRadio/InputRadio.vue";
import InputSwitch from "@/components/InputSwitch/InputSwitch.vue";
import LoadingSpinner from "@/components/LoadingSpinner/LoadingSpinner.vue";
import PopUp from "@/components/PopUp/PopUp.vue";
import TableViewLayout from "@/layouts/TableViewLayout/TableViewLayout.vue";
import SearchInput from "@/layouts/TableViewLayout/SearchInput.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import "./icons.js";
import "@/asesst/styles/app.scss";
import i18n from "./lang/locale";
import { Lang } from "@/services/lang/lang";

Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Vue.use(VueRouter);

Vue.component("InputText", InputText);
Vue.component("InputPassword", InputPassword);
Vue.component("Pagination", Pagination);
Vue.component("RightSideNav", RightSideNav);
Vue.component("TableRowDropDownMenu", TableRowDropDownMenu);
Vue.component("InputSelect", InputSelect);
Vue.component("InputSelectLocation", InputSelectLocation);
Vue.component("InputSelectLanguage", InputSelectLanguage);
Vue.component("InputSelectMultiple", InputSelectMultiple);
Vue.component("InputDate", InputDate);
Vue.component("InputNumber", InputNumber);
Vue.component("RecordsTable", RecordsTable);
Vue.component("ButtonWithIcon", ButtonWithIcon);
Vue.component("Draggable", draggable);
Vue.component("UserAvatar", UserAvatar);
Vue.component("AppNavBar", AppNavBar);
Vue.component("SortableTh", SortableTh);
Vue.component("InputRadio", InputRadio);
Vue.component("InputSwitch", InputSwitch);
Vue.component("LoadingSpinner", LoadingSpinner);
Vue.component("PopUp", PopUp);
Vue.component("TableViewLayout", TableViewLayout);
Vue.component("SearchInput", SearchInput);
Vue.component("SvgIcon", SvgIcon);
Vue.component("VLogo", VLogo);

const mount = () =>
  new Vue({
    components: {
      Application,
    },
    router,
    store,
    i18n,
  }).$mount("#application");

const token = localStorage.getItem("token");
const expiry = localStorage.getItem("expiry");
const promises = [];
const lang: Lang = new Lang();

if (!!token && !!expiry) {
  store.commit(AuthMutations.SET_TOKEN, { token: token, expiry: expiry });
  await store.dispatch(AuthActions.FETCH_ME);
  await store.dispatch(BrowserActions.APPLY_THEME);
  promises.push(lang.loadLang(store.getters.me));
} else {
  promises.push(lang.loadLang());
}

let app;
Promise.all(promises).then((response) => {
  app = mount();
});
