import {MutationTree} from "vuex";
import StatisticsState from "./state";
import {MutationTypes} from "@/vuex/modules/statistics-module/mutation-types";
import ShortStatistics from "@/vuex/models/statistics/short";

const mutations: MutationTree<StatisticsState> = {
    [MutationTypes.SET_SHORT_STATISTICS](state: StatisticsState, shortStatistics: ShortStatistics): void {
        state.short = shortStatistics;
    }
}

export default mutations;
