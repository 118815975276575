import store from "@/vuex/store";
import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component
export default class AppNavBar extends Vue {

  public isNavOpen = false;
  private isAuthenticated: boolean = store.getters.isAuthenticated;

  public OpenNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  public CloseNav() {
    this.isNavOpen = false;
  }

  private smoothScroll(id: any) {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
    this.CloseNav();
  }

  public getUrl(url: string) {
    return window.location.origin + '/images/homepage/' + url;
  }
}
