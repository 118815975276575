<template>
  <Modal class="modal-styles" :modal="isOpenNotificationModal">
    <template v-slot:header>
      <div></div>
    </template>
    <template v-slot:content>
      <div class="content-text">
        {{ $t("app.views.user.subscriptionExpired.notification") }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="wrapper-buttons">
        <button class="close-button" @click="$emit('close')">
          {{ $t("components.close") }}
        </button>
      </div>
    </template>
  </Modal>
</template>
    
    <script>
import Modal from "@/components/Modal/Modal.vue";

export default {
  name: "NotificationModal",
  components: {
    Modal,
  },
  props: {
    isOpenNotificationModal: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.content-text {
  padding: 0 90px;
  text-align: center;
  color: #6d6d6d;
  font-size: 14px;
  margin-bottom: 80px;
}

.close-button {
  height: 48px;
  border: 2px solid #1de591;
  border-radius: 122px;
  outline: none;
  cursor: pointer;

  text-align: center;
  font-size: 14px;
  color: #1de591;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;

  background-color: white;

  display: flex;
  align-items: center;
  padding: 0 50px;

  transition: all 0.3s;
  &:hover {
    background-color: #1de591;
    color: white;
    transition: all 0.3s;
  }
}

.wrapper-buttons {
  width: 100%;
  display: flex;
  justify-content: start;
}
</style>