<template>
  <Modal
    :isWidth="1074"
    class="modal-styles"
    :modal="modal"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <div class="comment-title">
        {{ $t("app.views.inspection.execution.addComment") }}
      </div>
    </template>

    <template v-slot:content>
      <textarea
        :placeholder="$t('app.views.inspection.execution.yourComment')"
        v-model="currentComment"
      ></textarea>
    </template>

    <template v-slot:footer>
      <div class="wrapper-buttons">
        <div class="MobileButtons">
          <ButtonsSaveCancel
            @close="$emit('cancelComment')"
            @save="$emit('addComment', currentComment)"
          />
        </div>
        <div class="DesktopButtons">
          <ButtonsSaveCancel
            @close="$emit('cancelComment')"
            @save="$emit('addComment', currentComment)"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";

export default {
  name: "CommentModal",
  components: {
    Modal,
    ButtonsSaveCancel,
  },
  data() {
    return {
      currentComment: "",
    };
  },
  props: {
    modal: {
      required: true,
      type: Boolean,
    },
    comment: {
      required: true,
    },
  },
  watch: {
    modal(value) {
      if (value) this.currentComment = this.comment;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/InspectionExecutionView/InspectionExecutionView.scss';
.comment-title {
  color: #0d0e34;
  font-size: 18px;
  text-align: left;
  width: 100%;
  font-weight: 600;
}

.wrapper-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

textarea {
  margin-top: 50px;
  padding: 1px;
  border: none;
  outline: none;
  width: 100%;
  height: 220px;
  resize: none;
}
</style>
