import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Component } from "vue-property-decorator";
import { ActionTypes as AuthActions } from "@/vuex/modules/auth-module/action-types";
import { UserProfileTabs } from "@/vuex/models/userProfile";
import PersonalDataTab from "@/views/UserProfile/components/PersonalDataTab.vue";
import NotificationModal from "@/views/UserProfile/components/NotificationModal/NotificationModal.vue";
import OrganisationTab from "@/views/UserProfile/components/OrganisationTab.vue";
import SecurityTab from "@/views/UserProfile/components/SecurityTab.vue";
import AppSettingsTab from "@/views/UserProfile/components/AppSettingsTab.vue";
import BillingTab from "@/views/UserProfile/components/BillingTab.vue";
import InspectionTab from "@/views/UserProfile/components/InspectionTab.vue";
import IncidentTab from "@/views/UserProfile/components/IncidentTab.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import Authorization from "@/services/authorization/authorization";
import UserFull from "@/vuex/models/user/user-full";

@Component({
  components: {
    PersonalDataTab,
    OrganisationTab,
    SecurityTab,
    AppSettingsTab,
    BillingTab,
    ButtonsSaveCancel,
    InspectionTab,
    IncidentTab,
    NotificationModal,
  },
  computed: mapGetters({
    me: "me",
  }),
  methods: {
    ...mapActions({
      fetchMe: AuthActions.FETCH_ME,
    }),
  },
})
export default class UserProfile extends Vue {
  private readonly fetchMe!: () => Promise<void>;

  protected readonly me!: UserFull;
  protected readonly auth: Authorization = new Authorization();

  protected isOpenNotificationModal: boolean = false;
  protected loading: boolean = false;
  protected currentTab: string = UserProfileTabs.PERSONAL_DATA;

  async created() {
    if (
      (this.auth.isInspector(this.me) || this.auth.isLocationAdmin(this.me)) &&
      this.subscriptionExpired(this.me)
    ) {
      this.isOpenNotificationModal = true;
    }
    if (!this.me) {
      await this.fetchMe();
    }

    if (!!this.$route.query.tab) {
      this.currentTab = this.$route.query.tab.toString();
    }
  }

  subscriptionExpired(user: UserFull | undefined) {
    if (!!user && !!user.stripe_customer) {
      return (
        !user.stripe_customer.on_trial &&
        !user.stripe_customer.has_active_subscription
      );
    }
    return false;
  }

  public async logout() {
    await this.$store.dispatch(AuthActions.LOGOUT);
  }

  private async setQueryParams() {
    await this.$router.replace({
      query: { ...this.$route.query, tab: this.currentTab },
    });
  }

  private async switchTab(tab: string): Promise<void> {
    this.currentTab = tab;
    await this.setQueryParams();
  }

  get tabs(): Array<string> {
    const tabs = [UserProfileTabs.PERSONAL_DATA];

    if (!this.auth.isSystemAdmin(this.me)) {
      tabs.push(UserProfileTabs.ORGANISATION);
    }

    if (this.auth.isOrganisationAdmin(this.me)) {
      tabs.push(UserProfileTabs.INSPECTION);
      tabs.push(UserProfileTabs.INCIDENT);
      tabs.push(UserProfileTabs.BILLING);
    }

    tabs.push(UserProfileTabs.SECURITY);
    tabs.push(UserProfileTabs.APP_SETTINGS);

    return tabs;
  }

  get currentTabComponent(): string | undefined {
    if (!this.currentTab) return;

    let currentTabComponents: { [key: string]: string } = {
      [UserProfileTabs.PERSONAL_DATA]: "PersonalDataTab",
      [UserProfileTabs.ORGANISATION]: "OrganisationTab",
      [UserProfileTabs.INSPECTION]: "InspectionTab",
      [UserProfileTabs.INCIDENT]: "incidentTab",
      [UserProfileTabs.BILLING]: "BillingTab",
      [UserProfileTabs.SECURITY]: "SecurityTab",
      [UserProfileTabs.APP_SETTINGS]: "AppSettingsTab",
    };

    return currentTabComponents[this.currentTab];
  }
}
