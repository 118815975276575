import { PaymentPlan } from "./paymentPlan";

export const generateTranslationForPlan = (slug: string) => {
  switch (slug) {
    case PaymentPlan.ENTERPRISE:
      return "stripe.plans.name.enterprise";
    case PaymentPlan.PRO:
      return "stripe.plans.name.pro";
    case PaymentPlan.TEAM:
      return "stripe.plans.name.team";
    default:
      return "stripe.plans.name.trial";
  }
};
