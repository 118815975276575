import {MutationTree} from "vuex";
import {MutationTypes} from "./mutation-types";
import InspectionsState from "@/vuex/modules/inspections-module/state";
import InspectionFull from "@/vuex/models/inspection/inspection-full";
import Meta from "@/vuex/models/meta";
import InspectionExecution from "@/vuex/models/inspection/inspection-execution";
import InspectionSetting from "@/vuex/models/inspection/inspection-setting";


const mutations: MutationTree<InspectionsState> = {
    [MutationTypes.SET_INSPECTIONS](state: InspectionsState, inspections: InspectionFull[]): void {
        state.inspections = inspections;
    },

    [MutationTypes.SET_INSPECTIONS_FOR_CSV](state: InspectionsState, inspections: InspectionFull[]): void {
        state.inspectionsForCsv = inspections;
    },

    [MutationTypes.SET_INSPECTIONS_META](state: InspectionsState, meta: Meta): void {
        state.meta = meta;
    },

    [MutationTypes.SET_INSPECTION](state: InspectionsState, inspection: InspectionExecution | null): void {
        state.inspection = inspection;
    },

    [MutationTypes.SET_INSPECTION_SETTINGS](state: InspectionsState, inspectionSettings: InspectionSetting | null): void {
        state.inspectionSettings = inspectionSettings;
    },
}

export default mutations;
