import { Component, Prop } from "vue-property-decorator";
import i18n from "@/lang/locale";
import { mapActions, mapGetters } from "vuex";

import { SelectOption } from "@/components/InputSelect/InputSelect";
import { ActionTypes } from "@/vuex/modules/incidents-module/action-types";

import { PageMixin } from "@/mixins/pageMixin";

import { ActiveTabTypes } from "../../types";

@Component({
  methods: {
    ...mapActions({
      fetchIncidentsTypesForFilter: ActionTypes.FETCH_INCIDENTS_TYPES_FOR_FILTER,
    }),
  },
  computed: mapGetters({
    incidentsTypesForFilterOptions: "incidentsTypesForFilterOptions",
  }),
})
export default class FilterPanel extends PageMixin {
  protected readonly fetchIncidentsTypesForFilter!: () => Promise<void>;
  protected incidentsTypesForFilterOptions!: SelectOption[];

  @Prop({
    type: String,
    required: true,
  })
  public activeTab!: string;

  @Prop({
    type: Number,
    required: true,
  })
  public status!: number | null;

  @Prop({
    type: Number,
    required: true,
  })
  public typeId!: number | null;

  protected readonly statusOptions = [
    {
      label: i18n.t("app.views.incident.allStatusOption").toString(),
      value: null,
    },
    {
      label: i18n.t("app.views.incident.solvedOption").toString(),
      value: 1,
    },
    {
      label: i18n.t("app.views.incident.unsolvedOption").toString(),
      value: 0,
    },
  ];

  protected readonly typeOptions = [
    {
      label: i18n.t("app.views.incident.allTypes").toString(),
      value: null,
    },
    {
      label: i18n.t("app.views.typeIncident.other").toString(),
      value: 0,
    },
  ];

  changeTab(tab: ActiveTabTypes) {
    this.$emit("changeTabHanler", tab);
  }

  created() {
    if (!this.auth.isSystemAdmin(this.me)) {
      this.fetchIncidentsTypesForFilter();
    }
  }
}
