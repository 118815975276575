import Template from "@/vuex/models/template/template";
import Meta from "@/vuex/models/meta";
import TemplateFull from "@/vuex/models/template/template-full";
import TemplateTags from "@/vuex/models/template/template-tags";

export default class TemplatesState {
  templates: Template[] = [];
  templatesForCsv: Template[] = [];
  meta!: Meta;
  template!: TemplateFull | null;
  filteredTemplates: Template[] = [];
  templateTags: TemplateTags[] = [];
}
