import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import TemplateSection from "@/vuex/models/template/template-section";
import TemplateQuestion from "@/vuex/models/template/template-question";
import {Translator} from "@/services/translator/translator";

@Component
export default class ReportSection extends Vue {
    @Prop({
        required: true
    })
    public section!: TemplateSection;

    protected modal: boolean = false;
    protected imagePath: string | null = null;
    protected translator: Translator = new Translator();

    protected getImageUrl(question: TemplateQuestion): string | null {
        const answer = question.answer;
        if (!!answer) {
            if (!!answer.image) {
                //@ts-ignore
                return answer.image.original_url;
            }
            if (!!answer.answer.signature) {
                return answer.answer.signature;
            }
        }
        return null;
    }

    protected showImage(question: TemplateQuestion): void {
        this.modal = true;
        this.imagePath = this.getImageUrl(question);
    }

    protected closeModal(): void {
        this.modal = false;
        this.imagePath = null;
    }
}
