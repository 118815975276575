import Vue from "vue";
import {mapGetters, mapMutations} from 'vuex';
import {Component, Watch} from "vue-property-decorator";
import {Notification, NotificationType} from "@/vuex/modules/notifications-module/state";
import {MutationTypes} from "@/vuex/modules/notifications-module/mutation-types";
import ToastTransition from "@/components/Application/components/Notifications/ToastTransition";

@Component({
  components: {ToastTransition},
  computed: mapGetters({
    notifications: 'getNotifications',
    flushNotifications: 'flushNotificationsState',
  }),
  methods: mapMutations({
    removeNotification: MutationTypes.REMOVE_NOTIFICATION,
    toggleFlushNotifications: MutationTypes.TOGGLE_FLUSH_NOTIFICATIONS,
  }),
})
export default class Notifications extends Vue {
  private notifications!: Notification[];
  private toggleFlushNotifications!: (toggle: boolean) => void;
  private removeNotification!: (notification: Notification) => void;

  @Watch('flushNotifications', {deep: false, immediate: true})
  private showNotificationsOn(newVal: boolean, oldVal: boolean): void {
    if (newVal) {
      const currentNotifications: Notification[] = [...this.notifications];
      for (const notification of currentNotifications) {
        if (notification.flushed) {
          continue;
        }

        notification.flushed = true;

        setTimeout(() => this.removeNotification(notification), 5000)
      }
    }

    this.toggleFlushNotifications(false);
  }

  get iconByType() {
    return (type: NotificationType) => {
      if (type === NotificationType.DANGER) {
        return 'alert'
      } else if (type === NotificationType.SUCCESS) {
        return 'check'
      }
      return null;
    }
  }
}
