import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { Component } from "vue-property-decorator";
import { PageMixin } from "@/mixins/pageMixin";

@Component({
  components: {},
})
export default class PdfView extends PageMixin {
  protected pdf!: string;

  private async getPdf() {
    this.loading = true;
    try {
      const response = await http({ responseType: "blob" }).get(
        appRoutes.api.reports.pdf(this.$route.params.id)
      );
      this.pdf = response.data;

      const pdfBlob: Blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      window.location.href = pdfBlobUrl;
    } catch (exception: any) {
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.getPdf();
  }
}
