import { ActionTypes } from "./action-types";
import LocationsState from "./state";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../store";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "./mutation-types";
import { Direction, LocationsSortableColumns } from "@/services/helpers/sort";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import LocationData from "@/vuex/models/location/location-data";

export interface LocationSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: LocationsSortableColumns | null;
  search: string | null;
  organisation_id: number | null;
}

type LocationsActionContext = ActionContext<LocationsState, RootState>;

const actions: ActionTree<LocationsState, RootState> = {
  async [ActionTypes.FETCH_LOCATIONS](
    store: LocationsActionContext,
    params: LocationSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.locations.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_LOCATIONS, response.data.data);
      store.commit(MutationTypes.SET_LOCATIONS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_LOCATIONS_FOR_CSV](
    store: LocationsActionContext,
    params: LocationSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.locations.all(), {
        params,
      });
      store.commit(MutationTypes.SET_LOCATIONS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_LOCATION](
    state: LocationsActionContext,
    params: LocationData
  ): Promise<void> {
    const response = await http().post(appRoutes.api.locations.index(), {
      ...params,
    });
    state.commit(MutationTypes.SET_LOCATION, response.data);
  },

  async [ActionTypes.UPDATE_LOCATION](
    state: LocationsActionContext,
    params: LocationData
  ): Promise<void> {
    if (!params.id) {
      throw new Error("cannot update model without id (not saved ?)");
    }

    const response = await http().put(
      appRoutes.api.locations.update(params.id),
      params
    );
    state.commit(MutationTypes.SET_LOCATION, response.data);
  },

  async [ActionTypes.DELETE_LOCATION](
    state: LocationsActionContext,
    locationId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.locations.destroy(locationId));
  },
};

export default actions;
