import { MutationTypes } from "@/vuex/modules/organisations-module/mutation-types";
import OrganisationsState from "@/vuex/modules/organisations-module/state";
import { MutationTree } from "vuex";
import Organisation from "@/vuex/models/organisation/organisation";
import Meta from "@/vuex/models/meta";

const mutations: MutationTree<OrganisationsState> = {
  [MutationTypes.SET_ORGANISATIONS](
    state: OrganisationsState,
    organisations: Organisation[] | []
  ): void {
    state.organisations = organisations;
  },
  [MutationTypes.SET_ORGANISATIONS_FOR_CSV](
    state: OrganisationsState,
    organisations: Organisation[] | []
  ): void {
    state.organisationsForCsv = organisations;
  },
  [MutationTypes.SET_ORGANISATIONS_META](
    state: OrganisationsState,
    meta: Meta
  ): void {
    state.meta = meta;
  },
};

export default mutations;
