<template>
  <div class="organisation_access_action">
    <input-switch :checked="orgAccess" v-model="orgAccess"></input-switch>
    <span class="organisation_message">
      {{ $t("app.views.user.accessMessage") }}
      <span class="organisation_access_action_email-domain">
        [{{ emailDomain }}]
      </span>
      <p class="text-italik" v-if="registerOrganisation">
        ({{ $t("app.organisation.access.thePopularDomains") }})
      </p>
    </span>
  </div>
</template>

<script>
export default {
  name: "OrganisationAccess",
  emits: ["update:access"],
  props: {
    emailDomain: {
      required: true,
      type: String,
    },
    registerOrganisation: {
      required: false,
      type: Boolean,
      default: false,
    },
    access: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    orgAccess: {
      get() {
        return this.access;
      },
      set(newValue) {
        this.$emit("update:access", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.text-italik {
  font: italic normal normal 11px/17px Poppins;
}
.organisation_access_action {
  display: flex;
  align-items: start;
  gap: 20px;

  &_email-domain {
    color: #1de591;
  }

  @media (max-width: 560px) {
    gap: 13px;
  }
}
</style>
