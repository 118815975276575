import { MutationTree } from "vuex";
import TemplatesState from "@/vuex/modules/templates-module/state";
import { MutationTypes } from "@/vuex/modules/templates-module/mutation-types";
import Template from "@/vuex/models/template/template";
import Meta from "@/vuex/models/meta";
import TemplateFull from "@/vuex/models/template/template-full";
import TemplateTags from "@/vuex/models/template/template-tags";

const mutations: MutationTree<TemplatesState> = {
  [MutationTypes.SET_TEMPLATES](
    state: TemplatesState,
    templates: Template[]
  ): void {
    state.templates = templates;
  },
  [MutationTypes.SET_TEMPLATES_FOR_CSV](
    state: TemplatesState,
    templates: Template[]
  ): void {
    state.templatesForCsv = templates;
  },
  [MutationTypes.SET_TEMPLATE_TAGS](
    state: TemplatesState,
    templateTags: TemplateTags[]
  ): void {
    state.templateTags = templateTags;
  },
  [MutationTypes.SET_TEMPLATES_META](state: TemplatesState, meta: Meta): void {
    state.meta = meta;
  },
  [MutationTypes.SET_TEMPLATE](
    state: TemplatesState,
    template: TemplateFull | null
  ): void {
    state.template = template;
  },
  [MutationTypes.SET_FILTERED_TEMPLATES](
    state: TemplatesState,
    filteredTemplates: Template[]
  ): void {
    state.filteredTemplates = filteredTemplates;
  },
};

export default mutations;
