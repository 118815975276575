import {Module} from "vuex";
import {RootState} from "../../store";
import NotificationsState from "@/vuex/modules/notifications-module/state";
import actions from './actions';
import getters from './getters'
import mutations from './mutations'


const module: Module<NotificationsState, RootState> = {
    state: new NotificationsState(),
    actions,
    getters,
    mutations
}

export default module;
