<template>
  <div class="Form">
    <loading-spinner v-if="loading" :show="loading" />

    <div class="Title">
      {{ $t("auth.register.register") }}
    </div>
    <div class="Inputs">
      <input-text
        :placeholder="$t('components.form.userName')"
        v-model="credentials.name"
        :hasError="formErrors.hasErrors('name')"
        :error="formErrors.get('name')?.toString()"
        appearance="auth"
      >
      </input-text>
      <input-text
        :placeholder="$t('app.views.user.surname')"
        v-model="credentials.surname"
        :hasError="formErrors.hasErrors('surname')"
        :error="formErrors.get('surname')?.toString()"
        appearance="auth"
      >
      </input-text>
      <input-text
        placeholder="E-mail"
        v-model="credentials.email"
        :hasError="formErrors.hasErrors('email')"
        :error="formErrors.get('email')?.toString()"
        appearance="auth"
      >
      </input-text>
      <input-text
        :placeholder="$t('auth.password')"
        v-model="credentials.password"
        :hasError="formErrors.hasErrors('password')"
        :error="formErrors.get('password')?.toString()"
        appearance="auth"
        type="password"
      >
      </input-text>
      <div class="Inputs IsSwitch">
        <div class="switch-row">
          <input-switch
            v-model="credentials.is_accept_privacy_policy"
            :hasError="formErrors.hasErrors('is_accept_privacy_policy')"
            :error="formErrors.get('is_accept_privacy_policy')?.toString()"
          />
          <p>
            {{ $t("auth.register.acceptRegulations") }}
            <a class="privacy" :href="rulesHref" target="_blank">
              {{ $t("auth.register.regulations") }}
            </a>
            {{ $t("auth.register.acceptPrivacyPolicy") }}
            <a class="privacy" :href="privacyHref" target="_blank">
              {{ $t("auth.register.privacyPolicy") }}
            </a>
          </p>
        </div>

        <div class="switch-row">
          <input-switch v-model="credentials.is_accept_marketing_consent" />
          <p>
            {{ $t("auth.register.acceptPersonalData") }}
          </p>
        </div>
      </div>

      <div class="Bottom">
        <div class="AdditionalInfo">
          <div class="row">
            <p>
              {{ $t("auth.login.personalData") }}
              <a v-show="!more" @click="readMore">{{
                $t("auth.login.readMore")
              }}</a>
              <span v-show="more">
                {{ $t("auth.login.more") }}
              </span>
            </p>
          </div>
        </div>
        <div class="Footer">
          <div class="BottomLine">
            <p>{{ $t("auth.register.alreadyHaveAccount") }}</p>
            <router-link to="/login">{{ $t("auth.login.logIn") }}</router-link>
          </div>
          <div class="wrapper-button">
            <div class="Button" @click="register">
              {{ $t("auth.register.register") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import Vue from "vue";
import FormErrors from "@/services/formErrors/FormErrors";
import { RegisterCredentials } from "@/vuex/modules/auth-module/actions";
import { ActionTypes as AuthActions } from "@/vuex/modules/auth-module/action-types";

@Component
export default class AuthRegisterView extends Vue {
  protected readonly formErrors = new FormErrors();
  protected readonly privacyHref: string = `https://checly.com/docs/checly-privacy-policy.pdf`;
  protected readonly rulesHref: string = `https://checly.com/docs/checly-terms-and-conditions.pdf`;

  protected privacyAccept: boolean = false;
  protected loading: boolean = false;
  protected credentials: RegisterCredentials = {
    name: "",
    surname: "",
    email: "",
    password: "",
    is_accept_privacy_policy: false,
    is_accept_marketing_consent: false,
    host: "",
  };
  public more: boolean = false;

  public async register(): Promise<void> {
    this.formErrors.clearErrors();
    this.loading = true;
    this.credentials.host = window.location.origin;

    try {
      const email = await this.$store.dispatch(
        AuthActions.REGISTER,
        this.credentials
      );
      await this.$router.push({
        name: "confirm-attention",
        params: {
          userEmail: email,
        },
      });
    } catch (exception: any) {
      this.formErrors.setErrors(exception.response.data.errors);
    }

    this.loading = false;
  }

  private readMore() {
    this.more = true;
  }
}
</script>

<style scoped lang="scss">
.switch-row p {
  font-size: 11px;
}

.AdditionalInfo {
  font-size: 11px;
  margin-top: -20px;
}

.Footer {
  font-size: 14px;

  .wrapper-button {
    display: block;

    @media (max-width: 560px) {
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column-reverse !important;
    align-items: start !important;
    gap: 40px;
  }
}
</style>
