import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import Organisation from "@/vuex/models/organisation/organisation";
import FormErrors from "@/services/formErrors/FormErrors";
import OrganisationData from "@/vuex/models/organisation/organisation-data";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { TimezoneOptions } from "@/services/options/timezone";
import { ActionTypes } from "@/vuex/modules/organisations-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import i18n from "@/lang/locale";
import Authorization from "@/services/authorization/authorization";
import UserFull from "@/vuex/models/user/user-full";
import { LocaleMessage } from "vue-i18n";

@Component({
  methods: {
    ...mapActions({
      create: ActionTypes.CREATE_ORGANISATION,
      update: ActionTypes.UPDATE_ORGANISATION,
    }),
  },
  computed: mapGetters(["me"]),
  components: {
    ButtonsSaveCancel,
  },
})
export default class OrganisationForm extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  public organisation!: Organisation | null;

  private readonly create!: (params: OrganisationData) => Promise<Organisation>;
  private readonly update!: (params: OrganisationData) => Promise<Organisation>;

  protected readonly yesNoOptions: SelectOption[] = [
    { label: i18n.t("components.yes").toString(), value: true },
    { label: i18n.t("components.no").toString(), value: false },
  ];
  protected readonly onOffOptions: SelectOption[] = [
    {
      label: i18n.t("components.table.manualPaymentOn").toString(),
      value: true,
    },
    {
      label: i18n.t("components.table.manualPaymentOff").toString(),
      value: false,
    },
  ];
  protected readonly timezoneOptions: SelectOption[] = TimezoneOptions;

  protected readonly auth: Authorization = new Authorization();
  protected readonly me!: UserFull;
  protected organisationData!: OrganisationData;
  protected formErrors: FormErrors = new FormErrors();

  protected get label(): LocaleMessage {
    return !!this.organisation
      ? this.$t("components.form.update")
      : this.$t("components.form.create");
  }

  protected created() {
    this.setOrganisationData();
  }

  private setOrganisationData() {
    this.organisationData = {
      id: !!this.organisation ? this.organisation.id : null,
      name: !!this.organisation ? this.organisation.name : null,
      allow_to_join: !!this.organisation
        ? this.organisation.allow_to_join
        : false,
      manual_payment: !!this.organisation
        ? this.organisation.manual_payment
        : false,
      timezone: !!this.organisation ? this.organisation.timezone : null,
      users_limit: !!this.organisation ? this.organisation.users_limit : null,
      active_users_count: !!this.organisation
        ? this.organisation.active_users_count
        : null,
      locations_limit: !!this.organisation
        ? this.organisation.locations_limit
        : null,
      inspections_limit: !!this.organisation
        ? this.organisation.inspections_limit
        : null,
      external_accesses_limit: !!this.organisation
        ? this.organisation.external_accesses_limit
        : null,
      qr_codes_limit: !!this.organisation
        ? this.organisation.qr_codes_limit
        : null,
      domain: !!this.organisation ? this.organisation.domain : null,
    };
  }

  protected close() {
    this.$emit("close", false);
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");

    if (!this.organisation) {
      await this.createOrganisation();
    } else {
      await this.updateOrganisation();
    }

    this.$emit("loading");
  }

  private async createOrganisation(): Promise<void> {
    try {
      await this.create(this.organisationData);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Organisation has been created.",
        type: NotificationType.SUCCESS,
      });
      this.formErrors.clearErrors();
      this.$emit("close", true);
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }

  private async updateOrganisation(): Promise<void> {
    try {
      await this.update(this.organisationData);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Organisation has been updated.",
        type: NotificationType.SUCCESS,
      });
      this.formErrors.clearErrors();
      this.$emit("close", true);
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }

  private async handleException(exception: any): Promise<void> {
    this.formErrors.setErrors(exception.response.data.errors);

    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: exception.response.data.message,
      type: NotificationType.DANGER,
    });
  }

  protected setManualPayment(value: boolean): void {
    this.organisationData.manual_payment = value;
  }

  protected setUserAccess(value: boolean): void {
    this.organisationData.allow_to_join = value;
  }
}
