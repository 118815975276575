import User from "@/vuex/models/user/user";
import Meta from "@/vuex/models/meta";

export interface UserLocation {
  readonly id: number;
  readonly name: string;
}

export interface UserInspector {
  readonly id: number;
  readonly name: string;
  readonly surname: string;
}

export default class UsersState {
  public userInspectors!: UserInspector[];
  public users: Array<User> | [] = [];
  public meta!: Meta;
  public usersForAssign: Array<User> | [] = [];
  public usersForCsv: Array<User> | [] = [];
}

export enum UserAccess {
  MINE = "mine",
  ALL = "all",
}
