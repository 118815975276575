<template>
  <div class="subscriptions_plan-selection">
    <div class="subscriptions_help">
      <AttentionSign class="subscriptions_help_attention"/>
      <span class="subscriptions_help_text">{{ $t('app.views.billing.subscriptionsNotification') }}</span>
      <a href="mailto:hello@checly.app" class="subscriptions_help_text green">hello@checly.app</a>
    </div>
    <div class="subscriptions_plan-selection_plans">
      <SubscriptionPlan
          v-for="(product, index) in products"
          :key="product.id"
          :index="index"
          :product="product"
          @select="$emit('select', $event)"
      />
    </div>
  </div>
</template>

<script>
import AttentionSign from "@/components/AttentionSign.vue";
import SubscriptionPlan from "@/views/Billing/SelectSubscription/SubscriptionPlan.vue";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import {ActionTypes as AuthActions} from "@/vuex/modules/auth-module/action-types";

export default {
  name: "PlanSelectionTab",
  components: {SubscriptionPlan, AttentionSign},
  data() {
    return {
      products: [],
      plan: null
    }
  },
  async created() {
    await this.fetchProducts();
    await this.$store.dispatch(AuthActions.FETCH_ME);
  },
  methods: {
    async fetchProducts() {
      const response = await this.$store.dispatch(ActionTypes.FETCH_PRODUCTS);
      this.products = response.data.data;

      const sortOrder = { pro: 1, team: 2, enterprise: 3 };

      this.products.sort(function(a, b) {
        return sortOrder[a.slug] - sortOrder[b.slug];
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.subscriptions_plan-selection {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}

.subscriptions_help {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  background: rgba(29, 229, 145, 0.09) 0 0 no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 30px;

  &_attention {
    color: #1DE591;
    border-color: #1DE591;
    background-color: rgba(29, 229, 145, 0.09);
  }

  &_text {
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0;
  }

  .green {
    color: #1DE591;
  }
}
</style>
