import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import LocationForm from "@/views/LocationsView/components/LocationForm/LocationForm.vue";
import HalfLayout from "@/layouts/HalfLayout.vue";
import Input from "@/components/Input/Input.vue";
import TemplatePseudoTable from "@/views/TemplateFormView/components/TemplatePseudoTable.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import RightSideNav from "@/views/TemplateFormView/components/RightSideNav.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import { FormMixin } from "@/mixins/formMixin";
import InputSelectAccess from "@/components/InputSelectAccess/InputSelectAccess.vue";
import { ActionTypes } from "@/vuex/modules/templates-module/action-types";
import TemplateFull from "@/vuex/models/template/template-full";
import { TemplateAccessLevel } from "@/vuex/models/template/template-access-level";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import UserProfile from "@/vuex/models/user/user-profile";
import TemplateData from "@/vuex/models/template/template-data";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { MutationTypes } from "@/vuex/modules/templates-module/mutation-types";
import { TemplateTypeOfScore } from "@/vuex/models/template/template-type-of-score";
import i18n from "@/lang/locale";
import TemplateSection from "@/vuex/models/template/template-section";

@Component({
  components: {
    InputSelectOrganisation,
    InputSelectAccess,
    ButtonsSaveCancel,
    TemplatePseudoTable,
    Input,
    HalfLayout,
    LocationForm,
    ButtonWithIcon,
    RightSideNav,
  },
  methods: {
    ...mapActions({
      fetchTemplate: ActionTypes.FETCH_TEMPLATE,
      create: ActionTypes.CREATE_TEMPLATE,
      update: ActionTypes.UPDATE_TEMPLATE,
      clone: ActionTypes.CLONE_TEMPLATE,
    }),
  },
  computed: mapGetters({
    me: "me",
    template: "template",
    users: "users",
  }),
})
export default class TemplateFormView extends Mixins(FormMixin) {
  private readonly fetchTemplate!: (templateId: number) => Promise<void>;
  private readonly create!: (params: TemplateData) => Promise<void>;
  private readonly update!: (params: TemplateData) => Promise<void>;
  private readonly clone!: (templateId: number) => Promise<void>;

  private readonly template!: TemplateFull;

  protected loading: boolean = false;
  protected additionalFieldsLimitReached: boolean = false;
  protected templateData: TemplateData = {
    id: null,
    name: null,
    access_level: TemplateAccessLevel.ORGANISATION,
    type_of_score: TemplateTypeOfScore.POINTS,
    organisation_id: null,
    language_id: null,
    assigned_users: [],
    additional_fields: [],
    logo: null,
    sections: [],
    tags: [],
  };

  protected get header(): string {
    if (!!this.$route.params.action && !!this.template) {
      const header: string =
        this.$route.params.action === "view"
          ? i18n.t("app.views.template.viewTemplate").toString()
          : i18n.t("app.views.template.editTemplate").toString();
      return header + ' "' + this.template.name + '"';
    }

    return i18n.t("app.views.template.createTemplate").toString();
  }

  protected get isDisabled(): boolean {
    if (!!this.$route.params.action) {
      return "view" === this.$route.params.action;
    }

    return false;
  }

  protected get pageMode(): string {
    return !!this.$route.params.action ? this.$route.params.action : "create";
  }

  protected async created(): Promise<void> {
    this.loading = true;

    if (!!this.$route.params.id) {
      await this.fetchTemplate(Number(this.$route.params.id));
    } else {
      this.$store.commit(MutationTypes.SET_TEMPLATE, null);
    }

    this.setTemplateData();
    this.setAdditionalFieldsLimit();

    this.loading = false;
  }

  validateSections(sections: TemplateSection[]) {
    let isEmpty = false;

    if (!Array.isArray(sections) || sections.length === 0) {
      isEmpty = true;
      store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: i18n
          .t("app.views.template.notificationMessage.isValidateSections")
          .toString(),
        type: NotificationType.DANGER,
      });
    }

    for (const section of sections) {
      if (
        !section.questions ||
        !Array.isArray(section.questions) ||
        section.questions.length === 0
      ) {
        isEmpty = true;
        store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: i18n
            .t("app.views.template.notificationMessage.isValidateQuestions")
            .toString(),
          type: NotificationType.DANGER,
        });
      }
    }

    return isEmpty;
  }

  protected setTagIds(tagIds: number[]) {
    this.templateData.tags = tagIds;
  }

  private setTemplateData(): void {
    this.templateData = {
      tags: !!this.template
        ? this.template.tags.map((el: { id: number }) => el.id)
        : [],
      id: !!this.template ? this.template.id : null,
      name: !!this.template ? this.template.name : null,
      access_level: !!this.template
        ? this.template.access_level
        : TemplateAccessLevel.ORGANISATION,
      type_of_score: !!this.template
        ? this.template.type_of_score
        : TemplateTypeOfScore.POINTS,
      organisation_id: !!this.template ? this.template.organisation?.id : null,
      language_id:
        !!this.template && !!this.template.language
          ? this.template.language.id
          : this.me.language_id,
      assigned_users: !!this.template
        ? this.template.assigned_users.map((user: UserProfile) => user.id)
        : [],
      additional_fields: !!this.template ? this.template.additional_fields : [],
      sections: !!this.template ? this.template.sections : [],
      logo: null,
    };
  }

  private setAdditionalFieldsLimit(): void {
    this.additionalFieldsLimitReached =
      !!this.templateData.additional_fields &&
      this.templateData.additional_fields.length >= 9;
  }
  protected addAdditionalField(): void {
    if (!this.additionalFieldsLimitReached) {
      this.templateData.additional_fields.push({ name: "", value: "" });
    }
    this.setAdditionalFieldsLimit();
  }

  protected removeAdditionalField(index: number): void {
    this.templateData.additional_fields.splice(index, 1);
    this.setAdditionalFieldsLimit();
  }

  protected async submit(): Promise<void> {
    this.loading = true;
    this.formErrors.clearErrors();

    try {
      switch (this.pageMode) {
        case "create":
          await this.createTemplate();
          break;
        case "view":
          if (
            !this.auth.isSystemAdmin(this.me) &&
            !!this.template &&
            TemplateAccessLevel.PUBLIC === this.template.access_level
          ) {
            await this.cloneTemplate();
          } else {
            await this.$router.replace({
              params: { id: this.template.id.toString(), action: "update" },
            });
          }
          break;
        case "update":
          await this.updateTemplate();
          break;
      }
    } catch (exception: any) {
      await this.formErrors.setErrors(exception.response.data.errors);
    }

    this.loading = false;
  }

  private async createTemplate(): Promise<void> {
    if (this.validateSections(this.templateData.sections)) return;
    await this.create(this.templateData);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Template has been created.",
      type: NotificationType.SUCCESS,
    });
    await this.$router.push({ name: "library" });
  }

  private async updateTemplate(): Promise<void> {
    if (this.validateSections(this.templateData.sections)) return;
    await this.update(this.templateData);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Template has been updated.",
      type: NotificationType.SUCCESS,
    });
    await this.$router.push({ name: "library" });
  }

  private async cloneTemplate(): Promise<void> {
    await this.clone(this.template.id);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Template has been cloned.",
      type: NotificationType.SUCCESS,
    });
    await this.$router.replace({
      params: { id: this.template.id.toString(), action: "update" },
    });

    this.templateData.id = this.template.id;
    this.templateData.name = this.template.name;
    this.templateData.access_level = this.template.access_level;
  }
}
