import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { PageMixin } from "@/mixins/pageMixin";
import InputSelectAccess from "@/components/InputSelectAccess/InputSelectAccess.vue";
import InspectionCalendarForm from "@/views/InspectionsView/components/InspectionCalendarForm/InspectionCalendarForm.vue";
import { ActionTypes } from "@/vuex/modules/templates-module/action-types";
import { TemplateSearchParams } from "@/vuex/modules/templates-module/actions";
import Meta from "@/vuex/models/meta";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import Template from "@/vuex/models/template/template";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { TemplatesSortableColumns } from "@/services/helpers/sort";
import DateToString from "@/services/dateToString/dateToString";
import { Debounce } from "vue-debounce-decorator";
import { TemplateAccessLevel } from "@/vuex/models/template/template-access-level";
import DeleteTemplateModal from "@/views/TemplateView/components/DeleteTemplateModal.vue";
import Field from "@/components/Field/Field.vue";
import i18n from "@/lang/locale";

import { generateTranslatinsForAccessLevel } from "@/services/helpers/generateTranslatinsForAccessLevel";
import { generateTranslationsForLanguages } from "@/services/helpers/generateTranslationsForLanguages";

@Component({
  methods: mapActions({
    fetchTemplates: ActionTypes.FETCH_TEMPLATES,
    clone: ActionTypes.CLONE_TEMPLATE,
    fetchTemplateTags: ActionTypes.FETCH_TEMPLATE_TEGS,
    fetchTemplatesForCsv: ActionTypes.FETCH_TEMPLATES_FOR_CSV,
  }),
  computed: mapGetters({
    templates: "templates",
    meta: "templatesMeta",
    templateTagsOptions: "templateTagsOptions",
    templatesForCsv: "templatesForCsv",
  }),
  components: {
    DeleteTemplateModal,
    InputSelectAccess,
    InspectionCalendarForm,
    Field,
  },
})
export default class TemplateView extends Mixins(PageMixin) {
  private readonly fetchTemplates!: (
    params: TemplateSearchParams
  ) => Promise<void>;
  private readonly fetchTemplateTags!: () => Promise<void>;
  private readonly fetchTemplatesForCsv!: (
    params: TemplateSearchParams
  ) => Promise<void>;
  private readonly clone!: (templateId: number) => Promise<void>;

  protected readonly dateToString: DateToString = new DateToString();
  protected readonly sortableColumns = TemplatesSortableColumns;
  protected readonly templates!: Template[];
  protected readonly templatesForCsv!: Template[];
  protected readonly meta!: Meta;

  protected showInspectionForm: boolean = false;

  protected totalTemplates: number = 0;
  protected selectedTemplate: Template | null = null;
  protected searchParams: TemplateSearchParams = {
    page: 1,
    per_page: 25,
    column: null,
    direction: null,
    search: null,
    access_level: null,
    user_id: null,
    language_id: null,
    tag_id: null,
  };

  protected async created(): Promise<void> {
    if (!!this.$route.query.page) {
      this.searchParams.page = Number(this.$route.query.page);
    }

    await this.fetchTemplateTags();
    await this.paginate();
    this.totalTemplates = this.pagination.total;
  }

  @Watch("searchParams", { deep: true })
  private async paginate(): Promise<void> {
    this.loading = true;

    await this.fetchTemplates(this.searchParams);

    this.loading = false;
    this.pagination = this.meta;
  }

  protected async setPage(page: number): Promise<void> {
    this.searchParams.page = page;
    await this.$router.replace({ query: { page: page.toString() } });
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchTemplates(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected async viewTemplate(template: Template): Promise<void> {
    await this.$router.push({
      name: "template-form",
      params: { id: template.id.toString(), action: "view" },
    });
  }

  protected async editTemplate(template: Template): Promise<void> {
    await this.$router.push({
      name: "template-form",
      params: { id: template.id.toString(), action: "update" },
    });
  }

  protected canEdit(template: Template): boolean {
    const isSystemAdmin = this.auth.isSystemAdmin(this.me);
    const isOrganisationAdmin = this.auth.isOrganisationAdmin(this.me);
    const isLocationAdmin = this.auth.isLocationAdmin(this.me);

    switch (template.access_level) {
      case TemplateAccessLevel.PUBLIC:
        return isSystemAdmin;
      case TemplateAccessLevel.ORGANISATION:
        if (isSystemAdmin) {
          return true;
        } else if (
          isOrganisationAdmin &&
          template.organisation_id === this.me.organisation?.id
        ) {
          return true;
        } else if (isLocationAdmin && this.me.id === template.creator_id) {
          return true;
        }
        break;
      case TemplateAccessLevel.PRIVATE:
        return this.me.id === template.creator_id;
    }

    return false;
  }

  protected selectAccessLevel(event: TemplateAccessLevel) {
    if (
      event === TemplateAccessLevel.PRIVATE ||
      event === TemplateAccessLevel.ORGANISATION
    ) {
      this.searchParams.tag_id = null;
    }
    this.searchParams.access_level = event;
  }

  protected removeTemplate(template: Template): void {
    this.selectedTemplate = template;
    this.showDeleteModal = true;
  }

  protected generateTranslatinsForAccessLevelHandler(accessLevel: string) {
    return generateTranslatinsForAccessLevel(accessLevel);
  }

  protected generateTranslationsForLanguagesHandler = (code: string) => {
    return generateTranslationsForLanguages(code);
  };

  protected async closeDeleteModal(submitted: boolean): Promise<void> {
    this.selectedTemplate = null;
    this.showDeleteModal = false;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async closeInspectionForm() {
    this.showInspectionForm = false;
  }

  private async cloneTemplate(id: number): Promise<void> {
    await this.clone(id);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Template has been cloned.",
      type: NotificationType.SUCCESS,
    });

    await this.fetchTemplates(this.searchParams);
  }

  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      i18n.t("components.table.updatedAt").toString(),
      i18n.t("components.table.author").toString(),
      i18n.t("components.table.language").toString(),
      i18n.t("components.table.access").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchTemplatesForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.templatesForCsv.map((el) => ({
        [i18n.t("components.table.name").toString()]: el.name,
        [i18n.t("components.table.updatedAt").toString()]:
          this.dateToString.getLocaleString(new Date(el.created_at), [
            this.dateToString.LONG_DATE,
          ]),
        [i18n.t("components.table.author").toString()]: el.creator_name,
        [i18n.t("components.table.language").toString()]:
          this.generateTranslationsForLanguagesHandler(
            el?.language?.code ?? "-"
          ),
        [i18n.t("components.table.access").toString()]:
          this.generateTranslatinsForAccessLevelHandler(el?.access_level),
      })),
      this.generateHeaders(),
      "libraries.csv"
    );
  }
}
