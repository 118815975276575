import Vue from "vue";

const ToastTransition = Vue.extend({
  functional: true,
  render(h, {children}) {
    const data = {
      attrs: {tag: 'div', name: 'toast', type: 'transition'}
    }
    return h('transition-group', data, children)
  }
})

export default ToastTransition
