<template>
  <div class="organisation">
    <div class="organisation_info">
      <input-text
        :label="$tc('app.views.organisation.organisation', 1).capitalize()"
        v-model="organisation.name"
        :disabled="!auth.isOrganisationAdmin(me)"
        :hasError="formErrors.hasErrors('name')"
        :error="formErrors.get('name')"
      />
      <input-select
        :label="$t('app.views.organisation.timeZone').capitalize()"
        :options="timezoneOptions"
        :showSearchInput="true"
        :value="organisation.timezone"
        :current="currentTimezone.label"
        :disabled="!auth.isOrganisationAdmin(me)"
        :hasError="formErrors.hasErrors('timezone')"
        :error="formErrors.get('timezone')"
        @select="organisation.timezone = $event"
      />
    </div>
    <div class="organisation_access" v-if="auth.isOrganisationAdmin(me)">
      <p class="organisation_header">{{ $t("app.views.user.access") }}</p>
      <OrganisationAccess
        :access.sync="organisation.allow_to_join"
        :emailDomain="me.organisation.domain"
      />
    </div>

    <div class="organisation_delete" v-if="auth.isOrganisationAdmin(me)">
      <p class="organisation_header">
        {{ $t("app.views.user.deleteOrganisation").capitalize() }}
      </p>
      <div class="organisation_delete_notification">
        <AttentionSign
          class="organisation_delete_notification_attention"
        ></AttentionSign>
        <span class="organisation_message">
          {{ $t("app.views.user.deleteOrgNotification") }}
        </span>
      </div>
      <button
        class="organisation_delete_button"
        @click="deleteOrganisationModal = true"
      >
        {{ $t("app.views.user.deleteOrganisation") }}
      </button>
    </div>

    <div class="organisation_action">
      <ButtonsSaveCancel
        :dont-show-close="true"
        :save-label="$t('components.save')"
        @save="submit"
      ></ButtonsSaveCancel>
    </div>

    <DeleteOrganisationModal
      :organisation="me.organisation"
      :modal="deleteOrganisationModal"
      @close="closeModal"
      @loading="$emit('loading')"
    />
  </div>
</template>

<script>
import FormErrors from "@/services/formErrors/FormErrors";
import { mapGetters } from "vuex";
import { ActionTypes as OrganisationActions } from "@/vuex/modules/organisations-module/action-types";
import { ActionTypes as AuthActions } from "@/vuex/modules/auth-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import Authorization from "@/services/authorization/authorization";
import OrganisationAccess from "@/components/OrganisationAccess/OrganisationAccess.vue";
import DeleteOrganisationModal from "@/views/OrganisationsView/components/DeleteOrganisationModal.vue";
import AttentionSign from "@/components/AttentionSign.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { TimezoneOptions } from "@/services/options/timezone";
import { MutationTypes as AuthMutations } from "@/vuex/modules/auth-module/mutation-types";
import router from "@/routes";

export default {
  name: "OrganisationTab",
  emits: ["loading"],
  components: {
    ButtonsSaveCancel,
    AttentionSign,
    OrganisationAccess,
    DeleteOrganisationModal,
  },
  computed: {
    ...mapGetters(["me"]),
  },
  data() {
    return {
      auth: new Authorization(),
      formErrors: new FormErrors(),

      organisation: {
        id: null,
        name: null,
        timezone: null,
      },

      timezoneOptions: TimezoneOptions,
      currentTimezone: null,
      deleteOrganisationModal: false,
    };
  },
  created() {
    if (!!this.me.organisation) {
      this.organisation = {
        id: this.me.organisation.id,
        name: this.me.organisation.name,
        timezone: this.me.organisation.timezone,
        allow_to_join: this.me.organisation.allow_to_join,
      };
    }

    this.setTimezones();
  },
  methods: {
    setTimezones() {
      this.currentTimezone = this.timezoneOptions.find((timezone) => {
        return timezone.value === this.me.organisation.timezone;
      });

      if (!this.currentTimezone) {
        this.currentTimezone = { label: "N/A", value: null };
      }
    },
    async submit() {
      this.$emit("loading");

      try {
        await this.$store.dispatch(
          OrganisationActions.UPDATE_ORGANISATION,
          this.organisation
        );
        await this.$store.dispatch(AuthActions.FETCH_ME);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: "Organisation has been updated.",
          type: NotificationType.SUCCESS,
        });
      } catch (exception) {
        this.organisation.allow_to_join = false;
        await this.handleException(exception);
      }

      this.$emit("loading");
    },
    async closeModal(submitted) {
      this.deleteOrganisationModal = false;

      if (submitted) {
        this.$store.commit(AuthMutations.CLEAR_TOKEN);
        this.$store.commit(AuthMutations.CLEAR_ME);
        await router.push({ name: "login" });
        document.location.reload();
      }
    },
    async handleException(exception) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.organisation {
  &_header {
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0;
    color: #0d0e34;
    margin: 0;
  }

  &_message {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: #6d6d6d;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    margin-top: 40px;
  }

  &_access {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_action {
      display: flex;
      align-items: center;
      gap: 20px;

      &_email-domain {
        color: #1de591;
      }
    }
  }

  &_logo {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_input-box {
      display: flex;
      align-items: center;
      gap: 20px;

      &_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: 1px solid #e6e6e6;
        border-radius: 24px;
        height: 100px;
        width: 220px;

        span {
          font: normal normal normal 12px/18px Poppins;
        }
      }

      &_input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal normal 12px/18px Poppins;
        letter-spacing: 0;
        color: #1de591;
        cursor: pointer;
        border: 2px solid #1de591;
        border-radius: 46px;
        height: 35px;
        width: 120px;
        background-color: #fff;
      }

      &_button:hover {
        color: #fff;
        background-color: #1de591;
      }
    }
  }

  &_delete {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_notification {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 50%;

      &_attention {
        border-color: #ff5151;
        color: #ff5151;
        background-color: #fff;
      }
    }

    &_button {
      background: #ff5151 0 0 no-repeat padding-box;
      border-radius: 122px;
      color: #fff;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      max-width: 264px;
      height: 48px;
    }

    &_button:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    }
  }

  &_action {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
</style>