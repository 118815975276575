<template>
  <button
    :class="{ isTransparent: isTransparentButton }"
    class="styles-button"
    type="button"
    @click="$emit('clickHandler')"
  >
    {{ title }}
  </button>
</template>
  
  <script>
export default {
  name: "StylesButton",
  props: {
    isTransparentButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>
  
  <style scoped lang="scss">
.styles-button {
  height: 48px;
  border-radius: 122px;
  outline: none;
  cursor: pointer;

  text-align: center;
  font-size: 14px;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;

  padding: 0 50px;

  transition: all 0.3s;

  border: 2px solid #1de591;
  background-color: #1de591;
  color: white;

  &:hover {
    border: 2px solid #1de591;
    background-color: white;
    color: #1de591;
    transition: all 0.3s;
  }
}

.isTransparent {
  background-color: white;

  border: 2px solid #1de591;
  color: #1de591;

  &:hover {
    border: 2px solid #1de591;
    background-color: #1de591;
    color: white;
    transition: all 0.3s;
  }
}
</style>
  