import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Component, Watch } from "vue-property-decorator";
import Authorization, {
  Role,
} from "../../services/authorization/authorization";
import { ActionTypes as AuthActions } from "../../vuex/modules/auth-module/action-types";
import Notifications from "@/components/Application/components/Notifications/Notifications.vue";
import ProductFruits from "@/components/external/ProductFruits";
import AuthLayout from "@/layouts/AuthLayout.vue";
import { MutationTypes as LanguageMutations } from "@/vuex/modules/languages-module/mutation-types";
import BillingLayout from "@/layouts/BillingLayout.vue";
import UserFull from "@/vuex/models/user/user-full";

@Component({
  computed: mapGetters(["me"]),
  methods: {
    ...mapActions({
      fetchMe: AuthActions.FETCH_ME,
    }),
    ...mapMutations({
      setLocaleFromBrowser: LanguageMutations.SET_LOCALE_FROM_BROWSER,
      setTimezoneFromBrowser: LanguageMutations.SET_TIMEZONE_FROM_BROWSER,
    }),
  },
  components: {
    AuthLayout,
    Notifications,
    ProductFruits,
    BillingLayout,
  },
})
export default class Application extends Vue {
  /* end theme */

  private readonly fetchMe!: () => Promise<any>;
  private readonly setLocaleFromBrowser!: (locale: string) => void;
  private readonly setTimezoneFromBrowser!: (timezone: string) => void;

  protected me!: UserFull | null;
  protected auth: Authorization = new Authorization();
  protected isSideMenuMini: boolean = false;
  protected isNavOpen =
    document.body.offsetWidth > 1024 &&
    this.$route.name != "inspection-execution";
  protected isPanelVisible =
    document.body.offsetWidth < 1024 &&
    this.$route.name != "inspection-execution";
  protected langInited = false;

  protected hubspotVisibility: string = "hidden";

  async mounted() {
    if (!this.me) {
      // for low internet speed
      await this.fetchMe();
    }

    this.setLocaleFromBrowser(navigator.language);
    this.setTimezoneFromBrowser(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    await this.setLocaleMessages();

    //@ts-ignore
    window.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: "#hubspot-chat",
    };

    this.loadHubspotScript();
  }

  async created() {
    setTimeout(() => (this.isSideMenuMini = true), 5000);
  }

  updated() {
    if (
      this.$route.name == "inspection-execution" ||
      this.$route.name == "external-inspection" ||
      this.$route.name == "status-inspection" ||
      this.$route.name == "external-inspection-execution"
    ) {
      this.isNavOpen = false;
      this.isPanelVisible = false;
    } else if (
      this.$route.name != "inspection-execution" &&
      document.body.offsetWidth > 1024
    ) {
      this.isNavOpen = true;
      this.isPanelVisible = false;
    } else if (
      this.$route.name == "report-item" &&
      document.body.offsetWidth < 1024
    ) {
      this.isPanelVisible = false;
      this.isNavOpen = false;
    } else if (
      this.$route.name != "inspection-execution" &&
      document.body.offsetWidth < 1024
    ) {
      this.isPanelVisible = true;
    }
  }

  public closeNav() {
    this.isNavOpen = false;
  }

  public isSelected(route: string) {
    return this.$route.name === route;
  }

  public isDashboard() {
    return !(this.$route.name === "home");
  }

  public async setLocaleMessages() {
    this.langInited = true;
  }

  get productFruitLang() {
    let lang = navigator.language.split("-")[0].toLowerCase();
    if (!["en", "pl"].includes(lang)) {
      return "en";
    }

    return lang;
  }

  public async logout() {
    await this.$store.dispatch(AuthActions.LOGOUT);
  }

  get productFruitKey() {
    return process.env.VUE_APP_PRODUCT_FRUIT_KEY;
  }

  get productFruitProps() {
    return {
      isInspector: this.me?.role === Role.INSPECTOR,
      isOrgAdmin: this.me?.role === Role.ORGANISATION_ADMIN,
      isLocAdmin: this.me?.role === Role.LOCATION_ADMIN,
    };
  }

  onHubspotClosed() {
    this.hubspotVisibility = "hidden";
    //@ts-ignore
    window.productFruits.api.button.showButton();
  }

  loadHubspotScript() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-eu1.hs-scripts.com/26942789.js";
    document.head.appendChild(script);
  }

  @Watch("isSideMenuMini")
  onChangeIsSideMenuMini() {
    this.$nextTick(() =>
      document.dispatchEvent(new Event("content-width-updated"))
    );
  }

  protected asideLinks = [
    {
      access: "dashboard",
      route: "dashboard",
      iconsName: "dashboard",
      translationTitle: "dashboard",
    },
    {
      access: "inspections",
      route: "inspections",
      iconsName: "inspections",
      translationTitle: "inspections",
    },
    {
      access: "incidents",
      route: "incidents",
      iconsName: "incidents",
      translationTitle: "incidents",
    },
    {
      access: "reports",
      route: "reports",
      iconsName: "reports",
      translationTitle: "reports",
    },
    {
      access: "library",
      route: "library",
      iconsName: "library",
      translationTitle: "library",
    },
    {
      access: "statistics",
      route: "statistics",
      iconsName: "statistics",
      translationTitle: "statistics",
    },
    {
      access: "users",
      route: "users",
      iconsName: "users",
      translationTitle: "users",
    },
    {
      access: "qr-codes",
      route: "qr-codes",
      iconsName: "qrCodes",
      translationTitle: "qr_codes",
    },
    {
      access: "localizations",
      route: "localizations",
      iconsName: "localizations",
      translationTitle: "localizations",
    },
    {
      access: "settings",
      route: "settings",
      iconsName: "settings",
      translationTitle: "settings",
    },
    {
      access: "organisations",
      route: "organisations",
      iconsName: "organisations",
      translationTitle: "organisations",
    },
    {
      access: "logs",
      route: "logs",
      iconsName: "logs",
      translationTitle: "logs",
    },
    {
      access: "languages",
      route: "languages",
      iconsName: "languages",
      translationTitle: "languages",
    },
  ];
}
