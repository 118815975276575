import store from "@/vuex/store";
import i18n from "@/lang/locale";

export default class DateToString {
    public readonly LONG_DATE = 1;
    public readonly FULL_TIME = 2;
    public readonly SHORT_MONTH = 3;

    public convert(date: Date): string {
        return [
            String(date.getFullYear()).padStart(4, "0"),
            String(date.getMonth() + 1).padStart(2, "0"),
            String(date.getDate()).padStart(2, "0"),
        ].join("-")
    }

    public toTime(date: Date): string {
        return [
            String(date.getHours()).padStart(2, "0"),
            String(date.getMinutes()).padStart(2, "0"),
            String(date.getSeconds()).padStart(2, "0")
        ].join(":")
    }

    public getLocaleString(date: Date | null, types: number[]): string {
        if (date) {
            return date.toLocaleDateString(i18n.locale, this.getOptions(types))
        } else {
            return 'N/A';
        }
    }

    private getOptions(types: Array<number>): Object {
        const browserTimezone = store.getters.browserTimezone;

        let options: any = {timeZone: browserTimezone}

        types.forEach(type => {
            switch (type) {
                case this.LONG_DATE:
                    options['day'] = 'numeric';
                    options['month'] = 'long';
                    options['year'] = 'numeric';
                    break;
                case this.FULL_TIME:
                    options['hour'] = '2-digit';
                    options['minute'] = '2-digit';
                    options['second'] = '2-digit';
                    break;
                case this.SHORT_MONTH:
                    options['month'] = 'short';
                    break;
            }
        });

        return options;
    }
}
