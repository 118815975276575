<template>
  <div class="inspection-status__wrapper">
    <p class="inspection-status__notification">{{ notification }}</p>
    <p class="inspection-status__text">{{ text }}</p>
    <button
      @click="$emit('setContinueInspection')"
      v-if="showButton"
      class="inspection-status__button"
    >
      start inspection
    </button>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style scoped lang="scss">
.inspection-status__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspection-status__notification {
  color: #0d0e34;

  font-weight: 600;
  font-size: 26px;
  line-height: 42px;
  margin-bottom: 47px;
}

.inspection-status__text {
  color: #6d6d6d;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 36px;
}

.inspection-status__button {
  background: #1de591 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 1px solid #1de591;

  width: 234px;
  height: 48px;

  cursor: pointer;
  color: #ffffff;

  font-size: 14px;

  transition: all 0.3s;

  &:hover {
    background: white;
    color: #1de591;

    transition: all 0.3s;
  }
}

@media (max-width: 560px) {
  .inspection-status__notification {
    margin-bottom: 25px;
    text-align: center;
  }

  .inspection-status__text {
    margin-bottom: 36px;
    text-align: center;
  }
}
</style>