import {MutationTypes} from "./mutation-types";
import {BillingState} from "./state";
import {MutationTree} from "vuex";
import StripeCustomer from "@/vuex/models/billing/customer";
import StripeSubscription from "@/vuex/models/billing/subscription";
import StripePaymentMethod from "@/vuex/models/billing/payment-method";
import StripeAddress from "@/vuex/models/billing/address";
import StripeInvoice from "@/vuex/models/billing/invoice";
import Meta from "@/vuex/models/meta";

const mutations: MutationTree<BillingState> = {
    [MutationTypes.SET_CUSTOMER](state: BillingState, customer: StripeCustomer | null) {
        state.customer = customer;
    },
    [MutationTypes.SET_SUBSCRIPTION](state: BillingState, subscription: StripeSubscription | null) {
        state.subscription = subscription;
    },
    [MutationTypes.SET_PAYMENT_METHOD](state: BillingState, paymentMethod: StripePaymentMethod | null) {
        state.paymentMethod = paymentMethod;
    },
    [MutationTypes.SET_ADDRESS](state: BillingState, address: StripeAddress | null) {
        state.address = address;
    },
    [MutationTypes.SET_INVOICES](state: BillingState, response: { data: StripeInvoice[] | [] | null, meta: Meta }) {
        state.invoices = response.data;
        state.invoicesMeta = response.meta;
    },
}

export default mutations;