export enum ActionTypes {
    FETCH_CUSTOMER = "billing-module/fetch-customer",
    FETCH_SUBSCRIPTION = "billing-module/fetch-subscription",
    SAVE_SUBSCRIPTION = "billing-module/save-subscription",
    CANCEL_SUBSCRIPTION = 'billing-module/cancel-subscription',
    RESUME_SUBSCRIPTION = 'billing-module/resume-subscription',
    REQUEST_ENTERPRISE = "billing-module/request-enterprise",
    FETCH_PAYMENT_METHOD = "billing-module/fetch-payment-method",
    DELETE_PAYMENT_METHOD = "billing-module/delete-payment-method",
    FETCH_ADDRESS = "billing-module/fetch-address",
    SAVE_ADDRESS = "billing-module/save-address",
    DELETE_ADDRESS = "billing-module/delete-address",
    FETCH_COUNTRIES_LIST = 'billing-module/fetch-countries-list',
    FETCH_TAX_TYPES = 'billing-module/fetch-tax-types',
    FETCH_PRODUCTS = "billing-module/fetch-products",
    FETCH_INVOICES = "billing-module/fetch-invoices",
    CANNOT_PAY_REQUEST = 'billing-module/cannot-pay-request',
    FETCH_SETUP_INTENT = "billing-module/fetch-setup-intent",
    FETCH_PUBLIC_KEY = 'billing-module/fetch-public-key'
}
