import { ActionContext, ActionTree } from "vuex";

import { appRoutes } from "@/config";

import { http } from "@/services/http/http";

import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

import QRCodeData, {
  qrCodesPermissions,
  qrCodesType,
} from "@/vuex/models/qrCode/location-data";

import { RootState } from "../../store";

import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import QRCodeState from "./state";

export interface QRCodeSearchParams {
  page: number;
  per_page: number;
  search: string | null;
  location_id: number | null;
  permission: qrCodesPermissions.PUBLIC | qrCodesPermissions.PRIVATE | null;
  type: qrCodesType.INCIDENT | qrCodesType.INSPECTIONS | null;
}

type QRCodeActionContext = ActionContext<QRCodeState, RootState>;

const actions: ActionTree<QRCodeState, RootState> = {
  async [ActionTypes.FETCH_QR_CODES](
    store: QRCodeActionContext,
    params: QRCodeSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.qrCodes.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_QR_CODES, response.data.data);
      store.commit(MutationTypes.SET_QR_CODES_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_QR_CODE](
    store: QRCodeActionContext,
    id: number
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.qrCodes.view(id));
      return response.data;
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_QR_CODES_FOR_CSV](
    store: QRCodeActionContext,
    params: QRCodeSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.qrCodes.all(), {
        params,
      });
      store.commit(MutationTypes.SET_QR_CODES_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_QR_CODE](
    state: QRCodeActionContext,
    params: QRCodeData
  ): Promise<void> {
    await http().post(appRoutes.api.qrCodes.create(), {
      ...params,
    });
  },

  async [ActionTypes.UPDATE_QR_CODE](
    state: QRCodeActionContext,
    params: { data: QRCodeData; id: number }
  ): Promise<void> {
    if (!params.id) {
      throw new Error("cannot update model without id (not saved ?)");
    }

    await http().put(appRoutes.api.qrCodes.update(params.id), params.data);
  },

  async [ActionTypes.DELETE_QR_CODE](
    state: QRCodeActionContext,
    id: number
  ): Promise<void> {
    await http().delete(appRoutes.api.qrCodes.delete(id));
  },
};

export default actions;
