import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class StatisticalCells extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public title!: string;

  @Prop({
    type: [Number, String],
    required: true,
  })
  public count!: number | string;

  @Prop({
    type: Boolean,
    default: false,
  })
  public isEdit!: boolean;

  @Prop({
    type: Number,
    default: 0,
    required: false,
  })
  public id!: number;
}
