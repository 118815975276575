import {ActionTypes} from "./action-types";
import {http} from '@/services/http/http';
import {UserProfileState} from "./state";
import {ActionContext, ActionTree} from "vuex";
import {RootState} from "../../store";
import UserProfile from "@/vuex/models/user/user-profile";
import {appRoutes} from "@/config";
import {MutationTypes as AuthMutation} from "@/vuex/modules/auth-module/mutation-types";

type UserProfileActionContext = ActionContext<UserProfileState, RootState>

const actions: ActionTree<UserProfileState, RootState> = {
    async [ActionTypes.UPDATE_ME](store: UserProfileActionContext, profile: UserProfile): Promise<void> {
        try {
            const response = await http().put(appRoutes.api.profile.update(), profile);
            store.commit(AuthMutation.SET_ME, response.data);
        } catch (exception: any) {
            throw exception;
        }
    },
    async [ActionTypes.UPDATE_PASSWORD](store: UserProfileActionContext, payload: {
        password: string,
        password_confirmation: string
    }): Promise<void> {
        try {
            const response = await http().put(appRoutes.api.profile.password(), payload);
            store.commit(AuthMutation.SET_ME, response.data);
        } catch (exception: any) {
            throw exception;
        }
    },
    async [ActionTypes.UPDATE_SETTINGS](store: UserProfileActionContext, payload: {
        languageId: number
    }): Promise<void> {
        const response = await http().put(appRoutes.api.profile.settings(), payload);
        store.commit(AuthMutation.SET_ME, response.data);
    }
}

export default actions;