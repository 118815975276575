import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import VueI18n from "vue-i18n";


@Component
export default class InputNumber extends Vue {

  @Prop({
    type: Boolean,
    required: false,
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  public error!: string;

  @Prop({
    type: Number,
    required: false,
  })
  public value!: number;

  @Prop({
    required: false,
    default: '',
  })
  public label!: string | VueI18n.TranslateResult;

  @Prop({
    required: false,
    default: '',
  })
  public placeholder!: string | VueI18n.TranslateResult;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public disabled!: Boolean;
}
