import {Module} from "vuex";
import {RootState} from "../../store";
import OrganisationsState from "@/vuex/modules/organisations-module/state";
import actions from './actions';
import mutations from "@/vuex/modules/organisations-module/mutations";
import getters from "@/vuex/modules/organisations-module/getters";

const module: Module<OrganisationsState, RootState> = {
    state: new OrganisationsState(),
    actions,
    mutations,
    getters
}

export default module;
