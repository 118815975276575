import {ActionContext, ActionTree} from "vuex";
import {RootState} from "@/vuex/store";
import {ActionTypes} from "@/vuex/modules/browser-module/action-types";
import BrowserState from "@/vuex/modules/browser-module/state";
import {Theme} from "@/vuex/models/theme";

type BrowserActionsContext = ActionContext<BrowserState, RootState>

const actions: ActionTree<BrowserState, RootState> = {
    async [ActionTypes.APPLY_THEME](store: BrowserActionsContext): Promise<void> {
        const selectedTheme: Theme = store.rootGetters['me']?.theme || 'theme-default';

        let isDark = false
        switch (selectedTheme){
            case "theme-dark":
                isDark = true;
                break;
            case 'theme-light':
                isDark = false
                break;
            case "theme-default":
                isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
                break
        }

        localStorage.setItem('theme', selectedTheme)

        const root = document.querySelector(':root')!;
        if (isDark){
            root.classList.remove('theme-light');
            root.classList.add('theme-dark');
        }else{
            root.classList.remove('theme-dark');
            root.classList.add('theme-light');
        }
    }
}

export default actions;

