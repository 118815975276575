import i18n from "@/lang/locale";

export const generateTranslationsForLanguages = (code: string) => {
  let translatedLang;

  switch (code) {
    case "pl":
      translatedLang = i18n.t("app.views.name.code.pl").toString();
      break;
    case "en":
      translatedLang = i18n.t("app.views.name.code.en").toString();
      break;
    case "uk":
      translatedLang = i18n.t("app.views.name.code.uk").toString();
      break;
    case "fr":
      translatedLang = i18n.t("app.views.name.code.fr").toString();
      break;
    case "de":
      translatedLang = i18n.t("app.views.name.code.uk").toString();
      break;
    case "es":
      translatedLang = i18n.t("app.views.name.code.es").toString();
      break;
    default:
      translatedLang = "";
  }

  return translatedLang;
};
