<style lang="scss" src="./DeleteExternalAccessModal.scss" scoped></style>

<template>
  <Modal :modal="modal" class="modalDeleteExternalAccess">
    <div class="header" slot="header">
      {{ label }}
    </div>
    <div class="content" slot="content">
      <p class="warning-message">
        {{ $t("app.views.user.deleteNotificationAccess") }}
      </p>
    </div>
    <div class="footer" slot="footer">
      <ButtonsSaveCancel @close="$emit('close', false)" :danger="true">
        <span
          class="delete-button"
          slot="btn-save"
          @click="remove"
        >
          {{ $t("app.views.user.deleteUserAccess") }}
        </span>
      </ButtonsSaveCancel>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import LoadingSpinner from "@/components/LoadingSpinner/LoadingSpinner.vue";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import i18n from "@/lang/locale";
import Field from "@/components/Field/Field.vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/vuex/modules/external-access-module/action-types";
import store from "@/vuex/store";

export default {
  name: "DeleteUserModal",
  components: {
    Field,
    ButtonsSaveCancel,
    LoadingSpinner,
    Modal,
  },
  emits: ["loading", "close"],
  props: {
    access: {
      required: true,
    },
    modal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    label() {
      let label = i18n.t("app.views.user.deleteAccess");

      if (!!this.access) {
        label += ` ${this.access.name}`;
      }

      return label;
    },
  },
  methods: {
    ...mapActions({
      delete: ActionTypes.DELETE_EXTERNAL_ACCESS,
      fetchExternalAccesses: ActionTypes.FETCH_EXTERNAL_ACCESSES,
    }),

    async remove() {
      this.access.organisation_id = !!this.me && this.me.organisation ? this.me.organisation.id : 0;
      await this.delete(this.access.id);
      await this.fetchExternalAccesses({page: 1, per_page: 25});
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: this.$t("app.views.user.accessesDeleted"),
        type: NotificationType.SUCCESS,
      });
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.modalDeleteExternalAccess {
  .notification-text {
    margin-bottom: 25px !important;
    color: #3d3d3d;
  }
  .footer {
    margin-top: -35px;
  }
  .content {
    .input_default {
      p {
        margin: 0 !important;
      }
    }
    .custom-select {
      p {
        margin-bottom: 0px !important;
      }
    }
    .help.is-danger {
      text-align: left !important;
    }
  }
}
</style>
