import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class InputSwitch extends Vue {
  private value: Boolean = false

  @Prop({
    type: Boolean,
    required: false,
  })
  public hasError!: boolean

  @Prop({
    type: String,
    required: false,
  })
  public error!: string

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public label!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public disabled!: Boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public checked!: Boolean

  @Watch('checked', {immediate: true})
  private watchChecked(): void {
    this.value = this.checked;
  }
}
