import Organisation from "@/vuex/models/organisation/organisation";
import Meta from "@/vuex/models/meta";
import { SelectOption } from "@/components/InputSelect/InputSelect";

export default class OrganisationsState {
  public organisations: Organisation[] | [] = [];
  public organisationsForCsv: Organisation[] | [] = [];
  public selectOptions: SelectOption[] | [] = [];
  public meta!: Meta;
}
