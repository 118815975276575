import { Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import ReportSection from "@/views/ReportView/components/ReportSection.vue";
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { ActionTypes } from "@/vuex/modules/reports-module/action-types";
import { PageMixin } from "@/mixins/pageMixin";
import printJS from "print-js";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import FormErrors from "@/services/formErrors/FormErrors";
import ReportFull from "@/vuex/models/report/report-full";
import { MutationTypes } from "@/vuex/modules/reports-module/mutation-types";

@Component({
  methods: {
    ...mapActions({
      fetchReport: ActionTypes.FETCH_REPORT,
      fetchPdf: ActionTypes.FETCH_PDF,
      sendReport: ActionTypes.SEND_REPORT,
    }),
  },
  computed: {
    ...mapGetters({
      report: "report",
      pdf: "reportPdf",
    }),
  },
  components: {
    ReportSection,
    Modal,
    ButtonsSaveCancel,
    SvgIcon,
  },
})
export default class ReportView extends PageMixin {
  protected readonly fetchReport!: (reportId: number) => Promise<void>;
  protected readonly fetchPdf!: (reportUuid: string) => Promise<void>;
  protected readonly sendReport!: (params: {
    emails: string[];
    reportId: number;
  }) => Promise<void>;

  protected readonly report!: ReportFull;
  protected readonly pdf!: string;
  protected readonly printJS = printJS;
  protected readonly formErrors: FormErrors = new FormErrors();

  protected emailModal: boolean = false;
  protected emails: string[] = [];
  protected email: string | null = null;

  protected async created(): Promise<void> {
    this.loading = true;
    this.$store.commit(MutationTypes.SET_PDF, null);
    await this.fetchReport(Number(this.$route.params.id));

    if (!this.$route.params.organisation_name) {
      await this.$router.replace({
        params: {
          organisation_name: this.report.organisation.name.replace(" ", ""),
        },
        query: { first: this.$route.query.first },
      });
    }
    this.loading = false;
  }

  private goBackOrDashboard() {
    if (window.innerWidth < 1080) {
      return this.$router.push({ name: "dashboard" });
    }

    if (this.$route.query.first) {
      return this.$router.push({ name: "dashboard" });
    }
    this.$router.back();
  }

  private async getPdfBlobUrl(): Promise<string> {
    if (!this.pdf) {
      await this.fetchPdf(this.report.uuid);
    }

    const pdfBlob: Blob = new Blob([this.pdf], { type: "application/pdf" });

    return URL.createObjectURL(pdfBlob);
  }

  protected async print(): Promise<void> {
    this.loading = true;

    const pdfBlobUrl: string = await this.getPdfBlobUrl();
    this.printJS({ printable: pdfBlobUrl, type: "pdf" });
    URL.revokeObjectURL(pdfBlobUrl);

    this.loading = false;
  }

  protected async viewPdf(): Promise<void> {
    this.loading = true;

    const pdfBlobUrl: string = await this.getPdfBlobUrl();
    const pdfWindow = window.open(pdfBlobUrl);

    pdfWindow?.addEventListener("beforeunload", () => {
      URL.revokeObjectURL(pdfBlobUrl);
    });

    this.loading = false;
  }

  protected async sendEmail(): Promise<void> {
    this.loading = true;

    try {
      this.formErrors.clearErrors();

      if (!!this.email) {
        this.addEmail(this.email);
      }

      await this.sendReport({
        emails: this.emails,
        reportId: this.report.id,
      });

      this.closeModal();
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
      this.formErrors.setErrors(exception.response.data.errors);
    }

    this.loading = false;
  }

  protected addEmail(email: string): void {
    if (email?.includes("@") && email.includes(".")) {
      this.emails.push(email);
      this.email = null;
    }
  }

  protected removeEmail(index: number): void {
    this.emails.splice(index, 1);
  }

  protected closeModal(): void {
    this.emailModal = false;
    this.emails = [];
    this.email = null;
  }
}
