import { Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { FormMixin } from "@/mixins/formMixin";
import InspectionStatus from "@/components/InspectionStatus/InspectionStatus.vue";

@Component({
  components: {
    InspectionStatus,
  },
  methods: mapActions({}),
  computed: mapGetters({
    inspection: "inspection",
  }),
})
export default class InspectionStatusView extends FormMixin {
  protected statusInspection: string = "";

  protected handleContinueInspection() {
    this.$router.push({
      name: "external-inspection",
      params: { id: this.$route.query.id.toString() },
    });
  }

  protected async created(): Promise<void> {
    this.statusInspection = this.$route?.query?.status.toString() || "";
  }
}
