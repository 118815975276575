<template>
  <div class="view-wrapper TableViewLayout">
    <div class="Header">
      <div class="TitleWithItems">
        <div class="Title">{{ title }}</div>
        <div class="Items">
          <div v-if="$slots['header-items']" class="Left">
            <slot name="header-items" />
          </div>
          <div v-if="$slots['header-items-right']" class="Right">
            <slot name="header-items-right" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasFilters" class="Filters">
      <slot name="filters" />
      <drop-down @exportCsvFile="$emit('exportCsvFile')" v-if="isCsvButton" />
    </div>
    <div v-if="hasFilterPanelTab" class="Filter-tab-panel">
      <slot name="filter-tab-panel" />
      <drop-down @exportCsvFile="$emit('exportCsvFile')" v-if="isCsvButton" />
    </div>
    <div
      :class="{ with_filter: hasFilters, empty_table: isEmpty }"
      class="Table"
    >
      <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import DropDown from "./DropDown.vue";
export default {
  name: "TableViewLayout",
  components: { DropDown },
  props: {
    title: {
      required: true,
    },
    hasFilterPanelTab: {
      type: Boolean,
      default: false,
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isCsvButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenDropDown: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.closeDropDown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropDown);
  },
  methods: {
    handleIsOpenDropDown() {
      this.isOpenDropDown = !this.isOpenDropDown;
    },
    closeDropDown(event) {
      if (
        this.$refs.dropDownBlock &&
        !this.$refs.dropDownBlock.contains(event.target)
      ) {
        this.isOpenDropDown = false;
      }
    },
  },
};
</script>

<style lang="scss">
.TableViewLayout {
  display: flex;
  flex-direction: column;
  position: relative;

  .Header {
    padding-bottom: 26px;

    .TitleWithItems {
      display: flex;
      align-items: center;
      column-gap: 40px;

      .Title {
        color: #0d0e34;
        font-size: 26px;
        line-height: 42px;
        font-weight: 600;
        white-space: nowrap;
      }

      .Items {
        display: flex;
        column-gap: 30px;
        width: 100%;

        & > .Left,
        & > .Right {
          display: flex;
          column-gap: 30px;
          flex-grow: 1;

          .SearchInput {
            min-width: 150px;
            max-width: 368px;
            box-shadow: 0 0 16px #0000000f;
          }
        }

        & > .Left {
          .SearchInput {
            min-width: 150px;
            max-width: 368px;
            box-shadow: 0 0 16px #0000000f;
          }
        }

        & > .Right {
          display: flex;
          gap: 30px;
          justify-content: end;
          flex-grow: 1;

          .SearchInput {
            min-width: 150px;
            max-width: 300px;
            box-shadow: 0 0 16px #0000000f;
          }
        }

        .Button {
          display: flex;
          align-items: center;
          padding: 9px;
          min-width: 150px;
          background: #1de591;
          color: #ffffff;
          border-radius: 122px;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 16px #00000029;
          }

          &.IsTiny {
            min-width: unset;

            .Icon {
              margin-right: 0;
            }
          }

          .Icon {
            width: 30px;
            height: 30px;
            margin-right: 22px;
          }

          .Text {
            text-align: center;
          }
        }
      }
    }
  }

  .Filters {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    z-index: 10;

    padding: 18px 30px;

    min-height: 70px;
    width: 100%;
    background: #e6e6e6 0 0 no-repeat padding-box;
    border-radius: 10px 10px 0 0;

    .wrapper-icon {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    .export-csv_icon {
      cursor: pointer;
    }

    .filter-button-block {
      position: relative;
      line-height: 0;
    }
  }

  .dropDown-block {
    position: absolute;
    right: 0;
    z-index: 10;
    top: 30px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000000f;
    border: 1px solid #e6e6e6;
    border-radius: 3px;

    display: flex;
    align-items: center;

    width: 141px;
    height: 44px;
    padding: 0px 13px 0px 19px;

    cursor: pointer;

    span {
      font-size: 12px;
      line-height: 15px;
      color: #6d6d6d;
    }
  }

  .Filter-tab-panel {
    position: relative;
    z-index: 10;

    padding-top: 16px;

    width: 100%;

    background: #e6e6e6 0 0 no-repeat padding-box;
    border-radius: 10px 10px 0 0;
  }

  .Table {
    display: flex;
    border-radius: 0 0 10px 10px;

    &.with_filter {
      background: #e6e6e6 0 0 no-repeat padding-box;
    }

    &.empty_table {
      flex-grow: 1;
    }

    .table-wrapper {
      min-width: 100%;
    }
  }
}

.filter {
  max-width: 262px;
}
</style>
