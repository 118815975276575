export enum ActionTypes {
    FETCH_ME = "auth-module/fetch-me",
    LOGIN = "auth-module/login",
    LOGOUT = "auth-module/logout",
    REGISTER = "auth-module/register",
    FORGOT_PASSWORD = "auth-module/forgot-password",
    RESET_PASSWORD = "auth-module/reset-password",
    CONFIRM_EMAIL = "auth-module/confirm-email",
    RESEND_EMAIL = "auth-module/resend-email"
}
