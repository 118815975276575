export enum ActionTypes {
  FETCH_INCIDENTS = "incidents-module/fetch-incidents",
  FETCH_INCIDENTS_FOR_CSV = "incidents-module/fetch-incidents-for-csv",
  SOLVE_INCIDENT = "incidents-module/solve-incident",
  COUNT_INCIDENTS = "incidents-module/count-incidents",
  FETCH_INCIDENTS_TYPES = "incidents-module/fetch-incidents-types",
  FETCH_INCIDENTS_TYPES_FOR_FILTER = "incidents-module/fetch-incidents-types-for-filter",
  CREATE_INCIDENT_TYPE = "incidents-module/create-incident-type",
  UPDATE_INCIDENT_TYPE = "incidents-module/update-incident-type",
  DELETE_INCIDENT_TYPE = "incidents-module/delete-incident-type",
  ADD_INCIDENT = "incidents-module/add-incident",
  ADD_INCIDENT_TYPE_REPORT = "incidents-module/add-incident-type-report",
}
