<template>
  <div class="personal_data">
    <div class="personal_data_title">
      <div class="personal_data_title_image">
        <img
          :src="`https://ui-avatars.com/api/?name=${me.name} ${me.surname}&color=FFFFFF&background=00E493`"
          class="radio"
          alt="ico profile"
        />
      </div>
    </div>

    <div class="personal_data_body">
      <input-text
        :label="$t('components.form.userName').capitalize()"
        type="text"
        v-model="name"
        :hasError="formErrors.hasErrors('name')"
        :error="formErrors.get('name')"
      />
      <input-text
        :label="$t('app.views.user.surname').capitalize()"
        type="text"
        v-model="surname"
        :hasError="formErrors.hasErrors('surname')"
        :error="formErrors.get('surname')"
      />
      <input-text
        :label="$t('app.views.user.email').capitalize() + ' (login)'"
        type="email"
        disabled
        v-model="me.email"
        :hasError="formErrors.hasErrors('email')"
        :error="formErrors.get('email')"
      />
      <input-text
        :label="$t('app.views.user.role').capitalize()"
        type="text"
        :value="
          $t('app.views.user.roles.' + auth.roleDisplayName(me.role)).toString()
        "
        :disabled="true"
      />
    </div>
    <div class="personal_data_action">
      <ButtonsSaveCancel
        :dont-show-close="true"
        :save-label="$t('components.save')"
        @save="submit"
      ></ButtonsSaveCancel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormErrors from "@/services/formErrors/FormErrors";
import Authorization from "@/services/authorization/authorization";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { ActionTypes } from "@/vuex/modules/profile-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

export default {
  name: "PersonalDataTab",
  emits: ["loading"],
  components: { ButtonsSaveCancel },
  computed: {
    ...mapGetters(["me"]),
  },
  data() {
    return {
      auth: new Authorization(),
      formErrors: new FormErrors(),

      name: null,
      surname: null,
    };
  },
  created() {
    this.name = this.me.name;
    this.surname = this.me.surname;
  },
  methods: {
    async submit() {
      const profile = {
        id: this.me.id,
        name: this.name,
        surname: this.surname,
      };

      this.$emit("loading");

      try {
        await this.$store.dispatch(ActionTypes.UPDATE_ME, profile);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: this.$t('app.views.user.personalDataUpdated'),
          type: NotificationType.SUCCESS,
        });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.$emit("loading");
    },
  },
};
</script>

<style scoped lang="scss">
.personal_data {
  &_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    @media (max-width: 640px) {
      margin-inline: 15px;
      flex-direction: column;
    }

    &_image {
      width: 150px;
      height: 150px;

      @media (max-width: 640px) {
        align-self: center;
        width: max-content;
      }

      img {
        border-radius: 50%;
        height: 150px;
        width: 150px;
      }
    }

    &_add-image {
      text-align: center;
      font: normal normal normal 12px/18px Poppins;
      letter-spacing: 0;
      color: #1de591;

      background: #ffffff 0 0 no-repeat padding-box;
      border: 2px solid #1de591;
      border-radius: 46px;
      cursor: pointer;
      height: 35px !important;
    }

    &_add-image:hover {
      color: #fff;
      background-color: #1de591;
    }
  }

  &_body {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_action {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
</style>
