export enum qrCodesStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum qrCodesPermissions {
  PUBLIC = 0,
  PRIVATE = 1,
  ORGANISATION = 2,
}

export enum qrCodesType {
  INCIDENT = 1,
  INSPECTIONS = 0,
}

export default interface QRCodeData {
  name: string | null;
  organisation_id: number | null;
  template_id: number | null;
  type: qrCodesType.INCIDENT | qrCodesType.INSPECTIONS;
  location_id: number | null;
  status: qrCodesStatus.ACTIVE | qrCodesStatus.INACTIVE;
  permission: qrCodesPermissions.PRIVATE | qrCodesPermissions.PUBLIC | qrCodesPermissions.ORGANISATION;
  users_ids?: number[];
}
