import { Component, Mixins, Watch } from "vue-property-decorator";
import OrganisationForm from "@/views/OrganisationsView/components/OrganistaionForm/OrganisationForm.vue";
import { mapActions, mapGetters } from "vuex";
import { PageMixin } from "@/mixins/pageMixin";
import { ActionTypes } from "@/vuex/modules/organisations-module/action-types";
import { OrganisationSearchParams } from "@/vuex/modules/organisations-module/actions";
import Organisation from "@/vuex/models/organisation/organisation";
import Meta from "@/vuex/models/meta";
import { OrganisationsSortableColumns } from "@/services/helpers/sort";
import UserForm from "@/views/UsersView/components/UserForm/UserForm";
import DeleteOrganisationModal from "@/views/OrganisationsView/components/DeleteOrganisationModal.vue";
import { Debounce } from "vue-debounce-decorator";
import i18n from "@/lang/locale";
import { generateTranslationForPlan } from "@/services/helpers/generateTranslationForPlan";

@Component({
  components: {
    DeleteOrganisationModal,
    UserForm,
    OrganisationForm,
  },
  methods: mapActions({
    fetchOrganisations: ActionTypes.FETCH_ORGANISATIONS,
    fetchOrganisationsForCsv: ActionTypes.FETCH_ORGANISATIONS_FOR_CSV,
  }),
  computed: mapGetters({
    organisations: "organisations",
    organisationsForCsv: "organisationsForCsv",
    meta: "organisationsMeta",
  }),
})
export default class OrganisationsView extends Mixins(PageMixin) {
  protected readonly fetchOrganisations!: (
    params: OrganisationSearchParams
  ) => Promise<void>;
  protected readonly fetchOrganisationsForCsv!: (
    params: OrganisationSearchParams
  ) => Promise<void>;

  protected readonly organisationsForCsv!: Organisation[] | [];
  protected readonly organisations!: Organisation[] | [];
  protected readonly meta!: Meta;
  protected readonly sortableColumns = OrganisationsSortableColumns;

  protected selectedOrganisation: Organisation | null = null;
  protected searchParams: OrganisationSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
    timezone: null,
    active: null,
    allow_to_join: null,
    manual_payment: null,
  };

  protected async created(): Promise<void> {
    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchOrganisations(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchOrganisations(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;
    this.selectedOrganisation = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async closeModal(submitted: boolean): Promise<void> {
    this.showDeleteModal = false;
    this.selectedOrganisation = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected update(organisation: Organisation): void {
    this.showForm = true;
    this.selectedOrganisation = organisation;
  }

  protected remove(organisation: Organisation): void {
    this.showDeleteModal = true;
    this.selectedOrganisation = organisation;
  }

  protected handleGnerateTranslationForPlan = (slug: string) => {
    return generateTranslationForPlan(slug);
  };

  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      i18n.t("components.table.organisationAdmin").toString(),
      i18n.t("components.table.planName").toString(),
      i18n.t("components.table.numberOfLocations").toString(),
      i18n.t("components.table.numberOfUsers").toString(),
      i18n.t("components.table.externalAccess").toString(),
      i18n.t("components.table.numberOfInspections").toString(),
      i18n.t("components.table.qrCodes").toString(),
      i18n.t("components.table.manualPayment").toString(),
      i18n.t("components.table.status").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchOrganisationsForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.organisationsForCsv.map((organisation) => ({
        [i18n.t("components.table.name").toString()]: organisation.name,
        [i18n.t("components.table.organisationAdmin").toString()]:
          !!organisation.admin
            ? organisation.admin.name + " " + organisation.admin.surname
            : "-",
        [i18n.t("components.table.planName").toString()]: i18n.t(
          this.handleGnerateTranslationForPlan(
            organisation?.product?.slug ?? ""
          )
        ),
        [i18n.t("components.table.numberOfLocations").toString()]:
          !!organisation.locations_limit
            ? organisation.locations_limit
            : i18n.t("components.unlimited").toString(),
        [i18n.t("components.table.numberOfUsers").toString()]:
          !!organisation.users_limit &&
          organisation?.product?.name == "Enterprise"
            ? organisation.users_limit
            : i18n.t("components.unlimited").toString(),
        [i18n.t("components.table.externalAccess").toString()]:
          !!organisation.external_accesses_limit && organisation?.product?.name === "Enterprise"
            ? organisation.external_accesses_limit
            : organisation?.product?.name === "Enterprise"
              ?  i18n.t("components.unlimited").toString()
              : "-",
        [i18n.t("components.table.numberOfInspections").toString()]:
          !!organisation.inspections_limit
            ? organisation.inspections_limit
            : i18n.t("components.unlimited").toString(),
        [i18n.t("components.table.qrCodes").toString()]:
          !!organisation.qr_codes_limit && organisation?.product?.name === "Enterprise"
            ? organisation.qr_codes_limit
            : organisation?.product?.name === "Enterprise"
              ?  i18n.t("components.unlimited").toString()
              : "-",
        [i18n.t("components.table.manualPayment").toString()]:
          organisation.manual_payment
            ? i18n.t("components.table.manualPaymentOn")
            : i18n.t("components.table.manualPaymentOff"),
        [i18n.t("components.table.status").toString()]: organisation.active
          ? i18n.t("app.views.userStatus.active")
          : i18n.t("app.views.userStatus.inactive"),
      })),
      this.generateHeaders(),
      "organisations.csv"
    );
  }
}
