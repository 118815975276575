import {Module} from "vuex";
import {RootState} from "../../store";
import actions from './actions';
import LanguagesState from "@/vuex/modules/languages-module/state";
import getters from "@/vuex/modules/languages-module/getters";
import mutations from "@/vuex/modules/languages-module/mutations";


const module: Module<LanguagesState, RootState> = {
    state: new LanguagesState(),
    actions,
    getters,
    mutations,
}

export default module

