import {Module} from "vuex";
import {RootState} from "../../store";
import actions from './actions';
import getters from './getters'
import mutations from './mutations'
import StatisticsState from "@/vuex/modules/statistics-module/state";


const module: Module<StatisticsState, RootState> = {
    state: new StatisticsState(),
    actions,
    getters,
    mutations,
}

export default module

