var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showForm)?_c('right-side-nav',{on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc("app.views.organisation.organisation", 1))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('form',[_c('div',{staticClass:"inputs"},[_c('input-text',{attrs:{"label":_vm.$t('components.form.name'),"hasError":_vm.formErrors.hasErrors('name'),"error":_vm.formErrors.get('name')?.toString()},model:{value:(_vm.organisationData.name),callback:function ($$v) {_vm.$set(_vm.organisationData, "name", $$v)},expression:"organisationData.name"}}),_c('input-select',{attrs:{"value":_vm.organisationData.timezone,"label":_vm.$t('app.views.organisation.timeZone'),"options":_vm.timezoneOptions,"with-search":true,"hasError":_vm.formErrors.hasErrors('timezone'),"error":_vm.formErrors.get('timezone')?.toString()},on:{"select":function($event){_vm.organisationData.timezone = $event}}}),_c('input-text',{attrs:{"label":_vm.$t('app.views.organisation.domain'),"hasError":_vm.formErrors.hasErrors('domain'),"error":_vm.formErrors.get('domain')?.toString()},model:{value:(_vm.organisationData.domain),callback:function ($$v) {_vm.$set(_vm.organisationData, "domain", $$v)},expression:"organisationData.domain"}}),_c('input-select',{attrs:{"label":_vm.$t('app.views.user.access'),"options":_vm.yesNoOptions,"value":_vm.organisationData.allow_to_join,"hasError":_vm.formErrors.hasErrors('allow_to_join'),"error":_vm.formErrors.get('allow_to_join')?.toString()},on:{"select":_vm.setUserAccess}}),(!!_vm.organisation)?_c('input-number',{attrs:{"disabled":!_vm.organisation?.product?.slug ||
            _vm.organisation?.product?.slug === 'team' ||
            _vm.organisation?.product?.slug === 'pro',"label":_vm.$t('components.form.numberOfUsers'),"hasError":_vm.formErrors.hasErrors('users_limit'),"error":_vm.formErrors.get('users_limit')?.toString()},model:{value:(_vm.organisationData.users_limit),callback:function ($$v) {_vm.$set(_vm.organisationData, "users_limit", $$v)},expression:"organisationData.users_limit"}}):_vm._e(),(!!_vm.organisation)?_c('input-number',{attrs:{"label":_vm.$t('components.form.numberOfLocations'),"hasError":_vm.formErrors.hasErrors('location_limit'),"error":_vm.formErrors.get('location_limit')?.toString()},model:{value:(_vm.organisationData.locations_limit),callback:function ($$v) {_vm.$set(_vm.organisationData, "locations_limit", $$v)},expression:"organisationData.locations_limit"}}):_vm._e(),(!!_vm.organisation)?_c('input-number',{attrs:{"disabled":!_vm.organisation?.product?.slug ||
            _vm.organisation?.product?.slug === 'team' ||
            _vm.organisation?.product?.slug === 'pro',"label":_vm.$t('components.form.numberOfInspections'),"hasError":_vm.formErrors.hasErrors('inspection_limit'),"error":_vm.formErrors.get('inspection_limit')?.toString()},model:{value:(_vm.organisationData.inspections_limit),callback:function ($$v) {_vm.$set(_vm.organisationData, "inspections_limit", $$v)},expression:"organisationData.inspections_limit"}}):_vm._e(),(!!_vm.organisation)?_c('input-number',{attrs:{"disabled":!_vm.organisation?.product?.slug ||
            _vm.organisation?.product?.slug === 'team' ||
            _vm.organisation?.product?.slug === 'pro',"label":_vm.$t('components.form.externalAccess'),"hasError":_vm.formErrors.hasErrors('external_accesses_limit'),"error":_vm.formErrors.get('external_accesses_limit')?.toString()},model:{value:(_vm.organisationData.external_accesses_limit),callback:function ($$v) {_vm.$set(_vm.organisationData, "external_accesses_limit", $$v)},expression:"organisationData.external_accesses_limit"}}):_vm._e(),(!!_vm.organisation)?_c('input-number',{attrs:{"disabled":!_vm.organisation?.product?.slug ||
            _vm.organisation?.product?.slug === 'team' ||
            _vm.organisation?.product?.slug === 'pro',"label":_vm.$t('components.form.qrCodes'),"hasError":_vm.formErrors.hasErrors('qr_codes_limit'),"error":_vm.formErrors.get('qr_codes_limit')?.toString()},model:{value:(_vm.organisationData.qr_codes_limit),callback:function ($$v) {_vm.$set(_vm.organisationData, "qr_codes_limit", $$v)},expression:"organisationData.qr_codes_limit"}}):_vm._e(),(!!_vm.organisation)?_c('input-select',{attrs:{"label":_vm.$t('components.table.manualPayment'),"options":_vm.onOffOptions,"value":_vm.organisationData.manual_payment,"hasError":_vm.formErrors.hasErrors('manual_payment'),"error":_vm.formErrors.get('manual_payment')?.toString()},on:{"select":_vm.setManualPayment}}):_vm._e()],1),_c('div',{staticClass:"buttons"},[_c('ButtonsSaveCancel',{on:{"close":_vm.close,"save":_vm.submit}})],1)])]},proxy:true}],null,false,1903456622)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }