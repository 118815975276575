import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../store";
import UsersState from "./state";
import { ActionTypes } from "./action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "./mutation-types";
import { Role } from "@/services/authorization/authorization";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { Direction, UsersSortableColumns } from "@/services/helpers/sort";
import UserData from "@/vuex/models/user/user-data";
import UserFull from "@/vuex/models/user/user-full";

type UsersActionsContext = ActionContext<UsersState, RootState>;

export interface UserSearchParams {
  page: number;
  per_page: number;
  column: UsersSortableColumns | null;
  direction: Direction | null;
  search: string | null;
  organisation_id: number | null;
  role: Role | null;
  locations_ids: Array<number>;
}

export interface UserOrganisationLocationsSearchParams {
  organisation_id: number | null;
}

export interface UserInspectorsSearchParams {
  location_id: number | null;
}

const actions: ActionTree<UsersState, RootState> = {
  async [ActionTypes.FETCH_USERS](
    store: UsersActionsContext,
    params: UserSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.users.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_USERS, response.data.data);
      store.commit(MutationTypes.SET_USERS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_USERS_FOR_CSV](
    store: UsersActionsContext,
    params: UserSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.users.all(), {
        params,
      });
      store.commit(MutationTypes.SET_USERS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_USERS_FOR_ASSIGN](
    store: UsersActionsContext,
    params: { id: number }
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.users.usersInUserLocation(params.id)
      );
      store.commit(MutationTypes.SET_USERS_FOR_ASSIGN, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_USER](
    store: UsersActionsContext,
    user: UserData
  ): Promise<UserFull> {
    const response = await http().post(appRoutes.api.users.index(), {
      ...user,
    });

    return response.data;
  },

  async [ActionTypes.UPDATE_USER](
    store: UsersActionsContext,
    user: UserData
  ): Promise<UserFull> {
    if (!user.id) {
      throw new Error("cannot update model without id (not saved ?)");
    }

    const response = await http().put(appRoutes.api.users.update(user.id), {
      ...user,
    });

    return response.data;
  },

  async [ActionTypes.DELETE_USER](
    store: UsersActionsContext,
    userId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.users.destroy(userId));
  },

  async [ActionTypes.ALLOW_USER](
    store: UsersActionsContext,
    params: {
      userId: number;
      host: string;
    }
  ): Promise<void> {
    await http().post(appRoutes.api.users.allow(params.userId), params);
  },

  async [ActionTypes.DENY_USER](
    store: UsersActionsContext,
    params: { userId: number; host: string }
  ): Promise<void> {
    await http().post(appRoutes.api.users.deny(params.userId), params);
  },
};

export default actions;
