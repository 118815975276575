import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";

import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";

import ModalForAssignment from "./../ModalForAssignment/ModalForAssignment.vue";
import PopupPhoto from "./../PopupPhoto/PopupPhoto.vue";

import { TIncidentPhotoData, TOpenAssignModal } from "../../types";
import { http } from "@/services/http/http";
import Incident from "@/vuex/models/incident/incident";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import moment from "moment";
import { FormMixin } from "@/mixins/formMixin";
import AssignUserColumn from "./../AssignUserColumn/AssignUserColumn.vue";

@Component({
  components: {
    ButtonsSaveCancel,
    ModalForAssignment,
    PopupPhoto,
    AssignUserColumn,
  },
})
export default class ModalIncidentInfo extends FormMixin {
  @Prop({
    required: true,
    type: Number,
    default: false,
  })
  public showIncidentInfoModal!: number;

  protected openAssignModalData: TOpenAssignModal | null = null;
  protected incidentPhotoData: TIncidentPhotoData | null = null;

  protected incidentData: Incident | null = null;

  protected openAssignModal(incidentData: Incident) {
    this.openAssignModalData = incidentData;
  }

  private get dateIncidnet(): string {
    return moment(this.incidentData?.date ?? new Date()).format("MMMM D, YYYY");
  }

  private get solvedDateIncidnet() {
    if (this.incidentData?.solved_date) {
      return moment(this.incidentData?.solved_date ?? new Date()).format(
        "MMMM D, YYYY"
      );
    }
  }

  protected openIncidentsPopup(name: string, photoUrl: string) {
    this.incidentPhotoData = {
      report_name: name,
      pictureUrl: photoUrl,
    };
  }

  protected closeAssignModal(isSubmit: boolean) {
    this.openAssignModalData = null;

    if (isSubmit) {
      this.getCurrentIncident();
      this.$emit("getIncidents");
    }
  }

  private async getCurrentIncident() {
    try {
      const response = await http().get(
        `/api/incidents/${+this.showIncidentInfoModal}`
      );
      this.incidentData = response.data;
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response?.data?.message,
        type: NotificationType.DANGER,
      });
    }
  }

  created() {
    this.getCurrentIncident();
  }
}
