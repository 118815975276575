import {ActionTypes} from "./action-types";
import {http} from '@/services/http/http';
import AuthState from "./state";
import {MutationTypes as AuthMutations, MutationTypes} from "./mutation-types";
import {ActionContext, ActionTree} from "vuex";
import {RootState} from "../../store";
import router from "@/routes";

export interface LoginCredentials {
    email: string;
    password: string;
    browser_language: string;
}

export interface RegisterCredentials {
    name: string;
    surname: string;
    email: string;
    password: string;
    host: string;
    is_accept_marketing_consent: boolean;
    is_accept_privacy_policy: boolean;
}

export interface ResetPasswordCredentials {
    password: string;
    code: string;
}

type AuthActionContext = ActionContext<AuthState, RootState>

const actions: ActionTree<AuthState, RootState> = {
    async [ActionTypes.FETCH_ME](store: AuthActionContext): Promise<void> {
        try {
            const response = await http().get('/api/auth/me');
            store.commit(MutationTypes.SET_ME, response.data);
        } catch (exception: any) {
            store.commit(MutationTypes.CLEAR_ME);
            store.commit(MutationTypes.CLEAR_TOKEN);
        }
    },

    async [ActionTypes.LOGIN](store: AuthActionContext, credentials: LoginCredentials): Promise<void> {
        try {
            const response = await http().post('/api/auth/login', credentials);
            const now: Date = new Date();

            store.commit(MutationTypes.SET_TOKEN, {
                token: response.data.token,
                expiry: now.getTime() + (response.data.expires_in * 1000)
            });

        } catch (exception: any) {
            throw exception;
        }
    },

    async [ActionTypes.LOGOUT](store: AuthActionContext): Promise<void> {
        try {
            await http().get('/api/auth/logout');

            store.commit(AuthMutations.CLEAR_TOKEN);
            store.commit(AuthMutations.CLEAR_ME);

            await router.push({name: 'login'});

            document.location.reload();
        } catch (exception: any) {
            throw exception;
        }
    },

    async [ActionTypes.REGISTER](store: AuthActionContext, credentials: RegisterCredentials): Promise<string> {
        const response = await http().post('/api/auth/register', credentials);

        return response.data.email;
    },

    async [ActionTypes.FORGOT_PASSWORD](store: AuthActionContext, params: {
        email: string,
        host: string
    }): Promise<void> {
        await http().post('/api/auth/send-password-reset-code', params);
    },

    async [ActionTypes.RESET_PASSWORD](store: AuthActionContext, params: ResetPasswordCredentials): Promise<void> {
        await http().post('/api/auth/reset-password', params);
    },

    async [ActionTypes.CONFIRM_EMAIL](store: AuthActionContext, code: string): Promise<void> {
        await http().get('/api/auth/confirm-email/' + code);
    },
    async [ActionTypes.RESEND_EMAIL](store: AuthActionContext, params: {
        code: string | null,
        email: string | null,
        host: string
    }): Promise<void> {
        await http().post('/api/auth/resend-email', params);
    }
}

export default actions;
