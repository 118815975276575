import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { LocationSearchParams } from "@/vuex/modules/locations-module/actions";

import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import InputSelectLocation from "@/components/InputSelectLocation/InputSelectLocation.vue";
import { StatisticDataCounter } from "@/views/Statistics/components/StatisticCounterModalForm/StatisticDataCounter";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { Debounce } from "vue-debounce-decorator";
import { ActionTypes as LocationActions } from "@/vuex/modules/locations-module/action-types";
import {
  Direction,
  LocationsSortableColumns,
  UsersSortableColumns,
} from "@/services/helpers/sort";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { FormMixin } from "@/mixins/formMixin";
import { http } from "@/services/http/http";
import UserFull from "@/vuex/models/user/user-full";
import i18n from "@/lang/locale";
import moment from "moment";
@Component({
  methods: mapActions({
    fetchLocations: LocationActions.FETCH_LOCATIONS,
    fetchUsers: UserActions.FETCH_USERS,
  }),
  components: {
    InputSelectLocation,
    ButtonsSaveCancel,
    Field,
  },
  computed: mapGetters({
    user: "me",
    locationOptions: "locationSelectOptions",
    usersOptions: "userSelectOptions",
  }),
})
export default class StatisticFormCounter extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean | string;

  @Prop({
    required: true,
    type: Number,
  })
  public activeBoard!: number;

  @Prop({
    required: true,
    type: Number,
  })
  public currentCounterId!: number;

  @Prop({
    required: true,
    type: Number,
  })
  public currentChartId!: number;

  public label: string = "";

  protected statisticData: StatisticDataCounter = new StatisticDataCounter();
  protected readonly fetchLocations!: (
    params: LocationSearchParams
  ) => Promise<void>;

  protected readonly fetchUsers!: (params: UserSearchParams) => Promise<void>;
  protected readonly locationOptions!: SelectOption[];
  protected readonly usersOptions!: SelectOption[];
  protected readonly user!: UserFull;

  protected readonly dataTypeOptions = [
    {
      label: i18n.t("app.views.statistics.numberOfInspections").toString(),
      value: 0,
    },
    {
      label: i18n
        .t("app.views.statistics.numberOfPastDueInspections")
        .toString(),
      value: 1,
    },
    {
      label: i18n.t("app.views.statistics.avgDurationOfInspections").toString(),
      value: 2,
    },
    {
      label: i18n.t("app.views.statistics.avgScoreOfInspection").toString(),
      value: 3,
    },
    {
      label: i18n.t("app.views.statistics.numberOfIncidents").toString(),
      value: 4,
    },
    {
      label: i18n
        .t("app.views.statistics.numberOfSolvedInspections")
        .toString(),
      value: 5,
    },
    {
      label: i18n
        .t("app.views.statistics.numberOfUnsolvedInspections")
        .toString(),
      value: 6,
    },
  ];

  protected locationSearchParams: LocationSearchParams = {
    page: 1,
    per_page: 100,
    column: LocationsSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    organisation_id: null,
  };

  protected usersSearchParams: UserSearchParams = {
    page: 1,
    per_page: 100,
    column: UsersSortableColumns.NAME,
    direction: Direction.DESC,
    search: null,
    locations_ids: [],
    organisation_id: null,
    role: null,
  };

  protected chartTypesOptions = [
    { label: i18n.t("app.views.chartType.line").toString(), value: 0 },
    { label: i18n.t("app.views.chartType.bar").toString(), value: 2 },
  ];

  protected getLabel() {
    switch (this.showForm) {
      case "add_counter":
        this.label = "app.views.statistics.addCounter";
        break;
      case "edit_counter":
        this.label = "app.views.statistics.editCounter";
        break;
      case "add_chart":
        this.label = "app.views.statistics.createChart";
        break;
      case "edit_chart":
        this.label = "app.views.statistics.editChart";
        break;
    }
  }

  private createStatisticData(response: any) {
    return {
      name: response.data.title,
      data_type: response.data.data_type,
      start_date: new Date(response.data.start_date),
      end_date: new Date(response.data.end_date),
      location_id: response.data?.locations?.[0]?.id,
      users_id: response.data?.users?.[0]?.id,
      chart_type: response.data?.chart_type ?? 0,
    };
  }

  private createStatisticParams() {
    const params: any = {
      location_ids: this.statisticData.location_id
        ? [this.statisticData.location_id]
        : [],
      user_ids: this.statisticData.users_id
        ? [this.statisticData.users_id]
        : [],
      data_type: this.statisticData.data_type,
      end_date: moment(this.statisticData.end_date).format("DD-MM-YYYY"),
      start_date: moment(this.statisticData.start_date).format("DD-MM-YYYY"),
      name: this.statisticData.name,
      board_id: this.activeBoard ? this.activeBoard : null,
    };

    if (this.showForm === "add_chart" || this.showForm === "edit_chart") {
      params.chart_type = this.statisticData.chart_type;
    }

    return params;
  }

  public defaultDateForUser() {
    if (this.auth.isSystemAdmin(this.me)) {
      return new Date(this.user?.created_at);
    } else {
      return new Date(this.user.organisation?.created_at ?? new Date());
    }
  }

  mounted() {
    this.getLabel();
    this.fetchLocations(this.locationSearchParams);
    if (!this.auth.isInspector(this.me)) {
      this.fetchUsers(this.usersSearchParams);
    }
    if (this.currentCounterId) {
      this.getCounter();
    }
    if (this.showForm === "add_counter" || this.showForm === "add_chart") {
      this.statisticData.start_date = this.defaultDateForUser();
    }
    if (this.currentChartId) {
      this.getChart();
    }
  }

  private async getCounter() {
    this.$emit("setLoading", true);
    try {
      const response: any = await http().get(
        `/api/statistics/counters/${this.currentCounterId}`
      );
      this.statisticData = this.createStatisticData(response);
    } catch (e) {
    } finally {
      this.$emit("setLoading", false);
    }
  }

  private async getChart() {
    this.$emit("setLoading", true);
    try {
      const response: any = await http().get(
        `/api/statistics/charts/${this.currentChartId}`
      );
      this.statisticData = this.createStatisticData(response);
    } catch (e) {
    } finally {
      this.$emit("setLoading", false);
    }
  }

  private async createCounterHandler() {
    try {
      const params = this.createStatisticParams();
      await http().post("/api/statistics/counters/", params);

      this.$emit("save");
    } catch (exception: any) {
      this.formErrors.setErrors(exception.response.data.errors);
    }
  }

  private async createChartHandler() {
    try {
      const params = this.createStatisticParams();
      await http().post("/api/statistics/charts/", params);

      this.$emit("saveChart");
    } catch (exception: any) {
      this.formErrors.setErrors(exception.response.data.errors);
    }
  }

  private async editCounterHandler() {
    try {
      const params = this.createStatisticParams();
      await http().put(
        `/api/statistics/counters/${this.currentCounterId}`,
        params
      );

      this.$emit("save");
    } catch (exception: any) {
      this.formErrors.setErrors(exception.response.data.errors);
    }
  }

  private async editChart() {
    try {
      const params = this.createStatisticParams();
      await http().put(`/api/statistics/charts/${this.currentChartId}`, params);

      this.$emit("saveChart");
    } catch (exception: any) {
      this.formErrors.setErrors(exception.response.data.errors);
    }
  }

  protected async selectUser(userId: number | null): Promise<void> {
    this.statisticData.users_id = userId;
  }

  protected async selectLocation(locationId: number): Promise<void> {
    this.statisticData.location_id = locationId;

    this.usersSearchParams.locations_ids = [locationId];

    if (!this.auth.isInspector(this.me)) {
      this.fetchUsers(this.usersSearchParams);
    }
  }

  @Debounce(350)
  protected async searchLocations(search: string | null): Promise<void> {
    this.locationSearchParams.search = search;
    await this.fetchLocations(this.locationSearchParams);
  }

  @Debounce(350)
  protected async searchUsers(search: string | null): Promise<void> {
    this.usersSearchParams.search = search;
    await this.fetchUsers(this.usersSearchParams);
  }
}
