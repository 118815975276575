<template>
  <div>
    <loading-spinner :show="loading" v-if="loading" />

    <TableViewLayout
      :title="$tc('app.views.language.language', 2)"
      :is-empty="languages.length < 1"
    >
      <template v-slot:header-items>
        <div class="Button IsTiny" @click="showForm = !showForm">
          <img class="Icon" src="/images/icons/plus.svg" alt="plus-icon" />
        </div>
      </template>

      <template>
        <records-table :rows="languages">
          <template #thead>
            <tr class="table_thead_tr">
              <th width="240">{{ $t("components.table.langCode") }}</th>
              <th>{{ $t("components.table.translatedWords") }}</th>
              <th></th>
            </tr>
          </template>
          <template #tbody>
            <tr v-for="language in languages">
              <td>
                <div
                  class="language-title"
                  @click="
                    $router.push({
                      name: 'language-words',
                      params: { id: language.id.toString() },
                    })
                  "
                >
                  {{ generateTranslationsForLanguagesHandler(language.code) }}
                </div>
              </td>
              <td
                class="words-count"
                :class="{
                  good: language.words_confirmed_count === language.words_count,
                }"
              >
                {{
                  language.words_confirmed_count + "/" + language.words_count
                }}
              </td>
              <td>
                <table-row-drop-down-menu>
                  <template v-slot:content>
                    <span
                      class="controls-dropdown-button"
                      @click="
                        $router.push({
                          name: 'language-words',
                          params: { id: language.id.toString() },
                        })
                      "
                    >
                      {{ $t("components.show") }}
                    </span>
                    <span
                      class="controls-dropdown-button"
                      @click="remove(language)"
                    >
                      {{ $t("components.delete") }}
                    </span>
                  </template>
                </table-row-drop-down-menu>
              </td>
            </tr>
          </template>
        </records-table>
      </template>
    </TableViewLayout>

    <language-form
      v-if="showForm"
      :is-visible="showForm"
      @close="closeForm"
      @loading="loading = !loading"
    ></language-form>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/vuex/modules/languages-module/action-types";
import { LanguagesSearchParams } from "@/vuex/modules/languages-module/actions";
import LanguageForm from "@/views/LanguagesView/components/LanguageForm.vue";
import { PageMixin } from "@/mixins/pageMixin";
import Language from "@/vuex/models/language/language";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { generateTranslationsForLanguages } from "@/services/helpers/generateTranslationsForLanguages";

@Component({
  components: {
    LanguageForm,
  },
  methods: mapActions({
    fetchLanguages: ActionTypes.FETCH_LANGUAGES,
    deleteLanguage: ActionTypes.DELETE_LANGUAGE,
  }),
  computed: mapGetters({
    languages: "languages",
  }),
})
export default class LanguagesView extends PageMixin {
  protected readonly fetchLanguages!: (
    params: LanguagesSearchParams
  ) => Promise<void>;
  protected readonly deleteLanguage!: (languageId: number) => Promise<void>;

  protected readonly languages!: Language[];
  protected readonly searchParams: LanguagesSearchParams = {
    page: 1,
    perPage: 10,
  };

  protected async created(): Promise<void> {
    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchLanguages(this.searchParams);
    this.loading = false;
  }

  protected generateTranslationsForLanguagesHandler = (code: string) => {
   return generateTranslationsForLanguages(code);
  };

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async remove(language: Language): Promise<void> {
    this.loading = false;

    try {
      if (!!language.id) {
        await this.deleteLanguage(language.id);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: "Language has been deleted.",
          type: NotificationType.SUCCESS,
        });
        await this.paginate();
      }
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
.words-count {
  color: #e5881d;

  &.good {
    color: #1de591;
  }
}

.language-title {
  cursor: pointer;

  &:hover {
    color: #1de591;
  }
}
</style>
