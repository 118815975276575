import StripeCustomer from "@/vuex/models/billing/customer";
import StripeSubscription from "@/vuex/models/billing/subscription";
import StripePaymentMethod from "@/vuex/models/billing/payment-method";
import StripeAddress from "@/vuex/models/billing/address";
import StripeInvoice from "@/vuex/models/billing/invoice";
import Meta from "@/vuex/models/meta";

export class BillingState {
    public customer!: StripeCustomer | null;
    public subscription!: StripeSubscription | null;
    public paymentMethod!: StripePaymentMethod | null;
    public address!: StripeAddress | null;
    public invoices!: StripeInvoice[] | [] | null;
    public invoicesMeta!: Meta | null;
}
