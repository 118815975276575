export const generateIncidentSourceType = (
  typeIncident: "inspection" | "reported" | "qr_code"
) => {
  switch (typeIncident) {
    case "inspection":
      return "app.views.incident.sourceType.inspection";
    case "reported":
      return "app.views.incident.sourceType.reported";
    case "qr_code":
      return "app.views.incident.sourceType.qr_code";
  }
};
