import mutations from "./mutations";
import actions from './actions';
import getters from './getters'
import AuthState from "./state";
import {Module} from "vuex";
import {RootState} from "../../store";


const module: Module<AuthState, RootState> = {
    state: () => new AuthState(),
    actions,
    getters,
    mutations,
}

export default module


