import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import actions from './actions';
import LogsState from "@/vuex/modules/logs-module/state";
import mutations from "./mutations";
import getters from "./getters";

const module: Module<LogsState, RootState> = {
    actions,
    state: new LogsState(),
    mutations,
    getters
}

export default module;
