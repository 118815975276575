export const generateShortTextForTemplate = (templateType: string) => {
  let shortText;

  switch (templateType) {
    case "PUBLIC":
      shortText = "publ";
      break;
    case "ORGANISATION":
      shortText = "org";
      break;
    case "PRIVATE":
      shortText = "priv";
      break;
    default:
      shortText = "";
  }

  return shortText;
};
