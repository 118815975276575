<template>
  <div class="Field">
    <p class="Label">
      {{ label }}
    </p>
    <slot/>
  </div>
</template>

<script>
import VueI18n from "vue-i18n";

export default {
  name: "Field",
  props: {
    label: {
      required: false,
      type: String | VueI18n.TranslateResult
    }
  }
}
</script>

<style scoped lang="scss">

.Field {
  margin-bottom: 25px;

  .Label {
    padding-left: 13px;
    padding-bottom: 7px;
  }
}
</style>
