export enum MutationTypes {
    SET_LANGUAGES = 'languages-module/set-languages',
    SET_AVAILABLE_LANGUAGES = 'languages-module/set-available-languages',
    SET_LANGUAGE_WORDS = 'languages-module/set-language-words',
    SET_LANGUAGE = 'languages-module/set-language',
    SET_DEFAULT_LANGUAGE = 'languages-module/set-default-language',

    SET_LOCALE_FROM_BROWSER = 'languages-module/set-locale-from-browser',
    SET_TIMEZONE_FROM_BROWSER = 'languages-module/set-timezone-from-browser'
}

