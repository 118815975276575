import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import InspectionFull from "@/vuex/models/inspection/inspection-full";
import InspectionData from "@/vuex/models/inspection/inspection-data";
import { InspectionRepeatOption } from "@/vuex/models/inspection/inspection-repeat-option";
import { FormMixin } from "@/mixins/formMixin";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { ButtonStyles } from "@/components/ButtonWithIcon/ButtonWithIcon";
import { ActionTypes as TemplateActions } from "@/vuex/modules/templates-module/action-types";
import { TemplateSearchParams } from "@/vuex/modules/templates-module/actions";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import {
  Direction,
  LocationsSortableColumns,
  TemplatesSortableColumns,
  UsersSortableColumns,
  AccessSortableColumns,
} from "@/services/helpers/sort";
import { Debounce } from "vue-debounce-decorator";
import { ActionTypes as LocationActions } from "@/vuex/modules/locations-module/action-types";
import { LocationSearchParams } from "@/vuex/modules/locations-module/actions";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import { ExternalAccessSearchParams } from "@/vuex/modules/external-access-module/actions";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as ExternalAccessActions } from "@/vuex/modules/external-access-module/action-types";
import LocationFull from "@/vuex/models/location/location-full";
import { ActionTypes } from "@/vuex/modules/inspections-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import LocationForm from "@/views/LocationsView/components/LocationForm/LocationForm.vue";
import i18n from "@/lang/locale";

@Component({
  methods: mapActions({
    fetchFilteredTemplates: TemplateActions.FETCH_FILTERED_TEMPLATES,
    fetchLocations: LocationActions.FETCH_LOCATIONS,
    fetchInspectors: UserActions.FETCH_USERS,
    fetchExternalAccess: ExternalAccessActions.FETCH_EXTERNAL_ACCESSES,
    create: ActionTypes.CREATE_INSPECTION,
    update: ActionTypes.UPDATE_INSPECTION,
  }),
  computed: mapGetters({
    templateOptions: "templateSelectOptions",
    locationOptions: "locationSelectOptions",
    inspectorOptions: "userSelectOptions",
    externalAccessOptions: "externalAccessOptions",
    createdLocation: "location",
  }),
  components: {
    InputSelectOrganisation,
    AccessModal,
    ButtonsSaveCancel,
    Field,
    LocationForm,
  },
})
export default class InspectionCalendarForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public isVisible!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  public inspection!: InspectionFull | null;

  protected readonly fetchFilteredTemplates!: (
    params: TemplateSearchParams
  ) => Promise<void>;
  protected readonly fetchLocations!: (
    params: LocationSearchParams
  ) => Promise<void>;
  protected readonly fetchInspectors!: (
    params: UserSearchParams
  ) => Promise<void>;
  protected readonly fetchExternalAccess!: (
    params: ExternalAccessSearchParams
  ) => Promise<void>;
  protected readonly create!: (params: InspectionData) => Promise<void>;
  protected readonly update!: (params: InspectionData) => Promise<void>;

  protected readonly ButtonStyles = ButtonStyles;
  protected readonly inspectionRepeatOption = InspectionRepeatOption;
  protected readonly inspectionRepeatOptions: SelectOption[] = [
    {
      label: i18n.t("app.views.inspectionRepeatOptions.noRepeat").toString(),
      value: InspectionRepeatOption.NO_REPEAT,
    },
    {
      label: i18n.t("app.views.inspectionRepeatOptions.eachDay").toString(),
      value: InspectionRepeatOption.DAY,
    },
    {
      label: i18n.t("app.views.inspectionRepeatOptions.eachWeek").toString(),
      value: InspectionRepeatOption.WEEK,
    },
    {
      label: i18n.t("app.views.inspectionRepeatOptions.eachMonth").toString(),
      value: InspectionRepeatOption.MONTH,
    },
    {
      label: i18n.t("app.views.inspectionRepeatOptions.eachYear").toString(),
      value: InspectionRepeatOption.YEAR,
    },
  ];
  protected readonly templateOptions!: SelectOption[];
  protected readonly locationOptions!: SelectOption[];
  protected readonly inspectorOptions!: SelectOption[];
  protected readonly externalAccessOptions!: SelectOption[];
  protected readonly createdLocation!: LocationFull | null;
  protected showLocationForm: boolean = false;

  protected selectedOrganisation: SelectOption | null = null;
  protected inspectionData: InspectionData = {
    id: null,
    name: null,
    date: new Date(),
    organisation_id: null,
    location_id: null,
    inspector_id: null,
    template_id: null,
    repeat: InspectionRepeatOption.NO_REPEAT,
    end_repeat_date: null,
    allow_to_submit_after_due: false,
    external_access: false,
    external_inspector_id: null,
  };

  protected templateSearchParams: TemplateSearchParams = {
    page: 1,
    per_page: 1000,
    column: TemplatesSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    access_level: null,
    user_id: null,
    language_id: null,
  };

  protected locationSearchParams: LocationSearchParams = {
    page: 1,
    per_page: 100,
    column: LocationsSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    organisation_id: null,
  };

  protected inspectorSearchParams: UserSearchParams = {
    page: 1,
    per_page: 100,
    column: UsersSortableColumns.NAME,
    direction: Direction.DESC,
    search: null,
    locations_ids: [],
    organisation_id: this.inspectionData.organisation_id,
    role: null,
  };

  protected externalAccessSearchParams: ExternalAccessSearchParams = {
    page: 1,
    per_page: 100,
    column: AccessSortableColumns.NAME,
    direction: Direction.DESC,
    search: null,
    organisation_id: null,
  };

  protected async created() {
    if (!!this.inspection) {
      this.inspectorSearchParams.locations_ids = [this.inspection.location.id];
      await this.fetchInspectors(this.inspectorSearchParams);

      this.inspectionData = {
        organisation_id: this.inspection.organisation.id,
        location_id: this.inspection.location.id,
        inspector_id: this.inspection.inspector.id,
        template_id: this.inspection.template.id,
        id: this.inspection.id,
        name: this.inspection.name,
        date: new Date(this.inspection.date),
        repeat: null,
        end_repeat_date: null,
        allow_to_submit_after_due: this.inspection.allow_to_submit_after_due,
        external_access: this.inspection.external_access,
        external_inspector_id: this.inspection.inspector.id,
      };
    }

    await this.fetchExternalAccess(this.externalAccessSearchParams);

    if (!this.auth.isSystemAdmin(this.me) && !!this.me.organisation) {
      await this.selectOrganisation(
        {
          value: this.me.organisation.id,
          label: this.me.organisation.name,
        },
        false
      );
    }
  }

  protected async selectOrganisation(
    organisation: SelectOption,
    isClearField = true
  ): Promise<void> {
    const organisationId: number = Number(organisation.value);
    this.selectedOrganisation = organisation;

    this.inspectionData.organisation_id = organisationId;

    if (isClearField) {
      this.inspectionData.location_id = null;
      this.inspectionData.inspector_id = null;
      this.inspectionData.template_id = null;
      this.inspectionData.external_inspector_id = null;
    }

    if (this.auth.isSystemAdmin(this.me)) {
      this.locationSearchParams.organisation_id = organisationId;
      this.externalAccessSearchParams.organisation_id = organisationId;
    }

    await this.fetchLocations(this.locationSearchParams);
    await this.fetchExternalAccess(this.externalAccessSearchParams);
  }

  protected async selectLocation(locationId: number): Promise<void> {
    this.inspectionData.location_id = locationId;
    this.inspectionData.inspector_id = null;
    this.inspectionData.template_id = null;

    this.inspectorSearchParams.locations_ids = [locationId];

    await this.fetchInspectors(this.inspectorSearchParams);
    this.fetchInspectors(this.inspectorSearchParams);
  }

  protected async selectInspector(inspectorId: number): Promise<void> {
    this.inspectionData.inspector_id = inspectorId;
    this.inspectionData.template_id = null;
    this.inspectionData.external_inspector_id = null;

    if (this.auth.isSystemAdmin(this.me)) {
      this.templateSearchParams.user_id = inspectorId;
    }
    await this.fetchFilteredTemplates(this.templateSearchParams);
  }

  protected async selectExternalAccess(externalAccessId: number): Promise<void> {
    this.inspectionData.external_inspector_id = externalAccessId;
    this.inspectionData.inspector_id = null;
    await this.fetchFilteredTemplates(this.templateSearchParams);
  }

  protected selectRepeatOption(option: InspectionRepeatOption): void {
    this.inspectionData.repeat = option;
    this.inspectionData.end_repeat_date =
      InspectionRepeatOption.NO_REPEAT !== option ? new Date() : null;
  }

  protected async closeLocationWrapper(submitted: boolean): Promise<void> {
    this.showLocationForm = false;

    if (submitted && !!this.createdLocation) {
      this.locationOptions.push({
        value: this.createdLocation.id,
        label: this.createdLocation.name,
      });
      await this.selectLocation(this.createdLocation.id);
    }
    if (this.inspectionData.location_id) {
      await this.selectLocation(this.inspectionData.location_id);
    }
  }

  @Debounce(350)
  protected async searchTemplates(search: string | null): Promise<void> {
    this.templateSearchParams.search = search;

    await this.fetchFilteredTemplates(this.templateSearchParams);
  }

  @Debounce(350)
  protected async searchLocations(search: string | null): Promise<void> {
    this.locationSearchParams.search = search;
    await this.fetchLocations(this.locationSearchParams);
  }

  @Debounce(350)
  protected async searchInspectors(search: string | null): Promise<void> {
    this.inspectorSearchParams.search = search;
    await this.fetchInspectors(this.inspectorSearchParams);
  }

  @Debounce(350)
  protected async searchExternalAccess(search: string | null): Promise<void> {
    this.externalAccessSearchParams.search = search;
    await this.fetchExternalAccess(this.externalAccessSearchParams);
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");

    if (!this.auth.isSystemAdmin(this.me)) {
      this.inspectionData.organisation_id = null;
    }

    try {
      if (!!this.inspection) {
        await this.updateInspection();
      } else {
        await this.createInspection();
      }

      this.$emit("close", true);
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.$emit("loading");
  }

  protected async createInspection(): Promise<void> {
    await this.create(this.inspectionData);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Inspection has been created.",
      type: NotificationType.SUCCESS,
    });
  }

  protected async updateInspection(): Promise<void> {
    await this.update(this.inspectionData);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Inspection has been updated.",
      type: NotificationType.SUCCESS,
    });
  }
}
