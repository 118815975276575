import { Component, Watch } from "vue-property-decorator";
import InspectionCalendarForm from "@/views/InspectionsView/components/InspectionCalendarForm/InspectionCalendarForm.vue";
import { mapActions, mapGetters } from "vuex";
import InspectionEventShow from "@/views/InspectionsView/components/InspectionEventShow/InspectionEventShow.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import InputSelectAccess from "@/components/InputSelectAccess/InputSelectAccess.vue";
import { PageMixin } from "@/mixins/pageMixin";
import { ActionTypes } from "@/vuex/modules/inspections-module/action-types";
import { InspectionsSearchParams } from "@/vuex/modules/inspections-module/actions";
import InspectionFull from "@/vuex/models/inspection/inspection-full";
import Meta from "@/vuex/models/meta";
import { InspectionsSortableColumns } from "@/services/helpers/sort";
import { Debounce } from "vue-debounce-decorator";
import DateToString from "@/services/dateToString/dateToString";
import Location from "@/vuex/models/location/location";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import DateFilter from "@/components/DateFilter/DateFilter.vue";
import DeleteInspectionModal from "@/views/InspectionsView/components/DeleteInspectionModal.vue";
import i18n from "@/lang/locale";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import Modal from "@/components/Modal/Modal.vue";
import {http} from "@/services/http/http";
import {appRoutes} from "@/config";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";

@Component({
  components: {
    DeleteInspectionModal,
    DateFilter,
    InspectionCalendarForm,
    InspectionEventShow,
    ButtonWithIcon,
    InputSelectAccess,
    ButtonsSaveCancel,
    Modal,
  },
  methods: mapActions({
    fetchInspections: ActionTypes.FETCH_INSPECTIONS,
    fetchInspectionsForCsv: ActionTypes.FETCH_INSPECTIONS_FOR_CSV,
  }),
  computed: mapGetters({
    inspections: "inspections",
    inspectionsForCsv: "inspectionsForCsv",
    meta: "inspectionsMeta",
  }),
})
export default class InspectionCalendarView extends PageMixin {
  private readonly fetchInspections!: (
    params: InspectionsSearchParams
  ) => Promise<void>;
  private readonly fetchInspectionsForCsv!: (
    params: InspectionsSearchParams
  ) => Promise<void>;

  protected readonly inspections!: InspectionFull[];
  protected readonly inspectionsForCsv!: InspectionFull[];
  protected readonly meta!: Meta;
  protected readonly sortableColumns = InspectionsSortableColumns;
  protected readonly dateToStringConverter: DateToString = new DateToString();
  protected readonly mineAllOptions: SelectOption[] = [
    {
      label: i18n.t("app.views.inspections.mineAllOptions.all").toString(),
      value: 0,
    },
    {
      label: i18n.t("app.views.inspections.mineAllOptions.mine").toString(),
      value: 1,
    },
  ];
  protected readonly statuses: SelectOption[] = [
    {
      label: i18n.t("app.views.inspections.statuses.toDo").toString(),
      value: 0,
    },
    {
      label: i18n.t("app.views.inspections.statuses.made").toString(),
      value: 1,
    },
    {
      label: i18n.t("app.views.inspections.statuses.pastDue").toString(),
      value: 2,
    },
  ];
  protected readonly types: SelectOption[] = [
    {
      label: i18n.t("components.all").toString().toLowerCase(),
      value: 0,
    },
    {
      label: i18n.t("components.table.typeInternal").toString().toLowerCase(),
      value: 1,
    },
    {
      label: i18n.t("components.table.typeExternal").toString().toLowerCase(),
      value: 2,
    },
  ];

  protected statusValue: null | 0 | 1 | 2 = 0;
  protected typeValue: null | 0 | 1 | 2 = 0;
  protected showViewModal: boolean = false;
  protected selectedInspection: InspectionFull | null = null;
  protected searchParams: InspectionsSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
    is_ended: 0,
    is_mine: 0,
    from_date: null,
    to_date: null,
    location_id: null,
    can_start: null,
    past_due: null,
    external_access: null,
  };

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchInspections(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchInspections(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected filterDates(dates: { start: Date | null; end: Date | null }): void {
    this.searchParams.from_date = !!dates.start
      ? dates.start.toDateString()
      : null;
    this.searchParams.to_date = !!dates.end ? dates.end.toDateString() : null;
  }

  protected selectStatus(status: 1 | 0 | 2 | null): void {
    this.statusValue = status;
    if (status === 2) {
      this.searchParams.is_ended = null;
      this.searchParams.past_due = 1;
    } else {
      this.searchParams.is_ended = status;
      this.searchParams.past_due = null;
    }
  }

  protected selectType(type: 1 | 0 | 2 | null): void {
    this.typeValue = type;
    if (type === 2) {
      this.searchParams.external_access = 1;
    } else if (type === 1) {
      this.searchParams.external_access = 0;
    } else {
      this.searchParams.external_access = null;
    }
  }

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;
    this.selectedInspection = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async closeModal(submitted: boolean): Promise<void> {
    this.showViewModal = false;
    this.showDeleteModal = false;
    this.selectedInspection = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected show(inspection: InspectionFull): void {
    this.selectedInspection = inspection;
    this.showViewModal = true;
  }

  protected update(inspection: InspectionFull): void {
    this.showForm = true;
    this.selectedInspection = inspection;
  }

  protected remove(inspection: InspectionFull): void {
    this.showDeleteModal = true;
    this.selectedInspection = inspection;
  }

  protected async startInspection(inspection: InspectionFull): Promise<void> {
    await this.$router.push({
      name: "inspection-execution",
      params: { id: inspection.id.toString() },
    });
  }

  protected showStartButton(inspection: InspectionFull): boolean {
    return (
      inspection.view_start_button &&
      inspection.inspector.id === this.me.id &&
      !inspection.is_ended
    );
  }

  protected showAction(inspection: InspectionFull): boolean {
    if (!inspection.is_started && !inspection.is_ended) {
      if (this.auth.isSystemAdmin(this.me)) {
        return true;
      }
      if (
        this.auth.isOrganisationAdmin(this.me) &&
        inspection.organisation.id === this.me.organisation?.id
      ) {
        return true;
      }
      if (
        this.auth.isLocationAdmin(this.me) &&
        this.me.locations.find(
          (location: Location) => location.id === inspection.location.id
        )
      ) {
        return true;
      }
    }
    return false;
  }
  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      i18n.t("components.table.inspectionDate").toString(),
      i18n.t("components.table.executionDate").toString(),
      ...(this.auth.isSystemAdmin(this.me)
        ? [i18n.t("components.table.organisation").toString()]
        : []),
      i18n.t("components.table.location").toString(),
      i18n.t("components.table.inspector").toString(),
      i18n.t("components.table.status").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchInspectionsForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.inspectionsForCsv.map((el) => ({
        [i18n.t("components.table.name").toString()]: el.name,
        [i18n.t("components.table.inspectionDate").toString()]:
          this.dateToStringConverter.getLocaleString(new Date(el.date), [
            this.dateToStringConverter.LONG_DATE,
          ]),
        [i18n.t("components.table.executionDate").toString()]: el.end_datetime
          ? this.dateToStringConverter.getLocaleString(
              new Date(el.end_datetime),
              [this.dateToStringConverter.LONG_DATE]
            )
          : "-",
        ...(this.auth.isSystemAdmin(this.me) && {
          [i18n.t("components.table.organisation").toString()]:
            el.organisation.name,
        }),
        [i18n.t("components.table.location").toString()]: el.location.name,
        [i18n
          .t("components.table.inspector")
          .toString()]: `${el.inspector.name} ${el.inspector.surname}`,
        [i18n.t("components.table.status").toString()]: el.is_ended
          ? i18n.t("app.views.inspections.statuses.made").toString()
          : el.is_expired
          ? i18n.t("app.views.inspections.statuses.pastDue").toString()
          : i18n.t("app.views.inspections.statuses.toDo").toString(),
      })),
      this.generateHeaders(),
      "inspections.csv"
    );
  }

  public async sendExternalInspectionsEmail(inspection: InspectionFull) {
    try {
      const url = `${appRoutes.api.inspections.sendInspectionExternalEmails(inspection.id)}`;

      await http().get(url);

      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: i18n.t("app.views.inspection.execution.notificationMessage.messageSent").toString(),
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  }
}
