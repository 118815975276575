<template>
  <records-table :rows="users">
    <template v-slot:thead>
      <sortable-th
        width="20%"
        :direction="userSearchParams.direction"
        :sorted="usersSortableColumns.NAME === userSearchParams.column"
        @click="sortColumn(usersSortableColumns.NAME)"
      >
        {{ $t("components.table.fullName") }}
      </sortable-th>
      <sortable-th
        width="15%"
        :direction="userSearchParams.direction"
        :sorted="usersSortableColumns.ROLE === userSearchParams.column"
        @click="sortColumn(usersSortableColumns.ROLE)"
      >
        {{ $t("components.table.role") }}
      </sortable-th>
      <th width="15%" v-if="auth.isSystemAdmin(me)">
        {{ $t("components.table.organisation") }}
      </th>
      <th width="20%">
        {{ $t("components.table.location") }}
      </th>
      <sortable-th
        width="15%"
        :direction="userSearchParams.direction"
        :sorted="usersSortableColumns.EMAIL === userSearchParams.column"
        @click="sortColumn(usersSortableColumns.EMAIL)"
      >
        {{ $t("components.table.email") }}
      </sortable-th>
      <th
        v-if="auth.isSystemAdmin(me) || auth.isOrganisationAdmin(me)"
        width="10%"
      >
        {{ $t("components.table.status") }}
      </th>
      <th width="20px"></th>
    </template>

    <template v-if="users.length > 0" v-slot:tbody>
      <tr v-for="user in users" :key="user.id" class="table_thead_tr">
        <td class="user-name">
          <span>
            {{ userFullName(user) }}
          </span>
        </td>
        <td>
          {{ !!user.role ? $t(translateRole(user?.role)) : "-" }}
        </td>
        <td v-if="auth.isSystemAdmin(me)">
          {{ !!user.organisation ? user.organisation.name : "-" }}
        </td>
        <td>{{ userLocations(user) }}</td>
        <td>{{ user.email }}</td>
        <td v-if="auth.isSystemAdmin(me) || auth.isOrganisationAdmin(me)">
          {{
            user.active
              ? $t("app.views.userStatus.active")
              : $t("app.views.userStatus.inactive")
          }}
        </td>
        <td>
          <table-row-drop-down-menu v-if="user.id !== me.id">
            <template v-slot:content>
              <span class="controls-dropdown-button" @click="update(user)">
                {{ $t("components.edit") }}
              </span>
              <span class="controls-dropdown-button" @click="remove(user)">
                {{ $t("components.delete") }}
              </span>
            </template>
          </table-row-drop-down-menu>
        </td>
      </tr>
    </template>
  </records-table>
</template>

<script>
import { UsersSortableColumns } from "@/services/helpers/sort";
import { mapGetters } from "vuex";
import Authorization from "@/services/authorization/authorization";

export default {
  name: "UsersTable",
  emits: ["showForm", "showDeleteModal", "sortColumn"],
  props: {
    users: {
      required: true,
    },
    userSearchParams: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["me"]),
  },
  data() {
    return {
      usersSortableColumns: UsersSortableColumns,
      auth: new Authorization(),
    };
  },
  methods: {
    sortColumn(columnName) {
      this.$emit("sortColumn", columnName);
    },
    translateRole(typeRole) {
      let translatedRole;
      switch (typeRole) {
        case "INSPECTOR":
          translatedRole = "role.inspector";
          break;
        case "ORG_ADMIN":
          translatedRole = "role.organisationAdmin";
          break;
        case "LOC_ADMIN":
          translatedRole = "role.locationAdmin";
          break;
        case "SUP_ADMIN":
          translatedRole = "role.sysAdmin";
          break;
        default:
          translatedRole = "";
      }

      return translatedRole;
    },
    update(user) {
      this.$emit("showForm", user);
    },
    remove(user) {
      this.$emit("showDeleteModal", user);
    },
    userLocations(user) {
      return user.locations.map((location) => location.name).join(", ");
    },
    userFullName(user) {
      let fullName = "";

      if (!!user.name) {
        fullName += user.name;
      }

      if (!!user.surname) {
        fullName += " " + user.surname;
      }

      return fullName;
    },
  },
};
</script>

<style scoped lang="scss">
</style>