<template>
  <LoadingSpinner v-if="loading" :show="loading"/>
  <div v-else class="enterprise-plan">
    <BillingForm
        :save-label="$t('app.views.billing.cancelSubscription').toString()"
        @back="goToBillingTab"
        @save="cancel"
        :isDanger="true"
    >
      <template slot="form">
        <h2>
          {{ $t('app.views.billing.cancelSubscription') }}
        </h2>
        <div class="cancel_notification">
          <span>
            <strong>
              {{ $t('app.views.billing.cancelSubscriptionNotification1', [expirationDate]) }}
            </strong>
          </span>
          <span>
            {{ $t('app.views.billing.cancelSubscriptionNotification2') }}
          </span>
          <span>
            {{ $t('app.views.billing.cancelSubscriptionNotification3') }}
          </span>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import DateToString from "@/services/dateToString/dateToString";
import {UserProfileTabs} from "@/vuex/models/userProfile";

export default {
  name: "CancelSubscription",
  components: {BillingForm},
  data() {
    return {
      loading: false,
      dateToStringConverter: new DateToString(),
    }
  },
  computed: {
    ...mapGetters(['subscription']),
    expirationDate() {
      if (!!this.subscription) {
        return this.dateToStringConverter.getLocaleString(new Date(this.subscription.current_period_end), [this.dateToStringConverter.LONG_DATE])
      }
    }
  },
  async created() {
    this.loading = true;
    this.$parent.title = this.$t('app.views.billing.planCancellation');

    if (!this.subscription) {
      await this.$store.dispatch(ActionTypes.FETCH_SUBSCRIPTION);
    }
    this.loading = false;
  },
  methods: {
    async cancel() {
      this.loading = true;

      await this.$store.dispatch(ActionTypes.CANCEL_SUBSCRIPTION, this.subscription.id);
      await this.goToBillingTab();

      this.loading = false;
    },
    async goToBillingTab() {
      await this.$router.push({name: 'settings', query: {tab: UserProfileTabs.BILLING}});
    },
  }
}
</script>

<style lang="scss" scoped>
h2 {
  text-align: left;
  font: normal normal 600 22px/33px Poppins;
  letter-spacing: 0;
  color: #0D0E34;
}

.cancel_notification {
  display: flex;
  flex-direction: column;
  margin-bottom: 250px;
}
</style>