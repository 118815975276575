import StatisticsState from "./state";
import {GetterTree} from "vuex";
import {RootState} from "../../store";
import ShortStatistics from "@/vuex/models/statistics/short";

const getters: GetterTree<StatisticsState, RootState> = {
    shortStatistics(state: StatisticsState): ShortStatistics {
        return state.short;
    }
}

export default getters;
