import { render, staticRenderFns } from "./InspectionEventShow.vue?vue&type=template&id=5633d75f&scoped=true"
import script from "./InspectionEventShow.ts?vue&type=script&lang=ts&external"
export * from "./InspectionEventShow.ts?vue&type=script&lang=ts&external"
import style0 from "./InspectionEventShow.scss?vue&type=style&index=0&id=5633d75f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5633d75f",
  null
  
)

export default component.exports