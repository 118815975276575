import ReportsState from "@/vuex/modules/reports-module/state";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import { Direction, ReportsSortableColumns } from "@/services/helpers/sort";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes } from "@/vuex/modules/reports-module/action-types";
import { MutationTypes } from "@/vuex/modules/reports-module/mutation-types";

export interface ReportsSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: ReportsSortableColumns | null;
  search: string | null;
  location_id: number | null;
  is_mine: boolean | 1 | 0 | null;
}

type ReportsActionContext = ActionContext<ReportsState, RootState>;

const actions: ActionTree<ReportsState, RootState> = {
  async [ActionTypes.FETCH_REPORTS](
    store: ReportsActionContext,
    params: ReportsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.reports.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_REPORTS, response.data.data);
      store.commit(MutationTypes.SET_REPORTS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_REPORTS_FOR_CSV](
    store: ReportsActionContext,
    params: ReportsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.reports.all(), {
        params,
      });
      store.commit(MutationTypes.SET_REPORTS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_REPORT](
    store: ReportsActionContext,
    reportId: number
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.reports.view(reportId));
      store.commit(MutationTypes.SET_REPORT, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_PDF](
    store: ReportsActionContext,
    reportUuid: string
  ): Promise<void> {
    try {
      const response = await http({ responseType: "blob" }).get(
        appRoutes.api.reports.pdf(reportUuid)
      );
      store.commit(MutationTypes.SET_PDF, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.SEND_REPORT](
    store: ReportsActionContext,
    params: {
      emails: string[];
      reportId: number;
    }
  ): Promise<void> {
    await http().post(appRoutes.api.reports.send(params.reportId), params);
  },
};

export default actions;
