<template>
  <div>
    <records-table :rows="rows">
      <template v-slot:thead>
        <tr class="table_thead_tr">
          <th width="25%"><strong>{{ $t('components.table.date') }}</strong></th>
          <th width="20%">{{ $t('components.table.invoiceNumber') }}</th>
          <th width="10%">{{ $t('components.table.amount') }}</th>
          <th></th>
        </tr>
      </template>
      <template v-slot:tbody>
        <tr v-for="invoice in invoices">
          <td>
            {{
              dateToStringConverter.getLocaleString(new Date(invoice.date), [dateToStringConverter.LONG_DATE])
            }}
          </td>
          <td>{{ invoice.number }}</td>
          <td>{{ invoice.total + ' ' }}</td>
          <td>
            <button class="download_button" @click="downloadPdf(invoice)">
              {{ $t('components.download') }}
            </button>
          </td>
        </tr>
      </template>
    </records-table>
    <Pagination
        :current-page="currentPage"
        :last-page="lastPage"
        :loading="loading"
        @paginate="paginate"
    />
  </div>
</template>

<script>
import DateToString from "@/services/dateToString/dateToString";
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";

export default {
  name: "InvoicesTable",
  emits: ['loading'],
  data() {
    return {
      dateToStringConverter: new DateToString(),
      currentPage: 1,
      lastPage: 1,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['invoices', 'invoicesMeta']),
    rows() {
      return !!this.invoices ? this.invoices : [];
    }
  },
  async created() {
    this.setPagination();
  },
  watch: {
    invoicesMeta() {
      this.setPagination();
    }
  },
  methods: {
    setPagination() {
      if (!!this.invoicesMeta) {
        this.currentPage = this.invoicesMeta.current_page;
        this.lastPage = this.invoicesMeta.last_page;
      }
    },
    async downloadPdf(invoice) {
      this.$emit('loading');
      await window.location.replace(invoice.invoice_pdf);
      this.$emit('loading');
    },
    async paginate(page) {
      this.loading = true;
      await this.$store.dispatch(ActionTypes.FETCH_INVOICES, {
        page: page,
        per_page: 5
      });
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.download_button {
  border: 2px solid #1DE591;
  border-radius: 46px;
  height: 35px;
  padding: 0 30px;
  cursor: pointer;

  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0;
  color: #1DE591;
  background-color: #FFF;

  &:hover {
    color: #FFF;
    background-color: #1DE591;
  }
}
</style>