import { Component, Prop } from "vue-property-decorator";
import { FormMixin } from "@/mixins/formMixin";
import Incident from "@/vuex/models/incident/incident";

@Component({})
export default class AssignUserColumn extends FormMixin {
  @Prop({
    required: true,
    type: Object,
  })
  public incident!: Incident;
}
