export enum ActionTypes {
  FETCH_USERS = "users-module/fetch-users",
  FETCH_USERS_FOR_ASSIGN = "users-module/fetch-users-for-assign",
  CREATE_USER = "users-module/create-user",
  UPDATE_USER = "users-module/update-user",
  DELETE_USER = "users-module/delete-user",
  ALLOW_USER = "users-module/allow-user",
  DENY_USER = "users-module/deny-user",
  FETCH_USERS_FOR_CSV = "users-module/fetch-users-for-csv",
}
