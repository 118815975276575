import { GetterTree } from "vuex";
import { RootState } from "@/vuex/store";
import Meta from "@/vuex/models/meta";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import IncidentsState from "@/vuex/modules/incidents-module/state";
import Incident from "@/vuex/models/incident/incident";
import IncidentsTypes from "@/vuex/models/incident/incidentTypes";

const getters: GetterTree<IncidentsState, RootState> = {
  incidents(state: IncidentsState): Incident[] {
    return state.incidents;
  },
  incidentsForCsv(state: IncidentsState): Incident[] {
    return state.incidentsForCsv;
  },
  incidentsTypesOption(state: IncidentsState): SelectOption[] {
    return state.incidentsTypes.map((el: IncidentsTypes) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
  },
  incidentsTypesForFilterOptions(state: IncidentsState): SelectOption[] {
    return state.incidentsTypesForFilter.map((el: IncidentsTypes) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
  },
  incidentsMeta(state: IncidentsState): Meta {
    return state.meta;
  },
  incidentsCount(state: IncidentsState): number {
    return state.count;
  },
};

export default getters;
