import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import vSelect from 'vue-select'

export type SelectOption = {
  value: string | number;
  label: string;
}

@Component({
  components: {
    'v-select': vSelect
  }
})
export default class InputSelectMultiple extends Vue {

  @Prop({
    required: false,
    default: '',
  })
  public value!: string[] | number[];

  @Prop({
    type: Array,
    required: true,
  })
  public options!: SelectOption[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public error!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  public label!: string;

  private get selected(): SelectOption[] {
    // @ts-ignore
    return this.options.filter(option => this.value.includes(option.value))
  }
}
