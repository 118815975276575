import QRCode from "qrcode";

export class qrCodes {
  public async downloadQrCode(qr_code_url: string) {
    try {
      const options = {
        type: "svg",
        errorCorrectionLevel: "H",
        margin: 1,
        width: 800,
        scale: 1,
      };
      const svgString = await QRCode.toString(qr_code_url, options as any);

      const link = document.createElement("a");
      link.href =
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(svgString as any);
      link.download = "qrcode.svg";
      link.click();
    } catch (error) {}
  }
}
