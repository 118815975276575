import {MutationTree} from "vuex";
import UsersState from "./state";
import {MutationTypes} from './mutation-types';
import BrowserState from "./state";
import {Theme} from "@/vuex/models/theme";

const mutations: MutationTree<BrowserState> = {
    [MutationTypes.SET_THEME](state: UsersState, theme: Theme): void {
        state.theme = theme;
    }
}

export default mutations;
