import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import UserData from "@/vuex/models/user/user-data";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { Role } from "@/services/authorization/authorization";
import UserFull from "@/vuex/models/user/user-full";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { FormMixin } from "@/mixins/formMixin";
import { ActionTypes } from "@/vuex/modules/users-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as AuthActions } from "../../../../vuex/modules/auth-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import User from "@/vuex/models/user/user";
import i18n from "@/lang/locale";

@Component({
  computed: mapGetters({
    me: "me",
    organisationOptions: "organisationSelectOptions",
  }),
  methods: mapActions({
    create: ActionTypes.CREATE_USER,
    update: ActionTypes.UPDATE_USER,
    fetchMe: AuthActions.FETCH_ME,
  }),
  components: {
    InputSelectOrganisation,
    AccessModal,
    ButtonsSaveCancel,
    Field,
  },
})
export default class UserForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  public user!: User | null;

  @Prop({
    required: true,
    default: String,
  })
  public typeModal!: string;

  @Prop({
    required: true,
    type: Object,
  })
  public notificationAboutAddNewUserData!: UserData;

  protected readonly create!: (params: UserData) => Promise<UserFull>;
  protected readonly update!: (params: UserData) => Promise<UserFull>;
  private readonly fetchMe!: () => Promise<any>;

  protected readonly statusOptions: SelectOption[] = [
    { label: i18n.t("app.views.userStatus.active").toString(), value: 1 },
    { label: i18n.t("app.views.userStatus.inactive").toString(), value: 0 },
  ];
  protected readonly roleOptions: SelectOption[] = [
    { label: i18n.t("role.sysAdmin").toString(), value: Role.SYSTEM_ADMIN },
    {
      label: i18n.t("role.organisationAdmin").toString(),
      value: Role.ORGANISATION_ADMIN,
    },
    {
      label: i18n.t("role.locationAdmin").toString(),
      value: Role.LOCATION_ADMIN,
    },
    { label: i18n.t("role.inspector").toString(), value: Role.INSPECTOR },
  ];

  protected userStatus: number = 1;
  protected userData!: UserData;
  protected showOrganisationSelect: boolean = false;

  protected get label(): string {
    const action = !!this.user
      ? this.$t("components.form.update")
      : this.$t("components.form.create");

    return action + " " + this.$tc("app.views.user.user", 1);
  }

  @Watch("me.organisation", { immediate: true, deep: true })
  @Watch("userData.active", { immediate: true, deep: true })
  @Watch("typeModal")
  private checkNotificationModal() {
    const organization = this.me.organisation;
    const isActiveUser = this.userData.active;
    const activeUsersCount = organization?.active_users_count ?? 0;
    const usersLimit = organization?.users_limit ?? 0;

    if (organization && isActiveUser) {
      let isNotificationModal = false;

      if (this.typeModal === "create") {
        isNotificationModal =
          usersLimit !== null && activeUsersCount >= usersLimit;
      } else if (this.typeModal === "update") {
        if (
          activeUsersCount >= usersLimit &&
          this.userStatus !== isActiveUser
        ) {
          isNotificationModal = true;
        }
      }

      return isNotificationModal;
    }

    return false;
  }

  protected get filteredRoleOptions(): SelectOption[] {
    if (!this.auth.isSystemAdmin(this.me)) {
      const sysAdminOption = this.roleOptions.find(
        (roleOption: SelectOption) => roleOption.value === Role.SYSTEM_ADMIN
      );
      const orgAdminOption = this.roleOptions.find(
        (roleOption: SelectOption) =>
          roleOption.value === Role.ORGANISATION_ADMIN
      );

      const locationAdminOption = this.roleOptions.find(
        (roleOption: SelectOption) => roleOption.value === Role.LOCATION_ADMIN
      );

      if (!!sysAdminOption && !!orgAdminOption) {
        this.roleOptions.splice(this.roleOptions.indexOf(sysAdminOption), 1);
        this.roleOptions.splice(this.roleOptions.indexOf(orgAdminOption), 1);
      }

      if (
        !!locationAdminOption &&
        this.auth.isOrganisationAdmin(this.me) &&
        this.me.organisation?.subscription_product_slug === "pro"
      ) {
        this.roleOptions.splice(
          this.roleOptions.indexOf(locationAdminOption),
          1
        );
      }
    }

    return this.roleOptions;
  }

  protected created() {
    this.setUserData();

    if (!!this.user) {
      this.selectRole(this.user.role);
    }

    this.setUserDataWithNotificationData();
  }

  private setUserData(): void {
    this.userData = {
      id: !!this.user ? this.user.id : null,
      name: !!this.user ? this.user.name : null,
      surname: !!this.user ? this.user.surname : null,
      email: !!this.user ? this.user.email : null,
      role: !!this.user ? this.user.role : null,
      active: !!this.user ? (this.user.active ? 1 : 0) : 1,
      organisation_id:
        !!this.user && this.user.organisation
          ? this.user.organisation.id
          : null,
      host: window.location.origin,
    };
    this.userStatus = !!this.user ? (this.user.active ? 1 : 0) : 1;
  }

  protected selectRole(role: Role): void {
    this.userData.role = role;
    this.showOrganisationSelect =
      Role.SYSTEM_ADMIN !== role && this.auth.isSystemAdmin(this.me);

    if (Role.SYSTEM_ADMIN === role) {
      this.userData.organisation_id = null;
    }
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");
    try {
      if (
        this.auth.isSystemAdmin(this.me) ||
        this.auth.isLocationAdmin(this.me)
      ) {
        if (!!this.user) {
          await this.updateUser();
        } else {
          await this.createUser();
        }
        this.$emit("close", true);
      } else {
        if (this.checkNotificationModal()) {
          this.$emit("setNewUserDataHandler", this.userData);
          this.$emit("openNotificationModal");
        } else {
          if (!!this.user) {
            await this.updateUser();
          } else {
            await this.createUser();
          }
          this.$emit("close", true);
        }
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.$emit("loading");
  }

  private setUserDataWithNotificationData() {
    if (
      this.auth.isOrganisationAdmin(this.me) &&
      this.notificationAboutAddNewUserData
    ) {
      this.userData = {
        id: this.notificationAboutAddNewUserData.id,
        name: this.notificationAboutAddNewUserData.name,
        surname: this.notificationAboutAddNewUserData.surname,
        email: this.notificationAboutAddNewUserData.email,
        role: this.notificationAboutAddNewUserData.role,
        active:
          this.typeModal === "update"
            ? 0
            : Number(this.notificationAboutAddNewUserData.active),
        organisation_id: null,
        host: this.notificationAboutAddNewUserData.host,
      };
      this.userStatus =
        this.typeModal === "update"
          ? 0
          : Number(this.notificationAboutAddNewUserData.active);
    }
  }

  private async createUser(): Promise<void> {
    await this.create(this.userData);
    await this.fetchMe();
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.userCreated"),
      type: NotificationType.SUCCESS,
    });
  }

  private async updateUser(): Promise<void> {
    if (!this.auth.isSystemAdmin(this.me)) {
      this.userData.organisation_id = null;
    }

    await this.update(this.userData);
    await this.fetchMe();
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.userUpdated"),
      type: NotificationType.SUCCESS,
    });
  }
}
