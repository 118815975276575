import LogsState from "./state";
import {GetterTree} from "vuex";
import {RootState} from "../../store";
import Meta from "@/vuex/models/meta";
import Log from "@/vuex/models/log/log";

const getters: GetterTree<LogsState, RootState> = {
    logs(state: LogsState): Array<Log> {
        return state.logs;
    },
    logsForCsv(state: LogsState): Array<Log> {
        return state.logsForCsv;
    },
    logsMeta(state: LogsState): Meta {
        return state.meta;
    }
}

export default getters;
