<template>
  <right-side-nav v-if="isVisible" @close="$emit('close', false)">
    <template v-slot:header>
      {{ $t('components.form.create') }}
      <br>
      {{ $tc('app.views.language.language', 1) }}
    </template>

    <template v-slot:content>
      <form>
        <div class="inputs">
          <Field :label="$t('components.form.languageCode')">
            <input-select
                placeholder="Select language"
                :options="languages"
                :has-error="formErrors.hasErrors('code')"
                :error="formErrors.get('code')?.toString()"
                :value="code"
                @select="code = $event"
                with-search
            />
          </Field>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <ButtonsSaveCancel @close="$emit('close', false)" @save="submit"/>
    </template>
  </right-side-nav>
</template>

<script lang="ts">
import {Component, Prop} from "vue-property-decorator";
import {mapActions, mapGetters} from "vuex";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import {ActionTypes} from "@/vuex/modules/languages-module/action-types";
import {SelectOption} from "@/components/InputSelect/InputSelect";
import Field from "@/components/Field/Field.vue";
import {FormMixin} from "@/mixins/formMixin";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";

@Component({
  methods: mapActions({
    fetchAvailableLanguages: ActionTypes.FETCH_AVAILABLE_LANGUAGES,
    createLanguage: ActionTypes.CREATE_LANGUAGE,
  }),
  computed: mapGetters({
    languages: 'languageAvailableSelectOptions'
  }),
  components: {
    Field,
    ButtonsSaveCancel
  }
})
export default class LanguageForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean
  })
  public isVisible!: boolean;

  protected createLanguage!: (params: { code: string, name: string }) => Promise<void>;
  protected fetchAvailableLanguages!: () => Promise<void>;

  protected readonly languages!: SelectOption[];

  protected code!: string;

  protected async created(): Promise<void> {
    this.$emit('loading');
    await this.fetchAvailableLanguages();
    this.$emit('loading');
  }

  protected async submit(): Promise<void> {
    this.$emit('loading');
    this.formErrors.clearErrors();

    const name = this.languages.find((option: SelectOption) => option.value === this.code)?.label;

    try {
      if (!!name) {
        await this.createLanguage({
          code: this.code,
          name: name
        });
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: 'Language has benn added.',
          type: NotificationType.SUCCESS
        });
        this.$emit('close', true);
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.$emit('loading');
  }
}

</script>
