<template>
  <div class="Form">
    <LoadingSpinner :show="loading" v-if="loading" />

    <div class="Title">
      {{
        confirmed
          ? $t("auth.register.emailConfirmed")
          : $t("auth.email.youAreLate")
      }}
    </div>

    <p class="ConfirmAttentionText">
      {{
        confirmed
          ? $t("auth.email.youCanLogin")
          : $t("auth.email.emailConfirmationLinkExpired")
      }}
    </p>

    <div class="Bottom">
      <div class="Footer center">
        <button v-if="confirmed" class="Button" @click="toLogin">
          {{ $t("auth.login.logIn") }}
        </button>
        <button v-else class="Button" @click="resend">
          {{ $t("auth.login.resendEmail") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthRegisterOrganisation from "@/views/Auth/AuthRegisterOrganisation.vue";
import { ActionTypes } from "@/vuex/modules/auth-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

export default {
  name: "AuthConfirmMail",
  components: { AuthRegisterOrganisation },
  data() {
    return {
      loading: false,
      confirmed: false,
      errorMessage: "",
      code: null,
    };
  },
  created() {
    this.code = this.$route.params.code;
    this.confirmEmail();
  },
  methods: {
    async confirmEmail() {
      this.loading = true;

      try {
        await this.$store.dispatch(ActionTypes.CONFIRM_EMAIL, this.code);
        this.confirmed = true;
      } catch (exception) {
        this.errorMessage = exception.response.data.message;
      }

      this.loading = false;
    },
    async toLogin() {
      await this.$router.push({ name: "login" });
    },
    async resend() {
      this.loading = true;

      try {
        await this.$store.dispatch(ActionTypes.RESEND_EMAIL, {
          code: this.code,
          host: window.location.origin,
        });
        await this.$router.push({ name: "login" });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response?.data?.message,
          type: NotificationType.DANGER,
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.center {
  justify-content: center !important;
  margin-top: 30px;
}
.Button {
  width: 234px !important;
}

.ConfirmAttentionText {
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;
  font-size: 12px;
  color: #3d3d3d;
}
</style>