import Modal from "@/components/Modal/Modal.vue";
import AttentionSign from "@/components/AttentionSign.vue";
import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import UserData from "@/vuex/models/user/user-data";
import UserFull from "@/vuex/models/user/user-full";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { ActionTypes } from "@/vuex/modules/users-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as AuthActions } from "../../../../vuex/modules/auth-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { PageMixin } from "@/mixins/pageMixin";
import FormErrors from "@/services/formErrors/FormErrors";

@Component({
  computed: mapGetters({
    me: "me",
    organisationOptions: "organisationSelectOptions",
  }),
  methods: mapActions({
    create: ActionTypes.CREATE_USER,
    update: ActionTypes.UPDATE_USER,
    fetchMe: AuthActions.FETCH_ME,
  }),
  components: {
    InputSelectOrganisation,
    AccessModal,
    ButtonsSaveCancel,
    Field,
    Modal,
    AttentionSign,
  },
})
export default class NotificationAboutAddNewUser extends PageMixin {
  @Prop({
    required: true,
    default: String,
  })
  public typeModal!: string;

  @Prop({
    required: true,
    type: Object,
  })
  public notificationAboutAddNewUserData!: UserData;

  @Prop({
    required: true,
    type: Boolean,
  })
  public isOpenNotificationModal!: boolean;

  protected readonly create!: (params: UserData) => Promise<UserFull>;
  protected readonly update!: (params: UserData) => Promise<UserFull>;
  private readonly fetchMe!: () => Promise<any>;

  protected formErrors: FormErrors = new FormErrors();

  protected async handleException(exception: any): Promise<void> {
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: exception.response.data.message,
      type: NotificationType.DANGER,
    });

    this.formErrors.setErrors(exception.response.data.errors);
  }

  protected async submit(): Promise<void> {
    this.loading = true;

    this.notificationAboutAddNewUserData.organisation_id = null;

    try {
      if (this.typeModal == "update") {
        await this.updateUser();
      } else {
        await this.createUser();
      }

      this.$emit("saveAndClose", true);
      this.$emit("close");
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  get resutlSum() {
    const price =
      (this?.me?.organisation?.subscription_product_amount ?? 0) / 100;

    const activeUsersCount =
      (this?.me?.organisation?.active_users_count as number) + 1 ?? 0;

    return +price.toFixed(0) * activeUsersCount;
  }

  openModalHandler() {
    this.$emit("openFormModal");
    this.$emit("close");
  }

  private async createUser(): Promise<void> {
    await this.create(this.notificationAboutAddNewUserData);
    await this.fetchMe();
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t('app.views.user.userCreated'),
      type: NotificationType.SUCCESS,
    });
  }

  private async updateUser(): Promise<void> {
    await this.update(this.notificationAboutAddNewUserData);
    await this.fetchMe();
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t('app.views.user.userUpdated'),
      type: NotificationType.SUCCESS,
    });
  }
}
