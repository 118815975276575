import { PageMixin } from "@/mixins/pageMixin";
import { Component, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import BoardData from "@/vuex/models/statistics/board";
import Field from "@/components/Field/Field.vue";
import DefaultStatisticsCounters from "@/components/DefaultStatisticsCounters/DefaultStatisticsCounters.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import ChartLayoutForFilters from "@/components/ChartLayoutForFilters/ChartLayoutForFilters.vue";
import AddChartButton from "@/components/AddChartButton/AddChartButton.vue";
import ChartLine from "@/components/Charts/ChartLine/ChartLine.vue";

import EditStatisticBoardModal from "./components/EditStatisticBoardModal/EditStatisticBoardModal.vue";

import AddNewBoard from "./components/AddNewBoard/AddNewBoard.vue";
import StatisticCounterModalForm from "./components/StatisticCounterModalForm/StatisticCounterModalForm.vue";
import NotificationModal from "./components/NotificationModal/NotificationModal.vue";
import ChartDataLayout from "@/components/ChartDataLayout/ChartDataLayout.vue";
import StatisticalCells from "@/components/StatisticalCells/StatisticalCells.vue";
import { http } from "@/services/http/http";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";

@Component({
  components: {
    StatisticalCells,
    ButtonWithIcon,
    DefaultStatisticsCounters,
    ChartLine,
    Field,
    ChartLayoutForFilters,
    AddChartButton,
    EditStatisticBoardModal,
    StatisticCounterModalForm,
    ChartDataLayout,
    AccessModal,
    AddNewBoard,
    ButtonsSaveCancel,
    NotificationModal,
  },
  computed: mapGetters({
    userOrganisations: "organisations",
    userLocations: "locations",
    userInspectors: "userInspectors",
    user: "me",
  }),
})
export default class StatisticView extends PageMixin {
  protected boardData: BoardData = {
    id: 0,
    name: null,
  };
  protected updateBoardData: BoardData = {
    id: 0,
    name: "Statistics",
  };

  protected isOpenBoard: boolean = false;
  protected isOpenBoardEditModal: boolean = false;
  protected isOpenNotificationModal: boolean = false;

  protected showModalForm: boolean | string = false;

  protected currentCounterId: number = 0;
  protected currentChartId: number = 0;

  protected counters = [];
  protected charts = [];
  protected countInspectionChartData: any = [];
  protected boards: BoardData[] = [];

  protected isDefaultDataOnChart: boolean = false;

  protected activeBoard: number | string = 0;

  private chartTypes: any = {
    0: "line",
    1: "pie",
    2: "bar",
  };

  public defaultChartData = [];

  get chartsData() {
    return this.charts.map((chart: any) => ({
      ...chart,
      type: this.chartTypes[chart.type],
    }));
  }

  get boardsLabel() {
    const initialObject = {
      label: "Statistics",
      value: 0,
    };

    const boardsLabelArray = this.boards.map((board) => ({
      label: board.name,
      value: board.id,
    }));

    boardsLabelArray.unshift(initialObject);

    return boardsLabelArray;
  }

  async created() {
    this.validateProcessPlan();
    await this.getBoards();
    await this.getCounters();
    await this.getCharts();
  }

  protected selectBoard(board: number | string): void {
    this.activeBoard = board;
  }

  protected validateProcessPlan() {
    const isAdmin = this.auth.isSystemAdmin(this.me);
    const isProUser =
      this.me?.organisation?.subscription_product_slug === "pro";

    this.isOpenNotificationModal = isAdmin ? false : isProUser;
  }

  @Watch("activeBoard", { deep: true })
  private async getCounters() {
    this.loading = true;
    this.counters = (
      await http().get(
        `/api/statistics/counters/board/${
          this.activeBoard ? this.activeBoard : ""
        }`
      )
    ).data;
    this.loading = false;
  }

  @Watch("activeBoard", { deep: true })
  private async getCharts() {
    this.isDefaultDataOnChart = true;

    this.loading = true;
    const params = {
      start_date: dayjs().add(-7, "days").toDate(),
      end_date: dayjs().toDate(),
    };

    this.charts = (
      await http().get(
        `/api/statistics/charts/board/${
          this.activeBoard ? this.activeBoard : ""
        }`,
        {
          params,
        }
      )
    ).data;
    this.loading = false;
    this.isDefaultDataOnChart = false;
  }

  @Watch("activeBoard")
  onIsOpenBoardModal(value: number) {
    const foundBoard = this.boardsLabel.find((el) => el.value === value);

    if (foundBoard) {
      this.updateBoardData = {
        id: foundBoard.value,
        name: foundBoard.label,
      };

      if (this.isOpenBoard && foundBoard.value) {
        this.isOpenBoardEditModal = true;
      }
    }
  }

  protected openEditBoardModalHandler() {
    if (this.updateBoardData.id) {
      this.isOpenBoardEditModal = true;
    }
  }

  protected closeBoardModal() {
    this.isOpenBoardEditModal = false;
  }

  protected closeForm() {
    this.showModalForm = false;
    this.currentCounterId = 0;
    this.currentChartId = 0;
  }

  protected openEditCounter(id: number) {
    this.showModalForm = "edit_counter";
    this.currentCounterId = id;
  }

  protected openEditChart(id: number) {
    this.showModalForm = "edit_chart";
    this.currentChartId = id;
  }

  private async getBoards() {
    this.boards = (await http().get("/api/statistics/boards/")).data;
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  private async createBoard(board: number) {
    this.loading = true;

    await this.getBoards();
    this.activeBoard = board;
    this.isOpenBoard = false;

    this.loading = false;
  }

  private async updateBoard(board_id: number) {
    this.loading = true;

    this.isOpenBoardEditModal = false;
    await this.getBoards();
    this.isOpenBoard = false;
    this.selectBoard(board_id);

    this.loading = false;
  }

  private async deleteBoard() {
    try {
      await http().delete(`/api/statistics/boards/${this.updateBoardData.id}`);

      await this.getBoards();
      this.isOpenBoardEditModal = false;
      this.activeBoard = 0;
      this.isOpenBoard = false;
    } catch (e) {
      console.log(e);
    }
  }

  async getCountInspectionData({ startDate, endDate }: any) {
    try {
      const response = await http().get(
        `/api/statistics/charts/count-of-inspections`,
        {
          params: {
            start_date: startDate.toDate(),
            end_date: endDate.toDate(),
          },
        }
      );
      this.countInspectionChartData = response.data.dataset;
    } catch (e) {
      console.log(e);
    }
  }

  private async getCounterAfterCreateAdnUpdate() {
    this.closeForm();
    await this.getCounters();
  }

  private async getChartsAfterCreateAdnUpdate() {
    this.closeForm();
    await this.getCharts();
    await this.getCountInspectionData({
      startDate: dayjs().add(-7, "day"),
      endDate: dayjs(),
    });
  }

  private async deleteCounter() {
    try {
      await http().delete(`/api/statistics/counters/${this.currentCounterId}`);
      await this.getCounters();
      this.currentCounterId = 0;
      this.showModalForm = false;
    } catch (e) {
      console.log(e);
    }
  }

  private async deleteChart() {
    try {
      await http().delete(`/api/statistics/charts/${this.currentChartId}`);
      await this.getCharts();
      this.currentChartId = 0;
      this.showModalForm = false;
    } catch (e) {
      console.log(e);
    }
  }
}
