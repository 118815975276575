<template>
  <input-select
    :with-search="auth.isSystemAdmin(me)"
    @searchOptions="searchOrganisationOptions"
    :search-placeholder="$t('app.views.selectorForOrganisations.placeholder')"
    :options="organisationSelectOptions"
    :value="organisationValue"
    :hasError="hasError"
    :error="error"
    @select="setOrganisation"
    :disabled="disabled"
    :return-full-option="returnFullOption"
  ></input-select>
</template>

<script>
import {mapGetters} from "vuex";
import {
  Direction,
  OrganisationsSortableColumns,
} from "@/services/helpers/sort";
import Authorization from "@/services/authorization/authorization";
import { ActionTypes } from "@/vuex/modules/organisations-module/action-types";
import { MutationTypes } from "@/vuex/modules/organisations-module/mutation-types";

export default {
  name: "InputSelectOrganisation",
  emits: ["select", "loading"],
  data() {
    return {
      auth: new Authorization(),
      organisationValue: null,
    };
  },
  props: {
    organisationId: {
      required: false,
      default: null,
    },
    organisationName: {
      required: false,
      default: null,
    },
    hasError: {
      required: false,
      default: false,
    },
    error: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    returnFullOption: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  async created() {
    if (this.auth.isSystemAdmin(this.me)) {
      await this.searchOrganisationOptions(
        !!this.organisationName ? this.organisationName : null
      );
    } else {
      await this.$store.commit(MutationTypes.SET_ORGANISATIONS, [this.me.organisation]);
    }
    this.organisationValue = this.organisationId;
  },
  computed: {
    ...mapGetters(["me", "organisationSelectOptions"]),
  },
  methods: {
    async searchOrganisationOptions(search) {
      const searchParams = {
        page: 1,
        per_page: 30,
        direction: Direction.ASC,
        column: OrganisationsSortableColumns.NAME,
        search: this.auth.isSystemAdmin(this.me) ? search : null,
      };
      await this.$store.dispatch(ActionTypes.FETCH_ORGANISATIONS, searchParams);
    },
    setOrganisation(organisationId) {
      this.$emit("select", organisationId);
    },
  },
  watch: {
    organisationName: {
      async handler() {
        await this.searchOrganisationOptions(this.organisationName);
        this.organisationValue = this.organisationId;
      },
    },
  },
};
</script>

<style scoped>
</style>
