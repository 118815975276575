<template>
  <Modal :modal="modal" @close="$emit('close')">
    <template v-slot:header>
      <div class="header">
        {{ $t("app.views.inspection.execution.cancelTheAudit") }}
      </div>
    </template>
    <template v-slot:content>
      <div class="cancel">
        {{ $t("app.views.inspection.execution.cancelNotification") }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="action-buttons">
        <button class="cancel" @click="handleSubmit">
          {{ $t("app.views.inspection.execution.yesCancel") }}
        </button>
        <button class="back" @click="$emit('close')">
          {{ $t("app.views.inspection.execution.noCancel") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";

export default {
  name: "CancelModal",
  components: {
    Modal,
  },
  props: {
    modal: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    handleSubmit() {
      if (!this.$route?.meta?.isExternalInspection) {
        this.$emit("cancel");
      } else {
        this.$emit("handleCancelExternalInspection");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: calc(48px + 48px + 20px);
  margin-bottom: calc(55px - 25px);

  button {
    border: 2px solid;
    border-radius: 122px;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 48px;
    cursor: pointer;

    text-align: center;
    font: normal normal normal 12px/21px Poppins;
    letter-spacing: 0;
    background-color: #fff;
  }

  .cancel {
    border-color: #ff5151;
    color: #ff5151;
  }

  .cancel:hover {
    background-color: #ff5151;
    color: #fff;
  }

  .back {
    border-color: #1de591;
    color: #1de591;
  }

  .back:hover {
    background-color: #1de591;
    color: #fff;
  }
}

.header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #0d0e34;
}

.cancel {
  font: normal normal normal 12px/21px Poppins;
  letter-spacing: 0;
  color: #ff5151;
}
</style>