import {MutationTypes} from "./mutation-types";
import {MutationTree} from "vuex";
import Meta from "@/vuex/models/meta";
import OrganisationRequestsState from "@/vuex/modules/organisation-requests-module/state";
import OrganisationRequest from "@/vuex/models/org-request/org-request";

const mutations: MutationTree<OrganisationRequestsState> = {
    [MutationTypes.SET_REQUESTS](state: OrganisationRequestsState, requests: OrganisationRequest[]): void {
        state.requests = requests;
    },
    [MutationTypes.SET_REQUESTS_META](state: OrganisationRequestsState, meta: Meta): void {
        state.meta = meta;
    }
}

export default mutations;
