import Meta from "@/vuex/models/meta";
import Incident from "@/vuex/models/incident/incident";
import IncidentsTypes from "@/vuex/models/incident/incidentTypes";

export default class IncidentsState {
  public incidents: Incident[] = [];
  public incidentsForCsv: Incident[] = [];
  public incidentsTypes: IncidentsTypes[] = [];
  public incidentsTypesForFilter: IncidentsTypes[] = [];
  public meta!: Meta;
  public count!: number;
}
