import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import BrowserState from "./state";

const module: Module<BrowserState, RootState> = {
    state: new BrowserState(),
    actions,
    mutations,
    getters,
}

export default module
