import { Component, Mixins, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import { mapActions, mapGetters } from "vuex";

import Meta from "@/vuex/models/meta";
import { PageMixin } from "@/mixins/pageMixin";
import { QRCodeSortableColumns } from "@/services/helpers/sort";
import i18n from "@/lang/locale";

import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";

import QRCodeFull from "@/vuex/models/qrCode/qrCode-full";
import { QRCodeSearchParams } from "@/vuex/modules/qr-code-module/actions";
import { ActionTypes } from "@/vuex/modules/qr-code-module/action-types";

import QRCodeItem from "./components/QRCodeItem/QRCodeItem.vue";
import QRCodeForm from "./components/QRCodeForm/QRCodeForm.vue";
import DeleteModal from "./components/DeleteModal.vue";
import {
  qrCodesPermissions,
  qrCodesType,
} from "@/vuex/models/qrCode/location-data";
import AccessModal from "@/components/Modal/components/AccessModal.vue";

@Component({
  components: {
    QRCodeForm,
    ButtonWithIcon,
    QRCodeItem,
    DeleteModal,
    AccessModal,
  },
  methods: mapActions({
    fetchQRCodes: ActionTypes.FETCH_QR_CODES,
    fetchQRCodesForCsv: ActionTypes.FETCH_QR_CODES_FOR_CSV,
    deleteQrCode: ActionTypes.DELETE_QR_CODE,
  }),
  computed: mapGetters({
    listQRCodes: "listQRCodes",
    qrCodesForCsv: "qrCodesForCsv",
    meta: "qrCodesMeta",
  }),
})
export default class QRCodeView extends Mixins(PageMixin) {
  private readonly fetchQRCodes!: (params: QRCodeSearchParams) => Promise<void>;
  private readonly deleteQrCode!: (id: number) => Promise<void>;
  private readonly fetchQRCodesForCsv!: (
    params: QRCodeSearchParams
  ) => Promise<void>;

  protected readonly listQRCodes!: QRCodeFull[] | [];
  protected readonly qrCodesForCsv!: QRCodeFull[] | [];
  protected readonly meta!: Meta;

  protected showAccessModal: boolean = false;
  protected sortableColumns = QRCodeSortableColumns;
  protected selectedId: number | null = null;
  protected deleteData: { name: string; id: number | null } = {
    name: "",
    id: null,
  };
  protected searchParams: QRCodeSearchParams = {
    page: 1,
    per_page: 25,
    search: null,
    location_id: null,
    permission: null,
    type: null,
  };

  protected get limitReached(): boolean {
    if (!this.auth.isSystemAdmin(this.me) && !!this.me.organisation) {
      // @ts-ignore
      return (
        null !== this.me.organisation.qr_codes_limit &&
        this.listQRCodes.length >= this.me.organisation.qr_codes_limit
      );
    }

    return false;
  }

  protected readonly permissionsOptions = [
    {
      label: i18n.t("app.views.qrCodes.permissionOptions.public").toString(),
      value: qrCodesPermissions.PUBLIC,
    },
    {
      label: i18n
        .t("app.views.qrCodes.permissionOptions.organisation")
        .toString(),
      value: qrCodesPermissions.ORGANISATION,
    },
    {
      label: i18n.t("app.views.qrCodes.permissionOptions.private").toString(),
      value: qrCodesPermissions.PRIVATE,
    },
  ];

  protected readonly typeOptions = [
    {
      label: i18n.t("app.views.qrCodes.typeOptions.incident").toString(),
      value: qrCodesType.INCIDENT,
    },
    {
      label: i18n.t("app.views.qrCodes.typeOptions.inspections").toString(),
      value: qrCodesType.INSPECTIONS,
    },
  ];

  protected async created(): Promise<void> {
    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchQRCodes(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected getPermissionText(
    option:
      | qrCodesPermissions.PUBLIC
      | qrCodesPermissions.PRIVATE
      | qrCodesPermissions.ORGANISATION
  ) {
    switch (option) {
      case qrCodesPermissions.ORGANISATION:
        return i18n
          .t("app.views.qrCodes.permissionOptions.organisation")
          .toString();
      case qrCodesPermissions.PRIVATE:
        return i18n.t("app.views.qrCodes.permissionOptions.private").toString();
      case qrCodesPermissions.PUBLIC:
        return i18n.t("app.views.qrCodes.permissionOptions.public").toString();
    }
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchLocations(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected openForm(): void {
    console.log(this.limitReached);
    if (!this.limitReached) {
      this.showForm = true;
    } else {
      this.showAccessModal = true;
    }
  }

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;
    this.selectedId = null;
    this.deleteData = {
      id: null,
      name: "",
    };

    if (submitted) {
      await this.paginate();
    }
  }

  protected update(locationId: number): void {
    this.selectedId = locationId;

    this.showForm = true;
  }

  protected async remove(id: number, name: string) {
    this.deleteData = {
      name,
      id,
    };
  }

  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      i18n.t("components.table.typeIncident").toString(),
      ...(this.auth.isSystemAdmin(this.me)
        ? [i18n.t("components.table.organisation").toString()]
        : []),
      i18n.t("components.table.location").toString(),
      i18n.t("components.table.template").toString(),
      i18n.t("components.table.access").toString(),
      i18n.t("components.table.status").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchQRCodesForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.qrCodesForCsv?.map((el) => ({
        [i18n.t("components.table.name").toString()]: el?.name,
        [i18n.t("components.table.typeIncident").toString()]:
          el.type === 1
            ? i18n.t("app.views.qrCodes.typeOptions.incident")
            : i18n.t("app.views.qrCodes.typeOptions.inspections"),
        ...(this.auth.isSystemAdmin(this.me) && {
          [i18n.t("components.table.organisation").toString()]:
            el?.organisation?.name,
        }),
        [i18n.t("components.table.location").toString()]:
          el?.location?.name ?? "-",
        [i18n.t("components.table.template").toString()]:
          el?.template?.name ?? "-",
        [i18n.t("components.table.access").toString()]: this.getPermissionText(
          el?.permission
        ),
        [i18n.t("components.table.status").toString()]:
          el?.status === 0
            ? i18n.t("app.views.qrCodes.statusOptions.inactive")
            : i18n.t("app.views.qrCodes.statusOptions.active"),
      })),
      this.generateHeaders(),
      "qr_codes.csv"
    );
  }
}
