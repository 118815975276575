import {Component, Vue} from 'vue-property-decorator';
import store from "@/vuex/store";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import FormErrors from "@/services/formErrors/FormErrors";
import Authorization from "@/services/authorization/authorization";
import {mapGetters} from "vuex";
import UserFull from "@/vuex/models/user/user-full";

@Component({
    computed: {
        ...mapGetters(['me'])
    }
})
export class FormMixin extends Vue {
    protected formErrors: FormErrors = new FormErrors();

    protected readonly me!: UserFull;
    protected readonly auth: Authorization = new Authorization();

    protected async handleException(exception: any): Promise<void> {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
            text: exception.response.data.message,
            type: NotificationType.DANGER
        });

        this.formErrors.setErrors(exception.response.data.errors);
    }

    protected close(): void {
        this.$emit('close', false);
    }
}