import { Component } from "vue-property-decorator";
import { mapActions } from "vuex";

import { FormMixin } from "@/mixins/formMixin";
import i18n from "@/lang/locale";
import store from "@/vuex/store";

import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { ActionTypes as IncidentActions } from "@/vuex/modules/incidents-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

import { TIncidentFormData } from "./types";
import IncidentFormData from "@/vuex/models/incident/incident-form-data";
import { http } from "@/services/http/http";

@Component({
  methods: mapActions({
    addIncidentTypeReport: IncidentActions.ADD_INCIDENT_TYPE_REPORT,
  }),
})
export default class IncidentFormReportingView extends FormMixin {
  public isSuccessReporting: boolean | null = null;
  public loading: Boolean = false;
  public incidentTypes: { label: string; value: number }[] = [];

  protected readonly addIncidentTypeReport!: (params: {
    data: IncidentFormData;
  }) => Promise<void>;

  protected readonly defaultTypeOptions = [
    {
      label: i18n.t("app.views.typeIncident.other").toString(),
      value: 0,
    },
  ];

  protected incidentData: TIncidentFormData = {
    description: null,
    incident_type_id: null,
    image: null,
    qr_token: null,
  };

  created() {
    this.incidentData.qr_token = this.$route.params.code;
    this.getIncidentTypes();
  }

  protected selectIncidentType(incidentTypeId: number) {
    this.incidentData.incident_type_id = incidentTypeId;
  }

  async getIncidentTypes() {
    this.loading = true;
    try {
      const response = await http().get(
        `/api/incident-types/get-for-qr-form?qr_token=${this.$route.params.code}`
      );
      this.incidentTypes = response?.data?.data?.map(
        (el: { id: number; name: string }) => ({
          value: el.id,
          label: el.name,
        })
      );
    } catch (exception) {
      this.isSuccessReporting = false;
    } finally {
      this.loading = false;
    }
  }

  protected async submit(): Promise<void> {
    this.loading = true;
    try {
      await this.createIncidentReport();
      this.isSuccessReporting = true;
    } catch (exception: any) {
      if (exception.response.status === 422) {
        await this.formErrors.setErrors(exception.response.data.errors);
      } else if (exception.response.status === 400) {
        this.isSuccessReporting = false;
      } else {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    } finally {
      this.loading = false;
    }
  }

  protected addImage(event: any) {
    if (event.target.files[0].size > 8388608) {
      return store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: i18n
          .t(
            "app.views.inspection.execution.notificationMessage.isValidateImage"
          )
          .toString(),
        type: NotificationType.DANGER,
      });
    }
    this.incidentData.image = event.target.files[0];
    const imageInput: any = document.getElementById("imageInput");
    imageInput.value = null;
  }

  protected async createIncidentReport(): Promise<void> {
    await this.addIncidentTypeReport({ data: this.incidentData });
  }
}
