import LanguageAvailable from "@/vuex/models/language/language-available";
import Language from "@/vuex/models/language/language";
import LanguageWord from "@/vuex/models/language/langauge-word";
import LanguageWithWords from "@/vuex/models/language/language-with-words";

export default class LanguagesState {
    languages: Language[] = [];
    languagesAvailable: LanguageAvailable[] = [];
    languageWords: LanguageWord[] = [];
    language: LanguageWithWords | null = null;
    languageDefault: LanguageWithWords | null = null;

    browserLocale: string | null = null
    browserTimezone: string = ''
}
