import UserFull from "@/vuex/models/user/user-full";
import store from "@/vuex/store";
import {ActionTypes} from "@/vuex/modules/languages-module/action-types";

export class Lang {
  public async loadLang(userFull?: UserFull) {
    const queryLang: string | null = new URL(window.location.href).searchParams.get('lang');
    const browserLang: string = navigator.language.split('-')[0].toLowerCase();

    const langCode: string | number = queryLang || userFull?.language_id || browserLang;
    const {messages, languageCode} = await store.dispatch(ActionTypes.FETCH_TRANSLATIONS, langCode);

    document.querySelector(':root')!.setAttribute('lang', languageCode)
  }
}
