import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import LocationForm from "./components/LocationForm/LocationForm.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import { ActionTypes } from "@/vuex/modules/locations-module/action-types";
import { LocationSearchParams } from "@/vuex/modules/locations-module/actions";
import Meta from "@/vuex/models/meta";
import { PageMixin } from "@/mixins/pageMixin";
import { LocationsSortableColumns } from "@/services/helpers/sort";
import LocationFull from "@/vuex/models/location/location-full";
import UserProfile from "@/vuex/models/user/user-profile";
import DeleteLocationModal from "@/views/LocationsView/components/DeleteLocationModal.vue";
import { Debounce } from "vue-debounce-decorator";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import LocationItem from "./components/LocationItem/LocationItem.vue";
import i18n from "@/lang/locale";

@Component({
  components: {
    AccessModal,
    DeleteLocationModal,
    LocationForm,
    ButtonWithIcon,
    LocationItem,
  },
  methods: mapActions({
    fetchLocations: ActionTypes.FETCH_LOCATIONS,
    fetchLocationsForCsv: ActionTypes.FETCH_LOCATIONS_FOR_CSV,
  }),
  computed: mapGetters({
    locations: "locations",
    locationsForCsv: "locationsForCsv",
    meta: "locationsMeta",
  }),
})
export default class LocationsView extends Mixins(PageMixin) {
  private readonly fetchLocations!: (
    params: LocationSearchParams
  ) => Promise<void>;

  private readonly fetchLocationsForCsv!: (
    params: LocationSearchParams
  ) => Promise<void>;

  protected readonly locations!: LocationFull[] | [];
  protected readonly locationsForCsv!: LocationFull[] | [];
  protected readonly meta!: Meta;

  protected showAccessModal: boolean = false;
  protected sortableColumns = LocationsSortableColumns;
  protected selectedLocationId: number | null = null;
  protected locationName: string = "";
  protected searchParams: LocationSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
    organisation_id: null,
  };

  protected get limitReached(): boolean {
    if (!this.auth.isSystemAdmin(this.me) && !!this.me.organisation) {
      // @ts-ignore
      return (
        null !== this.me.organisation.locations_limit &&
        this.locations.length >= this.me.organisation.locations_limit
      );
    }

    return false;
  }

  protected async created(): Promise<void> {
    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchLocations(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchLocations(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  protected openForm(): void {
    if (!this.limitReached) {
      this.showForm = true;
    } else {
      this.showAccessModal = true;
    }
  }

  protected locationUsers(location: LocationFull): string {
    const users: string[] = location.users.map(
      (user: UserProfile) => `${user.name} ${user.surname}`
    );

    return users.join(", ");
  }

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;
    this.selectedLocationId = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async closeModal(submitted: boolean): Promise<void> {
    this.showDeleteModal = false;
    this.selectedLocationId = null;
    this.locationName = "";

    if (submitted) {
      await this.paginate();
    }
  }

  protected update(locationId: number): void {
    this.selectedLocationId = locationId;

    this.showForm = true;
  }

  protected remove(locationId: number, locationName: string): void {
    this.selectedLocationId = locationId;
    this.locationName = locationName;
    this.showDeleteModal = true;
  }

  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      ...(this.auth.isSystemAdmin(this.me)
        ? [i18n.t("components.table.organisation").toString()]
        : []),
      i18n.t("components.table.assignedUsers").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchLocationsForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.locationsForCsv.map((el) => ({
        [i18n.t("components.table.name").toString()]: el.name,
        ...(this.auth.isSystemAdmin(this.me) && {
          [i18n.t("components.table.organisation").toString()]:
            el.organisation.name,
        }),
        [i18n.t("components.table.assignedUsers").toString()]: !!el.users.length
          ? el.users.map((u) => `${u.name} ${u.surname}`).join(", ")
          : "-",
      })),
      this.generateHeaders(),
      "sites.csv"
    );
  }
}
