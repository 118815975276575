<template>
  <div class="Checkbox" @click="onClick" v-bind="$attrs">
    <input type="checkbox" v-model="checked">
    <div class="Check">
      <svg v-if="value" xmlns="http://www.w3.org/2000/svg" width="12.518" height="8.654" viewBox="0 0 12.518 8.654">
        <path id="Path_16" data-name="Path 16" d="M9198.242,3277.69l3.451,3.45,6.238-6.24"
              transform="translate(-9196.828 -3273.486)" fill="none" stroke="#1de591" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  inheritAttrs: false,
  props: {
    value: [Boolean, Number],
    disabled: Boolean
  },
  computed: {
    currentValue: {
      get() {
        return this.value === true || this.value === 1;
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    checked() {
      return this.currentValue === true;
    }
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }

      this.currentValue = !this.currentValue;
    }
  }
}
</script>

<style scoped lang="scss">
$size: 24px;

.Checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: $size;
  height: $size;
  background-color: #FFF;

  &.IsWhite input[type=checkbox] + .Check {
    //border: 1px solid $white-color;
    //color: $white-color;
  }

  input[type=checkbox] {
    position: absolute;
    left: 0;
    outline: none;
    z-index: -1;
    opacity: 0;

    + .Check {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: $size;
      height: $size;
      border: 1px solid #CECECE;
      border-radius: 3px;
    }
  }

}
</style>