import Vue from "vue";
import { Bar } from "vue-chartjs/legacy";
import { mapGetters } from "vuex";
import { Component, Prop, Watch } from "vue-property-decorator";

import { TminMaxValue } from "@/components/ChartDataLayout/types";
import UserFull from "@/vuex/models/user/user-full";

@Component({
  components: {
    Bar,
  },
  computed: mapGetters({
    me: "me",
  }),
})
export default class ChartBar extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 400,
  })
  public width!: number;

  @Prop({
    type: Number,
    required: false,
    default: 350,
  })
  public height!: number;

  @Prop({
    type: Object,
    required: false,
  })
  public chartData!: any;

  @Prop({
    type: Number,
    required: false,
  })
  public isDuration!: number;

  @Prop({
    type: Object,
    required: false,
  })
  public minMaxValue!: TminMaxValue;

  protected readonly me!: UserFull;

  public chartOptions = {};

  @Watch("isDuration", { immediate: true })
  @Watch("minMaxValue", { immediate: true, deep: true })
  updateOptions() {
    let isThemeDark =
      this.me.theme === "theme-dark" ||
      (this.me.theme === "theme-default" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    let trickColor = isThemeDark ? "#CCCCCC" : "#6D6D6D";
    let gridColor = isThemeDark ? "#585858" : "#DEDEDE";
    const baseChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          displayColors: false,
          backgroundColor: "white",
          bodyColor: "#1DE591",
          borderWidth: 1,
          padding: {
            top: 8,
            left: 22.2,
            right: 22.2,
            bottom: 8,
          },
          borderColor: "#DEDEDE",
          titleFont: {
            size: 0,
          },
          bodyFont: {
            size: 14,
            color: "black",
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          ticks: {
            maxTicksLimit: 4,
            callback: (value: number) => {
              return value.toFixed(0);
            },
            color: trickColor,
          },
          grid: {
            color: gridColor,
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            color: trickColor,
          },
          grid: {
            display: false,
          },
        },
      },
    };

    if (this.minMaxValue) {
      this.chartOptions = {
        ...baseChartOptions,
        scales: {
          y: {
            ticks: {
              maxTicksLimit: 4,
              callback: (value: number) => {
                return this.isDuration === 2
                  ? value.toFixed(1) + " m"
                  : value.toFixed(0);
              },
              color: trickColor,
            },
            grid: {
              color: gridColor,
              drawBorder: false,
            },
            ...this.minMaxValue,
          },
          x: {
            ticks: {
              color: trickColor,
            },
            grid: {
              display: false,
            },
          },
        },
      };
    } else {
      this.chartOptions = {
        ...baseChartOptions,
        scales: {
          y: {
            ticks: {
              maxTicksLimit: 4,
              callback: (value: number) => {
                return this.isDuration === 2
                  ? value.toFixed(1) + " m"
                  : value.toFixed(0);
              },
              color: trickColor,
            },
            grid: {
              color: gridColor,
              drawBorder: false,
            },
          },
          x: {
            ticks: {
              color: trickColor,
            },
            grid: {
              display: false,
            },
          },
        },
      };
    }
  }
}
