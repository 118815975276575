import { FormMixin } from "@/mixins/formMixin";
import { Component, Prop, Watch } from "vue-property-decorator";
import BoardData from "@/vuex/models/statistics/board";

import Field from "@/components/Field/Field.vue";
import AddChartButton from "@/components/AddChartButton/AddChartButton.vue";
import { http } from "@/services/http/http";

@Component({
  components: {
    Field,
    AddChartButton,
  },
})
export default class AddNewBoard extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public isOpenBoard!: boolean;
  @Prop({
    required: true,
    type: Object,
  })
  public boardData!: BoardData;

  @Watch("isOpenBoard")
  onIsOpenBoardChange(value: boolean) {
    if (!value) {
      this.formErrors.clearErrors();
    }
  }

  private async addBoardHandler() {
    try {
      const { data: board } = await http().post("/api/statistics/boards/", {
        name: this.boardData.name,
      });
      await this.$emit("createBoard", board.id);
      this.boardData.name = "";
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }
}
