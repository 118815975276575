import { GetterTree } from "vuex";
import { RootState } from "@/vuex/store";
import LanguagesState from "@/vuex/modules/languages-module/state";
import Language from "@/vuex/models/language/language";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import LanguageAvailable from "@/vuex/models/language/language-available";
import LanguageWord from "@/vuex/models/language/langauge-word";
import LanguageWithWords from "@/vuex/models/language/language-with-words";
import { generateTranslationsForLanguages } from "@/services/helpers/generateTranslationsForLanguages";

const getters: GetterTree<LanguagesState, RootState> = {
  languages(state: LanguagesState): Language[] {
    return state.languages;
  },
  languagesAvailable(state: LanguagesState): LanguageAvailable[] {
    return state.languagesAvailable;
  },
  languageSelectOptions(state: LanguagesState): SelectOption[] {
    return state.languages.map((language: Language) => {
      return {
        value: language.id,
        label: generateTranslationsForLanguages(language.code),
      };
    });
  },
  languageAvailableSelectOptions(state: LanguagesState): SelectOption[] {
    return state.languagesAvailable.map((language: LanguageAvailable) => {
      return {
        value: language.code,
        label: language.name,
      };
    });
  },
  languageWords(state: LanguagesState): LanguageWord[] {
    return state.languageWords;
  },
  language(state: LanguagesState): LanguageWithWords | null {
    return state.language;
  },
  languageDefault(state: LanguagesState): LanguageWithWords | null {
    return state.languageDefault;
  },

  browserLocale(state: LanguagesState): string | null {
    return state.browserLocale;
  },
  browserTimezone(state: LanguagesState): string | null {
    return state.browserTimezone;
  },
};

export default getters;
