import {Component, Prop} from "vue-property-decorator";

import {http} from "@/services/http/http";

import StatisticalCells from "@/components/StatisticalCells/StatisticalCells.vue";
import {TDefaultCounters} from "./types";
import {PageMixin} from "@/mixins/pageMixin";

@Component({
  components: {
    StatisticalCells,
  },
})
export default class DefaultStatisticsCounters extends PageMixin {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public isRightPosition!: boolean;

  protected defaultCounters: TDefaultCounters = {
    made_inspections: 0,
    solved_incidents: 0,
    not_solved_incidents: 0,
    avg_score: 0,
  };

  async created() {
    await this.getDefaultCounters();
  }

  async getDefaultCounters() {
    this.loading = true
    try {
      const response = await http().get(`/api/statistics/counters/default`);
      this.defaultCounters = response.data;
    } catch (error) {
      console.error("Error making the HTTP request:", error);
    } finally {
      this.loading = false
    }
  }
}
