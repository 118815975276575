<template>
  <div class="bg-popup" @click.self="$emit('close')">
    <div class="popup-background">
      <h1 class="popup-title">{{ incidentPhotoData.report_name }}</h1>
      <div class="image-wrapper">
        <img alt="photo incident" :src="incidentPhotoData.pictureUrl" />
      </div>
      <div class="popup-footer">
        <styles-button
          :isTransparentButton="true"
          @clickHandler="$emit('close')"
          title="Go back"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StylesButton from "@/components/Buttons/StylesButton.vue";

export default {
  components: { StylesButton },
  name: "PopupPhoto",
  props: {
    incidentPhotoData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-popup {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.77);
}

.popup-background {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 1434px;
  max-height: 875px;
  width: 90%;
  height: 90%;

  padding: 30px;

  box-shadow: 0px 0px 16px #0000000f;
  border-radius: 10px;
  background-color: white;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;

  img {
    width: auto;
    height: 100%;
  }
}

.popup-footer {
  display: flex;
  justify-content: end;
}

.popup-title {
  color: #0d0e34;
  font-size: 18px;
  font-weight: bold;
}
</style>
