<template>
  <Modal :modal="modal">
    <div class="header" slot="header">
      {{ label }}
    </div>
    <div class="content" slot="content">
      {{ $t("app.views.user.deleteLocNotification") }}
    </div>
    <div class="footer" slot="footer">
      <ButtonsSaveCancel @close="$emit('close', false)" :danger="true">
        <span class="delete-button" slot="btn-save" @click="remove">
          {{ $t("app.views.user.deleteLocation") }}
        </span>
      </ButtonsSaveCancel>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes } from "@/vuex/modules/locations-module/action-types";

export default {
  name: "DeleteLocationModal",
  emits: ["close", "loading"],
  components: { ButtonsSaveCancel, Modal },
  props: {
    modal: {
      required: true,
      type: Boolean,
    },
    locationId: {
      required: true,
      type: Number,
    },
    locationName: {
      required: true,
      type: String,
    },
  },
  computed: {
    label() {
      let label = this.$t("app.views.user.deleteLocationTitle");

      if (!!this.locationId) {
        label += ` "${this.locationName}"`;
      }

      return label;
    },
  },
  methods: {
    async remove() {
      this.$emit("loading");

      try {
        await this.$store.dispatch(
          ActionTypes.DELETE_LOCATION,
          this.locationId
        );
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: this.$t('app.views.user.locationDeleted'),
          type: NotificationType.SUCCESS,
        });
        this.$emit("close", true);
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.$emit("loading");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #ff5151;
}

.content {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0;
  color: #3d3d3d;
  margin: 0;
}

.footer {
  width: 100%;
  padding: 0;
  background: none;

  .ButtonsSaveCancel {
    justify-content: space-between;
  }

  .delete-button {
    min-width: 220px;
  }
}
</style>
