<template>
  <div class="Form">
    <LoadingSpinner v-if="loading" :show="loading" />

    <div class="Title" v-if="hasOrganisations">
      {{ $t("auth.register.joinOrganisation") }}
    </div>
    <div class="OrgInput" v-if="hasOrganisations">
      <div class="ExistingOrgs">
        <input-select
          with-search
          :options="organisationOptions"
          :value="organisationToJoin.id"
          @select="selectOrganisation"
          @searchOptions="searchOrganisations"
        />
        <ButtonWithIcon @click="joinOrganisation" v-if="allowedToJoin">
          <span slot="text">{{
            $t("auth.register.joinOrganisation").capitalize()
          }}</span>
        </ButtonWithIcon>
        <ButtonWithIcon @click="requestToJoin" v-else>
          <span slot="text">{{
            $t("components.requestToJoin").capitalize()
          }}</span>
        </ButtonWithIcon>
      </div>
    </div>

    <div class="Title">
      {{
        hasOrganisations
          ? $t("auth.register.orCreateOrganisation")
          : $t("auth.register.createOrganisation")
      }}
    </div>

    <div class="ErrorDesc" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="Inputs">
      <div class="NewOrg">
        <input-text
          :placeholder="$t('auth.register.organisationName')"
          v-model="newOrganisation.name"
          :hasError="formErrors.hasErrors('name')"
          :error="formErrors.get('name')?.toString()"
          appearance="auth"
        ></input-text>
        <div class="AllowDomain mobile">
          <OrganisationAccess
            :registerOrganisation="true"
            :email-domain="emailDomain"
            :access.sync="newOrganisation.allow_to_join"
            v-on:update:access="newOrganisation.allow_to_join = $event"
          />
        </div>
        <ButtonWithIcon @click="create">
          <span slot="text">{{ $t("auth.register.createOrganisation") }}</span>
        </ButtonWithIcon>
      </div>
      <div class="AllowDomain desctop">
        <OrganisationAccess
          :registerOrganisation="true"
          :email-domain="emailDomain"
          :access.sync="newOrganisation.allow_to_join"
          v-on:update:access="newOrganisation.allow_to_join = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganisationAccess from "@/components/OrganisationAccess/OrganisationAccess.vue";
import FormErrors from "@/services/formErrors/FormErrors";
import { ActionTypes } from "@/vuex/modules/organisations-module/action-types";
import { ActionTypes as AuthActions } from "@/vuex/modules/auth-module/action-types";
import { mapGetters } from "vuex";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import {
  Direction,
  OrganisationsSortableColumns,
} from "@/services/helpers/sort";

export default {
  name: "AuthRegisterOrganisation",
  components: { OrganisationAccess },
  data() {
    return {
      newOrganisation: {
        name: null,
        allow_to_join: false,
      },
      organisationToJoin: {
        id: null,
        allow_to_join: false,
        name: null,
      },
      searchParams: {
        page: 1,
        per_page: 100,
        search: null,
        direction: Direction.ASC,
        column: OrganisationsSortableColumns.NAME,
      },
      formErrors: new FormErrors(),
      errorMessage: null,
      loading: false,
      hasOrganisations: false,
      host: null,
    };
  },
  computed: {
    ...mapGetters({
      me: "me",
      organisations: "organisations",
      organisationOptions: "organisationSelectOptions",
    }),
    emailDomain() {
      const index = this.me.email.indexOf("@");
      return this.me.email.substring(index + 1);
    },
    allowedToJoin() {
      return !!this.organisationToJoin
        ? this.organisationToJoin.allow_to_join
        : false;
    },
  },
  async created() {
    await this.fetchOrganisations();
    this.hasOrganisations = this.organisations.length > 0;
    this.host = window.location.origin;
  },
  methods: {
    async fetchOrganisations() {
      this.loading = true;
      await this.$store.dispatch(
        ActionTypes.FETCH_ORGANISATIONS,
        this.searchParams
      );

      if (this.organisations.length > 0) {
        this.organisationToJoin = this.organisations[0];
      }
      this.loading = false;
    },
    async searchOrganisations(search) {
      this.searchParams.search = search;
      await this.fetchOrganisations();
    },
    selectOrganisation(organisationId) {
      const organisation = this.organisations.find(
        (org) => org.id === organisationId
      );
      if (!!organisation) {
        this.organisationToJoin = organisation;
      }
    },
    async create() {
      this.loading = true;

      this.newOrganisation["host"] = this.host;

      try {
        await this.$store.dispatch(
          ActionTypes.CREATE_ORGANISATION,
          this.newOrganisation
        );
        await this.$store.dispatch(AuthActions.FETCH_ME);
        await this.$router.push({ name: "dashboard" });
      } catch (exception) {
        this.newOrganisation.allow_to_join = false;
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
        this.formErrors.setErrors(exception.response.data.errors);
      }

      this.loading = false;
    },
    async joinOrganisation() {
      this.loading = true;

      try {
        await this.$store.dispatch(ActionTypes.JOIN_ORGANISATION, {
          organisationId: this.organisationToJoin.id,
          host: this.host,
        });
        await this.$store.dispatch(AuthActions.FETCH_ME);
        await this.$router.push({ name: "dashboard" });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.loading = false;
    },
    async requestToJoin() {
      this.loading = true;

      try {
        await this.$store.dispatch(ActionTypes.JOIN_ORGANISATION, {
          organisationId: this.organisationToJoin.id,
          host: this.host,
        });
        await this.$store.dispatch(AuthActions.FETCH_ME);
        await this.$router.push({ name: "organisation-requested" });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/asesst/styles/mixins";

.NewOrg {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include mobile {
    flex-direction: column !important;
  }

  .auth {
    width: 100%;
  }
}

.OrgInput {
  background: #ffffff 0 0 no-repeat padding-box;
  width: 100%;
  margin-bottom: 30px;
  padding: 14px calc((100% - 535px) / 2);

  .ExistingOrgs {
    display: flex;
    flex-direction: row !important;
    align-items: end;
    gap: 20px;
    justify-content: space-between;

    @include mobile {
      flex-direction: column !important;
    }
  }
}

.mobile {
  display: none;
}

@include mobile {
  .desctop {
    display: none;
  }

  .mobile {
    display: block;
    width: 100%;
  }
}

.button {
  padding: 0;
  font-size: 14px;
  text-align: center;
  min-width: 240px !important;

  @include mobile {
    width: 247px !important;
    margin: 0 auto;
  }
}
</style>
