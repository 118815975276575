export enum NotificationType {
    INFO = 'info',
    SUCCESS = 'success',
    DANGER = 'error',
    WARNING = 'warning',
}

export interface Notification {
    text: string;
    type: NotificationType;
    flushed?: boolean;
}


export default class NotificationsState {
    notifications: Notification[] = [];
    flushNotifications: boolean = false;
}
