import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { FormMixin } from "@/mixins/formMixin";
import { ActionTypes } from "@/vuex/modules/external-access-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import ExternalAccess from "@/vuex/models/external-access/external-access";
import { ExternalAccessSearchParams } from "@/vuex/modules/external-access-module/actions";
import i18n from "@/lang/locale";

@Component({
  computed: mapGetters({
    me: "me",
  }),
  methods: mapActions({
    create: ActionTypes.CREATE_EXTERNAL_ACCESS,
    update: ActionTypes.UPDATE_EXTERNAL_ACCESS,
    fetchExternalAccess: ActionTypes.FETCH_EXTERNAL_ACCESS,
    fetchExternalAccesses: ActionTypes.FETCH_EXTERNAL_ACCESSES,
  }),
  components: {
    AccessModal,
    ButtonsSaveCancel,
    Field,
    InputSelectOrganisation,
  },
})
export default class ExternalAccessForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  public access!: ExternalAccess | null;

  protected readonly create!: (params: { name: string | null; id: number | null; email: string | null; status: number }) => Promise<ExternalAccess>;
  protected readonly update!: (params: { name: string | null; id: number | null; email: string | null; status: number }) => Promise<ExternalAccess>;
  private readonly fetchExternalAccesses!: (params: ExternalAccessSearchParams) => Promise<any>;
  private extAccessSearchParams: ExternalAccessSearchParams = {
    page: 1,
    per_page: 25,
    column: null,
    direction: null,
    search: null,
    organisation_id: null,
  };

  protected readonly statusOptions: SelectOption[] = [
    { label: i18n.t("app.views.userStatus.active").toString(), value: 1 },
    { label: i18n.t("app.views.userStatus.inactive").toString(), value: 0 },
  ];

  protected externalAccessData!: { name: string | null; id: number | null; email: string | null; status: number; organisation_id: number | null };

  protected get label(): string {
    const action = !!this.access
      ? this.$t("components.form.update")
      : this.$t("components.form.create");

    return action + " " + (this.$tc("app.views.user.accesses", 1) ?? 'Access');
  }

  protected created() {
    this.setExternalAccess();
  }

  private setExternalAccess(): void {
    this.externalAccessData = {
      id: !!this.access ? this.access.id : null,
      name: !!this.access ? this.access.name : null,
      email: !!this.access ? this.access.email : null,
      status: !!this.access ? (this.access.status ? 1 : 0) : 1,
      organisation_id:
        !!this.me && this.me.organisation
          ? this.me.organisation.id
          : null,
    };
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");
    try {
      if (
        this.auth.isSystemAdmin(this.me) ||
        this.auth.isOrganisationAdmin(this.me)
      ) {
        if (!!this.access) {
          await this.updateExternalAccess();
        } else {
          await this.createExternalAccess();
        }
        this.$emit("close", true);
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }
    this.$emit("loading");
  }

  private async createExternalAccess(): Promise<void> {
    if (this.auth.isOrganisationAdmin(this.me)) this.externalAccessData.organisation_id = null;
    await this.create(this.externalAccessData);
    await this.fetchExternalAccesses(this.extAccessSearchParams);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.accessesCreated"),
      type: NotificationType.SUCCESS,
    });
  }

  private async updateExternalAccess(): Promise<void> {
    if (!this.auth.isSystemAdmin(this.me)) {
      this.extAccessSearchParams.organisation_id = null;
      this.externalAccessData.organisation_id = null;
    }
    await this.update(this.externalAccessData);
    await this.fetchExternalAccesses(this.extAccessSearchParams);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.accessesUpdated"),
      type: NotificationType.SUCCESS,
    });
  }
}
