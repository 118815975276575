import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import InputNumber from "@/components/InputNumber/InputNumber.vue";
import Input from "@/components/Input/Input.vue";
import TemplateQuestion from "@/vuex/models/template/template-question";
import InspectionAnswer from "@/vuex/models/inspection/inspection-answer";
import { TemplateQuestionType } from "@/vuex/models/template/template-question-type";

@Component({
  components: { InputNumber, Input },
})
export default class SimpleAnswer extends Vue {
  @Prop({
    required: true,
  })
  public question!: TemplateQuestion;

  @Prop({
    required: true,
  })
  public answer!: InspectionAnswer;

  protected questionType = TemplateQuestionType;
  protected value: string | number | null = null;

  protected created(): void {
    if (!!this.answer.answer && !Array.isArray(this.answer.answer)) {
      this.value = this.answer.answer;
    }

    if (this.question.type.slug === this.questionType.NUMBER) {
      this.value = 0;
    }
  }

  @Watch("value")
  protected setValue(): void {
    this.$emit("setAnswer", this.value);
  }
}
