<template>
  <LoadingSpinner v-if="loading" :show="loading"/>
  <div v-else class="subscriptions">
    <div class="subscriptions_tabs">
      <div
          class="subscriptions_tabs_tab"
          :class="{'active': currentTab === tab}"
          v-for="tab in tabs"
      >
        {{ $t('app.views.billing.' + tab) }}
      </div>
    </div>
    <component
        :is="selectedTab"
        :selectedProduct="selectedProduct"
        @select="selectProduct"
        @backToPlans="backToPlans"
        @loading="loading = !loading"
    ></component>
  </div>
</template>

<script>
import {SubscriptionTabs} from "@/vuex/models/billing/subscription-tabs";
import PlanSelectionTab from "@/views/Billing/SelectSubscription/PlanSelectionTab.vue";
import ConfirmPlanTab from "@/views/Billing/SelectSubscription/ConfirmPlanTab.vue";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import {mapGetters} from "vuex";

export default {
  name: "SelectSubscription",
  components: {PlanSelectionTab, ConfirmPlanTab},
  data() {
    return {
      loading: false,
      currentTab: SubscriptionTabs.PLAN_SELECTION,
      tabs: [
        SubscriptionTabs.PLAN_SELECTION,
        SubscriptionTabs.CONFIRM
      ],
      selectedProduct: null
    }
  },
  computed: {
    ...mapGetters(['subscription', 'customer']),
    selectedTab() {
      switch (this.currentTab) {
        case SubscriptionTabs.PLAN_SELECTION:
          return 'PlanSelectionTab';
        case SubscriptionTabs.CONFIRM:
          return 'ConfirmPlanTab';
      }
    }
  },
  async created() {
    this.$parent.title = this.$t('app.views.billing.selectPlan');

    this.loading = true;
    if (!this.subscription) {
      await this.$store.dispatch(ActionTypes.FETCH_SUBSCRIPTION);
    }

    if (!this.customer) {
      await this.$store.dispatch(ActionTypes.FETCH_CUSTOMER);
    }
    this.loading = false;
  },
  methods: {
    selectProduct(product) {
      if (product.slug === 'enterprise') {
        this.$router.push({name: 'enterprise-plan-form'})
      } else {
        this.currentTab = SubscriptionTabs.CONFIRM;
        this.selectedProduct = product;
      }
    },
    backToPlans() {
      this.currentTab = SubscriptionTabs.PLAN_SELECTION;
      this.selectedProduct = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.subscriptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &_tabs {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E6E6E6;

    &_tab {
      background: #FFFFFF 0 0 no-repeat padding-box;
      border-radius: 46px;
      height: 35px;
      padding: 0 35px;

      display: flex;
      align-items: center;

      text-align: center;
      font: normal normal normal 12px/18px Poppins;
      letter-spacing: 0;
      color: #0D0E34;
    }

    .active {
      color: #1DE591;
      border: 2px solid #1DE591;
    }
  }
}
</style>
