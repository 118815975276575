import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import DateToString from "@/services/dateToString/dateToString";
import InspectionFull from "@/vuex/models/inspection/inspection-full";

@Component({
    components: {ButtonsSaveCancel}
})
export default class InspectionEventShow extends Vue {
    protected readonly dateToStringConverter: DateToString = new DateToString();

    @Prop({
        required: true
    })
    public isVisible!: boolean;

    @Prop({
        required: true
    })
    public inspection!: InspectionFull;
}
