<template>
  <div class="Form">
    <LoadingSpinner :show="loading" v-if="loading" />

    <div class="Title">
      {{ $t("auth.login.logIn") }}
    </div>
    <div class="ErrorDesc" v-show="unauthorized">
      <template v-if="errors.generic">
        {{ errors.generic }}
        <a v-if="resendEmail" role="button" @click.prevent="resend">{{
          $t("auth.login.resendEmail")
        }}</a>
      </template>
      <template v-else>
        {{ $t("auth.login.incorrectCreds") }}
        <br />
        {{ $t("auth.login.incorrectCredsSolution") }}
      </template>
    </div>
    <div class="Inputs">
      <input-text
        :placeholder="$t('auth.field.login')"
        v-model="loginCredentials.email"
        :hasError="errors.email?.length >= 1"
        :error="!!errors.email ? errors.email : ''"
        appearance="auth"
      >
      </input-text>

      <input-text
        :placeholder="$t('auth.password')"
        type="password"
        v-model="loginCredentials.password"
        :hasError="errors.password?.length >= 1"
        :error="!!errors.password ? errors.password : ''"
        appearance="auth"
        @enter="login"
      >
      </input-text>
    </div>
    <div class="Bottom">
      <div class="AdditionalInfo">
        <div class="row">
          <p>
            {{ $t("auth.login.acceptRegulation") }}
            <a class="privacy" :href="rulesHref" target="_blank">
              {{ $t("auth.register.regulations") }}
            </a>
            {{ $t("auth.login.acceptPrivacyPolicy") }}
            <a class="privacy" :href="privacyHref" target="_blank">
              {{ $t("auth.register.privacyPolicy") }}
            </a>
          </p>
        </div>
        <div class="row">
          <p>
            {{ $t("auth.login.personalData") }}
            <a v-show="!more" @click="readMore">{{
              $t("auth.login.readMore")
            }}</a>
            <span v-show="more">
              {{ $t("auth.login.more") }}
            </span>
          </p>
        </div>
      </div>
      <div class="Footer">
        <router-link
          v-if="!$route?.query?.qr_token?.toString()"
          :to="{ name: 'forgot-password' }"
          >{{ $t("auth.forgotPassword") }}</router-link
        >
        <div v-else></div>
        <div class="Button" @click="login">
          {{ $t("auth.login.button") }}
        </div>
      </div>
      <div v-if="!$route?.query?.qr_token?.toString()" class="BottomLine">
        <p>{{ $t("auth.login.dontHaveAccount") }}</p>
        <router-link to="/register">{{
          $t("auth.register.register")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  ActionTypes,
  ActionTypes as AuthActions,
} from "@/vuex/modules/auth-module/action-types";
import { LoginCredentials } from "@/vuex/modules/auth-module/actions";
import Authorization from "@/services/authorization/authorization";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { ActionTypes as BrowserActions } from "@/vuex/modules/browser-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { Lang } from "@/services/lang/lang";
import store from "@/vuex/store";
import UserFull from "@/vuex/models/user/user-full";
import { UserProfileTabs } from "@/vuex/models/userProfile";

@Component
export default class AuthLoginView extends Vue {
  public errors: {
    email: string | null;
    password: string | null;
    generic: string;
  } = {
    email: "",
    password: "",
    generic: "",
  };
  public loginCredentials: LoginCredentials = {
    email: "",
    password: "",
    browser_language: store.getters.browserLocale,
  };
  public unauthorized: boolean = false;
  public hasErrors: boolean = true;
  public resendEmail: boolean = false;
  public loading: boolean = false;
  public more: boolean = false;

  protected readonly privacyHref: string = `https://checly.com/docs/checly-privacy-policy.pdf`;
  protected readonly rulesHref: string = `https://checly.com/docs/checly-terms-and-conditions.pdf`;

  private readonly auth: Authorization = new Authorization();
  private readonly lang: Lang = new Lang();

  public async resend() {
    this.loading = true;

    this.dropErrors();

    try {
      await this.$store.dispatch(ActionTypes.RESEND_EMAIL, {
        email: this.loginCredentials.email,
        host: window.location.origin,
      });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response?.data?.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  public async login() {
    this.loading = true;

    this.dropErrors();

    try {
      await this.$store.dispatch(AuthActions.LOGIN, this.loginCredentials);
      await this.$store.dispatch(AuthActions.FETCH_ME);
      await this.lang.loadLang(store.getters.me);
      await this.$store.dispatch(BrowserActions.APPLY_THEME);
      await this.redirectMe();
    } catch (exception: any) {
      this.handleErrors(exception);
    }

    this.loading = false;
  }

  subscriptionExpired(user: UserFull | undefined) {
    if (!!user && !!user.stripe_customer) {
      return (
        !user.stripe_customer.on_trial &&
        !user.stripe_customer.has_active_subscription
      );
    }
    return false;
  }

  private async redirectMe() {
    const me = this.$store.getters.me;

    if (!this?.$route?.query?.qr_token?.toString()) {
      if (this.auth.isSystemAdmin(me) || !!me.organisation) {
        if (this.subscriptionExpired(me)) {
          if (this.auth.isInspector(me) || this.auth.isLocationAdmin(me)) {
            await this.$router.push({
              name: "settings",
              query: { tab: UserProfileTabs.PERSONAL_DATA },
            });
          } else if (this.auth.isOrganisationAdmin(me)) {
            await this.$router.push({
              name: "settings",
              query: { tab: UserProfileTabs.BILLING },
            });
          }
        } else {
          await this.$router.push({ name: "dashboard" });
        }
      } else {
        await this.$router.push({ name: "register-organisation" });
      }
    } else {
      this.$router.push({
        name: "external-inspection",
        params: { id: this?.$route?.query?.qr_token?.toString() || "" },
      });
    }
  }

  private handleErrors(exception: any) {
    const status = exception.response?.status;

    if (status === 422) {
      this.setValidationError(exception.response.data.errors);
    }

    if (status === 403) {
      this.setNotConfirmedError();
    }

    if (status === 415) {
      this.setNotActivatedError();
    }

    if (status === 401) {
      this.setUnauthorizedError(exception.response.data.message);
    }
  }

  private dropErrors() {
    this.unauthorized = false;
    this.resendEmail = false;
    this.errors.email = "";
    this.errors.password = "";
  }

  private setValidationError(errors: any) {
    this.errors.email = Array.isArray(errors.email) ? errors.email[0] : "";
    this.errors.password = Array.isArray(errors.password)
      ? errors.password[0]
      : "";
  }

  private setUnauthorizedError(message: string) {
    this.unauthorized = true;
    this.errors.generic = message;
  }

  private setNotConfirmedError() {
    this.unauthorized = true;
    this.resendEmail = true;
    this.errors.generic = this.$t("auth.email.emailNotConfirmed").toString();
  }

  private setNotActivatedError() {
    this.unauthorized = true;
    this.errors.generic = this.$t("auth.account.notActive").toString();
  }
  private readMore() {
    this.more = true;
  }
}
</script>

<style scoped lang="scss">
.AdditionalInfo {
  font-size: 11px;
}
.Footer {
  font-size: 14px;
}

.BottomLine {
  font-size: 14px;
}
</style>
