<style lang="scss" src="./DeleteUserModal.scss" scoped></style>

<template>
  <Modal :modal="modal" class="modalDeleteUser">
    <div class="header" slot="header">
      {{ label }}
    </div>

    <div class="content" slot="content">
      <p class="warning-message">
        {{ $t("app.views.user.deleteNotification") }}
      </p>
      <p class="notification-text">
        {{ $t("app.views.users.text.whatDoYouWantToDoWith") }}
        {{ `${user.name} ${user.surname}` }}
        {{ $t("app.views.users.text.plannedInspections") }}?
      </p>
      <form>
        <input-select
          :value="formData.type"
          :options="menuOptions"
          @select="selectType"
        />
        <input-select
          :error="formErrors.get('user_id')"
          :has-error="formErrors.hasErrors('user_id')"
          v-if="formData.type === 'assign_to_user'"
          :value="formData.user_id"
          :options="userOptionsForAssignInspections"
          @select="formData.user_id = $event"
        />
      </form>
      <p class="notification-text">
        {{ $t("app.views.users.text.whatDoYouWantToDoWith") }}
        {{ `${user.name} ${user.surname}` }}
        {{ $t("app.views.users.text.plannedIncidents") }}?
      </p>
      <form>
        <input-select
          :value="formData.assignType"
          :options="assignOption"
          @select="selectAssignType"
        />
        <input-select
          v-if="formData.assignType === 'assign_to_another_user'"
          :error="formErrors.get('assign_user_id')"
          :has-error="formErrors.hasErrors('assign_user_id')"
          :value="formData.assign_user_id"
          :options="userOptionsForAssignInspections"
          @select="formData.assign_user_id = $event"
        />
      </form>
    </div>

    <div class="footer" slot="footer">
      <ButtonsSaveCancel @close="$emit('close', false)" :danger="true">
        <span
          class="delete-button"
          slot="btn-save"
          @click="reassignAllUserInspections"
        >
          {{ $t("app.views.user.deleteUser") }}
        </span>
      </ButtonsSaveCancel>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import LoadingSpinner from "@/components/LoadingSpinner/LoadingSpinner.vue";
import InputSelect from "@/components/InputSelect/InputSelect.vue";
import FormErrors from "@/services/formErrors/FormErrors";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes } from "@/vuex/modules/users-module/action-types";
import i18n from "@/lang/locale";
import Field from "@/components/Field/Field.vue";
import { mapGetters, mapActions } from "vuex";
import { ActionTypes as AuthActions } from "../../../../vuex/modules/auth-module/action-types";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { OptionValues, AssignOption } from "./consts.ts";
import { http } from "@/services/http/http";

export default {
  name: "DeleteUserModal",
  components: {
    Field,
    InputSelect,
    ButtonsSaveCancel,
    LoadingSpinner,
    Modal,
  },
  emits: ["loading", "close"],
  props: {
    user: {
      required: true,
    },
    modal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      formErrors: new FormErrors(),
      menuOptions: [
        {
          label: i18n.t("app.views.users.option.assignToUserLabel"),
          value: OptionValues.ASSIGN_TO_USER,
        },
        {
          label: i18n.t("app.views.users.option.deleteInspectionsLabel"),
          value: OptionValues.DELETE_INSPECTIONS,
        },
      ],
      assignOption: [
        {
          label: i18n.t("app.views.users.assignedToAnotherUserOption"),
          value: AssignOption.ASSIGN_TO_ANOTHER_USER,
        },
        {
          label: i18n.t("app.views.users.assignedToOrgAdminOption"),
          value: AssignOption.ASSIGN_TO_ORG_ADMIN,
        },
      ],
      formData: {
        user_id: null,
        type: OptionValues.ASSIGN_TO_USER,
        assignType: AssignOption.ASSIGN_TO_ANOTHER_USER,
        assign_user_id: null,
      },
    };
  },
  computed: {
    label() {
      let label = i18n.t("app.views.user.deleteUser");

      if (!!this.user) {
        label += ` ${this.user.name} ${this.user.surname}`;
      }

      return label;
    },
    ...mapGetters({
      userOptionsForAssignInspections: "userSelectOptionsForAssign",
    }),
  },
  created() {
    this.getUsers();
  },
  methods: {
    ...mapActions({
      fetchUserForAssignInspections: UserActions.FETCH_USERS_FOR_ASSIGN,
    }),

    async reassignAllUserInspections() {
      if (
        (this.formData.type === OptionValues.ASSIGN_TO_USER &&
          !this.formData.user_id) ||
        (this.formData.assignType === AssignOption.ASSIGN_TO_ANOTHER_USER &&
          !this.formData.assign_user_id)
      ) {
        const errors = {};

        if (
          this.formData.type === OptionValues.ASSIGN_TO_USER &&
          !this.formData.user_id
        ) {
          errors.user_id = [
            i18n.t("app.views.users.validation.userId").toString(),
          ];
        }

        if (
          this.formData.assignType === AssignOption.ASSIGN_TO_ANOTHER_USER &&
          !this.formData.assign_user_id
        ) {
          errors.assign_user_id = [
            i18n.t("app.views.users.validation.userId").toString(),
          ];
        }

        this.formErrors.setErrors(errors);
        return;
      }

      try {
        if (this.formData.type === OptionValues.ASSIGN_TO_USER) {
          await http().post(`/api/inspections/reassign-all-user-inspections`, {
            user_id_from: this.user.id,
            user_id_to: this.formData.user_id,
          });
        }
        await http().post(
          `/api/incidents/reassign-all-user-inspection-incidents`,
          {
            user_id_from: this.user.id,
            user_id_to: this.formData.assign_user_id,
          }
        );
        this.remove();
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    },

    selectType(value) {
      if (value === OptionValues.DELETE_INSPECTIONS) {
        this.formData.user_id = null;
      }
      this.formData.type = value;
    },

    selectAssignType(value) {
      this.formData.assignType = value;
      if (value === AssignOption.ASSIGN_TO_ANOTHER_USER) {
        this.formData.assign_user_id = null;
      } else if (value === AssignOption.ASSIGN_TO_ORG_ADMIN)
        this.formData.assign_user_id = this.user.organisation.admin_id;
    },

    async getUsers() {
      await this.fetchUserForAssignInspections({ id: this.user.id });
    },

    async remove() {
      this.$emit("loading");

      try {
        await this.$store.dispatch(ActionTypes.DELETE_USER, this.user.id);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: this.$t("app.views.user.userDeleted"),
          type: NotificationType.SUCCESS,
        });
        await this.$store.dispatch(AuthActions.FETCH_ME);
        this.$emit("close", true);
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      } finally {
        this.formErrors.clearErrors();
      }

      this.$emit("loading");
    },
  },
};
</script>

<style lang="scss">
.modalDeleteUser {
  .notification-text {
    margin-bottom: 25px !important;
    color: #3d3d3d;
  }
  .footer {
    margin-top: -35px;
  }
  .content {
    .input_default {
      p {
        margin: 0 !important;
      }
    }
    .custom-select {
      p {
        margin-bottom: 0px !important;
      }
    }
    .help.is-danger {
      text-align: left !important;
    }
  }
}
</style>
