var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{ DragActive: _vm.questionDragActive }},[(_vm.section.questions.length > 0)?_c('draggable',{staticClass:"Questions",attrs:{"handle":".question-drag-handle"},on:{"start":function($event){_vm.questionDragActive = true},"end":function($event){_vm.questionDragActive = false}},model:{value:(_vm.section.questions),callback:function ($$v) {_vm.$set(_vm.section, "questions", $$v)},expression:"section.questions"}},_vm._l((_vm.section.questions),function(question,index){return _c('div',{key:index,staticClass:"Question"},[_c('div',{staticClass:"TopLine"},[_c('div',{staticClass:"Row"},[_c('SvgIcon',{class:'ListIcon ' + (!_vm.disabled && 'question-drag-handle'),attrs:{"name":"list","width":"30","height":"30"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.name),expression:"question.name"}],staticClass:"question-input",class:{
                'has-error': _vm.formErrors.hasErrors(
                  'sections.' + _vm.sectionIndex + '.questions.' + index + '.name'
                ),
              },attrs:{"placeholder":_vm.$t('app.views.library.form.writeQuestion'),"disabled":_vm.disabled},domProps:{"value":(question.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return $event.preventDefault()},"input":function($event){if($event.target.composing)return;_vm.$set(question, "name", $event.target.value)}}}),(!_vm.disabled)?_c('SvgIcon',{staticClass:"TrashIcon",attrs:{"name":"trash","width":"24","height":"24"},on:{"click":function($event){return _vm.removeQuestion(index)}}}):_vm._e()],1),_c('div',{staticClass:"Row displayRow"},[_c('div',{staticClass:"cell-1"},[_c('span',{staticClass:"cell-head"},[_vm._v(" "+_vm._s(_vm.$t("app.views.library.form.answerType")))]),_c('InputSelect',{staticClass:"question-select",attrs:{"options":_vm.questionTypes,"value":question.type,"isChagneStylesForTemplate":true,"disabled":_vm.disabled,"is-minimal":"","has-error":_vm.formErrors.hasErrors(
                    'sections.' +
                      _vm.sectionIndex +
                      '.questions.' +
                      index +
                      '.type'
                  )},on:{"select":function($event){question.type = $event}}})],1),(_vm.canHaveIncident(question.type))?_c('div',{staticClass:"cell-2"},[_c('span',{staticClass:"cell-head"},[_vm._v(_vm._s(_vm.$t("app.views.library.form.incident")))])]):_vm._e(),(_vm.canHaveIncident(question.type))?_c('div',{staticClass:"cell-3"}):_vm._e(),(_vm.canHaveIncident(question.type))?_c('div',{staticClass:"cell-4"},[_c('div',{staticClass:"cell-container"},[_c('span',{staticClass:"cell-head"},[_vm._v(_vm._s(_vm.$t("app.views.library.form.weight")))]),(!_vm.disabled && _vm.canHaveIncident(question.type))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.weight),expression:"question.weight"}],staticClass:"weight-input",class:{
                    'has-error': _vm.formErrors.hasErrors(
                      'sections.' +
                        _vm.sectionIndex +
                        '.questions.' +
                        index +
                        '.weight'
                    ),
                  },attrs:{"type":"text","placeholder":"00","disabled":_vm.disabled},domProps:{"value":(question.weight)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(question, "weight", $event.target.value)}}}):(_vm.canHaveIncident(question.type))?_c('p',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(question.weight)+" ")]):_c('div',{staticClass:"mx-auto"})])]):_vm._e()])]),(
            _vm.formErrors.hasErrors(
              'sections.' +
                _vm.sectionIndex +
                '.questions.' +
                index +
                '.answer_options'
            )
          )?_c('div',{staticClass:"TopLine"},[_c('p',{staticClass:"danger has-error"},[_vm._v(" "+_vm._s(_vm.formErrors.get( "sections." + _vm.sectionIndex + ".questions." + index + ".answer_options" ))+" ")])]):_vm._e(),_c('AnswerOptions',{attrs:{"question":question,"disabled":_vm.disabled,"form-errors":_vm.formErrors,"question-drag-active":_vm.questionDragActive,"section-drag-active":_vm.sectionDragActive},on:{"update:question":function($event){question = $event}}}),_c('div',{staticClass:"TopLine"},[_c('div',{staticClass:"ExtraActions"},[(
                _vm.canHaveIncident(question.type) &&
                _vm.TemplateQuestionType.SINGLE_CHOICE !== question.type
              )?_c('div',{staticClass:"Action"},[_c('InputCheckbox',{attrs:{"disabled":_vm.disabled},on:{"input":function($event){return _vm.setReverseScore(question, $event)}},model:{value:(question.reverse_score),callback:function ($$v) {_vm.$set(question, "reverse_score", $$v)},expression:"question.reverse_score"}}),_c('span',[_vm._v(_vm._s(_vm.$t("app.views.library.form.revertScore")))])],1):_vm._e(),_c('div',{staticClass:"Action"},[_c('InputCheckbox',{attrs:{"disabled":_vm.disabled},on:{"input":function($event){return _vm.setRequired(question, $event)}},model:{value:(question.required),callback:function ($$v) {_vm.$set(question, "required", $$v)},expression:"question.required"}}),_c('span',[_vm._v(_vm._s(_vm.$t("app.views.library.form.required")))])],1),(
                _vm.canHaveIncident(question.type) &&
                _vm.TemplateQuestionType.SINGLE_CHOICE !== question.type
              )?_c('div',{staticClass:"Action"},[_c('InputCheckbox',{attrs:{"value":!!question.answer_options.find(
                    (option) =>
                      option.answer_option === _vm.NOT_APPLICABLE_OPTION()
                  )
                    ? 1
                    : 0,"disabled":_vm.disabled},on:{"input":function($event){return _vm.setNotApplicableAnswer(question, $event)}}}),_c('span',[_vm._v(_vm._s(_vm.$t("app.views.library.form.addNotApplicable")))])],1):_vm._e()])])],1)}),0):_vm._e()],1),(!_vm.disabled)?_c('div',{staticClass:"AddQuestion",on:{"click":function($event){return _vm.addQuestion()}}},[_c('img',{attrs:{"src":"/images/icons/template/plus.svg","alt":"plus-icon"}}),_c('p',[_vm._v(_vm._s(_vm.$t("app.views.library.form.addQuestion")))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }