<template>
  <records-table v-if="rows.length > 0" :rows="rows">
    <template v-slot:thead>
      <tr class="table_thead_tr">
        <th>{{ $t('components.table.paymentMethod') }}</th>
        <th width="20px"></th>
      </tr>
    </template>
    <template v-slot:tbody>
      <tr>
        <td v-if="!!customer && ! customer.manual_payment">
          <PaymentMethod/>
        </td>
        <td v-else>
          <div class="manual-payment">
            <img class="manual-payment_logo" src="/images/manual-payment.svg" alt="manual-payment-logo">

            <div class="manual-payment_info">
              <p class="manual-payment_info_account">
                {{ $t('app.views.billing.clientBankAccount') }}
              </p>
              <p class="manual-payment_info_notification">
                {{ $t('app.views.billing.manualPaymentNotification') }}
              </p>
            </div>
          </div>
        </td>

        <td v-if="!!customer && ! customer.manual_payment">
          <table-row-drop-down-menu>
            <template v-slot:content>
              <span class="controls-dropdown-button" @click="deletePaymentMethodFunction">
                {{ $t('components.delete').capitalize() }}
              </span>
            </template>
          </table-row-drop-down-menu>
        </td>
      </tr>
    </template>
  </records-table>
  <div class="container" v-else>
    <AlternativeEmptyTable :message="$t('app.views.billing.addCreditCard').toString()"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PaymentMethod from "@/views/Billing/components/PaymentMethod.vue";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import AlternativeEmptyTable from "@/components/RecordsTable/components/AlternativeEmptyTable.vue";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {MutationTypes} from "@/vuex/modules/billing-module/mutation-types";

export default {
  name: "PaymentMethodTable",
  emits: ['loading'],
  components: {PaymentMethod, AlternativeEmptyTable},
  data() {
    return {
      rows: []
    }
  },
  computed: {
    ...mapGetters(['paymentMethod', 'customer']),
  },
  async created() {
    if (!!this.paymentMethod) {
      this.rows.push(this.paymentMethod)
    }

    if (!!this.customer && this.customer.manual_payment) {
      this.rows.push(1);
    }
  },
  watch: {
    paymentMethod() {
      if (!!this.paymentMethod) {
        this.rows.push(this.paymentMethod)
      }
    },
    customer() {
      if (!!this.customer && this.customer.manual_payment) {
        this.rows.push(1);
      }
    }
  },
  methods: {
    async deletePaymentMethodFunction() {
      this.$emit('loading');

      await this.$store.dispatch(ActionTypes.DELETE_PAYMENT_METHOD);
      await this.$store.commit(MutationTypes.SET_PAYMENT_METHOD, null);
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: 'Payment method has been deleted.',
        type: NotificationType.SUCCESS
      });
      this.rows = [];

      this.$emit('loading');
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000F;
  border-radius: 10px;
  padding: 60px 0;
}

.manual-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;

  &_logo {
    height: 72px;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      text-align: left;
      letter-spacing: 0;
      font-family: Poppins, serif;
    }

    &_account {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      color: #0D0E34;
    }

    &_notification {
      font-size: 12px;
      line-height: 18px;
      color: #6D6D6D;
    }
  }
}
</style>