import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Chart as ChartJS } from "chart.js";
import chartTrendline from "chartjs-plugin-trendline";

import ChartLayoutForFilters from "@/components/ChartLayoutForFilters/ChartLayoutForFilters.vue";
import ChartLine from "@/components/Charts/ChartLine/ChartLine.vue";
import ChartBar from "@/components/Charts/ChartBar/ChartBar.vue";
import { http } from "@/services/http/http";

import { TminMaxValue } from "./types";
ChartJS.register(chartTrendline);
@Component({
  components: {
    ChartLayoutForFilters,
    ChartLine,
    ChartBar,
  },
})
export default class ChartDataLayout extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public title!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public isDashboardChart!: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  public id!: number;

  @Prop({
    type: Object,
    required: false,
  })
  public chartData!: any;

  @Prop({
    type: Number,
    required: true,
  })
  public type!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public isDefaultChart!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  public isDefaultDataOnChart!: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  public isDuration!: number;

  public currentChartData: any = [];
  public generatedChartData: any = [];
  public isShowLineTrend: boolean = false;
  public filterNumberValue: number | null = null;
  public minMaxValue: TminMaxValue | null = null;

  public comparisonState: "default" | "bigger" | "lower" = "default";

  setCurrentId(id: number) {
    this.$emit("openEditChart", id);
  }

  setComparison(value: "bigger" | "lower" | "default") {
    this.comparisonState = value;
  }

  updateDefaultChart(startDate: string, endDate: string) {
    this.$emit("getChartData", startDate, endDate);
  }

  setIsShowLineTrend(value: boolean) {
    this.isShowLineTrend = value;
  }

  generateChartLine(labels: string[], dataBody: number[]) {
    const defaultChartOptions = {
      labels: labels,
      datasets: [
        {
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(29, 229, 145, 0.5)");
            gradient.addColorStop(1, "rgba(200, 255, 230, 0.1)");
            return gradient;
          },
          data: dataBody,
          borderColor: "rgba(29, 229, 145, 0.5)",
          borderWidth: 3,
          pointRadius: 10,
          fill: "start",
          pointBackgroundColor: "#1DE591",
        },
      ],
    };

    if (dataBody?.length > 1 && this.isShowLineTrend) {
      //@ts-ignore
      defaultChartOptions.datasets[0].trendlineLinear = {
        colorMin: "#E5881D",
        colorMax: "#E5881D",
        lineStyle: "solid",
        width: 2,
      };
    }

    return defaultChartOptions;
  }

  generateChartBar(labels: string[], dataBody: number[]) {
    return {
      labels: labels,
      datasets: [
        {
          data: dataBody,
          backgroundColor: "rgba(29, 229, 145, 0.5)",
          borderColor: "#1DE591",
          borderWidth: 3,
        },
      ],
    };
  }

  getChartData(labels: string[], dataBody: number[]) {
    if (this.type === 0) {
      this.generatedChartData = this.generateChartLine(labels, dataBody);
    }
    if (this.type === 2) {
      this.generatedChartData = this.generateChartBar(labels, dataBody);
    }
  }

  private async updateChart({ startDate, endDate }: any) {
    try {
      const response = await http().get(`/api/statistics/charts/${this.id}`, {
        params: {
          start_date: startDate.toDate(),
          end_date: endDate.toDate(),
        },
      });

      this.currentChartData = response?.data?.dataset;

      this.updateChartData();
    } catch (e) {}
  }

  private changeFilterValue(value: number) {
    this.filterNumberValue = value;
  }

  @Watch("chartData", { immediate: true, deep: true })
  updateCurrentChartData(newChartData: any) {
    this.currentChartData = newChartData;
  }

  @Watch("comparisonState", { immediate: true, deep: true })
  @Watch("filterNumberValue", { immediate: true, deep: true })
  updateShowLineTrend() {
    if (this.comparisonState !== "default") {
      this.isShowLineTrend = false;

      if (
        this.comparisonState === "bigger" &&
        this.filterNumberValue !== null
      ) {
        this.minMaxValue = { min: +this.filterNumberValue };
      } else if (
        this.comparisonState === "lower" &&
        this.filterNumberValue !== null
      ) {
        this.minMaxValue = { max: +this.filterNumberValue };
      }
    }

    if (this.comparisonState === "default") {
      this.filterNumberValue = null;
      this.minMaxValue = null;
    }
  }

  @Watch("isShowLineTrend", { immediate: true, deep: true })
  isShowLineTrendFunction() {
    const updatedChartData = JSON.parse(
      JSON.stringify(this.generatedChartData)
    );

    const mainDataset = updatedChartData.datasets[0];

    if (mainDataset?.data?.length > 1 && this.isShowLineTrend) {
      mainDataset.trendlineLinear = {
        colorMin: "#E5881D",
        colorMax: "#E5881D",
        lineStyle: "solid",
        width: 2,
      };
    } else {
      mainDataset.trendlineLinear = undefined;
    }

    this.generatedChartData = updatedChartData;
  }

  @Watch("currentChartData", { immediate: true })
  updateChartData() {
    const labels = Object.keys(this.currentChartData);
    let dataBody: number[];

    if (this.isDuration === 2) {
      dataBody = Object.values(this.currentChartData).map(
        (seconds) => (seconds as number) / 60
      );
    } else {
      dataBody = Object.values(this.currentChartData);
    }

    this.getChartData(labels, dataBody as number[]);
  }
}
