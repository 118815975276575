<template>
  <div class="HalfLayout">
    <div class="Left">
      <div class="Header" v-if="$slots.header">
        <slot name="header"/>
      </div>
      <div class="Body" :class="{'WithoutBox': bodyWithOutBox}">
        <slot/>
      </div>
    </div>
    <div class="Right">
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "HalfLayout",
  props: {
    bodyWithOutBox: Boolean
  }
}
</script>

<style lang="scss">
@import "@/asesst/styles/mixins";

.HalfLayout {
  display: flex;
  background: #F5F5F5;
  min-height: 100vh;
  @include not-mobile {
    padding-left: 30px;
  }
  @include mobile {
    flex-direction: column;
    padding: 35px 23px 35px 18px;
    min-height: calc(100vh - 92px);
  }

  & > .Left {
    padding-top: 60px;
  }

  & > .Right {
    padding-top: 59px;
  }

  & > .Right, & > .Left {
    @include mobile {
      padding-top: unset;
    }
  }

  & > .Left {
    display: flex;
    flex-direction: column;

    width: calc(100% - 460px);

    @include mobile {
      width: unset;
    }

    & > .Header {
      font-family: Poppins;
      font-weight: 600;
      font-size: 26px;
      line-height: 42px;
      margin-bottom: 20px;
      padding-right: 32px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        padding-right: unset;
      }

      & > .Button {
        display: flex;
        align-items: center;
        padding: 9px;
        min-width: 212px;
        background: #1DE591;
        color: #FFFFFF;
        border-radius: 122px;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 0px 16px #0000000F;
        }

        & > .Icon {
          width: 30px;
          height: 30px;
          margin-right: 22px;
        }

        & > .Text {
          text-align: center;
          font-size: 12px;
          line-height: 21px;
        }

      }
    }

    & > .Body {
      height: 100%;
      box-shadow: 0px 0px 16px #0000000F;
      border-radius: 10px;
      background: white;
      padding: 36px 32px 32px 32px;
      margin-right: 22px;
      margin-bottom: 23px;

      @include mobile {
        margin-right: unset;
      }

      &.WithoutBox {
        box-shadow: unset;
        background: unset;
        border-radius: unset;
        padding: 0;
      }
    }
  }

  & > .Right {
    width: 460px;
    background: #FFFFFF;
    padding-left: 30px;
    padding-right: 30px;

    & > .StickyContent {
      position: sticky;
      top: 60px;
    }
  }
}
</style>