import { render, staticRenderFns } from "./LocationsView.vue?vue&type=template&id=fb779852"
import script from "./LocationsView.ts?vue&type=script&lang=ts&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&external"
export * from "./LocationsView.ts?vue&type=script&lang=ts&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports