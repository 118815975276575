import { MutationTree } from "vuex";
import ExternalAccessState from "./state";
import { MutationTypes } from "./mutation-types";
import ExternalAccess from "@/vuex/models/external-access/external-access";
import Meta from "@/vuex/models/meta";

const mutations: MutationTree<ExternalAccessState> = {
  [MutationTypes.SET_EXTERNAL_ACCESSES](state: ExternalAccessState, externalAccesses: Array<ExternalAccess> | []): void {
    state.items = externalAccesses;
  },

  [MutationTypes.SET_EXTERNAL_ACCESS](state: ExternalAccessState, { ExternalAccessId, status }: { ExternalAccessId: number, status: number | boolean }): void {
    const item = state.items.find(item => item.id === ExternalAccessId);
    if (item) {
      item.status = status;
    }
  },

  [MutationTypes.SET_EXTERNAL_ACCESSES_META](state: ExternalAccessState, meta: Meta): void {
    state.meta = meta;
  }
};

export default mutations;
