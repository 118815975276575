import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import { PageMixin } from "@/mixins/pageMixin";

import { http } from "@/services/http/http";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import QRCodeFull from "@/vuex/models/qrCode/qrCode-full";
import { qrCodes } from "@/services/qrCodes/qrCodes";
import { qrCodesPermissions } from "@/vuex/models/qrCode/location-data";

@Component({})
export default class QRCodeItem extends Mixins(PageMixin) {
  @Prop({
    required: false,
    type: Object,
  })
  public qrCodeItem!: QRCodeFull;

  @Prop({
    type: Function,
    required: true,
  })
  public getPermissionText!: (
    option:
      | qrCodesPermissions.PUBLIC
      | qrCodesPermissions.PRIVATE
      | qrCodesPermissions.ORGANISATION
  ) => string;

  protected readonly qrCodes = new qrCodes();

  public isDownloadButton: boolean = false;

  @Watch("qrCodeItem.status", { immediate: true, deep: true })
  onStatusChanged(newValue: string) {
    this.isDownloadButton = !!newValue;
  }

  async handleDownloadQrCode() {
    await this.qrCodes.downloadQrCode(this.qrCodeItem?.link ?? "");
  }

  public getPermissionTextForOption(
    option:
      | qrCodesPermissions.PUBLIC
      | qrCodesPermissions.PRIVATE
      | qrCodesPermissions.ORGANISATION
  ) {
    return this.getPermissionText(option);
  }

  async swithQrCode(isSwitch: boolean, id: number) {
    try {
      const response = await http().put(`/api/qr-codes/change-status/${id}`);
      this.isDownloadButton = !!response.data.status;
    } catch (exception: any) {
      this.isDownloadButton = !!this.qrCodeItem.status;
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  }
}
