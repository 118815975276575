<template>
  <input-select
    :label="label"
    :value="accessLevel"
    :options="options"
    :class="{ filter: isFilter }"
    :placeholder="placeholder"
    :hasError="hasError"
    :error="error"
    @select="$emit('select', $event)"
    :disabled="disabled"
  ></input-select>
</template>

<script>
import Authorization from "@/services/authorization/authorization";
import { mapGetters } from "vuex";
import i18n from "@/lang/locale";
import { TemplateAccessLevel } from "@/vuex/models/template/template-access-level";

export default {
  name: "InputSelectAccess",
  emits: ["select", "loading"],
  props: {
    accessLevel: {
      required: false,
    },
    isFilter: {
      required: false,
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    hasError: {
      required: false,
      default: false,
    },
    error: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["me"]),
    label() {
      return this.isFilter ? this.$t("app.views.library.permissions") : "";
    },
  },
  data() {
    return {
      auth: new Authorization(),
      options: [
        {
          label: i18n.t("app.views.select.access.public").toString(),
          value: TemplateAccessLevel.PUBLIC,
        },
        {
          label: i18n.t("app.views.select.access.private").toString(),
          value: TemplateAccessLevel.PRIVATE,
        },
        {
          label: i18n.t("app.views.select.access.organisation").toString(),
          value: TemplateAccessLevel.ORGANISATION,
        },
      ],
    };
  },
  created() {
    if (!this.isFilter) {
      this.filterOptions();
    }
  },
  methods: {
    filterOptions() {
      if (this.auth.isSystemAdmin(this.me)) {
        this.options = this.options.filter((option) => {
          return (
            option.value === TemplateAccessLevel.PUBLIC ||
            option.value === TemplateAccessLevel.ORGANISATION
          );
        });
      } else {
        this.options = this.options.filter((option) => {
          return (
            option.value === TemplateAccessLevel.ORGANISATION ||
            option.value === TemplateAccessLevel.PRIVATE
          );
        });
      }
    },
  },
};
</script>

<style scoped>
.filter {
  max-width: 262px;
}
</style>
