import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import DashboardView from "@/views/DashboardView/DashboardView.vue";
import IncidentsView from "@/views/Incidents/IncidentsView.vue";
import TemplateView from "@/views/TemplateView/TemplateView.vue";
import StatisticView from "@/views/Statistics/StatisticView.vue";
import UsersView from "@/views/UsersView/UsersView.vue";
import LocationsView from "@/views/LocationsView/LocationsView.vue";
import store from "@/vuex/store";
import Authorization from "@/services/authorization/authorization";
import UserProfileView from "@/views/UserProfile/UserProfileView.vue";
import TemplateFormView from "@/views/TemplateFormView/TemplateFormView.vue";
import InspectionView from "@/views/InspectionsView/InspectionView.vue";
import InspectionExecutionView from "@/views/InspectionExecutionView/InspectionExecutionView.vue";
import OrganisationsView from "@/views/OrganisationsView/OrganisationsView.vue";
import ReportsView from "@/views/ReportsView/ReportsView.vue";
import ReportView from "@/views/ReportView/ReportView.vue";
import LogsView from "@/views/LogsView/LogsView.vue";
import HomeView from "@/views/HomeView/HomeView.vue";
import AuthLoginView from "@/views/Auth/AuthLoginView.vue";
import AuthRegisterView from "@/views/Auth/AuthRegisterView.vue";
import AuthForgotPasswordView from "@/views/Auth/AuthForgotPasswordView.vue";
import LanguagesView from "@/views/LanguagesView/LanguagesView.vue";
import AuthConfirmAttention from "@/views/Auth/AuthConfirmAttention.vue";
import AuthResetPassword from "@/views/Auth/AuthResetPassword.vue";
import LanguageWordsView from "@/views/LanguageWordsView/LanguageWordsView.vue";
import AuthRegisterOrganisation from "@/views/Auth/AuthRegisterOrganisation.vue";
import AuthOrganisationRequested from "@/views/Auth/AuthOrganisationRequested.vue";
import PaymentMethodForm from "@/views/Billing/PaymentMethodForm/PaymentMethodForm.vue";
import SelectSubscription from "@/views/Billing/SelectSubscription/SelectSubscription.vue";
import EnterprisePlanForm from "@/views/Billing/EnterprisePlanForm/EnterprisePlanForm.vue";
import BillingAddressForm from "@/views/Billing/BillingAddressForm/BillingAddressForm.vue";
import CancelSubscription from "@/views/Billing/CancelSubscription/CancelSubscription.vue";
import IncidentFormReportingView from "@/views/IncidentFormReportingView/IncidentFormReportingView.vue";
import InspectionStatusView from "@/views/InspectionStatusView/InspectionStatusView.vue";
import QRCodeView from "@/views/QRCodeView/QRCodeView.vue";
import PdvView from "@/views/PdfView/PdfView.vue";
import CantPayByCard from "@/views/Billing/CantPayByCard/CantPayByCard.vue";
import AuthConfirmMail from "@/views/Auth/AuthConfirmMail.vue";
import { MutationTypes } from "@/vuex/modules/auth-module/mutation-types";
import UserFull from "@/vuex/models/user/user-full";
import { UserProfileTabs } from "@/vuex/models/userProfile";

export const routes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: AuthLoginView,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/register",
    name: "register",
    component: AuthRegisterView,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/confirm-attention",
    name: "confirm-attention",
    component: AuthConfirmAttention,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/confirm-email/:code",
    name: "confirm-email",
    component: AuthConfirmMail,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset-password/:code",
    name: "reset-password",
    component: AuthResetPassword,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: AuthForgotPasswordView,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/register-organisation",
    name: "register-organisation",
    component: AuthRegisterOrganisation,
    meta: {
      layout: "auth",
    },
    props: true,
  },
  {
    path: "/organisation-requested",
    name: "organisation-requested",
    component: AuthOrganisationRequested,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/report/:organisation_name?/:id",
    name: "report-item",
    component: ReportView,
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
  },
  {
    path: "/incidents",
    name: "incidents",
    component: IncidentsView,
  },
  {
    path: "/incident-form-reporting/:code",
    name: "incident-form-reporting",
    component: IncidentFormReportingView,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/library",
    name: "library",
    component: TemplateView,
  },
  {
    path: "/qr-codes",
    name: "qr-codes",
    component: QRCodeView,
  },
  {
    path: "/template-creator/:id?/:action?",
    name: "template-form",
    component: TemplateFormView,
    props: { action: "edit" },
  },
  {
    path: "/inspections",
    name: "inspections",
    component: InspectionView,
  },
  {
    path: "/statistics",
    name: "statistics",
    component: StatisticView,
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
  },
  {
    path: "/sites",
    name: "localizations",
    component: LocationsView,
  },
  {
    path: "/settings",
    name: "settings",
    component: UserProfileView,
  },
  {
    path: "/external-inspection/:id?",
    name: "external-inspection",
    component: InspectionExecutionView,
    meta: {
      hideSidemenu: true,
      isExternalInspection: true,
    },
  },
  {
    path: "/external-inspection-execution/:id?",
    name: "external-inspection-execution",
    component: InspectionExecutionView,
    meta: {
      hideSidemenu: true,
      isExternalInspection: true,
    },
  },
  {
    path: "/inspection-execution/:id?",
    name: "inspection-execution",
    component: InspectionExecutionView,
    meta: {
      hideSidemenu: true,
      isExternalInspection: false,
    },
  },
  {
    path: "/status-inspection",
    name: "status-inspection",
    component: InspectionStatusView,
    meta: {
      hideSidemenu: true,
    },
  },
  {
    path: "/pdf/:id?",
    name: "pdf",
    component: PdvView,
    meta: {
      hideSidemenu: true,
    },
  },
  {
    path: "/organisations",
    name: "organisations",
    component: OrganisationsView,
  },
  {
    path: "/logs",
    name: "logs",
    component: LogsView,
  },
  {
    path: "/languages",
    name: "languages",
    component: LanguagesView,
  },
  {
    path: "/languages/:id",
    name: "language-words",
    component: LanguageWordsView,
  },
  {
    path: "/payment-method-form",
    name: "payment-method-form",
    component: PaymentMethodForm,
    meta: {
      layout: "billing",
    },
  },
  {
    path: "/select-subscription",
    name: "select-subscription",
    component: SelectSubscription,
    meta: {
      layout: "billing",
    },
  },
  {
    path: "/enterprise-plan-form",
    name: "enterprise-plan-form",
    component: EnterprisePlanForm,
    meta: {
      layout: "billing",
    },
  },
  {
    path: "/billing-address-form",
    name: "billing-address-form",
    component: BillingAddressForm,
    meta: {
      layout: "billing",
    },
  },
  {
    path: "/cancel-subscription",
    name: "cancel-subscription",
    component: CancelSubscription,
    meta: {
      layout: "billing",
    },
  },
  {
    path: "/cant-pay-by-card",
    name: "cant-pay-by-card",
    component: CantPayByCard,
    meta: {
      layout: "billing",
    },
  },
];

const router: VueRouter = new VueRouter({
  mode: "history",
  routes,
});

const subscriptionExpired = (user: UserFull | undefined) => {
  if (!!user && !!user.stripe_customer) {
    return (
      !user.stripe_customer.on_trial &&
      !user.stripe_customer.has_active_subscription
    );
  }
  return false;
};

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  // removeKeyboardActions();

  const now: Date = new Date();
  const expiry: string | null = localStorage.getItem("expiry");

  if ((!!expiry && now.getTime().toString() > expiry) || !expiry) {
    store.commit(MutationTypes.CLEAR_TOKEN);
  }

  const me = store.getters.me;
  const auth = new Authorization();

  if (to.path === "/") {
    if (subscriptionExpired(me)) {
      if (auth.isLocationAdmin(me) || auth.isInspector(me)) {
        return next({
          name: "settings",
          query: { tab: UserProfileTabs.PERSONAL_DATA },
        });
      } else {
        return next({
          name: "settings",
          query: { tab: UserProfileTabs.BILLING },
        });
      }
    } else {
      return next({ name: "dashboard" });
    }
  }

  if (!me || null === localStorage.getItem("token")) {
    return auth.isNonAuthRoute(to.name) ? next() : next({ name: "login" });
  }

  if (null === me.role) {
    if (me.request_to_org) {
      return to.name === "organisation-requested"
        ? next()
        : next({ name: "organisation-requested" });
    } else {
      return to.name === "register-organisation"
        ? next()
        : next({ name: "register-organisation" });
    }
  }

  return auth.hasAccess(me, to.name)
    ? next()
    : subscriptionExpired(me)
    ? auth.isLocationAdmin(me) || auth.isInspector(me)
      ? next({
          name: "settings",
          query: { tab: UserProfileTabs.PERSONAL_DATA },
        })
      : auth.isOrganisationAdmin(me)
      ? next({ name: "settings", query: { tab: UserProfileTabs.BILLING } })
      : next({ name: "dashboard" })
    : next({ name: "dashboard" });
});

router.afterEach(() => {
  // @ts-ignore
  window.productFruits?.pageChanged?.();
});

export default router;
