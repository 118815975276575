import {Module} from "vuex";
import {RootState} from "../../store";
import {UserProfileState} from "./state";
import actions from './actions';
import getters from './getters'
import mutations from './mutations'


const module: Module<UserProfileState, RootState> = {
    state: new UserProfileState(),
    actions,
    getters,
    mutations,
}

export default module

