import {GetterTree} from "vuex";
import NotificationsState, {Notification} from "@/vuex/modules/notifications-module/state";
import {RootState} from "@/vuex/store";


const getters: GetterTree<NotificationsState, RootState> = {
    getNotifications(state: NotificationsState): Notification[] {
        if (state.notifications.length >= 1) {
            return state.notifications;
        }

        return [];
    },

    flushNotificationsState(state: NotificationsState): boolean {
        return state.flushNotifications;
    }
}

export default getters;
