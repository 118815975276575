<template>
  <Modal :modal="modal" @close="$emit('close')">
    <template v-slot:header>
      <div class="header">
        {{ $t('app.views.inspection.execution.pauseTheAudit') }}
      </div>
    </template>
    <template v-slot:content>
      <div>
        {{ $t('app.views.inspection.execution.pauseNotification') }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="action-buttons">
        <button @click="$emit('submit')">
          {{ $t('components.yes') }}
        </button>
        <button @click="$emit('close')">
          {{ $t('components.no') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";

export default {
  name: "PauseModal",
  components: {
    Modal
  },
  props: {
    modal: {
      required: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: calc(48px + 48px + 20px);
  margin-bottom: calc(55px - 25px);

  button {
    border: 2px solid #1DE591;
    border-radius: 122px;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 48px;
    cursor: pointer;

    text-align: center;
    font: normal normal normal 12px/21px Poppins;
    letter-spacing: 0;
    color: #1DE591;
    background-color: #FFF;
  }

  button:hover {
    background-color: #1DE591;
    color: #FFF;
  }
}

.header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #0D0E34;
}
</style>