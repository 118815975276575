<template>
  <Input
      class="SearchInput"
      v-bind="$attrs"
      @input="(value) => $emit('input', value)"
      @focusin="$emit('focusin')"
      @focusout="$emit('focusout')"
      :placeholder="placeholder"
  >
    <template v-slot:icon-right>
      <img src="/images/icons/search.svg">
    </template>
  </Input>
</template>

<script>
import Input from "@/components/Input/Input.vue";

export default {
  name: "SearchInput",
  components: {Input},
  inheritAttrs: false,
  props: {
    placeholder: {
      required: false,
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>