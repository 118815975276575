import { render, staticRenderFns } from "./ConfirmPlanTab.vue?vue&type=template&id=55e70db8&scoped=true"
import script from "./ConfirmPlanTab.vue?vue&type=script&lang=js"
export * from "./ConfirmPlanTab.vue?vue&type=script&lang=js"
import style0 from "./ConfirmPlanTab.vue?vue&type=style&index=0&id=55e70db8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e70db8",
  null
  
)

export default component.exports