import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import actions from './actions';
import mutations from "./mutations";
import getters from "./getters";
import IncidentsState from "@/vuex/modules/incidents-module/state";


const module: Module<IncidentsState, RootState> = {
    state: new IncidentsState(),
    actions,
    mutations,
    getters,
}

export default module;
