export enum ActionTypes {
  FETCH_INSPECTIONS = "inspections-module/fetch-inspections",
  FETCH_INSPECTIONS_FOR_CSV = "inspections-module/fetch-inspections-for-csv",
  CREATE_INSPECTION = "inspections-module/create-inspection",
  UPDATE_INSPECTION = "inspections-module/update-inspection",
  DELETE_INSPECTION = "inspections-module/delete-inspection",
  FETCH_INSPECTION = "inspections-module/fetch-inspection",
  EXECUTE_INSPECTION = "inspections-module/execute-inspection",
  CANCEL_INSPECTION = "inspections-module/cancel-inspection",
  CANCEL_EXTERNAL_INSPECTION = "inspections-module/cancel-external-inspection",
  EXECUTE_EXTERNAL_INSPECTION = "inspections-module/execute-external-inspection",
  FETCH_INSPECTION_SETTINGS = "inspections-module/fetch-inspection-settings",
  UPDATE_INSPECTION_SETTINGS = "inspections-module/update-inspection-settings",
  START_INSPECTION = "inspections-module/start-inspection",
}
