import { routes } from "@/routes";
import UserFull from "@/vuex/models/user/user-full";

export enum Role {
  SYSTEM_ADMIN = "SUP_ADMIN",
  ORGANISATION_ADMIN = "ORG_ADMIN",
  LOCATION_ADMIN = "LOC_ADMIN",
  INSPECTOR = "INSPECTOR",
}

export default class Authorization {
  private routeAccess = {
    [Role.INSPECTOR]: [
      "dashboard",
      "inspections",
      "incidents",
      "settings",
      "inspection-execution",
      "reports",
      "report-item",
      "statistics",
      "pdf",
      "external-inspection",
      "status-inspection",
      "incident-form-reporting",
      "external-inspection-execution",
    ],
    [Role.ORGANISATION_ADMIN]: [
      "dashboard",
      "reports",
      "incidents",
      "inspections",
      "library",
      "statistics",
      "users",
      "localizations",
      "settings",
      "template-form",
      "inspection-form",
      "inspection-execution",
      "report-item",
      "payments",
      "payment-method-form",
      "select-subscription",
      "enterprise-plan-form",
      "billing-address-form",
      "cancel-subscription",
      "cant-pay-by-card",
      "pdf",
      "qr-codes",
      "external-inspection",
      "status-inspection",
      "incident-form-reporting",
      "external-inspection-execution",
    ],
    [Role.LOCATION_ADMIN]: [
      "dashboard",
      "reports",
      "incidents",
      "inspections",
      "library",
      "users",
      "template-form",
      "localizations",
      "statistics",
      "settings",
      "inspection-form",
      "inspection-execution",
      "report-item",
      "pdf",
      "qr-codes",
      "external-inspection",
      "status-inspection",
      "incident-form-reporting",
      "external-inspection-execution",
    ],
    [Role.SYSTEM_ADMIN]: [
      "dashboard",
      "reports",
      "incidents",
      "inspections",
      "library",
      "statistics",
      "users",
      "localizations",
      "settings",
      "template-form",
      "inspection-execution",
      "organisations",
      "payments",
      "report-item",
      "logs",
      "languages",
      "language-words",
      "pdf",
      "qr-codes",
      "external-inspection",
      "status-inspection",
      "incident-form-reporting",
      "external-inspection-execution",
    ],
  };

  private expiredSubscriptionRoutes = {
    [Role.SYSTEM_ADMIN]: ["settings"],
    [Role.LOCATION_ADMIN]: ["settings"],
    [Role.INSPECTOR]: ["settings"],
    [Role.ORGANISATION_ADMIN]: [
      "settings",
      "payments",
      "payment-method-form",
      "select-subscription",
      "enterprise-plan-form",
      "billing-address-form",
      "cancel-subscription",
      "cant-pay-by-card",
    ],
  };

  public hasAccess(
    user: UserFull | null,
    routeName: string | null | undefined
  ): boolean {
    if (!user || !routeName) {
      return false;
    }

    if (!this.routeExists(routeName)) {
      throw new Error("Route not found: " + routeName);
    }

    if (
      routeName === "qr-codes" &&
      user?.role !== Role.SYSTEM_ADMIN &&
      user?.organisation?.subscription_product_slug !== "enterprise"
    ) {
      return false;
    }

    if (this.subscriptionExpired(user)) {
      return this.expiredSubscriptionRoutes[user.role].includes(routeName);
    }

    return this.routeAccess[user.role].includes(routeName);
  }

  private routeExists(routeName: string): boolean {
    return !!routes.find((route) => route.name == routeName);
  }

  public isNonAuthRoute(name: string | null | undefined): boolean {
    return (
      name === "login" ||
      name === "register" ||
      name === "reset-password" ||
      name === "forgot-password" ||
      name === "home" ||
      name === "confirm-attention" ||
      name === "confirm-email" ||
      name === "pdf" ||
      name === "external-inspection" ||
      name === "incident-form-reporting" ||
      name === "status-inspection" ||
      name === "external-inspection-execution"
    );
  }

  public isNonRoleRoute(name: string | null | undefined): boolean {
    return (
      name === "organisation-requested" || name === "register-organisation"
    );
  }

  public isSystemAdmin(user: UserFull | undefined): boolean {
    return user?.role === Role.SYSTEM_ADMIN;
  }

  public isOrganisationAdmin(user: UserFull | undefined): boolean {
    return user?.role === Role.ORGANISATION_ADMIN;
  }

  public isLocationAdmin(user: UserFull | undefined): boolean {
    return user?.role === Role.LOCATION_ADMIN;
  }

  public isInspector(user: UserFull | undefined): boolean {
    return user?.role === Role.INSPECTOR;
  }

  public subscriptionExpired(user: UserFull | undefined): boolean {
    if (!!user && !!user.stripe_customer) {
      return (
        !user.stripe_customer.on_trial &&
        !user.stripe_customer.has_active_subscription
      );
    }
    return false;
  }

  public roleDisplayName(role: string | null): string | null {
    const names: { [key: string]: string } = {
      [Role.SYSTEM_ADMIN]: "systemAdmin",
      [Role.ORGANISATION_ADMIN]: "organisationAdmin",
      [Role.LOCATION_ADMIN]: "locationAdmin",
      [Role.INSPECTOR]: "inspector",
    };

    if (!!role && !names.hasOwnProperty(role)) {
      throw new Error("Role " + role + " not found");
    }

    return !!role ? names[role] : null;
  }
}
