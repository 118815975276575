<template>
  <div class="billing-form-container">
    <div class="logo">
      <v-logo />
    </div>

    <div class="billing_form">
      <slot name="form"></slot>
      <div class="billing_form_actions">
        <button @click="$emit('back')" class="billing_form_actions_back">
          {{ backLabel ? backLabel : this.$t("app.views.billing.goBack") }}
        </button>
        <button
          @click="$emit('save')"
          class="billing_form_actions_save"
          :class="{ danger: isDanger }"
        >
          {{ saveLabel ? saveLabel : this.$t("app.views.billing.update") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingForm",
  props: {
    backLabel: {
      required: false,
      type: String,
      default: null,
    },
    saveLabel: {
      required: false,
      type: String,
      default: null,
    },
    isDanger: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-form-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.logo {
  margin: 0 auto;
  width: 170px;
}

.danger {
  background-color: #ff5151 !important;
  border-color: #ff5151 !important;
}

.billing_form {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  padding: 30px 40px;
  width: calc(660px - 80px);

  &_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 48px;
      padding: 0 40px;
      border: 2px solid #1de591;
      border-radius: 122px;
      cursor: pointer;

      text-align: center;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0;
    }

    &_back {
      background-color: #fff;
      color: #1de591;

      &:hover {
        background-color: #1de591;
        color: #fff;
      }
    }

    &_save {
      background-color: #1de591;
      color: #fff;

      &:hover {
        box-shadow: 0 0 16px #00000029;
      }
    }
  }
}
</style>