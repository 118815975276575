<template>
  <div>
    <LoadingSpinner :show="loading"></LoadingSpinner>
    <BillingForm @save="savePaymentMethod" @back="$router.back()">
      <template slot="form">
        <div id="payment-element"></div>

        <div class="payment-notification">
          <span>{{
            $t("app.views.billing.paymentMethodNotificationPart1")
          }}</span>
          <span>{{
            $t("app.views.billing.paymentMethodNotificationPart2")
          }}</span>
          <span>{{
            $t("app.views.billing.paymentMethodNotificationPart3")
          }}</span>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import { ActionTypes } from "@/vuex/modules/billing-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import StripeService from "@/services/stripe/stripeService";
import { mapGetters } from "vuex";

export default {
  name: "PaymentMethodForm",
  components: { BillingForm },
  data() {
    return {
      loading: false,
      stripeService: new StripeService(),
      elements: null,
      paymentElement: null,
    };
  },
  async created() {
    this.$parent.title = this.$t("components.table.paymentMethod");

    await this.mountPaymentElement();
  },
  computed: {
    ...mapGetters(["me"]),
  },
  methods: {
    async mountPaymentElement() {
      this.loading = true;

      try {
        await this.stripeService.init();
        const response = await this.$store.dispatch(
          ActionTypes.FETCH_SETUP_INTENT
        );
        this.stripeService
          .createElements(response.data.client_secret, this.me.theme)
          .mountPaymentElement("#payment-element");
      } catch (exception) {
        await this.handleError(exception.response.data.message);
      }

      this.loading = false;
    },
    async savePaymentMethod() {
      this.loading = true;

      const response = await this.stripeService.savePaymentMethod();
      if (response.error) {
        await this.handleError(response.error.message);
      }

      this.loading = false;
    },
    async handleError(message) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: message,
        type: NotificationType.DANGER,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card_details {
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 20px;
  max-width: calc(100% - 20px);
}

.payment-notification {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0;
  color: #707070;

  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>