import { StatisticData } from "./../StatisticData";

export class StatisticDataCounter implements StatisticData {
  name: string = "";
  data_type: number = 0;
  start_date: Date = new Date();
  chart_type?: StatisticChartType = StatisticChartType.line;
  end_date: Date = new Date();
  location_id: null | number = null;
  users_id: null | number = null;
}

export enum StatisticChartType {
  line,
  bar,
}
