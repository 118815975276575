import { MutationTree } from "vuex";

import { MutationTypes } from "./mutation-types";

import Meta from "@/vuex/models/meta";
import QRCodeFull from "@/vuex/models/qrCode/qrCode-full";

import QRCodeState from "./state";

const mutations: MutationTree<QRCodeState> = {
  [MutationTypes.SET_QR_CODES](
    state: QRCodeState,
    listQRCodes: Array<QRCodeFull>
  ): void {
    state.listQRCodes = listQRCodes;
  },
  [MutationTypes.SET_QR_CODES_FOR_CSV](
    state: QRCodeState,
    qrCodesForCsv: Array<QRCodeFull>
  ): void {
    state.qrCodesForCsv = qrCodesForCsv;
  },
  [MutationTypes.SET_QR_CODES_META](state: QRCodeState, meta: Meta): void {
    state.meta = meta;
  },
};

export default mutations;
