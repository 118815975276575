import LocationsState from "./state";
import {GetterTree} from "vuex";
import {RootState} from "../../store";
import Meta from "@/vuex/models/meta";
import LocationFull from "@/vuex/models/location/location-full";
import {SelectOption} from "@/components/InputSelect/InputSelect";

const getters: GetterTree<LocationsState, RootState> = {
    locations(state: LocationsState): Array<LocationFull> | [] {
        return state.locations;
    },
    locationsForCsv(state: LocationsState): Array<LocationFull> | [] {
        return state.locationsForCsv;
    },
    locationsMeta(state: LocationsState): Meta {
        return state.meta;
    },
    locationSelectOptions(state: LocationsState): SelectOption[] {
        return state.locations.map((location: LocationFull) => {
            return {
                value: location.id,
                label: location.name
            }
        });
    },
    location(state: LocationsState): LocationFull | null {
        return state.location;
    }
}

export default getters;
