import { Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { FormMixin } from "@/mixins/formMixin";
import { ActionTypes } from "@/vuex/modules/inspections-module/action-types";
import InspectionExecution from "@/vuex/models/inspection/inspection-execution";
import PauseModal from "@/views/InspectionExecutionView/components/Modals/PauseModal.vue";
import CancelModal from "@/views/InspectionExecutionView/components/Modals/CancelModal.vue";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import InspectionExecutionData from "@/vuex/models/inspection/inspection-execution-data";
import DateToString from "@/services/dateToString/dateToString";
import TemplateSection from "@/vuex/models/template/template-section";
import { ButtonStyles } from "@/components/ButtonWithIcon/ButtonWithIcon";
import TemplateQuestion from "@/vuex/models/template/template-question";
import InspectionAnswer from "@/vuex/models/inspection/inspection-answer";
import { TemplateQuestionType } from "@/vuex/models/template/template-question-type";
import CommentModal from "@/views/InspectionExecutionView/components/Modals/CommentModal.vue";
import { VueClass } from "vue-class-component/lib/declarations";
import SimpleAnswer from "./components/AnswerComponents/SimpleAnswer/SimpleAnswer.vue";
import WithIncidentAnswer from "./components/AnswerComponents/WithIncidentAnswer/WithIncidentAnswer.vue";
import WithIncidentAnswerBySelectButton from "./components/AnswerComponents/WithIncidentAnswerBySelectButton/WithIncidentAnswerBySelectButton.vue";
import ComplexAnswer from "./components/AnswerComponents/ComplexAnswer/ComplexAnswer.vue";
import i18n from "@/lang/locale";
import store from "@/vuex/store";
import { appRoutes } from "@/config";
import { http } from "@/services/http/http";
import { MutationTypes } from "@/vuex/modules/inspections-module/mutation-types";
import PinCode from "./components/PinCode/PinCode.vue";

@Component({
  components: {
    CancelModal,
    ButtonsSaveCancel,
    SvgIcon,
    PauseModal,
    CommentModal,
    PinCode,
  },
  methods: mapActions({
    startInspection: ActionTypes.START_INSPECTION,
    cancelInspection: ActionTypes.CANCEL_INSPECTION,
    executeInspection: ActionTypes.EXECUTE_INSPECTION,
    cancelExternalInspection: ActionTypes.CANCEL_EXTERNAL_INSPECTION,
    executeExternalInspection: ActionTypes.EXECUTE_EXTERNAL_INSPECTION,
  }),
  computed: mapGetters({
    inspection: "inspection",
  }),
})
export default class InspectionExecutionView extends FormMixin {
  protected readonly startInspection!: (inspectionId: number) => Promise<void>;
  protected readonly cancelInspection!: (inspectionId: number) => Promise<void>;
  protected readonly executeInspection!: (params: {
    data: InspectionExecutionData;
    id: number;
  }) => Promise<number | null>;

  protected readonly cancelExternalInspection!: (params: {
    inspectionId: number;
    qr_token: string;
    pin: string | null;
  }) => Promise<void>;
  protected readonly executeExternalInspection!: (params: {
    data: InspectionExecutionData;
    inspectionId: number;
    qr_token: string;
    pin: string | null;
  }) => Promise<number | null>;

  protected readonly inspection!: InspectionExecution;
  protected readonly dateToString: DateToString = new DateToString();
  protected readonly buttonStyles = ButtonStyles;

  protected loading: boolean = false;
  protected pauseModal: boolean = false;
  protected cancelModal: boolean = false;
  protected commentModal: boolean = false;
  protected counter: number = 1;
  protected questions: TemplateQuestion[] = [];
  protected isPinCode: boolean = false;
  protected isInspectionExecution: boolean = false;

  protected currentAnswer: InspectionAnswer = {
    id: null,
    answer: null,
    comment: null,
    image: null,
    question_id: null,
    report_answer: null,
    has_incident: 0,
    skipped: 0,
  };

  protected executionData: InspectionExecutionData = {
    start_datetime: null,
    answers: [],
  };

  protected get currentQuestionIndex(): number {
    return this.counter - 1;
  }

  protected get showCommentButton(): boolean {
    const question = this.getCurrentQuestion();

    return !!question
      ? question.type.slug !== TemplateQuestionType.COMMENT
      : false;
  }

  protected get questionCanBeSkipped(): boolean {
    return !this.getCurrentQuestion()?.required;
  }

  protected get showImageButton(): boolean {
    const question = this.getCurrentQuestion();

    return !!question
      ? question.type.slug !== TemplateQuestionType.SIGNATURE
      : false;
  }

  protected get currentAnswerComponent(): VueClass<Vue> | undefined {
    const question = this.getCurrentQuestion();
    if (!question) {
      return;
    }

    let component;

    switch (question.type.slug) {
      case TemplateQuestionType.COMMENT:
      case TemplateQuestionType.NUMBER:
      case TemplateQuestionType.TEXT:
        component = SimpleAnswer;
        break;
      case TemplateQuestionType.SCALE_1_TO_5:
      case TemplateQuestionType.SCALE_1_TO_10:
      case TemplateQuestionType.SINGLE_CHOICE:
        component = WithIncidentAnswer;
        break;
      case TemplateQuestionType.YES_NO:
        component = WithIncidentAnswerBySelectButton;
        break;
      case TemplateQuestionType.GEOLOCATION:
      case TemplateQuestionType.SIGNATURE:
        component = ComplexAnswer;
        break;
    }

    if (!component) {
      return;
    }

    return component;
  }

  protected async startInspectionExternal(qrToken: string) {
    try {
      const response = await http().get(
        appRoutes.api.inspections.startInspectionExternal(qrToken, null)
      );
      store.commit(MutationTypes.SET_INSPECTION, response.data);
    } catch (exception: any) {
      if (exception.response.status === 403 || this.isInspectionExecution) {
        this.isPinCode = true;
      } else {
        await this.$router.push({
          name: "status-inspection",
          query: { id: this?.$route?.params?.id.toString(), status: "error" },
        });
      }
    } finally {
      this.loading = false;
    }
  }

  protected handleCancelInspection() {
    this.cancelModal = !this.cancelModal;
  }

  protected async created(): Promise<void> {
    this.loading = true;
    if (!this.$route.params.id) {
      this.$router.back();
    }
    this.isInspectionExecution = this.$route.name == "external-inspection-execution";

    if (!this.$route?.meta?.isExternalInspection) {
      await this.startInspection(Number(this.$route.params.id));
    } else {
      await this.startInspectionExternal(this.$route.params.id.toString());
      this.loading = false;
    }

    this.setQuestions();
    this.setExecutionData();
    this.goToActualQuestion();

    this.loading = false;
  }

  protected getSectionName(question: TemplateQuestion): string {
    let name: string = "";
    this.inspection.template.sections.forEach((section: TemplateSection) => {
      if (section.questions.indexOf(question) > -1) {
        name = section.name;
      }
    });

    return name;
  }

  private setExecutionData(): void {
    const now: Date = new Date();
    const utc: Date = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    this.executionData.answers = this.inspection.answers;
    this.executionData.start_datetime = !!this.inspection.start_datetime
      ? this.inspection.start_datetime
      : this.dateToString.convert(utc) + " " + this.dateToString.toTime(utc);
  }

  private setQuestions(): void {
    this.inspection.template.sections.forEach(
      (section: TemplateSection): void => {
        section.questions.forEach((question: TemplateQuestion) =>
          this.questions.push(question)
        );
      }
    );
  }

  private goToActualQuestion(): void {
    this.counter = this.executionData.answers.length + 1;
    this.setCurrentAnswer();
  }

  protected async pause(): Promise<void> {
    this.loading = true;

    try {
      await this.executeInspection({
        data: this.executionData,
        id: this.inspection.id,
      });
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Inspection has been paused",
        type: NotificationType.SUCCESS,
      });
      if (window?.innerWidth < 1080) {
        await this.$router.push({ name: "dashboard" });
      } else {
        await this.$router.push({ name: "inspections" });
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  protected async handleExecuteExternalInspection(
    skipped: boolean
  ): Promise<void> {
    debugger;
    this.loading = true;
    try {
      this.currentAnswer.skipped = skipped ? 1 : 0;
      this.addAnswer();
      await this.executeExternalInspection({
        data: this.executionData,
        inspectionId: this.inspection.id,
        qr_token: this.$route.params.id.toString(),
        pin: this.inspection.pin,
      });
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Inspection has been executed",
        type: NotificationType.SUCCESS,
      });

      await this.$router.push({
        name: "status-inspection",
        query: { id: this?.$route?.params?.id.toString(), status: "result" },
      });
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  protected async handleCancelExternalInspection(): Promise<void> {
    this.loading = true;

    try {
      await this.cancelExternalInspection({
        inspectionId: this.inspection.id,
        qr_token: this.$route.params.id.toString(),
        pin: this.inspection.pin,
      });
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Inspection has been canceled",
        type: NotificationType.SUCCESS,
      });

      await this.$router.push({
        name: "status-inspection",
        query: { id: this.$route?.params?.id?.toString(), status: "stopped" },
      });
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  protected async cancel(): Promise<void> {
    this.loading = true;

    try {
      await this.cancelInspection(this.inspection.id);
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Inspection has been canceled",
        type: NotificationType.SUCCESS,
      });
      if (window?.innerWidth < 1080) {
        await this.$router.push({ name: "dashboard" });
      } else {
        await this.$router.push({ name: "inspections" });
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  protected async execute(skipped: boolean): Promise<void> {
    this.loading = true;

    try {
      this.currentAnswer.skipped = skipped ? 1 : 0;
      this.addAnswer();

      const reportId: number | null = await this.executeInspection({
        data: this.executionData,
        id: this.inspection.id,
      });
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Inspection has been executed",
        type: NotificationType.SUCCESS,
      });

      if (!!reportId) {
        await this.$router.push({
          name: "report-item",
          params: { id: reportId.toString() },
          query: { first: "true" },
        });
      } else {
        throw new Error(
          "Report has not been created. Refresh the page and try again."
        );
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.loading = false;
  }

  private addAnswer(): void {
    const answer = this.getCurrentAnswer();
    if (!!answer) {
      this.executionData.answers[this.currentQuestionIndex] =
        this.currentAnswer;
    } else {
      this.executionData.answers.push(this.currentAnswer);
    }
  }

  protected nextQuestion(skipped: boolean = false): void {
    this.currentAnswer.skipped = skipped ? 1 : 0;
    this.addAnswer();
    this.counter += 1;
    this.setCurrentAnswer();
  }

  protected prevQuestion(): void {
    this.counter -= 1;
    this.setCurrentAnswer();
  }

  protected getCurrentQuestion(): TemplateQuestion {
    return this.questions[this.currentQuestionIndex];
  }

  protected getCurrentAnswer(): InspectionAnswer | undefined {
    return this.executionData.answers[this.currentQuestionIndex];
  }

  private setCurrentAnswer(): void {
    const answer = this.getCurrentAnswer();

    this.currentAnswer = !!answer
      ? answer
      : {
          id: null,
          answer: null,
          comment: null,
          image: null,
          report_answer: null,
          has_incident: 0,
          skipped: 0,
          question_id: this.getCurrentQuestion().id,
        };
  }

  protected addComment(comment: string): void {
    this.commentModal = false;
    this.currentAnswer.comment = comment;
  }

  protected cancelComment(): void {
    this.commentModal = false;
    this.currentAnswer.comment = null;
  }

  protected addImage(event: any) {
    if (event.target.files[0].size > 8388608) {
      return store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: i18n
          .t(
            "app.views.inspection.execution.notificationMessage.isValidateImage"
          )
          .toString(),
        type: NotificationType.DANGER,
      });
    }
    this.currentAnswer.image = event.target.files[0];
    const imageInput: any = document.getElementById("imageInput");
    imageInput.value = null;
  }

  protected cancelImage(): void {
    this.currentAnswer.image = null;

    const imageInput: any = document.getElementById("imageInput");
    imageInput.value = null;
  }

  protected setPin(data: any): void {
    this.loading = true;
    this.isPinCode = false;
    store.commit(MutationTypes.SET_INSPECTION, data);
    this.setQuestions();
    this.setExecutionData();
    this.goToActualQuestion();
    this.loading = false;
  }
}
