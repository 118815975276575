<template>
  <div class="confirm_plan">
    <BillingForm
      @back="$emit('backToPlans')"
      @save="subscribe"
      :save-label="$t('app.views.billing.changePlan').toString()"
    >
      <template slot="form">
        <div class="confirm_plan_container_title">
          {{ $t("app.views.billing.getStarted") + " " + selectedProduct.name }}
        </div>
        <div class="confirm_plan_container_information">
          <div>
            <span>
              {{ $t("app.views.billing.selectPlanNotificationPart1") }}
            </span>
            <span v-if="selectedProduct.slug === 'pro'">
              {{ $t("app.views.billing.selectPlanProNotification") }}
            </span>
            <span>
              {{ $t("app.views.billing.selectPlanNotificationPart2") + " " }}
              <strong>
                {{
                  (
                    (selectedProduct.amount / 100) *
                    me.organisation.active_users_count
                  ).toFixed(2) + " "
                }}
              </strong>
              {{
                selectedProduct.currency +
                " / " +
                $t("app.views.billing.selectPlanNotificationPart3") +
                " "
              }}
            </span>
          </div>
        </div>
        <div class="confirm_plan_container_payment-method">
          <span>
            {{ $t("app.views.billing.willCharge") }}
          </span>
          <PaymentMethod />

          <div class="confirm_plan_container_payment-method_notification">
            <span>
              {{ $t("app.views.billing.chargeNotification") }}
            </span>
          </div>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import PaymentMethod from "@/views/Billing/components/PaymentMethod.vue";
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import { UserProfileTabs } from "@/vuex/models/userProfile";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes } from "@/vuex/modules/billing-module/action-types";
import { mapGetters } from "vuex";
import { ActionTypes as AuthActions } from "@/vuex/modules/auth-module/action-types";

export default {
  name: "ConfirmPlanTab",
  emits: ["loading", "backToPlans"],
  components: { BillingForm, PaymentMethod },
  props: {
    selectedProduct: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["me"]),
    UserProfileTabs() {
      return UserProfileTabs;
    },
    privacyHref() {
      return `${window.location.origin}/polityka_prywatnosci_checly.pdf`;
    },
    rulesHref() {
      return `${window.location.origin}/regulamin_checly.pdf`;
    },
  },
  methods: {
    async subscribe() {
      await this.saveSubscription();
    },
    async saveSubscription() {
      try {
        await this.$store.dispatch(
          ActionTypes.SAVE_SUBSCRIPTION,
          this.selectedProduct.id
        );
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text:
            "You successfully subscribed to plan " +
            this.selectedProduct.name +
            ".",
          type: NotificationType.SUCCESS,
        });

        await this.$store.dispatch(AuthActions.FETCH_ME);

        await this.$router.push({
          name: "settings",
          query: { tab: UserProfileTabs.BILLING },
        });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_plan {
  display: flex;
  justify-content: center;

  &_container {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 0 16px #0000000f;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 27px;
    width: 40%;
    gap: 20px;

    &_title {
      text-align: left;
      font: normal normal 600 22px/33px Poppins;
      letter-spacing: 0;
      color: #0d0e34;
    }

    &_information {
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        display: flex;
        flex-direction: column;
      }

      span,
      a {
        font: normal normal normal 12px/26px Poppins;
        letter-spacing: 0;
      }
    }

    &_payment-method {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px 0;
      border-top: 1px solid #e6e6e6;

      &_notification {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          font: normal normal normal 10px/16px Poppins;
          letter-spacing: 0;
          color: #707070;
        }
      }

      &_privacy {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font: normal normal normal 12px/18px Poppins;
          letter-spacing: 0;
        }
      }
    }
  }
}

.green {
  color: #1de591;
}
</style>
