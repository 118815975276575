<template>
  <div class="payment_method" v-if="!!paymentMethod">
    <img src="/images/card.svg" alt="payment_method_logo">
    <div class="payment_method_info">
      <span class="payment_method_info_card-number">
        {{ '**** **** **** ' + paymentMethod.last4 }}
      </span>
      <span class="payment_method_info_expires">
        {{ $t('app.views.billing.expires').capitalize() + ' ' + expMonth + '/' + paymentMethod.exp_year }}
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";

export default {
  name: "PaymentMethod",
  computed: {
    ...mapGetters(['paymentMethod']),
    expMonth() {
      if (!!this.paymentMethod && this.paymentMethod.exp_month.toString().length < 2) {
        return '0' + this.paymentMethod.exp_month;
      }
      return 0;
    }
  },
  async created() {
    if (!this.paymentMethod) {
      await this.$store.dispatch(ActionTypes.FETCH_PAYMENT_METHOD);
    }
  }
}
</script>

<style lang="scss" scoped>
.payment_method {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;

  img {
    width: 102px;
    height: 72px;
    border-radius: 21px;
    background: black;
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &_card-number {
      text-align: left;
      font: normal normal 600 14px/21px Poppins;
      letter-spacing: 0;
      color: #0D0E34;

      display: flex;
      align-items: center;
    }

    &_expires {
      text-align: left;
      font: normal normal normal 12px/18px Poppins;
      letter-spacing: 0;
      color: #6D6D6D;
    }
  }
}
</style>