import { Component, Mixins, Prop } from "vue-property-decorator";

import { PageMixin } from "@/mixins/pageMixin";

import LocationFull from "@/vuex/models/location/location-full";
import UserProfile from "@/vuex/models/user/user-profile";

@Component({})
export default class LocationItem extends Mixins(PageMixin) {
  @Prop({
    required: false,
    type: Object,
  })
  public location!: LocationFull;

  protected locationUsers(location: LocationFull): string {
    const users: string[] = location.users.map(
      (user: UserProfile) => `${user.name} ${user.surname}`
    );

    return users.join(", ");
  }
}
