import { MutationTree } from "vuex";
import UsersState from "./state";
import { MutationTypes } from "./mutation-types";
import User from "@/vuex/models/user/user";
import Meta from "@/vuex/models/meta";

const mutations: MutationTree<UsersState> = {
  [MutationTypes.SET_INSPECTORS](state: UsersState, inspectors: any[]): void {
    state.userInspectors = inspectors;
  },

  [MutationTypes.SET_USERS](state: UsersState, users: Array<User> | []): void {
    state.users = users;
  },

  [MutationTypes.SET_USERS_FOR_CSV](
    state: UsersState,
    users: Array<User> | []
  ): void {
    state.usersForCsv = users;
  },

  [MutationTypes.SET_USERS_FOR_ASSIGN](
    state: UsersState,
    users: Array<User> | []
  ): void {
    state.usersForAssign = users;
  },

  [MutationTypes.SET_USERS_META](state: UsersState, meta: Meta): void {
    state.meta = meta;
  },
};

export default mutations;
