import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import { ActionTypes } from "./action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import LogsState from "@/vuex/modules/logs-module/state";
import { Direction, LogsSortableColumns } from "@/services/helpers/sort";
import { MutationTypes } from "@/vuex/modules/logs-module/mutation-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

export interface LogsSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: LogsSortableColumns | null;
  search: string | null;
}

type LogsActionContext = ActionContext<LogsState, RootState>;

const actions: ActionTree<LogsState, RootState> = {
  async [ActionTypes.FETCH_LOGS](
    store: LogsActionContext,
    params: LogsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.logs.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_LOGS, response.data.data);
      store.commit(MutationTypes.SET_LOGS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },
  async [ActionTypes.FETCH_LOGS_FOR_CSV](
    store: LogsActionContext,
    params: LogsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.logs.all(), {
        params,
      });
      store.commit(MutationTypes.SET_LOGS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },
};

export default actions;
