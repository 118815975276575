import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import TemplateQuestion from "@/vuex/models/template/template-question";
import InspectionAnswer from "@/vuex/models/inspection/inspection-answer";
import { TemplateQuestionType } from "@/vuex/models/template/template-question-type";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import TemplateAnswerOption from "@/vuex/models/template/template-answer-option";
import i18n from "@/lang/locale";

@Component({})
export default class WithIncidentAnswer extends Vue {
  @Prop({
    required: true,
  })
  public question!: TemplateQuestion;

  @Prop({
    required: true,
  })
  public answer!: InspectionAnswer;

  protected questionType = TemplateQuestionType;
  protected value: number | null = null;
  protected options: SelectOption[] = [];

  protected created(): void {
    this.options = this.question.answer_options.map(
      (option: TemplateAnswerOption) => {
        return {
          value: option.id,
          label:
            option.answer_option === "notApplicable"
              ? i18n
                  .t("app.views.inspection.execution.notApplicable")
                  .toString()
              : option.answer_option,
        };
      }
    );

    if (typeof this.answer.answer === "number") {
      this.value = this.answer.answer;
    }
  }

  protected setValue(value: number): void {
    this.$emit("setAnswer", value);

    this.question.answer_options.forEach((option: TemplateAnswerOption) => {
      if (option.incident_level !== null && option.id === value) {
        this.$emit("setIncident", 1);
      }
    });
  }
}
