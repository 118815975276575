<template>
  <div class="main-access-block">
  <records-table :rows="users">
    <template v-slot:thead>
      <tr class="table_thead_tr">
        <sortable-th
          width="10%"
          :direction="AccessSearchParams.direction"
          :sorted="AccessSortableColumns.NAME === AccessSearchParams.column"
          @click="sortColumn(AccessSortableColumns.NAME)"
        >
          {{ $t('components.table.name') }}
        </sortable-th>
        <sortable-th
          width="10%"
          :direction="AccessSearchParams.direction"
          :sorted="AccessSortableColumns.EMAIL === AccessSearchParams.column"
          @click="sortColumn(AccessSortableColumns.EMAIL)"
        >
          {{ $t('components.table.email') }}
        </sortable-th>
        <th width="15%" v-if="auth.isSystemAdmin(me)">
          {{ $t("components.table.organisation") }}
        </th>
        <th width="15%" v-if="!auth.isInspector(me)">
          {{ $t("components.table.pin") }}
        </th>
        <sortable-th
          width="10%"
          :direction="AccessSearchParams.direction"
          :sorted="AccessSortableColumns.STATUS === AccessSearchParams.column"
          @click="sortColumn(AccessSortableColumns.STATUS)"
        >
          {{ $t("components.table.status") }}
        </sortable-th>
        <th width="30%"></th>
        <th width="10%"></th>
      </tr>
    </template>

    <template v-if="users.length > 0" v-slot:tbody>
      <tr v-for="user in users" :key="user.id">
        <td>{{ user.name }} {{  }}</td>
        <td>{{ user.email }}</td>
        <td v-if="auth.isSystemAdmin(me)">
          {{ !!user.organisation ? user.organisation.name : "-" }}
        </td>
        <td v-if="!auth.isInspector(me)" class="pin">
          <div class="input-container">
            <input
              :type="passwordVisibility.includes(user.id) ? 'text' : 'password'"
              v-model="user.pin"
              class="search-input"
              maxlength="4"
              readonly
            />
            <button @click="togglePasswordVisibility(user)" class="eye-button">
              <img :src="passwordVisibility.includes(user.id) ? '/images/icons/eye-off.svg' : '/images/icons/eye.svg'" />
            </button>
          </div>
        </td>
        <td>{{
            user.status
              ? $t("app.views.userStatus.active")
              : $t("app.views.userStatus.inactive")
          }}</td>
        <td>
          <div class="actions">
            <button v-if="user.status > 0" class="send" @click.prevent="send(user)">
              {{ $t("components.send") }}
            </button>
          </div>
        </td>
        <td>
          <table-row-drop-down-menu>
            <template v-slot:content>
              <span class="controls-dropdown-button" @click="update(user)">
                {{ $t("components.edit") }}
              </span>
              <span class="controls-dropdown-button" @click="remove(user)">
                {{ $t("components.delete") }}
              </span>
            </template>
          </table-row-drop-down-menu>
        </td>
      </tr>
    </template>
  </records-table>

  <Modal :modal="emailModal" @close="closeModal">
    <template slot="header">
      <p class="modal_header">{{ $t("app.views.user.accesses.send") }}</p>
    </template>
    <template slot="content">
      <div class="modal_content">
        <div class="modal_content_description">
          <p>
            {{ $t("app.views.user.accesses.enter") }}
          </p>
        </div>
        <div class="modal_content_input">
          <input
            type="text"
            v-model="emptyEmailUser.email"
          />
        </div>
      </div>
    </template>
    <template slot="footer">
      <ButtonsSaveCancel
        class="modal_footer"
        @close="closeModal"
        @save="send(emptyEmailUser)"
        :saveLabel="$t('components.send')"
        :save-icon="'send'"
        :save-color="'#FFF'"
      ></ButtonsSaveCancel>
    </template>
  </Modal>
  </div>
</template>

<script>
import { AccessSortableColumns } from "@/services/helpers/sort";
import Authorization from "@/services/authorization/authorization";
import {mapGetters} from "vuex";
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";

export default {
  name: 'UsersExternalAccessTable',
  emits: ["showForm", "showDeleteModal", "sortColumn"],
  components: {
    Modal,
    ButtonsSaveCancel,
  },
  props: {
    users: {
      required: true,
    },
    AccessSearchParams: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["me"]),
  },
  data() {
    return {
      AccessSortableColumns: AccessSortableColumns,
      auth: new Authorization(),
      emailModal: false,
      emptyEmailUser: {email: null},
      passwordVisibility: [],
    };
  },
  methods: {
    sortColumn(columnName) {
      this.$emit("sortColumn", columnName);
    },
    update(user) {
      this.$emit("showForm", user);
    },
    remove(user) {
      this.$emit("showDeleteModal", user);
    },
    send(user) {
      if (!user.email) {
        this.emailModal = true;
        this.emptyEmailUser = {...user};
      } else {
        this.closeModal();
        this.$emit('sendEmail', user);
      }
    },
    togglePasswordVisibility(user) {
      const index = this.passwordVisibility.indexOf(user.id);
      if (index === -1) {
        this.passwordVisibility.push(user.id);
      } else {
        this.passwordVisibility.splice(index, 1);
      }
      console.log('this.passwordVisibility', this.passwordVisibility);
    },
    closeModal() {
      this.emailModal = false;
      this.email = null;
    }
  },
};
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  button {
    width: 120px;
    height: 35px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 46px;
    border: 2px solid;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #FFF;
    }
  }

  .send {
    border-color: #1DE591;
    color: #1DE591;

    &:hover {
      background: #1DE591 0 0 no-repeat padding-box;
    }
  }
}

.pin {
  min-width: 85px;

  .input-container {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 30px;
    padding: 5px 10px;
    width: fit-content;
    min-width: 85px;
  }

  .search-input {
    border: none;
    background: none;
    outline: none;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 30px;
    width: 60px;
    text-align: center;
    font-size: 16px;
    background-color: #f0f0f0!important;
    color: #737373!important;
  }

  .search-input[readonly] {
    cursor: not-allowed;
  }

  .eye-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-left: 5px;
    height: 20px;
  }

  .eye-button img {
    width: 20px;
    height: 20px;
    color:#737373!important;
  }
}

.main-access-block {
  display: contents;

  .modal {
    &_header {
      text-align: center;
      font: normal normal 600 18px/42px Poppins;
      letter-spacing: 0px;
      color: #0d0e34;
      opacity: 1;
    }

    &_content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      width: 70%;

      &_description {
        font: normal normal normal 12px/21px Poppins;
        letter-spacing: 0;
        color: #3d3d3d;
        text-align: center;
      }

      &_input {
        width: 100%;
        max-width: 480px;
        min-height: 48px;
        background: #ffffff 0 0 no-repeat padding-box;
        box-shadow: 0 0 16px #0000000f;
        border: 1px solid #e6e6e6;
        border-radius: 24px;

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        padding: 10px;
        gap: 10px;

        &_emails {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
        }

        &_email {
          background: #ececec 0 0 no-repeat padding-box;
          border-radius: 61px;
          padding: 5px 14px;

          font: normal normal normal 12px/18px Poppins;
          letter-spacing: 0;
          color: #6d6d6d;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 9px;
        }

        &_close {
          width: 18px;
          height: 18px;
          cursor: pointer;
          transition: all 0.5s;
          color: red;
        }

        &_close:hover {
          scale: 1.05;
          transition: all 0.5s;
        }

        input {
          border: 0;
          outline: 0;
          font: normal normal normal 12px/24px Poppins;
          letter-spacing: 0;
          color: #6d6d6d;
          display: flex;
          flex-grow: 1;
        }
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &_footer {
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
