export const generateTranslationRole = (
  typeRole: "INSPECTOR" | "ORG_ADMIN" | "LOC_ADMIN" | "SUP_ADMIN"
) => {
  switch (typeRole) {
    case "INSPECTOR":
      return "role.inspector";
    case "ORG_ADMIN":
      return "role.organisationAdmin";
    case "LOC_ADMIN":
      return "role.locationAdmin";
    case "SUP_ADMIN":
      return "role.sysAdmin";
  }
};
