import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component
export default class HomeView extends Vue {

    public getUrl(url: string) {
        return window.location.origin + '/images/homepage/' + url;
    }

    public clickMail() {
        window.open('mailto:contact@checly.pl', '_blank');
    }

    public grid_1 = [
        {
            img: this.getUrl("lines.png"),
            desc: "Zbiera dane, standaryzuje procesy, automatyzuje raportowanie, identyfikuje obszary ryzyka i wspiera zarządzanie jakością."
        },
        {
            img: this.getUrl("clip_note.png"),
            desc: "Wspiera przeprowadzanie inspekcji. Posiada gotowe listy kontrolne oraz konfigurowalne formularze."
        },
        {
            img: this.getUrl("note.png"),
            desc: "Ułatwia planowanie. Umożliwia łatwe przydzielanie zadań i analizę ich wykonania."
        },
        {
            img: this.getUrl("hourglass.png"),
            desc: "Przypisuje akcje do użytkownika. Priorytetyzuje zadania."
        },
        {
            img: this.getUrl("person.png"),
            desc: "Automatyzuje raportowanie. Umożliwia predykcję, analizę i śledzenie trendów."
        },
        {
            img: this.getUrl("phone.png"),
            desc: "Aplikacja mobilna umożliwia wygodną pracę w terenie (działa na telefonach i tabletach, również offline)."
        },
        {
            img: this.getUrl("cogs.png"),
            desc: "Jest intuicyjna i łatwa w implementacji oraz użytkowaniu."
        },
        {
            img: this.getUrl("graph.png"),
            desc: "Monitoruje działania naprawcze. Ułatwia filtrowanie danych."
        }
    ];

    public grid_2 = [
        {
            img: this.getUrl("display.png"),
            desc: "Automatyzuje i digitalizuje procesy"
        },
        {
            img: this.getUrl("plug.png"),
            desc: "Podejście EKO – pomaga wyeliminować papierowe checklisty i raporty"
        },
        {
            img: this.getUrl("timer.png"),
            desc: "Pięciokrotnie skraca czas inspekcji"
        },
        {
            img: this.getUrl("new_cashp.png"),
            desc: "Poprawia komunikacje i ujednolica standardy"
        },
        {
            img: this.getUrl("cash.png"),
            desc: "Optymalizuje koszty"
        },
        {
            img: this.getUrl("chart.png"),
            desc: "Poprawia jakość i zmniejsza ryzyko"
        },
    ]
}
