<template>
  <div class="InspectionTemplateQuestionsPseudoTable">
    <TemplateSectionTable
      :form-errors="formErrors"
      :template-data="templateData"
      :disabled="disabled"
      v-on:update:templateData="templateData = $event"
    />
  </div>
</template>

<script>
import TemplateSectionTable from "@/views/TemplateFormView/components/TemplateSectionTable.vue";
import WagaDetailIcon from "@/views/TemplateFormView/components/WagaDetailIcon.vue";

export default {
  name: "TemplatePseudoTable",
  emits: ["update:templateData"],
  components: {
    TemplateSectionTable,
    WagaDetailIcon,
  },
  props: {
    templateData: {
      required: true,
    },
    formErrors: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.InspectionTemplateQuestionsPseudoTable {
  width: 100%;
}
</style>
