import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class PopUp extends Vue {

  private close(): void {
    this.$emit('close', {});
  }

  @Prop({
    required: true
  })
  public background!: boolean;
}
