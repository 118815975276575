import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import InspectionsState from "@/vuex/modules/inspections-module/state";
import { ActionTypes } from "@/vuex/modules/inspections-module/action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "./mutation-types";
import { AxiosRequestConfig } from "axios";
import objectToFormData from "@/services/http/objectToFormData";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { Direction, InspectionsSortableColumns } from "@/services/helpers/sort";
import InspectionData from "@/vuex/models/inspection/inspection-data";
import DateToString from "@/services/dateToString/dateToString";
import InspectionExecutionData from "@/vuex/models/inspection/inspection-execution-data";
import InspectionSetting from "@/vuex/models/inspection/inspection-setting";

export interface InspectionsSearchParams {
  page: number;
  per_page: number;
  direction: Direction | null;
  column: InspectionsSortableColumns | null;
  search: string | null;
  location_id: number | null;
  is_ended: boolean | 0 | 1 | null;
  is_mine: boolean | 0 | 1 | null;
  from_date: string | null;
  to_date: string | null;
  can_start: boolean | 0 | 1 | null;
  past_due?: 1 | null;
  external_access: boolean | 0 | 1 | null;
}

type InspectionsActionContext = ActionContext<InspectionsState, RootState>;
const dateToString: DateToString = new DateToString();

const actions: ActionTree<InspectionsState, RootState> = {
  async [ActionTypes.FETCH_INSPECTIONS](
    store: InspectionsActionContext,
    params: InspectionsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.inspections.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_INSPECTIONS, response.data.data);
      store.commit(MutationTypes.SET_INSPECTIONS_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_INSPECTIONS_FOR_CSV](
    store: InspectionsActionContext,
    params: InspectionsSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.inspections.all(), {
        params,
      });
      store.commit(MutationTypes.SET_INSPECTIONS_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_INSPECTION](
    store: InspectionsActionContext,
    inspectionData: InspectionData
  ): Promise<void> {
    if (inspectionData.date instanceof Date) {
      inspectionData.date = dateToString.convert(inspectionData.date);
    }
    if (inspectionData.end_repeat_date instanceof Date) {
      inspectionData.end_repeat_date = dateToString.convert(
        inspectionData.end_repeat_date
      );
    }

    await http().post(appRoutes.api.inspections.create(), inspectionData);
  },

  async [ActionTypes.UPDATE_INSPECTION](
    store: InspectionsActionContext,
    inspectionData: InspectionData
  ): Promise<void> {
    if (!inspectionData.id) {
      throw new Error("cannot update model without id (not saved ?)");
    }
    if (inspectionData.date instanceof Date) {
      inspectionData.date = dateToString.convert(inspectionData.date);
    }
    if (inspectionData.end_repeat_date instanceof Date) {
      inspectionData.end_repeat_date = dateToString.convert(
        inspectionData.end_repeat_date
      );
    }

    await http().put(
      appRoutes.api.inspections.update(inspectionData.id),
      inspectionData
    );
  },

  async [ActionTypes.DELETE_INSPECTION](
    store: InspectionsActionContext,
    inspectionId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.inspections.destroy(inspectionId));
  },

  async [ActionTypes.FETCH_INSPECTION](
    store: InspectionsActionContext,
    inspectionId: number
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.inspections.view(inspectionId)
      );
      store.commit(MutationTypes.SET_INSPECTION, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.START_INSPECTION](
    store: InspectionsActionContext,
    inspectionId: number
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.inspections.startInspection(inspectionId)
      );
      store.commit(MutationTypes.SET_INSPECTION, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CANCEL_INSPECTION](
    store: InspectionsActionContext,
    inspectionId: number
  ): Promise<void> {
    await http().get(appRoutes.api.inspections.cancel(inspectionId));
    store.commit(MutationTypes.SET_INSPECTION, null);
  },

  async [ActionTypes.CANCEL_EXTERNAL_INSPECTION](
    store: InspectionsActionContext,
    params: {
      inspectionId: number;
      qr_token: string;
      pin: null | string;
    }
  ): Promise<void> {
    await http().get(
      appRoutes.api.inspections.cancelExternalInspection(
        params.inspectionId,
        params.qr_token,
        params.pin
      )
    );
    store.commit(MutationTypes.SET_INSPECTION, null);
  },

  async [ActionTypes.EXECUTE_EXTERNAL_INSPECTION](
    store: InspectionsActionContext,
    params: {
      data: InspectionExecutionData;
      inspectionId: number;
      qr_token: string;
      pin: string | null;
    }
  ) {
    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const formData: FormData = objectToFormData(params.data);
    await http(headers).post(
      appRoutes.api.inspections.executeExternalInspection(
        params.inspectionId,
        params.qr_token,
        params.pin,
      ),
      formData
    );
    store.commit(MutationTypes.SET_INSPECTION, null);
  },

  async [ActionTypes.EXECUTE_INSPECTION](
    store: InspectionsActionContext,
    params: {
      data: InspectionExecutionData;
      id: number;
    }
  ): Promise<number | null> {
    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const formData: FormData = objectToFormData(params.data);
    const response = await http(headers).post(
      appRoutes.api.inspections.execute(params.id),
      formData
    );
    store.commit(MutationTypes.SET_INSPECTION, null);

    return !!response.data.report_id ? response.data.report_id : null;
  },

  async [ActionTypes.FETCH_INSPECTION_SETTINGS](
    store: InspectionsActionContext
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.inspections.settings());
      store.commit(MutationTypes.SET_INSPECTION_SETTINGS, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },
  async [ActionTypes.UPDATE_INSPECTION_SETTINGS](
    store: InspectionsActionContext,
    settings: InspectionSetting
  ): Promise<void> {
    await http().put(appRoutes.api.inspections.settings(), settings);
  },
};

export default actions;
