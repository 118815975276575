import {SelectOption} from "@/components/InputSelect/InputSelect";
import timezones from "timezones-list";

function timezoneOptions(): SelectOption[] {
    return timezones.map(timezone => {
        return {
            label: timezone.label,
            value: timezone.tzCode
        }
    });
}

export const TimezoneOptions = timezoneOptions();