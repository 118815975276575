import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";

@Component({
  components: {
    DatePicker,
  },
})
export default class OfficeFormInputDate extends Vue {
  @Prop({
    required: true,
    type: Date,
  })
  public value!: Date;

  @Prop({
    type: Boolean,
    required: false,
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  public error!: string;

  @Prop({
    type: String,
    required: false,
    default: "",
  })
  public label!: string;

  public lang = {
    formatLocale: {
      firstDayOfWeek: 1,
    },
    monthBeforeYear: false,
  };

  @Watch("value", { deep: true })
  handleDatePickerInput() {
    if (this.value === null) {
      this.$emit("input", this.value);
      this.$forceUpdate();
      return;
    }

    this.$emit("input", this.value);
  }
}
