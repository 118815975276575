<template>
  <div class="plan">
    <div class="plan_name">
      {{ $t("stripe.plans.name." + product.slug) }}
    </div>

    <div class="plan_information">
      <div class="plan_information_row">
        <span class="plan_information_row-text">{{
          planData?.[index]?.description
        }}</span>
      </div>
    </div>

    <div class="plan_body">
      <div class="plan_select">
        <button
          v-if="viewButton()"
          :class="{ active: productIsSelected }"
          @click="selectProduct"
        >
          {{ buttonLabel.capitalize() }}
        </button>
      </div>

      <div class="plan_subscription-privileges">
        <span
          v-for="(item, itemIndex) in planData[index].subscriptionPrivileges"
          :key="itemIndex"
          class="card-description-item"
          :class="{
            'card-description-bold':
              product.slug === 'team' || product.slug === 'enterprise',
          }"
          >{{ item }}</span
        >
      </div>
    </div>

    <div
      class="plan_price"
      v-if="product.amount && product.slug !== 'enterprise'"
    >
      <span class="plan_price_text">
        {{ $t("app.views.billing.estimatedPrice") }}
      </span>
      <div class="plan_price_select">
        <span>
          <strong>{{ price }}</strong>
        </span>
        <span class="plan_price_text">{{
          product.currency.toUpperCase()
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserProfileTabs } from "@/vuex/models/userProfile";
import i18n from "@/lang/locale";

export default {
  name: "SubscriptionPlan",
  props: {
    product: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  data() {
    return {
      planData: [
        {
          description: i18n
            .t("app.views.billing.planPro.description")
            .toString(),
          subscriptionPrivileges: [
            i18n.t("app.views.billing.planPro.inspections").toString(),
            i18n.t("app.views.billing.planPro.templatesToUse").toString(),
            i18n.t("app.views.billing.planPro.checklistBuilder").toString(),
            i18n.t("app.views.billing.planPro.scoringSystem").toString(),
            i18n.t("app.views.billing.planPro.automaticReports").toString(),
          ],
        },

        {
          description: i18n
            .t("app.views.billing.planTeam.description")
            .toString(),
          subscriptionPrivileges: [
            i18n.t("app.views.billing.planTeam.inspections").toString(),
            i18n.t("app.views.billing.planTeam.smartStatistic").toString(),
            i18n.t("app.views.billing.planTeam.rolesAndPermissions").toString(),
            i18n.t("app.views.billing.planTeam.templatesToUse").toString(),
            i18n.t("app.views.billing.planTeam.checklistBuilder").toString(),
            i18n.t("app.views.billing.planTeam.scoringSystem").toString(),
            i18n.t("app.views.billing.planTeam.automaticReports").toString(),
          ],
        },
        {
          description: i18n
            .t("app.views.billing.planEnterprise.description")
            .toString(),
          subscriptionPrivileges: [
            i18n.t("app.views.billing.planEnterprise.customAddOns").toString(),
            i18n
              .t("app.views.billing.planEnterprise.apiIntegrations")
              .toString(),
            i18n
              .t("app.views.billing.planEnterprise.individualSupport")
              .toString(),
            i18n
              .t("app.views.billing.planEnterprise.smartStatistic")
              .toString(),
            i18n
              .t("app.views.billing.planEnterprise.rolesAndPermissions")
              .toString(),
            i18n
              .t("app.views.billing.planEnterprise.templatesToUse")
              .toString(),
            i18n
              .t("app.views.billing.planEnterprise.checklistBuilder")
              .toString(),
            i18n.t("app.views.billing.planEnterprise.scoringSystem").toString(),
            i18n
              .t("app.views.billing.planEnterprise.automaticReports")
              .toString(),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["subscription", "customer", "me"]),
    price() {
      return (
        (this.product.amount / 100) *
        this.me.organisation.active_users_count
      ).toFixed(2);
    },
    productIsSelected() {
      let result = false;

      if (!!this.subscription) {
        this.subscription.items.forEach((item) => {
          if (item.product.id === this.product.id) {
            result = true;
          }
        });
      }

      return result;
    },
    buttonLabel() {
      let label;

      if (this.product.slug === "enterprise") {
        label = this.$t("components.contactUs");
      } else {
        label = this.productIsSelected
          ? this.$t("components.keep")
          : this.$t("components.select.select");

        label += " " + this.product.name;
      }

      return label;
    },
  },
  methods: {
    selectProduct() {
      this.productIsSelected
        ? this.$router.push({
            name: "settings",
            query: { tab: UserProfileTabs.BILLING },
          })
        : this.$emit("select", this.product);
    },
    viewButton() {
      if (!!this.subscription) {
        const currentPlan = this.subscription.items[0].product.slug;
        const renderedPlan = this.product.slug;

        if (currentPlan !== renderedPlan) {
          const plans = ["pro", "team", "enterprise"];
          const currentIndex = plans.indexOf(currentPlan);
          const renderedIndex = plans.indexOf(renderedPlan);

          if (currentIndex < renderedIndex) {
            return true;
          }
        }

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.plan {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 10px;
  max-width: 384px;
  width: 100%;
  height: 540px;

  display: flex;
  flex-direction: column;

  &_body {
    padding: 0 45px 0 39px;
  }

  &_subscription-privileges {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-size: 12px;
    }
  }

  .card-description-item {
    font-size: 12px;
    color: #707070;

    &:nth-child(1) {
      font-weight: bold;
    }
  }

  .card-description-bold {
    font-size: 12px;
    color: #707070;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      font-weight: bold;
    }
  }

  &_name {
    text-align: center;
    font: normal normal 600 22px/33px Poppins;
    letter-spacing: 0;
    color: #0d0e34;
    padding: 15px 0;
  }

  &_help {
    background: #f5f5f5 0 0 no-repeat padding-box;
    text-align: center;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: #6d6d6d;
    padding: 20px 40px;
    width: 100%;
    height: 75px;
  }

  &_select {
    display: flex;
    justify-content: center;
    padding: 27px 0;

    height: 102px;

    button {
      height: 48px;
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 122px;
      border: 2px solid #1de591;
      background: #1de591 0 0 no-repeat padding-box;

      cursor: pointer;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0;
      color: #ffffff;

      &:hover {
        box-shadow: 0 0 16px #00000029;
      }
    }

    .active {
      background-color: #fff;
      color: #1de591;

      &:hover {
        background-color: #1de591;
        color: #fff;
      }
    }
  }

  &_information {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    padding: 0px 41px;

    &_row {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font: normal normal normal 12px/26px Poppins;
      letter-spacing: 0;
      height: 75px;
    }
  }

  &_price {
    display: flex;
    align-items: center;
    gap: 45px;
    padding: 20px 36px;
    border-top: 1px solid #e6e6e6;
    width: 100%;
    margin-top: auto;

    &_select {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &_text {
      font: normal normal normal 12px/18px Poppins;
      letter-spacing: 0;
      color: #6d6d6d;
    }
  }
}
</style>
