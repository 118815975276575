import { GetterTree } from "vuex";
import { RootState } from "@/vuex/store";
import TemplatesState from "@/vuex/modules/templates-module/state";
import Template from "@/vuex/models/template/template";
import Meta from "@/vuex/models/meta";
import TemplateFull from "@/vuex/models/template/template-full";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { generateShortTextForTemplate } from "@/services/helpers/generateShortTextForTemplate";
import { generateTranslationForTemplateTags } from "@/services/helpers/generateTranslationForTemplateTags";
import TemplateTags from "@/vuex/models/template/template-tags";

const getters: GetterTree<TemplatesState, RootState> = {
  templates(state: TemplatesState): Template[] | [] {
    return state.templates;
  },
  templatesForCsv(state: TemplatesState): Template[] | [] {
    return state.templatesForCsv;
  },
  filtredTemplates(state: TemplatesState): Template[] | [] {
    return state.filteredTemplates;
  },
  templatesMeta(state: TemplatesState): Meta {
    return state.meta;
  },
  template(state: TemplatesState): TemplateFull | null {
    return state?.template;
  },
  templateTagsOptions(state: TemplatesState) {
    const sortedTemplateTags = state.templateTags.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return sortedTemplateTags.map((template: TemplateTags) => {
      return {
        value: template.id,
        label: generateTranslationForTemplateTags(template.name),
      };
    });
  },
  templateSelectOptions(state: TemplatesState): SelectOption[] {
    return state.filteredTemplates.map((template: Template) => {
      return {
        value: template?.id,
        label: template?.name,
        typeTemplateText: generateShortTextForTemplate(template?.access_level),
      };
    });
  },
  templateOptions(state: TemplatesState): SelectOption[] {
    console.log(state.templates, "templates getters");
    return state.templates.map((template: Template) => {
      return {
        value: template.id,
        label: template.name,
      };
    });
  },
};

export default getters;
