import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import TemplatesState from "@/vuex/modules/templates-module/state";
import { ActionTypes } from "./action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "@/vuex/modules/templates-module/mutation-types";
import objectToFormData from "@/services/http/objectToFormData";
import { AxiosRequestConfig } from "axios";
import { Direction, TemplatesSortableColumns } from "@/services/helpers/sort";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import TemplateData from "@/vuex/models/template/template-data";
import { TemplateAccessLevel } from "@/vuex/models/template/template-access-level";

export interface TemplateSearchParams {
  page: number;
  per_page: number;
  column: TemplatesSortableColumns | null;
  direction: Direction | null;
  search: string | null;
  access_level: TemplateAccessLevel | null;
  user_id: number | null;
  language_id: number | null;
  tag_id?: number | null;
}

type TemplatesActionContext = ActionContext<TemplatesState, RootState>;

const actions: ActionTree<TemplatesState, RootState> = {
  async [ActionTypes.FETCH_TEMPLATES](
    store: TemplatesActionContext,
    params: TemplateSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.templates.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_TEMPLATES, response.data.data);
      store.commit(MutationTypes.SET_TEMPLATES_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_TEMPLATES_FOR_CSV](
    store: TemplatesActionContext,
    params: TemplateSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.templates.all(), {
        params,
      });
      store.commit(MutationTypes.SET_TEMPLATES_FOR_CSV, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_TEMPLATE_TEGS](
    store: TemplatesActionContext
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.templates.templateTags());
      store.commit(MutationTypes.SET_TEMPLATE_TAGS, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_FILTERED_TEMPLATES](
    store: TemplatesActionContext,
    params: TemplateSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.templates.paginate(), {
        params,
      });
      store.commit(
        MutationTypes.SET_FILTERED_TEMPLATES,
        response.data.data.filter((el: any) => el.access_level !== "PUBLIC")
      );
      store.commit(MutationTypes.SET_TEMPLATES_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_TEMPLATE](
    store: TemplatesActionContext,
    templateId: number
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.templates.view(templateId)
      );
      store.commit(MutationTypes.SET_TEMPLATE, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CREATE_TEMPLATE](
    store: TemplatesActionContext,
    params: TemplateData
  ): Promise<void> {
    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = objectToFormData(params);
    const response = await http(headers).post(
      appRoutes.api.templates.create(),
      formData
    );

    store.commit(MutationTypes.SET_TEMPLATE, response.data);
  },

  async [ActionTypes.UPDATE_TEMPLATE](
    store: TemplatesActionContext,
    params: TemplateData
  ): Promise<void> {
    if (!params.id) {
      throw new Error('cannot update model without "id" (not saved ?)');
    }

    const headers: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = objectToFormData(params);
    formData.append("_method", "put");
    const response = await http(headers).post(
      appRoutes.api.templates.update(params.id),
      formData
    );

    store.commit(MutationTypes.SET_TEMPLATE, response.data);
  },

  async [ActionTypes.CLONE_TEMPLATE](
    store: TemplatesActionContext,
    templateId: number
  ): Promise<void> {
    const response = await http().get(
      appRoutes.api.templates.clone(templateId)
    );

    store.commit(MutationTypes.SET_TEMPLATE, response.data);
  },

  async [ActionTypes.DELETE_TEMPLATE](
    store: TemplatesActionContext,
    templateId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.templates.destroy(templateId));
  },
};

export default actions;
