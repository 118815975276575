<template>
  <div :class="'Input ' + $attrs.class" :disabled="$attrs.disabled">
    <input
    :class="{'boxShadow' : isBoxShadow }"
      @input="($event) => !$attrs.disabled && $emit('input', $event.target.value)"
      v-bind="$attrs"
      @focusin="$emit('focusin')"
      @focusout="$emit('focusout')"
      :placeholder="placeholder"
    />
    <div class="Icon Right" v-if="$slots['icon-right']">
      <slot name="icon-right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    placeholder: {
      required: false,
      default: ''
    },
    isBoxShadow: {
      required: false,
      dafault: false
    }
  },
  inheritAttrs: false,
}
</script>

<style scoped lang="scss">

.Input {
  display: flex;
  align-items: center;

  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 24px;

  &[disabled] {
    background-color: #E6E6E6;
  }

  input {
    border-radius: 24px;

    padding: 13px 20px 13px 20px;

    width: 100%;
    line-height: 20px;
    border: none;
    font-size: 12px;

    &:focus {
      outline: none;
    }
  }

  .boxShadow{
    box-shadow: 0px 0px 16px #0000000F;
  }

  &.has-error {
    border-color: #E51D1D;
  }

  &.has-warn {
    border-color: #E5881D;
  }

  .Icon {
    min-width: 18px;
    height: 18px;
    margin-right: 20px;
  }
}

</style>
