<template>
  <div class="Form">
    <loading-spinner v-if="loading" :show="loading" />

    <div class="Title">
      {{ $t("auth.resetPassword.resetPasswordAction") }}
    </div>

    <div class="Inputs">
      <p v-if="sent">
        {{ $t("auth.resetPassword.resetPasswordSent") }}
      </p>
      <input-text
        v-else
        :placeholder="$t('auth.resetPassword.email')"
        v-model="email"
        appearance="auth"
        :hasError="formErrors.hasErrors('email')"
        :error="formErrors.get('email')?.toString()"
      >
      </input-text>
    </div>

    <div class="Bottom">
      <div class="Footer footer-wrapper">
        <router-link :class="{ Button: sent, backToLogin: sent }" to="/login">
          {{ $t("auth.resetPassword.backToLogin") }}
        </router-link>
        <div class="button-wrapper">
          <div v-if="!sent" class="Button reset-link" @click="submit">
            {{ $t("auth.resetPassword.resetLink") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { FormMixin } from "@/mixins/formMixin";
import { mapActions } from "vuex";
import { ActionTypes } from "@/vuex/modules/auth-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";

@Component({
  methods: mapActions({
    sendResetPasswordEmail: ActionTypes.FORGOT_PASSWORD,
  }),
})
export default class AuthForgotPasswordView extends FormMixin {
  protected readonly sendResetPasswordEmail!: (params: {
    email: string;
    host: string;
  }) => Promise<void>;

  protected loading: boolean = false;
  protected sent: boolean = false;
  protected email: string = "";

  public async submit() {
    this.loading = true;
    this.formErrors.clearErrors();

    try {
      await this.sendResetPasswordEmail({
        email: this.email,
        host: window.location.origin,
      });
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Email has been sent.",
        type: NotificationType.SUCCESS,
      });
      this.sent = true;
    } catch (exception: any) {
      await this.formErrors.setErrors(exception.response.data.errors);
    }

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
@import "@/asesst/styles/mixins";

.Inputs {
  padding-bottom: 6px;

  p {
    text-align: center;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0;
    font-size: 12px;
    color: #3d3d3d;
  }
}

.footer-wrapper {
  flex-direction: column !important;
  align-items: start !important;
  gap: 6px;
}

.Footer {
  font-size: 14px;
  .reset-link {
    width: 274px !important;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  @include mobile {
    flex-wrap: wrap;
    row-gap: 18px;
    text-align: center;

    .button-wrapper {
      justify-content: center;
    }

    & > * {
      width: 100%;
    }
  }
}

.backToLogin {
  width: 141px !important;
  margin: 0 auto;
}
</style>