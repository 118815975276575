import { GetterTree } from "vuex";
import { RootState } from "@/vuex/store";
import InspectionsState from "@/vuex/modules/inspections-module/state";
import InspectionFull from "@/vuex/models/inspection/inspection-full";
import Meta from "@/vuex/models/meta";
import InspectionExecution from "@/vuex/models/inspection/inspection-execution";
import InspectionSetting from "@/vuex/models/inspection/inspection-setting";

const getters: GetterTree<InspectionsState, RootState> = {
  inspections(state: InspectionsState): InspectionFull[] {
    return state.inspections;
  },
  inspectionsForCsv(state: InspectionsState): InspectionFull[] {
    return state.inspectionsForCsv;
  },
  inspectionsMeta(state: InspectionsState): Meta {
    return state.meta;
  },
  inspection(state: InspectionsState): InspectionExecution | null {
    return state.inspection;
  },
  inspectionSettings(state: InspectionsState): InspectionSetting | null {
    return state.inspectionSettings;
  },
};

export default getters;
