export enum ActionTypes {
    FETCH_TEMPLATES = "templates/fetch-templates",
    FETCH_TEMPLATES_FOR_CSV = "templates/fetch-templates-for-csv",
    FETCH_FILTERED_TEMPLATES = "templates/fetch-filtered-templates",
    FETCH_TEMPLATE = "templates/fetch-template",
    CREATE_TEMPLATE = "templates/create-template",
    UPDATE_TEMPLATE = "templates/update-template",
    CLONE_TEMPLATE = "templates/clone-template",
    DELETE_TEMPLATE = "templates/delete-template",
    FETCH_TEMPLATE_TEGS = "templates/fetch-template-tags"
}
