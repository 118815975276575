import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {Direction} from "@/services/helpers/sort";

@Component({
  computed: {
    Direction() {
      return Direction
    }
  }
})
export default class SortableTh extends Vue {
  @Prop({
    required: false,
    default: Direction.ASC
  })
  public direction!: Direction | null;

  @Prop({
    required: true,
    type: Boolean
  })
  public sorted!: boolean;
}


