import {Module} from "vuex";
import {RootState} from "../../store";
import actions from './actions';
import mutations from "./mutations";
import getters from "./getters";
import OrganisationRequestsState from "@/vuex/modules/organisation-requests-module/state";

const module: Module<OrganisationRequestsState, RootState> = {
    state: new OrganisationRequestsState(),
    actions,
    mutations,
    getters
}

export default module;
