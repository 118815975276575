<template>
  <div class="subscription-table">
    <div class="expire-notification" v-if="showExpiredNotification">
      <AttentionSign style="color: red" />
      <div class="expire-notification_days-left">
        <span>
          <strong v-if="!!customer && customer.on_trial">
            {{ $t("app.views.billing.trialExpired", [trialDaysLeft]) }}
          </strong>
          <strong v-if="!!subscription && !!subscription.cancel_at">
            {{ $t("app.views.billing.gracePeriod", [subscriptionDaysLeft]) }}
          </strong>
        </span>
        <span v-if="!!subscription && !!subscription.cancel_at">
          {{ $t("app.views.billing.willBeCanceled", [subscription.cancel_at]) }}
        </span>
        <span v-else>
          {{ $t("app.views.billing.canContinue") }}
        </span>
      </div>
    </div>
    <div class="expire-notification" v-if="subscriptionExpired">
      <AttentionSign style="color: red" />
      <div class="expire-notification_days-left">
        <span>
          <strong>
            {{ $t("app.views.billing.isExpiredOnTrialDate") }}
          </strong>
        </span>
        <span>
          {{ $t("app.views.billing.selectPlanToContinue") }}
        </span>
      </div>
    </div>
    <records-table :rows="rows">
      <template v-slot:thead>
        <tr class="table_thead_tr">
          <th width="20%">{{ $t("components.table.plan") }}</th>
          <th>{{ $t("components.table.users") }}</th>
          <th>{{ $t("components.table.priceEstimate") }}</th>
          <th>{{ $t("components.table.billingFrequency") }}</th>
          <th>
            {{
              showStatus
                ? $t("components.table.status")
                : $t("components.table.nextBillDate")
            }}
          </th>
          <th width="20px"></th>
        </tr>
      </template>
      <template v-if="!!subscription" v-slot:tbody>
        <tr v-for="item in subscription.items">
          <td>{{ item.product.name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ showPrice(item) }}</td>
          <td>{{ showInterval(item) }}</td>
          <td>{{ endDate }}</td>
          <td v-if="showDropMenu">
            <table-row-drop-down-menu>
              <template v-slot:content>
                <span
                  class="controls-dropdown-button"
                  @click="$router.push({ name: 'cancel-subscription' })"
                >
                  {{ $t("components.cancel").capitalize() }}
                </span>
              </template>
            </table-row-drop-down-menu>
          </td>
        </tr>
      </template>
      <template v-else v-slot:tbody>
        <tr>
          <td>Trial</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>{{ endDate }}</td>
        </tr>
      </template>
    </records-table>
  </div>
</template>

<script>
import DateToString from "@/services/dateToString/dateToString";
import { mapGetters } from "vuex";
import AttentionSign from "@/components/AttentionSign.vue";

export default {
  name: "SubscriptionTable",
  emits: ["loading"],
  components: { AttentionSign },
  data() {
    return {
      dateToStringConverter: new DateToString(),
    };
  },
  computed: {
    ...mapGetters(["customer", "subscription", "me"]),
    rows() {
      return !!this.subscription ? this.subscription.items : [1];
    },
    endDate() {
      if (!!this.customer && this.customer.on_trial) {
        const date = new Date(this.customer.trial_end_date);
        return this.dateToStringConverter.getLocaleString(date, [
          this.dateToStringConverter.LONG_DATE,
        ]);
      }

      if (!!this.subscription) {
        const date = new Date(this.subscription.current_period_end);
        return this.dateToStringConverter.getLocaleString(date, [
          this.dateToStringConverter.LONG_DATE,
        ]);
      }

      return this.$t("app.views.billing.canceled");
    },
    showStatus() {
      return (
        !this.subscription ||
        (!!this.customer &&
          !this.customer.on_trial &&
          !this.customer.has_active_subscription)
      );
    },
    showExpiredNotification() {
      return (
        (!!this.subscription && !!this.subscription.cancel_at) ||
        (!!this.customer && this.customer.on_trial)
      );
    },
    trialDaysLeft() {
      if (!!this.customer) {
        const today = new Date();
        const trialEnd = new Date(this.customer.trial_end_date);

        return this.subDates(today, trialEnd);
      }

      return 0;
    },
    subscriptionDaysLeft() {
      if (!!this.subscription && !!this.subscription.cancel_at) {
        const today = new Date();
        const cancelAt = new Date(this.subscription.cancel_at);

        return this.subDates(today, cancelAt);
      }

      return 0;
    },
    showDropMenu() {
      if (!!this.subscription) {
        return (
          this.subscription.items.length < 2 &&
          null === this.subscription.cancel_at
        );
      }

      return false;
    },

    subscriptionExpired() {
      if (!!this.me && !!this.me.stripe_customer) {
        return (
          !this.me.stripe_customer.on_trial &&
          !this.me.stripe_customer.has_active_subscription
        );
      }
      return false;
    },
  },
  methods: {
    subDates(start, end) {
      const timeDifferenceMs = end.getTime() - start.getTime();
      const millisecondsInOneDay = 24 * 60 * 60 * 1000; // Convert milliseconds to the desired units (days, hours, etc.)

      return Math.floor(timeDifferenceMs / millisecondsInOneDay);
    },
    showPrice(item) {
      const price = ((item.product.amount * item.quantity) / 100).toFixed(2);

      return price.toString() + " " + item.product.currency?.toUpperCase();
    },
    showInterval(item) {
      return (
        item.product.recurring_interval_count +
        " " +
        item.product.recurring_interval
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.expire-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  padding: 20px 30px;
  background: rgba(255, 81, 81, 0.09) 0 0 no-repeat padding-box;
  border-radius: 10px;
  width: 100%;

  &_days-left {
    display: flex;
    flex-direction: column;
    gap: 2px;

    span {
      text-align: left;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0;
      color: #0d0e34;
    }
  }
}
</style>