import Meta from "@/vuex/models/meta";
import Report from "@/vuex/models/report/report";
import ReportFull from "@/vuex/models/report/report-full";

export default class ReportsState {
  public reports: Report[] = [];
  public reportsForCsv: Report[] = [];
  public meta!: Meta;
  public report: ReportFull | null = null;
  public pdf!: string;
}
