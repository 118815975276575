<template>
  <div class="options">
    <span
      :class="{ selectedFilter: dateFilter === 'today' }"
      @click="toggleToday"
    >
      {{ $t("components.durations.today") }}
    </span>
    <span
      :class="{ selectedFilter: dateFilter === 'week' }"
      @click="toggleWeek"
    >
      {{ $t("components.durations.week") }}
    </span>

    <div class="month_switch">
      <div v-if="openCalendar" class="month-wrapper" ref="monthWrapper">
        <date-picker
          v-model="dataPickerValue"
          :inline="true"
          type="month"
          :lang="lang"
          @input="changeDateInPicker"
        ></date-picker>
      </div>

      <span @click="toggleMonth(-1)" class="arrow_icon">
        <img src="/images/icons/arrow-left.svg" alt="arrow-left" />
      </span>
      <span
        :class="{ selectedFilter: dateFilter === 'month' }"
        @click.stop="openCalendar = true"
      >
        {{ montFilterDateDisplay }}
      </span>
      <span @click="toggleMonth(1)" class="arrow_icon">
        <img src="/images/icons/arrow-right.svg" alt="arrow-right" />
      </span>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";

export default {
  components: { DatePicker },
  name: "DateFilter",
  props: {
    startDate: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      openCalendar: false,
      dateFilter: "month",
      dataPickerValue: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    };
  },

  computed: {
    monthFilterReferenceDate() {
      return this.startDate ? new Date(this.startDate) : new Date();
    },
    montFilterDateDisplay() {
      const months = {
        0: this.$t("components.months.january"),
        1: this.$t("components.months.february"),
        2: this.$t("components.months.march"),
        3: this.$t("components.months.april"),
        4: this.$t("components.months.may"),
        5: this.$t("components.months.june"),
        6: this.$t("components.months.july"),
        7: this.$t("components.months.august"),
        8: this.$t("components.months.september"),
        9: this.$t("components.months.october"),
        10: this.$t("components.months.november"),
        11: this.$t("components.months.december"),
      };

      return (
        months[this.monthFilterReferenceDate.getMonth()] +
        " " +
        this.monthFilterReferenceDate.getFullYear()
      );
    },
  },
  created() {
    this.toggleMonth(0);
  },

  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  },

  methods: {
    handleDocumentClick(event) {
      const monthWrapper = this.$refs.monthWrapper;

      if (monthWrapper && !monthWrapper.contains(event.target)) {
        this.closeCalendar();
      }
    },

    closeCalendar() {
      this.openCalendar = false;
    },

    toggleToday() {
      this.dateFilter = "today";

      this.openCalendar = false;
      this.dataPickerValue = new Date();
      this.setSearchDates(new Date(), new Date());
    },
    toggleWeek() {
      this.dateFilter = "week";

      this.dataPickerValue = new Date();
      this.openCalendar = false;
      const inWeekTime = new Date();
      inWeekTime.setDate(inWeekTime.getDate() - 6);

      this.setSearchDates(inWeekTime, new Date());
    },
    toggleMonth(index) {
      this.dateFilter = "month";

      const referenceDate = this.monthFilterReferenceDate;
      this.dataPickerValue = this.monthFilterReferenceDate;
      this.openCalendar = false;
      referenceDate.setMonth(referenceDate.getMonth() + index);

      const start = new Date(
        referenceDate.getFullYear(),
        referenceDate.getMonth(),
        1,
        12
      );
      const end = new Date(
        referenceDate.getFullYear(),
        referenceDate.getMonth() + 1,
        0,
        12
      );

      this.setSearchDates(start, end);
    },
    changeDateInPicker(value) {
      this.dateFilter = "month";
      this.openCalendar = false;

      const referenceDate = new Date(value);
      const start = new Date(
        referenceDate.getFullYear(),
        referenceDate.getMonth(),
        1,
        12
      );
      const end = new Date(
        referenceDate.getFullYear(),
        referenceDate.getMonth() + 1,
        0,
        12
      );

      this.setSearchDates(start, end);
    },
    setSearchDates(start, end) {
      this.$emit("select", {
        start: start,
        end: end,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  font-size: 12px;

  span {
    color: #3d3d3d;
    cursor: pointer;
    white-space: nowrap;
  }

  span:hover {
    color: #1de591;
    text-decoration: underline;
  }

  & .month_switch {
    position: relative;
    display: flex;
    align-items: center;
    gap: 32px;

    .month-wrapper {
      position: absolute;
      top: 28px;
      left: -10px;
      z-index: 22;
    }

    .selectedFilter {
      width: 98px;
      text-align: center;
    }

    .arrow_icon {
      width: 9px;
      height: 100%;

      display: flex;
      align-items: center;

      img {
        height: 1em;
      }
    }
  }
}

.selectedFilter {
  color: #1de591 !important;
}
</style>