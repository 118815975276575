import InspectionFull from "@/vuex/models/inspection/inspection-full";
import Meta from "@/vuex/models/meta";
import InspectionExecution from "@/vuex/models/inspection/inspection-execution";
import InspectionSetting from "@/vuex/models/inspection/inspection-setting";

export default class InspectionsState {
    public inspections: InspectionFull[] = [];
    public inspectionsForCsv: InspectionFull[] = [];
    public meta!: Meta;
    public inspection: InspectionExecution | null = null;
    public inspectionSettings: InspectionSetting | null = null;
}
