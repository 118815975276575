import ExternalAccessState from "./state";
import { GetterTree } from "vuex";
import { RootState } from "../../store";
import ExternalAccess from "@/vuex/models/external-access/external-access";
import Meta from "@/vuex/models/meta";
import { SelectOption } from "@/components/InputSelect/InputSelect.vue";

const getters: GetterTree<ExternalAccessState, RootState> = {
  externalAccessItems(state: ExternalAccessState): Array<ExternalAccess> | [] {
    return state.items;
  },
  externalAccessMeta(state: ExternalAccessState): Meta {
    return state.meta;
  },
  externalAccessOptions(state: ExternalAccessState): SelectOption[] {
    return state.items.map((item: ExternalAccess) => {
      return {
        value: item.id,
        label: item.name
      }
    });
  },
};

export default getters;
