import { GetterTree } from "vuex";
import ReportsState from "@/vuex/modules/reports-module/state";
import { RootState } from "@/vuex/store";
import Report from "@/vuex/models/report/report";
import Meta from "@/vuex/models/meta";
import ReportFull from "@/vuex/models/report/report-full";

const getters: GetterTree<ReportsState, RootState> = {
  reports(state: ReportsState): Report[] {
    return state.reports;
  },
  reportsForCsv(state: ReportsState): Report[] {
    return state.reportsForCsv;
  },
  reportsMeta(state: ReportsState): Meta {
    return state.meta;
  },
  report(state: ReportsState): ReportFull | null {
    return state.report;
  },
  reportPdf(state: ReportsState): any {
    return state.pdf;
  },
};

export default getters;
