import { Module } from "vuex";
import { RootState } from "../../store";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import QRCodeState from "./state";

const module: Module<QRCodeState, RootState> = {
  state: new QRCodeState(),
  actions,
  getters,
  mutations,
};

export default module;
