<template>
  <LoadingSpinner v-if="loading" :show="loading"/>
  <div v-else class="enterprise-plan">
    <BillingForm
        :save-label="$t('components.send').capitalize()"
        @back="$router.back()"
        @save="requestEnterprisePlan"
    >
      <template slot="form">
        <InputText
            :label="$t('app.views.billing.address.company').toString()"
            type="text"
            v-model="request.company_name"
        ></InputText>
        <InputSelect
            :options="countries"
            :label="$t('components.form.country').toString()"
            v-model="request.country"
        ></InputSelect>
        <InputText
            :label="$t('app.views.billing.usersYouNeed').toString()"
            type="number"
            v-model="request.users"
        ></InputText>
        <div class="user_details">
          <InputText
              :label="$t('components.form.name').toString()"
              type="text"
              v-model="request.name"
          ></InputText>
          <InputText
              :label="$t('auth.resetPassword.email').toString()"
              type="email"
              v-model="request.email"
          ></InputText>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {UserProfileTabs} from "@/vuex/models/userProfile";

export default {
  name: "EnterprisePlanForm",
  components: {BillingForm},
  data() {
    return {
      loading: false,
      request: {
        company_name: null,
        country: null,
        users: null,
        name: null,
        email: null
      },
      countries: []
    }
  },
  async created() {
    this.loading = true;
    this.$parent.title = 'Enterprise plan';
    await this.fetchCountries();

    this.loading = false;
  },
  methods: {
    async fetchCountries() {
      const response = await this.$store.dispatch(ActionTypes.FETCH_COUNTRIES_LIST);
      this.countries = response.data.data.map(country => {
        return {
          label: country.name,
          value: country.value
        }
      });
    },
    async requestEnterprisePlan() {
      this.loading = true;
      try {
        await this.$store.dispatch(ActionTypes.REQUEST_ENTERPRISE, this.request);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: 'Enterprise plan has been requested.',
          type: NotificationType.SUCCESS
        });
        await this.$router.push({name: 'settings', query: {tab: UserProfileTabs.BILLING}});
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER
        });
      }

      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.enterprise-plan {
  display: flex;
  justify-content: center;
}

.user_details {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
  max-width: calc(100% - 20px);
}
</style>