import {ActionContext, ActionTree} from "vuex";
import NotificationsState, {Notification} from "@/vuex/modules/notifications-module/state";
import {RootState} from "@/vuex/store";
import {ActionTypes} from "./action-types"
import {MutationTypes} from "./mutation-types";

type NotificationsActionContext = ActionContext<NotificationsState, RootState>;


const actions: ActionTree<NotificationsState, RootState> = {
    [ActionTypes.SHOW_NOTIFICATIONS](store: NotificationsActionContext, params?: any): void {
        store.commit(MutationTypes.TOGGLE_FLUSH_NOTIFICATIONS, true);
    },

    [ActionTypes.PUSH_NOTIFICATION](store: NotificationsActionContext, notification: Notification): void {
        store.commit(MutationTypes.ADD_NOTIFICATION, notification);

        store.commit(MutationTypes.TOGGLE_FLUSH_NOTIFICATIONS, true);
    }
}

export default actions;
