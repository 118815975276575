var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.isDiv ? 'div' : 'button',{tag:"button",class:{
          'flex-reverse': _vm.isReverse,
          'big-confirm': _vm.appearance === _vm.availableStyles.BIG_CONFIRM,
          'big-cancel': _vm.appearance === _vm.availableStyles.BIG_CANCEL,
          'round': _vm.appearance === _vm.availableStyles.ROUND,
          'button': true,
      },attrs:{"disabled":_vm.disabled},on:{"click":() => { if(!_vm.disabled) {_vm.$emit('click')}}}},[_vm._t("alert"),_vm._t("icon"),_vm._t("text")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }