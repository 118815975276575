import {TemplateIncidentLevel} from "@/vuex/models/template/template-incident-level";

export const NOT_APPLICABLE_OPTION: string = 'notApplicable';

export default interface TemplateAnswerOption {
    id: number | null;
    answer_option: string;
    order: number;
    score_points: number | null;
    incident_level: TemplateIncidentLevel | null;
}
