import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import Meta from "@/vuex/models/meta";
import IncidentsState from "@/vuex/modules/incidents-module/state";
import Incident from "@/vuex/models/incident/incident";
import IncidentsTypes from "@/vuex/models/incident/incidentTypes";

const mutations: MutationTree<IncidentsState> = {
  [MutationTypes.SET_INCIDENTS](
    state: IncidentsState,
    incidents: Incident[]
  ): void {
    state.incidents = incidents;
  },
  [MutationTypes.SET_INCIDENTS_FOR_CSV](
    state: IncidentsState,
    incidents: Incident[]
  ): void {
    state.incidentsForCsv = incidents;
  },
  [MutationTypes.SET_INCIDENTS_TYPES](
    state: IncidentsState,
    incidentsTypes: IncidentsTypes[]
  ): void {
    state.incidentsTypes = incidentsTypes;
  },
  [MutationTypes.SET_INCIDENTS_TYPES_FOR_FILTER](
    state: IncidentsState,
    incidentsTypes: IncidentsTypes[]
  ): void {
    state.incidentsTypesForFilter = incidentsTypes;
  },
  [MutationTypes.SET_INCIDENTS_META](state: IncidentsState, meta: Meta): void {
    state.meta = meta;
  },
  [MutationTypes.SET_INCIDENTS_COUNT](
    state: IncidentsState,
    count: number
  ): void {
    state.count = count;
  },
};

export default mutations;
