<template>
  <div class="Form">
    <div class="Title">
      {{ $t('auth.register.requestToJoinOrganisation') }}
    </div>
    <p class="ConfirmAttentionText">
      {{ $t('auth.register.requestNotification') }}
    </p>

    <div class="Bottom">
      <div class="Footer">
        <button class="Button" @click="logout">
          {{ $t('auth.login.signOut') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {ActionTypes as AuthActions} from "@/vuex/modules/auth-module/action-types";
import {MutationTypes as AuthMutations} from "@/vuex/modules/auth-module/mutation-types";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";

export default {
  name: "AuthOrganisationRequested",
  methods: {
    async logout() {
      try {
        await this.$store.dispatch(AuthActions.LOGOUT);
        this.$store.commit(AuthMutations.CLEAR_TOKEN);
        this.$store.commit(AuthMutations.CLEAR_ME);
        await this.$router.push({name: 'login'});
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ConfirmAttentionText {
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;
  font-size: 12px;
  color: #3D3D3D;
}

.Footer {
  margin-top: 20px;
  justify-content: center !important;
}

.Button {
  width: 35% !important;
  height: 48px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>