import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import InputText from "@/components/InputText/InputText.vue";
import TemplateQuestion from "@/vuex/models/template/template-question";
import InspectionAnswer from "@/vuex/models/inspection/inspection-answer";
import {TemplateQuestionType} from "@/vuex/models/template/template-question-type";
import {GoogleMapService} from "@/services/googleMap/googleMap";
import VueSignaturePad from 'vue-signature-pad';
import {SignaturePadService} from "@/services/signaturePad/signaturePad";

Vue.use(VueSignaturePad)

@Component({
    components: {InputText}
})
export default class ComplexAnswer extends Vue {
    @Prop({
        required: true
    })
    public question!: TemplateQuestion;

    @Prop({
        required: true
    })
    public answer!: InspectionAnswer;

    protected readonly questionType = TemplateQuestionType;
    protected readonly googleMapService: GoogleMapService = new GoogleMapService();
    protected readonly signaturePadService: SignaturePadService = new SignaturePadService();

    protected map: any = null;
    protected googleMapValue: { lng: number | null, lat: number | null, address: string | null } = {
        lng: null,
        lat: null,
        address: null
    };

    protected signatureValue: { name: string | null, signature: string | null } = {
        name: null,
        signature: null
    }

    protected get placeholder() {
        return this.$t('components.form.userName') + ' ' + this.$t('components.and') + ' ' + this.$t('app.views.user.surname')
    }

    protected created(): void {
        if (!!this.answer.answer) {
            if (this.question.type.slug === this.questionType.GEOLOCATION) {
                this.googleMapValue = this.answer.answer;
            }

            if (this.question.type.slug === this.questionType.SIGNATURE) {
                this.signatureValue = this.answer.answer;
            }
        }
    }

    protected setValue(value: any): void {
        this.$emit('setAnswer', value);
    }

    protected async mounted(): Promise<void> {
        if (this.question.type.slug === this.questionType.GEOLOCATION) {
            this.googleMapService.setCoordinates(Number(this.googleMapValue?.lat), Number(this.googleMapValue?.lng));
            this.map = await this.googleMapService.setGoogleMap(document.getElementById("map"));
        }

        if (this.question.type.slug === this.questionType.SIGNATURE) {
            this.signaturePadService.setSignaturePad(this.$refs.signaturePad);
            if (!!this.signatureValue.signature) {
                this.signaturePadService.setSignature(this.signatureValue.signature);
            }
        }
    }

    @Watch('googleMapService.address')
    protected setGoogleMavValue(): void {
        this.googleMapValue.address = this.googleMapService.getAddress();
        this.googleMapValue.lat = this.googleMapService.getCoordinates().lat;
        this.googleMapValue.lng = this.googleMapService.getCoordinates().lng;

        this.setValue(this.googleMapValue);
    }

    @Watch('signatureValue', {deep: true})
    protected setSignatureValue(): void {
        if (this.signatureValue.signature !== null && this.signatureValue.name !== null) {
            this.setValue(this.signatureValue);
        } else {
            this.setValue(null);
        }
    }

    protected addSignature(): void {
        this.signaturePadService.addSignature();
        this.signatureValue.signature = this.signaturePadService.getSignature();
    }

    protected clearSignature(): void {
        this.signaturePadService.clearSignature();
        this.signatureValue.signature = null;
    }
}
