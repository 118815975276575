import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../store";
import ExternalAccessState from "./state";
import { ActionTypes } from "./action-types";
import { http } from "@/services/http/http";
import { appRoutes } from "@/config";
import { MutationTypes } from "./mutation-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { Direction, AccessSortableColumns } from "@/services/helpers/sort";
import ExternalAccessData from "@/vuex/models/external-access/external-access";

type ExternalAccessContext = ActionContext<ExternalAccessState, RootState>;

export interface ExternalAccessSearchParams {
  page: number | 1;
  per_page: number | 25;
  column: AccessSortableColumns | null;
  direction: Direction | null;
  search: string | null;
  organisation_id: number | null;
}

const actions: ActionTree<ExternalAccessState, RootState> = {
  async [ActionTypes.GET_EXTERNAL_ACCESSES](
    store: ExternalAccessContext
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.externalAccess.index(), {});
      store.commit(MutationTypes.SET_EXTERNAL_ACCESSES, response.data.data);
      store.commit(MutationTypes.SET_EXTERNAL_ACCESSES_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_EXTERNAL_ACCESSES](
    store: ExternalAccessContext,
    params: ExternalAccessSearchParams
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.externalAccess.paginate(), {
        params,
      });
      store.commit(MutationTypes.SET_EXTERNAL_ACCESSES, response.data.data);
      store.commit(MutationTypes.SET_EXTERNAL_ACCESSES_META, response.data.meta);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    externalAccess: ExternalAccessData
  ): Promise<ExternalAccessData> {
    if (!externalAccess.id) {
      throw new Error("cannot get model without id");
    }

    const response = await http().get(appRoutes.api.externalAccess.view(externalAccess.id), {});

    return response.data;
  },

  async [ActionTypes.CREATE_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    externalAccess: ExternalAccessData
  ): Promise<ExternalAccessData> {
    const response = await http().post(appRoutes.api.externalAccess.create(), {
      ...externalAccess,
    });

    return response.data;
  },

  async [ActionTypes.UPDATE_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    externalAccess: ExternalAccessData
  ): Promise<ExternalAccessData> {
    if (!externalAccess.id) {
      throw new Error("cannot update model without id (not saved ?)");
    }

    const response = await http().put(appRoutes.api.externalAccess.update(externalAccess.id), {
      ...externalAccess,
    });

    return response.data;
  },

  async [ActionTypes.DELETE_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    externalAccessId: number
  ): Promise<void> {
    await http().delete(appRoutes.api.externalAccess.destroy(externalAccessId));
  },

  async [ActionTypes.CHANGE_STATUS_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    params: {
      externalAccessId: number;
      status: string;
    }
  ): Promise<void> {
    try {
      const response = await http().put(appRoutes.api.externalAccess.changeStatus(params.externalAccessId), params);
      store.commit(MutationTypes.SET_EXTERNAL_ACCESS, response.data.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.SEND_EMAIL_EXTERNAL_ACCESS](
    store: ExternalAccessContext,
    params: { externalAccessId: number; email: string }
  ): Promise<void> {
    await http().get(appRoutes.api.externalAccess.sendEmail(params.externalAccessId), {
      params,
    });
  },
};

export default actions;
