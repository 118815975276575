import Modal from "@/components/Modal/Modal.vue";
import Field from "@/components/Field/Field.vue";

import BoardData from "@/vuex/models/statistics/board";

import { Component, Prop } from "vue-property-decorator";
import { FormMixin } from "@/mixins/formMixin";
import { http } from "@/services/http/http";

@Component({
  components: {
    Field,
    Modal,
  },
})
export default class EditStatisticBoardModal extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public modal!: boolean;

  @Prop({
    required: true,
    type: Object,
  })
  public updateBoardData!: BoardData;

  private async updateBoardHandler() {
    try {
      const { data: board } = await http().put(
        `/api/statistics/boards/${this.updateBoardData.id}`,
        {
          name: this.updateBoardData.name,
        }
      );
      await this.$emit("updateBoard", board.id);
      this.formErrors.clearErrors();
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }
}
