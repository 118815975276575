import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import InspectionCalendarForm from "@/views/InspectionsView/components/InspectionCalendarForm/InspectionCalendarForm.vue";
import { PageMixin } from "@/mixins/pageMixin";
import { ActionTypes as InspectionActions } from "@/vuex/modules/inspections-module/action-types";
import { InspectionsSearchParams } from "@/vuex/modules/inspections-module/actions";
import {
  Direction,
  InspectionsSortableColumns,
  ReportsSortableColumns,
} from "@/services/helpers/sort";
import InspectionFull from "@/vuex/models/inspection/inspection-full";
import DateToString from "@/services/dateToString/dateToString";
import { ActionTypes as ReportActions } from "@/vuex/modules/reports-module/action-types";
import { ReportsSearchParams } from "@/vuex/modules/reports-module/actions";
import Report from "@/vuex/models/report/report";
import Location from "@/vuex/models/location/location";
import CreateIncidentModal from "@/views/Incidents/components/CreateIncidentModal/CreateIncidentModal.vue";
import { StatisticsShortSearchParams } from "@/vuex/modules/statistics-module/actions";
import StatisticalCells from "@/components/StatisticalCells/StatisticalCells.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import ChartLine from "@/components/Charts/ChartLine/ChartLine.vue";
import ChartLayoutForFilters from "@/components/ChartLayoutForFilters/ChartLayoutForFilters.vue";
import ChartDoughnut from "@/components/ChartDoughnut/ChartDoughnut.vue";
import DefaultStatisticsCounters from "@/components/DefaultStatisticsCounters/DefaultStatisticsCounters.vue";
import InputSelectLocation from "@/components/InputSelectLanguage/InputSelectLanguage.vue";
import ChartDataLayout from "@/components/ChartDataLayout/ChartDataLayout.vue";
import { http } from "@/services/http/http";
import { TDefaultCounters } from "@/components/DefaultStatisticsCounters/types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import TabList from "./components/TabList/TabList.vue";

@Component({
  methods: mapActions({
    fetchInspections: InspectionActions.FETCH_INSPECTIONS,
    fetchReports: ReportActions.FETCH_REPORTS,
  }),
  computed: mapGetters({
    inspections: "inspections",
    reports: "reports",
    locations: "locations",
  }),
  components: {
    ChartDataLayout,
    InputSelectLocation,
    ButtonWithIcon,
    StatisticalCells,
    DefaultStatisticsCounters,
    ChartLine,
    ChartDoughnut,
    ChartLayoutForFilters,
    InspectionCalendarForm,
    CreateIncidentModal,
    TabList,
  },
})
export default class DashboardView extends PageMixin {
  protected readonly fetchInspections!: (
    params: InspectionsSearchParams
  ) => Promise<void>;
  protected readonly fetchReports!: (
    params: ReportsSearchParams
  ) => Promise<void>;

  protected readonly dateToString: DateToString = new DateToString();
  protected readonly inspections!: InspectionFull[];
  protected readonly reports!: Report[];
  protected readonly locations!: Location[];
  protected countInspectionChartData: any = [];
  protected solvedInspectionChartData: any = [];
  protected inspectionArray: InspectionFull[] = [];

  protected showInspectionForm: boolean = false;
  protected isActionIncidentModal: boolean = false;
  protected showTabList: boolean = false;
  protected innerWidth: number = window?.innerWidth;

  protected inspectionsSearchParams: InspectionsSearchParams = {
    page: 1,
    per_page: 5,
    column: InspectionsSortableColumns.DATE,
    direction: Direction.ASC,
    search: null,
    location_id: null,
    from_date: null,
    to_date: null,
    is_mine: null,
    is_ended: 0,
    can_start: 1,
    external_access: null,
  };

  protected defaultCounters: TDefaultCounters = {
    made_inspections: 0,
    solved_incidents: 0,
    not_solved_incidents: 0,
    avg_score: 0,
  };

  protected chartDataDoughnut = {};

  generateChartDataDoughnut(data: { solved: number; unsolved: number }) {
    this.chartDataDoughnut = {
      datasets: [
        {
          backgroundColor: ["#DEDEDE", "#1DE591"],
          data: [data.unsolved, data.solved],
          borderWidth: 2,
        },
      ],
    };
  }

  async getCountInspectionData({ startDate, endDate }: any) {
    try {
      const response = await http().get(
        `/api/statistics/charts/count-of-inspections`,
        {
          params: {
            start_date: startDate.toDate(),
            end_date: endDate.toDate(),
          },
        }
      );
      this.countInspectionChartData = response.data.dataset;
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  }

  async getSolvedInspectionData({ startDate, endDate }: any) {
    try {
      const response = await http().get(
        `/api/statistics/charts/solved-incidents`,
        {
          params: {
            start_date: startDate.toDate(),
            end_date: endDate.toDate(),
          },
        }
      );

      this.solvedInspectionChartData = response.data.dataset;
      this.generateChartDataDoughnut(response.data.dataset);
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  }

  protected reportsSearchParams: ReportsSearchParams = {
    page: 1,
    per_page: 5,
    column: ReportsSortableColumns.CREATED_AT,
    direction: Direction.DESC,
    search: null,
    location_id: null,
    is_mine: null,
  };

  protected statisticsSearchParams: StatisticsShortSearchParams = {
    location_id: null,
    from_date: null,
    to_date: null,
  };

  protected async created(): Promise<void> {
    if (!this.auth.subscriptionExpired(this.me)) {
      await this.getDefaultCounters();
      if (this.auth.isInspector(this.me)) {
        await this.getInspections(true);
        await this.getReports(true);
      } else {
        await this.getInspections(false);
        await this.getReports(false);
      }
    }
  }

  protected showInspectionFormHandler() {
    this.showInspectionForm = true;
    this.showTabList = false;
  }

  protected isActionIncidentModalHandler() {
    this.isActionIncidentModal = true;
    this.showTabList = false;
  }

  protected closeActionIncidentModal(submited: boolean) {
    this.isActionIncidentModal = false;
  }

  protected async getInspections(mine: boolean): Promise<void> {
    this.loading = true;
    if (this.inspectionsSearchParams.is_mine !== mine) {
      this.inspectionArray = [];
      this.inspectionsSearchParams.page = 1;
    }
    this.inspectionsSearchParams.is_mine = mine ? 1 : 0;
    await this.fetchInspections(this.inspectionsSearchParams);
    this.loading = false;
  }

  protected async getReports(mine: boolean): Promise<void> {
    this.loading = true;
    this.reportsSearchParams.is_mine = mine ? 1 : 0;
    await this.fetchReports(this.reportsSearchParams);
    this.loading = false;
  }

  async getDefaultCounters() {
    this.loading = true;
    try {
      const response = await http().get(`/api/statistics/counters/default`);
      this.defaultCounters = response.data;
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    } finally {
      this.loading = false;
    }
  }

  protected async addNewReports() {
    this.loading = true;

    this.reportsSearchParams.page = this.reportsSearchParams.page + 1;

    await this.fetchReports(this.reportsSearchParams);

    this.loading = false;
  }

  protected async addNewInspections() {
    this.loading = true;

    this.inspectionsSearchParams.page = this.inspectionsSearchParams.page + 1;

    await this.fetchInspections(this.inspectionsSearchParams);

    this.loading = false;
  }

  protected async closeInspectionForm(submitted: boolean): Promise<void> {
    this.showInspectionForm = false;

    if (submitted) {
      await this.getInspections(true);
    }
  }

  @Watch("inspections", { immediate: true, deep: true })
  addNewInspectionsInArray(newInspections: InspectionFull[]) {
    const updatedInspectionsArray = [
      ...this.inspectionArray,
      ...newInspections,
    ];

    this.inspectionArray = updatedInspectionsArray;
  }

  protected getOverdueDays(date: Date): number {
    let diff: number = new Date().getTime() - date.getTime();

    return Math.floor(diff / 1000 / 60 / 60 / 24);
  }
}
