<template>
  <div class="app-settings">
    <div class="app-settings_language">
      <Field :label="$t('app.views.user.applicationLanguage')">
        <input-select
          :options="languages"
          :value="languageId"
          @select="languageId = $event"
        />
      </Field>
    </div>

    <div class="app-settings_mode">
      <Field :label="$t('app.views.user.applicationMode')">
        <input-select
          :options="themes"
          :value="theme"
          @select="theme = $event"
        />
      </Field>
    </div>

    <div class="app-settings_action">
      <ButtonsSaveCancel
        :dont-show-close="true"
        :save-label="$t('components.save')"
        @save="submit"
      ></ButtonsSaveCancel>
    </div>
  </div>
</template>

<script>
import Field from "@/components/Field/Field.vue";
import { mapGetters } from "vuex";
import { ActionTypes as LanguageActions } from "@/vuex/modules/languages-module/action-types";
import { ActionTypes as BrowserActions } from "@/vuex/modules/browser-module/action-types";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { ActionTypes } from "@/vuex/modules/profile-module/action-types";

export default {
  name: "InterfaceTab",
  emits: ["loading"],
  components: { ButtonsSaveCancel, Field },
  data() {
    return {
      languageId: null,
      theme: null,
      themes: [
        {
          label: this.$t("app.views.user.applicationModeLight"),
          value: "theme-light",
        },
        {
          label: this.$t("app.views.user.applicationModeDark"),
          value: "theme-dark",
        },
        {
          label: this.$t("app.views.user.applicationModeDeviceDefault"),
          value: "theme-default",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      languages: "languageSelectOptions",
      me: "me",
    }),
  },
  async created() {
    this.$emit("loading");
    await this.$store.dispatch(LanguageActions.FETCH_LANGUAGES);
    this.languageId = this.me.language_id;
    this.theme = this.me.theme;
    this.$emit("loading");
  },
  methods: {
    async submit() {
      this.$emit("loading");

      try {
        await this.$store.dispatch(ActionTypes.UPDATE_SETTINGS, {
          language_id: this.languageId,
          theme: this.theme,
        });
        await this.$store.dispatch(BrowserActions.APPLY_THEME);

        await this.$store.dispatch(
          LanguageActions.FETCH_TRANSLATIONS,
          this.languageId
        );

        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: this.$t("app.views.user.SettingsUpdatedAlert"),
          type: NotificationType.SUCCESS,
        });
      } catch (exception) {
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
      this.$emit("loading");
    },
  },
};
</script>

<style scoped lang="scss">
.app-settings {
  margin-top: 40px;

  &_language {
    max-width: 400px;
  }

  &_mode {
    max-width: 400px;
  }

  &_message {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0;
    color: #6d6d6d;
  }

  &_action {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
</style>
