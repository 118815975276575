import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component
export default class RecordsTable extends Vue {

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public activeRecords!: boolean;

  @Prop({
    required: false,
    type: Array,
    default: false,
  })
  public rows!: [];

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public isSmallRowPaddings!: boolean;

}
