<template>
  <div class="Body" :class="{ DragActive: sectionDragActive }">
    <draggable
      v-if="templateData.sections.length > 0"
      class="Sections"
      handle=".section-drag-handle"
      v-model="templateData.sections"
      @start="sectionDragActive = true"
      @end="sectionDragActive = false"
    >
      <div
        class="Section"
        v-for="(section, index) of templateData.sections"
        :key="index"
      >
        <div class="TopLine">
          <div class="Title">
            <SvgIcon
              name="list"
              width="30"
              height="30"
              :class="'ListIcon ' + (!disabled && 'section-drag-handle')"
            />
            <input
              class="section-input"
              :placeholder="$t('app.views.library.form.sectionName')"
              v-model="section.name"
              :class="{
                'has-error': formErrors.hasErrors(
                  'sections.' + index + '.name'
                ),
              }"
              v-on:keydown.enter.prevent="$event.preventDefault()"
              :disabled="disabled"
            />
          </div>

          <SvgIcon
            name="trash"
            class="TrashIcon"
            width="24"
            height="24"
            @click="removeSection(index)"
            v-if="!disabled"
          />
        </div>

        <TemplateQuestionTable
          :form-errors="formErrors"
          :section="section"
          :disabled="disabled"
          :section-drag-active="sectionDragActive"
          v-on:update:section="section = $event"
        />
      </div>
    </draggable>

    <div class="AddSection" @click="addSection" v-if="!disabled">
      <img src="/images/icons/template/plus.svg" alt="plus-icon" />
      <p>{{ $t("app.views.library.form.addSection") }}</p>
    </div>
  </div>
</template>

<script>
import TemplateQuestionTable from "@/views/TemplateFormView/components/TemplateQuestionTable.vue";

export default {
  name: "TemplateSectionTable",
  components: { TemplateQuestionTable },
  emits: ["update:templateData"],
  props: {
    templateData: {
      required: true,
    },
    formErrors: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sectionDragActive: false,
    };
  },
  methods: {
    addSection() {
      this.templateData.sections.push({
        id: null,
        name: null,
        order: this.templateData.sections.length + 1,
        questions: [],
      });
    },
    removeSection(index) {
      this.templateData.sections.splice(index, 1);
      this.setSectionOrder();
    },
    setSectionOrder() {
      this.templateData.sections.forEach(
        (section, i) => (section.order = i + 1)
      );
    },
  },
  watch: {
    sectionDragActive() {
      if (!this.sectionDragActive && this.templateData.sections.length > 0) {
        this.setSectionOrder();
      }
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";

$table-width: 1255;

.InspectionTemplateQuestionsPseudoTable {
  width: 100%;

  .ListIcon {
    cursor: move;
    cursor: grab;
    color: #cecece;

    &:hover {
      color: #0d0e34;
    }
  }

  .DragActive {
    & > .Questions {
      .Question {
        &:not([draggable="true"]) {
          opacity: 0.5;
        }
      }
    }

    & > .Sections {
      .Section {
        &:not([draggable="true"]) {
          opacity: 0.5;
        }
      }
    }
  }

  .TrashIcon {
    color: #cecece;
    cursor: pointer;

    &:hover {
      color: #0d0e34;
    }
  }

  .section-input,
  .question-input {
    border: 1px solid transparent;
    border-radius: 3px;

    padding: 2px 5px;

    font-size: 12px;

    width: 100%;
    height: 24px;
    margin-right: 26px;

    &:focus {
      outline: 0;
      border: 1px solid #cecece;
    }

    &:hover {
      border: 1px solid #cecece;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &::placeholder {
      font-weight: inherit;
      opacity: 1;
      color: #0d0e34;
    }

    &:disabled {
      border: none;
    }
  }

  .AddSection {
    cursor: pointer;
    color: #0d0e34;
    font-size: 12px;
    font-weight: bold;
  }

  .Section > .TopLine,
  .AddSection {
    background: #f2f2f2;
  }

  .Section > .TopLine,
  .AddSection > .TopLine > :first-child {
    padding: 9px 39px 9px 19px;
  }

  .Section > .TopLine > .Title {
    width: 100%;
  }

  .Section > .TopLine > .Title,
  .AddSection > .TopLine > :first-child {
    display: flex;
    column-gap: 21px;
    align-items: center;
    font-weight: 600;

    input::placeholder,
    textarea::placeholder,
    textarea {
      font-weight: 600;
    }
  }

  .Body {
    & > :first-child:not(.Sections),
    .Sections .Section:first-child .TopLine {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }

    & > :last-child {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .Sections {
      .Section {
        &[draggable="true"] {
          box-shadow: 0px 0px 16px #00000029;
        }

        & > .TopLine {
          display: flex;
          align-items: center;
          justify-content: space-between;

          background: #f2f2f2;

          & > .Title {
            width: 100%;

            input {
              font-weight: 600;
              color: #0d0e34;
            }
          }
        }
      }
    }
  }

  .has-error {
    color: #e51d1d !important;
  }

  .has-error::placeholder {
    color: #e51d1d !important;
  }
}
</style>
