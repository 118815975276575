import {MutationTypes} from "./mutation-types";
import AuthState from "./state";
import {MutationTree} from "vuex";
import UserFull from "@/vuex/models/user/user-full";

const mutations: MutationTree<AuthState> = {
    [MutationTypes.SET_TOKEN](state: AuthState, params: { token: string, expiry: string }): void {
        state.token = params.token;
        localStorage.setItem('token', params.token);
        localStorage.setItem('expiry', params.expiry);
    },
    [MutationTypes.CLEAR_TOKEN](store: AuthState): void {
        store.token = null;
        localStorage.removeItem('token');
        localStorage.removeItem('expiry');
    },
    [MutationTypes.SET_ME](state: AuthState, user: UserFull): void {
        state.me = user;
    },
    [MutationTypes.CLEAR_ME](state: AuthState): void {
        state.me = null;
    },
}

export default mutations;
