import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";


export interface RadioOption {
  value: string | number;
  label: string;
}

@Component
export default class InputRadio extends Vue {

  @Prop({
    required: false,
    type: String,
    default: 'vertical'
  })
  public type!: "horizontal" | "vertical";

  @Prop({
    required: true,
    type: Array,
  })
  public options!: RadioOption[];


  @Prop({
    required: false,
  })
  public value!: string | number;

  private input_name: string | null = null;

  private get name(): string {
    if (!this.input_name) {
      this.input_name = (Math.random() + 1).toString(36).substring(7);
    }

    return this.input_name;
  }
}
