import Papa from "papaparse";

export class ExportCsv {
  public async handleExportCsv(data: any, fields: string[], fileName: string) {
    const csv = Papa.unparse({ fields, data }, { delimiter: ";" });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = fileName;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}
