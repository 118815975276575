import {ActionContext, ActionTree} from "vuex";
import OrganisationRequestsState from "./state";
import store, {RootState} from "@/vuex/store";
import {ActionTypes} from "./action-types";
import {http} from "@/services/http/http";
import {appRoutes} from "@/config";
import {MutationTypes} from "./mutation-types";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {Direction, OrganisationRequestsSortableColumns} from "@/services/helpers/sort";


export interface OrganisationRequestSearchParams {
    page: number;
    per_page: number;
    direction: Direction | null;
    column: OrganisationRequestsSortableColumns | null;
    search: string | null;
}

type OrganisationRequestsActionContext = ActionContext<OrganisationRequestsState, RootState>;

const actions: ActionTree<OrganisationRequestsState, RootState> = {
    async [ActionTypes.FETCH_REQUESTS](state: OrganisationRequestsActionContext, params: OrganisationRequestSearchParams): Promise<void> {
        try {
            const response = await http().get(appRoutes.api.orgRequests.paginate(), {params});
            state.commit(MutationTypes.SET_REQUESTS, response.data.data);
            state.commit(MutationTypes.SET_REQUESTS_META, response.data.meta);
        } catch (exception: any) {
            await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
                text: exception.response.data.message,
                type: NotificationType.DANGER
            });
        }
    }
}

export default actions;
