import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VueI18n from "vue-i18n";

export enum SelectStyles {
  SEARCH = "search",
  FIELD = "field",
  AUTH = "auth",
}

export type SelectOption = {
  value: string | number | null | boolean;
  label: string;
  typeTemplateText?: string;
  roleUser?: string;
};

@Component
export default class InputSelect extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public options!: SelectOption[];

  @Prop({
    required: true,
  })
  public value!: number | number[] | string | string[] | boolean | null;

  @Prop({
    required: false,
    default: null,
  })
  public label!: string | VueI18n.TranslateResult;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public isMinimal!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public boxShadowNone!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public disabled!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  public error!: string | null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public withSearch!: boolean;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  public placeholder!: string | null;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  public searchPlaceholder!: string;

  @Prop({
    type: String,
    required: false,
    default: SelectStyles.FIELD,
  })
  public appearance!: SelectStyles;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public returnFullOption!: boolean;

  @Prop({
    required: false,
    type: String,
    default: "",
  })
  public addPlusButton!: string;

  @Prop({
    required: false,
    type: Object,
  })
  public userOrgOption!: SelectOption;

  @Prop({
    required: false,
    type: Object,
  })
  public userLocationRoleOption!: SelectOption;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public isChagneStylesForTemplate!: boolean;

  private open: boolean = false;
  private focusedOnSearch: boolean = false;
  private search: string | null = null;
  protected selectedOption: SelectOption = { label: "", value: null };
  protected selectedOptions: SelectOption[] = [];

  protected get filteredOptions() {
    let options: SelectOption[];

    if (!!this.search) {
      options = this.options.filter((option: SelectOption | null) => {
        if (!!option && !!option.label && !!this.search) {
          return option.label.toLowerCase().includes(this.search.toLowerCase());
        }
      });
    } else {
      options = this.options;
    }

    return options;
  }

  protected created(): void {
    this.setValue();
  }

  @Watch("options")
  private updateOptions(): void {
    this.setValue();
  }

  @Watch("value")
  private setValue(): void {
    if (Array.isArray(this.value)) {
      this.setSelectedOptions();
    } else {
      this.setSelectedOption();
    }
  }

  private setSelectedOptions(): void {
    if (!!this.value && Array.isArray(this.value)) {
      const options: SelectOption[] = [];

      this.value.forEach((value: string | number): void => {
        const option: SelectOption | undefined = this.findOption(value);

        if (!!option) {
          options.push(option);
        }
      });

      this.selectedOptions = options;
    }
  }

  private setSelectedOption(): void {
    if (!Array.isArray(this.value)) {
      const option: SelectOption | undefined = this.findOption(this.value);

      if (!!option) {
        this.selectedOption = option;
      } else {
        this.setPlaceholderOption();
      }
    }
  }

  private setPlaceholderOption(): void {
    if (!!this.placeholder) {
      this.selectedOption = { label: this.placeholder, value: null };
    }
  }

  private findOption(
    value: string | number | boolean | null
  ): SelectOption | undefined {
    return this.options.find(
      (option: SelectOption): boolean => option.value == value
    );
  }

  protected focusoutSelect(): void {
    setTimeout((): void => {
      if (!this.focusedOnSearch) {
        this.open = false;
      }
    }, 100);
  }

  protected focusoutSearch(): void {
    setTimeout((): boolean => (this.focusedOnSearch = false), 100);
  }

  protected openOptions(): void {
    if (!this.disabled) {
      this.open = !this.open;
    }
  }

  protected select(option: SelectOption): void {
    if (Array.isArray(this.value)) {
      if (!this.selectedOptions.includes(option)) {
        this.selectedOptions.push(option);
        const values = this.selectedOptions.map(
          (option: SelectOption) => option.value
        );
        this.$emit("select", values);
      }
    } else {
      this.$emit("select", this.returnFullOption ? option : option.value);
      this.selectedOption = option;
      this.open = false;
    }
  }

  protected unselect(option: SelectOption): void {
    const index: number = this.selectedOptions.indexOf(option);
    this.selectedOptions.splice(index, 1);

    const values = this.selectedOptions.map(
      (option: SelectOption) => option.value
    );
    this.$emit("select", values);
  }

  @Watch("open")
  public onOpen(val: boolean): void {
    if (!val) {
      this.search = null;
    }
  }
}
