<template>
  <div class="billing-address-form">
    <LoadingSpinner :show="loading" />
    <BillingForm @back="$router.back()" @save="saveAddress">
      <template slot="form">
        <div id="address-element"></div>
        <InputSelect
          :label="$t('app.views.billing.address.taxType').toString()"
          :options="taxTypes"
          :value="tax.tax_type"
          :error="formErrors.get('tax_type')"
          :has-error="formErrors.hasErrors('tax_type')"
          @select="tax.tax_type = $event"
          with-search
        />
        <InputText
          :label="$t('app.views.billing.address.taxNumber').toString()"
          type="text"
          v-model="tax.tax_number"
          :error="formErrors.get('tax_number')"
          :has-error="formErrors.hasErrors('tax_number')"
        ></InputText>
        <div class="billing-address-form_email">
          <p class="billing-address-form_email_label">
            {{ $t("app.views.billing.address.email") }}
          </p>
          <div class="billing-address-form_email_checkbox">
            <InputCheckbox :value="sameEmail" @input="sameEmail = !sameEmail" />
            <p>
              {{ $t("app.views.billing.address.sameAsAccountEmail") }}
            </p>
          </div>
          <InputText
            v-if="!sameEmail"
            type="email"
            v-model="email"
            :error="formErrors.get('billing_email')"
            :has-error="formErrors.hasErrors('billing_email')"
            :placeholder="$t('app.views.billing.address.email').toString()"
          ></InputText>
        </div>
      </template>
    </BillingForm>
  </div>
</template>

<script>
import BillingForm from "@/views/Billing/components/BillingForm.vue";
import { mapGetters } from "vuex";
import FormErrors from "@/services/formErrors/FormErrors";
import InputCheckbox from "@/components/InputCheckbox/InputCheckbox.vue";
import { ActionTypes } from "@/vuex/modules/billing-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { MutationTypes } from "@/vuex/modules/billing-module/mutation-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import StripeService from "@/services/stripe/stripeService";
import { UserProfileTabs } from "@/vuex/models/userProfile";

export default {
  name: "BillingAddressForm",
  components: { InputCheckbox, BillingForm },
  async created() {
    this.loading = true;

    if (!this.address) {
      await this.$store.dispatch(ActionTypes.FETCH_ADDRESS);
    }

    if (!this.customer) {
      await this.$store.dispatch(ActionTypes.FETCH_CUSTOMER);
    }

    await this.fetchTaxTypes();
    await this.mountAddressForm();

    this.setTaxData();
    this.setEmail();

    this.loading = false;
  },
  data() {
    return {
      loading: false,
      formErrors: new FormErrors(),
      stripeService: new StripeService(),
      sameEmail: true,
      taxTypes: [],
      tax: {
        tax_number: null,
        tax_type: null,
      },
      email: null,
    };
  },
  computed: {
    ...mapGetters(["address", "me", "customer"]),
  },
  methods: {
    async fetchTaxTypes() {
      const response = await this.$store.dispatch(ActionTypes.FETCH_TAX_TYPES);
      this.taxTypes = response.data.data.map((item) => {
        item["label"] = this.$t(item["label"]).toString();
        return item;
      });
      this.taxTypes.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    },
    async mountAddressForm() {
      await this.stripeService.init();
      const response = await this.$store.dispatch(
        ActionTypes.FETCH_SETUP_INTENT
      );
      await this.stripeService
        .createElements(response.data.client_secret, this.me.theme)
        .mountAddressElement(
          "#address-element",
          this?.address?.billing_name,
          this?.address
        );
    },
    async saveAddress() {
      this.loading = true;
      this.formErrors.clearErrors();
      const payload = await this.preparePayload();
      try {
        await this.$store.dispatch(ActionTypes.SAVE_ADDRESS, {
          ...payload,
          id: this?.address?.id ? this?.address?.id : 0,
        });
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: "Address has been saved.",
          type: NotificationType.SUCCESS,
        });
        const newData = {
          ...this.customer,
          tax_type: this.tax.tax_type,
          tax_number: this.tax.tax_number,
        };
        await this.$store.commit(MutationTypes.SET_CUSTOMER, newData);
        await this.$router.push({
          name: "settings",
          query: { tab: UserProfileTabs.BILLING },
        });
      } catch (exception) {
        this.formErrors.setErrors(exception.response.data.errors);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }

      this.loading = false;
    },
    async preparePayload() {
      const response = await this.stripeService.getAddressValues();

      return {
        address: {
          ...response.value.address,
          billing_name: response.value.name,
          billing_email: !this.sameEmail ? this.email : null,
        },
        tax: this.tax,
      };
    },
    setTaxData() {
      this.tax.tax_number = this.customer.tax_number;
      this.tax.tax_type = this.customer.tax_type;
    },
    setEmail() {
      if (!!this.address && this.address.billing_email !== this.me.email) {
        this.email = this.address.billing_email;
      }

      if (!!this.email) {
        this.sameEmail = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-address-form {
  display: flex;
  justify-content: center;

  &_email {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_label {
      margin-left: 20px;
      font: normal normal normal 12px/18px Poppins;
      color: #6d6d6d;
      letter-spacing: 0;
    }

    &_checkbox {
      margin-left: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.address_details {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
  max-width: calc(100% - 20px);
}
</style>
