import { MutationTree } from "vuex";
import LocationsState from "./state";
import { MutationTypes } from "./mutation-types";
import Meta from "@/vuex/models/meta";
import LocationFull from "@/vuex/models/location/location-full";

const mutations: MutationTree<LocationsState> = {
  [MutationTypes.SET_LOCATIONS](
    state: LocationsState,
    locations: Array<LocationFull>
  ): void {
    state.locations = locations;
  },
  [MutationTypes.SET_LOCATIONS_FOR_CSV](
    state: LocationsState,
    locations: Array<LocationFull>
  ): void {
    state.locationsForCsv = locations;
  },
  [MutationTypes.SET_LOCATIONS_META](state: LocationsState, meta: Meta): void {
    state.meta = meta;
  },
  [MutationTypes.SET_LOCATION](
    state: LocationsState,
    location: LocationFull | null
  ): void {
    state.location = location;
  },
};

export default mutations;
