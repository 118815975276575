import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";


@Component
export default class Pagination extends Vue {

  @Prop({
    required: true,
    type: Number,
  })
  public currentPage!: number;

  @Prop({
    required: true,
    type: Number,
    default: null
  })
  public lastPage!: number;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public loading!: number;

  public get pages() {
    const pages = [];

    for (const number of [this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1, this.currentPage + 2]) {
      if (number > 0) {
        pages.push(number);
      }
    }

    if (pages.length < 5) {
      const lastPage = pages[pages.length - 1];
      for (let i = 1; i <= 6 - pages.length; i++) {
        pages.push(lastPage + i)
      }
    }

    return pages.filter((page) => page < this.lastPage);
  }

  public prevPage() {
    if (this.currentPage == 1) {
      return;
    }

    this.$emit('paginate', this.currentPage - 1);
  }

  public nextPage() {
    if ((this.currentPage + 1) > this.lastPage) {
      return;
    }

    this.$emit('paginate', this.currentPage + 1);
  }

}
