import Vue from "vue";

export class SignaturePadService {
    private signature: string | null = null;
    private signaturePad!: any;
    private signaturePadStyle = {
        "background": "#FFFFFF 0% 0% no-repeat padding-box",
        "border": "1px solid #D9D9D9",
        "border-radius": "10px",
        "width": "100%"
    }

    public getStyle(): any {
        return this.signaturePadStyle;
    }

    public getSignature(): string | null {
        return this.signature;
    }

    public setSignature(value: string): any {
        (this.signaturePad as Vue & { fromDataURL: (string: string) => void }).fromDataURL(value);
        this.signature = value;
    }

    public setSignaturePad(signaturePad: any): void {
        this.signaturePad = signaturePad;
    }

    public addSignature(): void {
        const {isEmpty, data} = (this.signaturePad as Vue & {
            saveSignature: () => { isEmpty: boolean, data: string }
        }).saveSignature();

        if (!isEmpty) {
            this.signature = data;
        }
    }

    public clearSignature(): void {
        (this.signaturePad as Vue & { clearSignature: () => void }).clearSignature();
        this.signature = null;
    }
}