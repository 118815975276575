import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { Debounce } from "vue-debounce-decorator";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import { ActionTypes as QRCodeActions } from "@/vuex/modules/qr-code-module/action-types";
import { ActionTypes as LocationActions } from "@/vuex/modules/locations-module/action-types";
import { ActionTypes as TemplateActions } from "@/vuex/modules/templates-module/action-types";
import { TemplateSearchParams } from "@/vuex/modules/templates-module/actions";
import store from "@/vuex/store";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { FormMixin } from "@/mixins/formMixin";
import QRCodeData, {
  qrCodesPermissions,
  qrCodesStatus,
  qrCodesType,
} from "@/vuex/models/qrCode/location-data";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { LocationSearchParams } from "@/vuex/modules/locations-module/actions";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import {
  Direction,
  LocationsSortableColumns,
  TemplatesSortableColumns,
  UsersSortableColumns,
} from "@/services/helpers/sort";
import i18n from "@/lang/locale";
import QRCodeFull from "@/vuex/models/qrCode/qrCode-full";

@Component({
  components: {
    InputSelectOrganisation,
    AccessModal,
    ButtonsSaveCancel,
    Field,
  },
  computed: {
    ...mapGetters({
      organisationOptions: "organisationSelectOptions",
      userOptions: "userSelectOptions",
      locationOptions: "locationSelectOptions",
      templateOptions: "templateOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchTemplates: TemplateActions.FETCH_TEMPLATES,
      fetchQrCode: QRCodeActions.FETCH_QR_CODE,
      create: QRCodeActions.CREATE_QR_CODE,
      update: QRCodeActions.UPDATE_QR_CODE,
      fetchLocations: LocationActions.FETCH_LOCATIONS,
      fetchUserOptions: UserActions.FETCH_USERS,
    }),
  },
})
export default class QRCodeForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean;

  @Prop({
    required: false,
    type: Number,
  })
  public selectedId!: number | null;

  protected readonly locationOptions!: SelectOption[];
  protected readonly templateOptions!: SelectOption[];
  protected readonly userOptions!: SelectOption[];

  protected assignedUsers: SelectOption[] = [];
  protected searchUsersValue: string = "";
  protected organisationName: string = "";

  protected readonly create!: (params: QRCodeData) => void;
  protected readonly update!: (params: {
    data: QRCodeData;
    id: number;
  }) => void;
  protected readonly fetchQrCode!: (id: number) => QRCodeFull;
  protected readonly fetchLocations!: (
    params: LocationSearchParams
  ) => Promise<void>;
  protected readonly fetchTemplates!: (
    params: TemplateSearchParams
  ) => Promise<void>;
  protected readonly fetchUserOptions!: (
    params: UserSearchParams
  ) => Promise<void>;

  protected qrCodeData: QRCodeData = {
    organisation_id: null,
    name: null,
    type: qrCodesType.INCIDENT,
    template_id: null,
    location_id: null,
    status: qrCodesStatus.ACTIVE,
    permission: qrCodesPermissions.PUBLIC,
  };

  protected usersSearchParams: UserSearchParams = {
    page: 1,
    per_page: 100,
    direction: Direction.DESC,
    column: UsersSortableColumns.NAME,
    search: null,
    organisation_id: null,
    role: null,
    locations_ids: [],
  };

  protected templateSearchParams: TemplateSearchParams = {
    page: 1,
    per_page: 1000,
    column: TemplatesSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    access_level: null,
    user_id: null,
    language_id: null,
  };

  protected locationSearchParams: LocationSearchParams = {
    page: 1,
    per_page: 100,
    column: LocationsSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    organisation_id: null,
  };

  protected readonly statusOptions = [
    {
      label: i18n.t("app.views.qrCodes.statusOptions.active").toString(),
      value: qrCodesStatus.ACTIVE,
    },
    {
      label: i18n.t("app.views.qrCodes.statusOptions.inactive").toString(),
      value: qrCodesStatus.INACTIVE,
    },
  ];

  protected readonly permissionsOptions = [
    {
      label: i18n.t("app.views.qrCodes.permissionOptions.public").toString(),
      value: qrCodesPermissions.PUBLIC,
    },
    {
      label: i18n
        .t("app.views.qrCodes.permissionOptions.organisation")
        .toString(),
      value: qrCodesPermissions.ORGANISATION,
    },
    {
      label: i18n.t("app.views.qrCodes.permissionOptions.private").toString(),
      value: qrCodesPermissions.PRIVATE,
    },
  ];

  protected readonly typeOptions = [
    {
      label: i18n.t("app.views.qrCodes.typeOptions.incident").toString(),
      value: qrCodesType.INCIDENT,
    },
    {
      label: i18n.t("app.views.qrCodes.typeOptions.inspections").toString(),
      value: qrCodesType.INSPECTIONS,
    },
  ];

  protected get label(): string {
    return !!this.selectedId
      ? i18n.t("components.qrCode.editQRCode").toString()
      : i18n.t("components.qrCode.addQRCode").toString();
  }

  protected async created(): Promise<void> {
    this.$emit("loading");

    await this.fetchTemplates(this.templateSearchParams);

    if (this.selectedId) {
      const data = await this.fetchQrCode(this.selectedId);
      this.organisationName = data?.organisation?.name;
      this.qrCodeData = {
        organisation_id: data?.organisation?.id,
        name: data?.name,
        type: data?.type,
        template_id: data?.template?.id ?? null,
        location_id: data?.location?.id,
        status: data?.status,
        permission: data?.permission,
      };
      this.assignedUsers = data.users.map((el) => ({
        label: `${el?.name} ${el?.surname}`,
        value: el?.id,
      }));
    }

    if (this.auth.isSystemAdmin(this.me)) {
      this.usersSearchParams.organisation_id = this.qrCodeData.organisation_id;
      await this.fetchUserOptions(this.usersSearchParams);

      this.usersSearchParams.organisation_id = this.qrCodeData.organisation_id;
      await this.fetchUserOptions(this.usersSearchParams);

      this.locationSearchParams.organisation_id =
        this.qrCodeData.organisation_id;
      await this.fetchLocations(this.locationSearchParams);
    }

    this.$emit("loading");
  }

  protected get filteredUserOptions() {
    return this.userOptions.filter(
      (option) =>
        !this.assignedUsers.some((user) => user.value === option.value)
    );
  }

  protected addAccess(userOption: SelectOption): void {
    if (!this.assignedUsers.some((user) => user.value === userOption.value)) {
      this.assignedUsers.push(userOption);
    }
  }

  protected revokeAccess(userOption: SelectOption): void {
    this.assignedUsers = this.assignedUsers.filter(
      (user) => user.value !== userOption.value
    );
  }

  protected async selectOrganisation(organisationId: number): Promise<void> {
    this.qrCodeData.organisation_id = organisationId;
    this.qrCodeData.location_id = null;
    this.locationSearchParams.organisation_id = organisationId;
    this.assignedUsers = [];

    await this.fetchLocations(this.locationSearchParams);

    this.usersSearchParams.organisation_id = organisationId;

    await this.fetchUserOptions(this.usersSearchParams);
  }

  protected async selectLocation(locationId: number): Promise<void> {
    this.qrCodeData.location_id = locationId;
  }

  protected async selectType(
    type: qrCodesType.INCIDENT | qrCodesType.INSPECTIONS
  ): Promise<void> {
    if (type === qrCodesType.INCIDENT) {
      this.qrCodeData.permission = qrCodesPermissions.PUBLIC;
      this.qrCodeData.template_id = null;
      this.assignedUsers = [];
      this.searchUsersValue = "";
    }
    this.qrCodeData.type = type;
  }

  protected async selectPermissions(
    permission: qrCodesPermissions.PUBLIC | qrCodesPermissions.PRIVATE
  ): Promise<void> {
    if (permission === qrCodesPermissions.PUBLIC) {
      this.assignedUsers = [];
      this.searchUsersValue = "";
    }
    this.qrCodeData.permission = permission;
  }

  protected close(): void {
    this.$emit("close", false);
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");

    try {
      if (!!this.selectedId) {
        await this.updateQRCode();
      } else {
        await this.createQRCode();
      }

      this.$emit("close", true);
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.$emit("loading");
  }

  @Debounce(350)
  protected async searchTemplates(search: string | null): Promise<void> {
    this.templateSearchParams.search = search;

    await this.fetchTemplates(this.templateSearchParams);
  }

  @Debounce(350)
  protected async searchUsers(search: string | null): Promise<void> {
    this.usersSearchParams.search = search;

    await this.fetchUserOptions(this.usersSearchParams);
  }

  @Debounce(350)
  protected async searchLocations(search: string | null): Promise<void> {
    this.locationSearchParams.search = search;
    await this.fetchLocations(this.locationSearchParams);
  }

  private async sendQRCodeData(): Promise<QRCodeData> {
    return {
      organisation_id: this.auth.isSystemAdmin(this.me)
        ? this.qrCodeData.organisation_id
        : null,
      name: this.qrCodeData.name,
      type: this.qrCodeData.type,
      template_id: this.qrCodeData.template_id,
      location_id: this.qrCodeData.location_id,
      status: this.qrCodeData.status,
      permission: this.qrCodeData.permission,
      users_ids: this.assignedUsers.map((user) => Number(user.value)),
    };
  }

  private async createQRCode(): Promise<void> {
    const qrCodeData = await this.sendQRCodeData();
    await this.create(qrCodeData);
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.qrCodeCreated"),
      type: NotificationType.SUCCESS,
    });
  }

  private async updateQRCode(): Promise<void> {
    const qrCodeData = await this.sendQRCodeData();
    await this.update({
      data: qrCodeData,
      id: this.selectedId ?? 0,
    });
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t("app.views.user.qrCodeUpdated"),
      type: NotificationType.SUCCESS,
    });
  }
}
