import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import InspectionReportsState from "@/vuex/modules/reports-module/state";
import actions from './actions';
import mutations from "./mutations";
import getters from "./getters";


const module: Module<InspectionReportsState, RootState> = {
    state: new InspectionReportsState(),
    actions,
    getters,
    mutations,
}

export default module;
