<template>
  <div class="AuthLayout">
    <div class="Body">
      <div class="Logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="248px"
          height="60px"
          viewBox="0 0 398 112.45"
        >
          <g
            id="Component_1_1"
            data-name="Component 1 – 1"
            transform="translate(0 0)"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M372.57,263.62q8.311,7.767,8.752,21H361.636a14.139,14.139,0,0,0-5.2-8.421,16.514,16.514,0,0,0-10.337-3.064,19.685,19.685,0,0,0-14.217,6.016q-6.129,6.016-7.874,16.078a34.561,34.561,0,0,0-.549,5.577q0,7.662,4.047,12.035t10.936,4.373a21.527,21.527,0,0,0,11.322-3.061,23.16,23.16,0,0,0,8.15-8.314h19.794a45.918,45.918,0,0,1-16.3,20.946,43.341,43.341,0,0,1-25.7,7.821,35.276,35.276,0,0,1-16.952-3.827,25.94,25.94,0,0,1-10.83-10.774,33.678,33.678,0,0,1-3.719-16.136,47.719,47.719,0,0,1,.766-8.64,50.211,50.211,0,0,1,8.2-20.453,43.335,43.335,0,0,1,36.311-18.919Q364.262,255.857,372.57,263.62Z"
              transform="translate(-304.212 -253.011)"
              fill="#0d0e34"
            />
            <path
              id="Path_2"
              data-name="Path 2"
              d="M400.02,279.015Q405,284.1,404.993,293.29a38.3,38.3,0,0,1-.655,6.889L398.1,335.618H379.511l5.8-33.252a18.982,18.982,0,0,0,.329-3.279q0-4.7-2.624-7.22a10.17,10.17,0,0,0-7.328-2.515,13.884,13.884,0,0,0-8.916,3.008,16.608,16.608,0,0,0-5.412,8.7l-6.016,34.562h-18.81l12.712-72.359,20.316-8.58-5.468,30.846a26.209,26.209,0,0,1,22.094-11.6Q395.044,273.929,400.02,279.015Z"
              transform="translate(-258.443 -254.679)"
              fill="#0d0e34"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M422.424,269q6.832,6.4,6.833,17.117a41.907,41.907,0,0,1-.546,6.128,23.393,23.393,0,0,1-1.421,5.031H384.963q-.221,2.186-.22,2.953,0,9.952,9.518,9.952a12.893,12.893,0,0,0,7.217-2.078,12.09,12.09,0,0,0,4.6-5.359h19.8a33.208,33.208,0,0,1-12.523,16.189,35.442,35.442,0,0,1-20.838,6.234q-11.812,0-18.757-6.562t-6.944-18.158a45.613,45.613,0,0,1,.655-7.437,37.495,37.495,0,0,1,6.782-16.186,34.22,34.22,0,0,1,12.741-10.554,37.662,37.662,0,0,1,16.573-3.665Q415.583,262.6,422.424,269Zm-11.869,17.117q0-4.375-2.735-6.562a10.69,10.69,0,0,0-6.889-2.187,14.37,14.37,0,0,0-9.078,3.064,15.991,15.991,0,0,0-5.47,8.967h23.843A19.005,19.005,0,0,0,410.555,286.116Z"
              transform="translate(-215.563 -243.46)"
              fill="#0d0e34"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M450.271,269.108q7.053,6.509,7.164,17.883H437.528a8.778,8.778,0,0,0-2.515-6.123,8.985,8.985,0,0,0-6.454-2.189,12.137,12.137,0,0,0-8.8,3.885q-3.882,3.878-5.193,11.318a23.37,23.37,0,0,0-.44,4.6q0,5.248,2.515,7.928a8.71,8.71,0,0,0,6.674,2.68,12.069,12.069,0,0,0,7.217-2.187,13.149,13.149,0,0,0,4.593-6.125h19.8a36.2,36.2,0,0,1-13.181,17.883,35.174,35.174,0,0,1-20.944,6.509q-11.815,0-18.868-6.727t-7.055-18.54a51.523,51.523,0,0,1,.766-7.655,36.922,36.922,0,0,1,6.671-15.8,33.307,33.307,0,0,1,12.468-10.283,37.3,37.3,0,0,1,16.3-3.554Q443.216,262.6,450.271,269.108Z"
              transform="translate(-175.816 -243.46)"
              fill="#0d0e34"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M455.244,254.679l-14.217,80.939H422.212l12.731-72.473Z"
              transform="translate(-137.096 -254.679)"
              fill="#0d0e34"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M458.91,348.946l-22.026-61.09,3.607-1.508,21.089,27.318,24.752-46.7L514.317,255.2,458.9,348.912"
              transform="translate(-116.317 -253.937)"
              fill="#1de591"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M441.243,269.145l11.936,46.105-20.017,31.392h20.673l9.537-16.126Z"
              transform="translate(-121.588 -234.192)"
              fill="#0d0e34"
            />
          </g>
        </svg>
      </div>
      <div class="Content">
        <slot />
      </div>
    </div>
    <div v-if="isFooter" class="auth-footer__wrapper">
      <div>
        <span>checly.com</span>
      </div>
      <div class="logo-info">
        <span> {{ $t("app.views.authLayout.poweredBy") }}</span>
        <div class="logo-container Logo">
          <v-logo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  computed: {
    isFooter() {
      return this.$route.name === "incident-form-reporting";
    },
  },
};
</script>


<style lang="scss">
@import "@/asesst/styles/mixins";

.AuthLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Poppins;
  min-height: 100vh;

  .Body {
    padding: 100px 0 120px 0;
    margin: 0 auto;
    max-width: 560px;
    width: 100%;

    .Logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0px 60px;

      img {
        width: 248px;
      }

      @include mobile {
        svg {
          width: 209px;
          height: 60px;
        }
      }
    }

    .Content {
      .Form {
        display: flex;
        flex-direction: column;
      }

      .Title,
      .ErrorDesc {
        margin-bottom: 37px;

        @include mobile {
          margin-bottom: 20px;
        }
      }

      .Title {
        text-align: center;
        color: #0d0e34;
        font-weight: 600;
        font-size: 26px;
        line-height: 42px;

        @include mobile {
          font-size: 16px;
        }
      }

      .ErrorDesc {
        color: #e51d1d;
        font-size: 12px;
        line-height: 21px;
        align-self: center;
        gap: 5px;
        text-align: center;

        a {
          color: #e51d1d;
          text-decoration: underline;
        }
      }

      .Inputs {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 20px;
        max-width: 535px;
        width: 100%;
        align-self: center;

        &.IsSwitch {
          row-gap: 16px;
          margin-bottom: 17px;

          .switch-row {
            display: flex;
            column-gap: 12px;
            color: #6d6d6d;
            font-size: 12px;
            line-height: 21px;

            a {
              color: #1de591;
            }
          }
        }
      }

      .Bottom {
        font-size: 12px;
        line-height: 21px;
        max-width: 535px;
        width: 100%;
        align-self: center;

        a {
          color: #1de591;
        }

        .AdditionalInfo {
          color: #6d6d6d;

          .row {
            margin-bottom: 21px;
          }
        }

        .Footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 41px;

          .Button {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: content-box;

            font-size: 14px;
            width: 184px;
            height: 48px;

            background: #1de591;
            color: #ffffff;
            border-radius: 24px;
            cursor: pointer;
            border: none;

            @include mobile {
              width: 141px;
            }
          }
        }

        .BottomLine {
          display: flex;
          column-gap: 14px;
          color: #6d6d6d;
        }
      }
    }

    @include mobile {
      padding: 100px 20px 20px 20px;
    }
  }

  .auth-footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;

    margin: 0px 35px 0 35px;

    border-top: 2px solid black;

    span {
      color: #0d0e34;
      font-size: 10px;
      font-weight: bold;
    }

    @include mobile {
      margin: 40px 35px 0 35px;
    }

    .logo-info {
      display: flex;
      gap: 10px;
    }

    .logo-container {
      width: 74px;
      height: 21px;
    }
  }
}
</style>
