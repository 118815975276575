<template>
  <div class="incident-tab">
    <h1 class="incident-title">{{ $t("app.views.user.incidentTab") }}</h1>
    <div class="type-incident__wrapper">
      <div class="type-incident-title__wrapper">
        <h5>{{ $t("app.views.user.incidentsTypes") }}</h5>
      </div>
      <div class="type-incident-list__wrapper">
        <div class="incident-item">
          <input
            :class="{ error: error }"
            :placeholder="$t('app.views.user.placeholder.addType')"
            v-model="typeValue"
          />
          <SvgIcon
            @click="createIncidentTypeHandler"
            class="add-plus-icon"
            height="22"
            name="add-plus-icon"
            width="22"
          />
        </div>
        <incident-item
          v-for="incidentOption in incidentsTypesOption"
          :key="incidentOption.value"
          @deleteType="deleteIncidentTypeHandler"
          @update="updateIncidentTypeHandler"
          class="incident-item"
          :incidentOption="incidentOption"
        />
        <div class="incident-item">
          <input disabled :value="$t('app.views.typeIncident.other')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ActionTypes } from "@/vuex/modules/incidents-module/action-types";

import IncidentItem from "./../components/IncidentItem/IncidentItem.vue";

export default {
  name: "IncidentTab",
  components: {
    IncidentItem,
  },
  data() {
    return {
      typeValue: "",
      error: false,
    };
  },
  computed: {
    ...mapGetters(["incidentsTypesOption"]),
  },
  methods: {
    ...mapActions({
      fetchIncidentsTypes: ActionTypes.FETCH_INCIDENTS_TYPES,
      updateIncidentType: ActionTypes.UPDATE_INCIDENT_TYPE,
      createIncidentType: ActionTypes.CREATE_INCIDENT_TYPE,
      deleteIncidentType: ActionTypes.DELETE_INCIDENT_TYPE,
    }),

    createIncidentTypeHandler() {
      if (!this.typeValue.trim()) {
        return (this.error = true);
      }
      this.createIncidentType({ name: this.typeValue });
      this.typeValue = "";
      this.error = false;
    },

    updateIncidentTypeHandler(id, value) {
      this.updateIncidentType({ id, value });
    },

    deleteIncidentTypeHandler(id) {
      this.deleteIncidentType(id);
    },
  },
  created() {
    this.fetchIncidentsTypes();
  },
};
</script>

<style lang="scss" >
.incident-tab {
  padding: 58px 0px 44px 34px !important;
  .incident-title {
    margin-bottom: 79px;
    font-weight: bold;
    font-size: 26px;
    color: #0d0e34;
  }

  .type-incident__wrapper {
    border: 1px solid #0000000f;
    box-shadow: 0px 0px 16px #0000000f;
    border-radius: 10px;

    display: flex;
    flex-direction: column;

    width: 100%;
  }

  .type-incident-list__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 32px 32px 32px;
  }

  .type-incident-title__wrapper {
    border-bottom: 1px solid #0000000f;
    h5 {
      padding: 22px 32px;
      font-size: 14px;
      font-weight: bold;
      color: #0d0e34;
    }
  }

  .incident-item {
    display: flex;
    align-items: center;
    gap: 13px;

    input {
      background: white;
      border: 1px solid #cccccc;
      border-radius: 24px;

      width: 307px;
      height: 27px;

      padding: 0 10px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #cccccc;
      }

      &:disabled {
        border: 1px solid transparent;
        color: #6d6d6d;
      }

      &.error {
        border: 1px solid red;
      }
    }

    .actions__wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .add-plus-icon,
    .trash-icon,
    .edit-type-icon {
      cursor: pointer;
      color: #cecece;
      stroke: #cecece;
    }
  }
}
</style>
