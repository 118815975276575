<template>
  <Modal :modal="accessModal">
    <template slot="header">
      <h3 class="access_modal_header">
        {{ headers[type] }}
      </h3>
    </template>
    <template slot="content">
      <p class="access_modal_content">
        {{ reasons[type] }}
        {{ actionsMessage }}
        <span v-if="showEmail">{{ email }}</span>
        .
      </p>
    </template>
    <template slot="footer">
      <div class="access_modal_footer">
        <button class="access_modal_footer_cancel" @click="$emit('close')">
          {{ $t("app.views.billing.goBack") }}
        </button>
        <button
          v-if="isOrgAdmin"
          class="access_modal_footer_save"
          @click="
            $router.push({
              name: 'settings',
              query: { tab: UserProfileTabs.BILLING },
            })
          "
        >
          {{ $t("app.views.billing.changePlan") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import { mapGetters } from "vuex";
import { UserProfileTabs } from "@/vuex/models/userProfile";

export default {
  name: "AccessModal",
  components: { Modal },
  props: {
    accessModal: {
      required: true,
      type: Boolean,
    },
    type: {
      required: true,
      type: String,
    },
    isOrgAdmin: {
      required: true,
      type: Boolean,
    },
    organisation: {
      required: false,
    },
  },
  data() {
    return {
      types: ["statistics", "users", "locations", "inspections", "externalAccesses", "qrCodes"],
      headers: {
        statistics: this.$t("app.views.planLimits.doNotHavAccess"),
        users: this.$t("app.views.planLimits.usersLimit"),
        locations: this.$t("app.views.planLimits.locationsLimit"),
        inspections: this.$t("app.views.planLimits.inspectionsLimit"),
        externalAccesses: this.$t("app.views.planLimits.externalAccessesLimit"),
        qrCodes: this.$t("app.views.planLimits.qrCodesLimit"),
      },
      reasons: {
        statistics: this.$t("app.views.planLimits.doesNotIncludeStatistic"),
        users: this.$t("app.views.planLimits.usersLimitReached"),
        locations: this.$t("app.views.planLimits.locationsLimitReached"),
        inspections: this.$t("app.views.planLimits.inspectionsLimitReached"),
        externalAccesses: this.$t("app.views.planLimits.externalAccessesLimitReached"),
        qrCodes: this.$t("app.views.planLimits.qrCodesLimitReached"),
      },
      actions: {
        changePlan: this.$t("app.views.planLimits.changePlan"),
        changePlanOrContact: this.$t(
          "app.views.planLimits.changePlanOrContact"
        ),
        contactAdmin: this.$t("app.views.planLimits.contactAdmin"),
      },
    };
  },
  computed: {
    UserProfileTabs() {
      return UserProfileTabs;
    },
    ...mapGetters(["me"]),
    actionsMessage() {
      if (!this.isOrgAdmin) {
        return this.actions.contactAdmin;
      }

      if (this.type === "statistics") {
        return this.actions.changePlan;
      }

      return this.actions.changePlanOrContact;
    },
    showEmail() {
      return !(this.type === "statistics" && this.isOrgAdmin) && !!this.email;
    },
    email() {
      const email = !!this.me.organisation
        ? this.me.organisation.admin_email
        : this.organisation?.admin?.email;
      return this.isOrgAdmin ? "hello@checly.app" : email;
    },
  },
};
</script>

<style lang="scss" scoped>
.access_modal_header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #0d0e34;
}

.access_modal_content {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0;

  span {
    color: #1de591;
  }
}

.access_modal_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    height: 48px;
    border: 2px solid #1de591;
    border-radius: 122px;
    outline: none;
    cursor: pointer;

    text-align: center;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0;

    display: flex;
    align-items: center;
    padding: 0 50px;
  }

  &_cancel {
    background-color: #fff;
    color: #1de591;

    &:hover {
      background-color: #1de591;
      color: #fff;
    }
  }

  &_save {
    background-color: #1de591;
    color: #fff;

    &:hover {
      box-shadow: 0 0 16px #00000029;
    }
  }
}
</style>
