export interface ErrorBagAll {
    [key: string]: string[];
}

export default class FormErrors {
    private errors: ErrorBagAll = {};

    public setErrors(errors: ErrorBagAll) {
        this.errors = errors;
    }

    public clearErrors() {
        this.errors = {};
    }

    /**
     * Get first message from error bag
     * @param key
     */
    public get(key: string): string | null {
        return this.hasErrors(key) ? this.errors[key][0] : null;
    }

    public hasErrors(key: string): boolean {
        return this.errors?.hasOwnProperty(key)
            && Array.isArray(this.errors[key])
            && this.errors[key].length >= 1
    }

}
