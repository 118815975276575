<template>
  <Modal class="modal-styles" :modal="isOpenNotificationModal">
    <template v-slot:header>
      <div class="comment-title">
        {{ $t("app.views.statistics.notificationModal.title") }}
      </div>
    </template>

    <template v-slot:content>
      <div class="content-text">
        {{
          auth.isOrganisationAdmin(me)
            ? $t("app.views.statistics.notificationModal.orgAdminText")
            : $t("app.views.statistics.notificationModal.notificationText")
        }}
      </div>
    </template>

    <template v-slot:footer>
      <div class="wrapper-buttons">
        <button class="goBack-button" @click="goBack">
          {{ $t("app.views.statistics.notificationModal.buttonGoBack") }}
        </button>
        <ButtonWithIcon
          v-if="auth.isOrganisationAdmin(me)"
          class="change-plan-button"
          @click="navigateSelectPlan"
        >
          <span slot="text">{{
            $t(
              "app.views.statistics.notificationModal.buttonNavigateSelectPlan"
            )
          }}</span>
        </ButtonWithIcon>
      </div>
    </template>
  </Modal>
</template>
  
  <script>
import Modal from "@/components/Modal/Modal.vue";
import Authorization from "@/services/authorization/authorization";
import { mapGetters } from "vuex";

export default {
  name: "NotificationModal",
  components: {
    Modal,
  },
  data() {
    return {
      auth: new Authorization(),
    };
  },
  props: {
    isOpenNotificationModal: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["me"]),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    navigateSelectPlan() {
      this.$router.push({ name: "select-subscription" });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.comment-title {
  color: #0d0e34;
  font-size: 18px;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.goBack-button {
  height: 48px;
  border: 2px solid #1de591;
  border-radius: 122px;
  outline: none;
  cursor: pointer;

  text-align: center;
  font-size: 14px;
  color: #1de591;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;

  background-color: white;

  display: flex;
  align-items: center;
  padding: 0 50px;

  transition: all 0.3s;
  &:hover {
    background-color: #1de591;
    color: white;
    transition: all 0.3s;
  }
}

.change-plan-button {
  font-size: 14px;

  border: 1px solid #1de591;

  &:hover {
    border: 1px solid #1de591;
    color: white;
  }
}

.content-text {
  padding: 0 90px;
  text-align: center;
  color: #6d6d6d;
  font-size: 12px;
  margin-bottom: 80px;
}

.wrapper-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>