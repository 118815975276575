import { loadStripe, StripePaymentElementOptions } from "@stripe/stripe-js";
import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import i18n from "@/lang/locale";
import { StripeElements } from "@stripe/stripe-js/types/stripe-js/elements-group";
import {
  StripeAddressElement,
  StripePaymentElement,
} from "@stripe/stripe-js/types/stripe-js/elements";
import store from "@/vuex/store";
import { ActionTypes } from "@/vuex/modules/billing-module/action-types";
import { UserProfileTabs } from "@/vuex/models/userProfile";
import StripeAddress from "@/vuex/models/billing/address";

export default class StripeService {
  private stripe!: Stripe | null;
  private elements!: StripeElements;
  private paymentElement!: StripePaymentElement;
  private addressElement!: StripeAddressElement;

  public async init() {
    await store.dispatch(ActionTypes.FETCH_PUBLIC_KEY).then((response) => {
      loadStripe(response.data).then((stripe: Stripe | null) => {
        this.stripe = stripe;
      });
    });
  }

  public createElements(clientSecret: string, theme: string) {
    let isThemeDark =
      theme === "theme-dark" ||
      (theme === "theme-default" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    const appearance = {
      theme: "none",
      variables: {
        colorPrimary: "#1DE591",
        colorBackground: isThemeDark ? "#000000" : "#FFFFFF",
        colorText: isThemeDark ? "#ffffff" : "#6D6D6D",
        colorDanger: "#DF1B41",
        fontFamily: "Poppins",
        spacingUnit: "4.5px",
        borderRadius: "24px",
      },
      rules: {
        ".Input": {
          border: isThemeDark ? "1px solid white" : "1px solid #E6E6E6",
          boxShadow: "0px 0px 16px #0000000F",
          fontFamily: "Poppins",
          fontSize: "12px",
          lineHeight: "18px",
          bakgroundColor: "#000",
        },
        ".Input--invalid": {
          border: "1px solid red",
        },
        ".Label": {
          fontFamily: "Poppins",
          fontSize: "12px",
          lineHeight: "18px",
          paddingLeft: "20px",
        },
        ".TermsText": {
          fontFamily: "Poppins",
          fontSize: "10px",
          lineHeight: "16px",
          color: isThemeDark ? "#cecece" : "#707070",
        },
        ".Error": {
          fontFamily: "Poppins",
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    };

    const options = {
      clientSecret: clientSecret,
      locale: i18n.locale,
      appearance: appearance,
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Poppins",
        },
      ],
    };

    // @ts-ignore
    this.elements = this.stripe?.elements(options);

    return this;
  }

  public mountPaymentElement(elementDom: string): void {
    const paymentOptions: StripePaymentElementOptions = {
      paymentMethodOrder: ["card"],
      wallets: {
        applePay: "never",
        googlePay: "never",
      },
    };

    this.paymentElement = this.elements.create("payment", paymentOptions);
    this.paymentElement.mount(elementDom);
  }

  public async mountAddressElement(
    elementDom: string,
    companyName: string | null,
    stripeAddress: StripeAddress | null
  ) {
    const countriesList = await this.getCountriesList();
    const address = this.setAddress(stripeAddress);

    // @ts-ignore
    this.addressElement = this.elements.create("address", {
      mode: "billing",
      allowedCountries: countriesList,
      display: {
        name: "organization",
      },
      defaultValues: {
        name: companyName,
        address: address,
      },
      fields: {
        phone: "never",
      },
    });

    this.addressElement.mount(elementDom);
  }

  public async savePaymentMethod() {
    if (!!this.stripe) {
      const options = {
        elements: this.elements,
        confirmParams: {
          return_url:
            window.location.origin + "/settings?tab=" + UserProfileTabs.BILLING,
        },
      };

      return await this.stripe.confirmSetup(options);
    }
  }

  public async getAddressValues() {
    return await this.addressElement.getValue();
  }

  private async getCountriesList(): Promise<string[]> {
    const response = await store.dispatch(ActionTypes.FETCH_COUNTRIES_LIST);

    return response.data.data.map(
      (country: { value: string; name: string }) => country.value
    );
  }

  private setAddress(stripeAddress: StripeAddress | null) {
    let address;
    if (!!stripeAddress) {
      address = {
        line1: stripeAddress.line1,
        line2: stripeAddress.line2,
        city: stripeAddress.city,
        state: stripeAddress.state,
        postal_code: stripeAddress.postal_code,
        country: stripeAddress.country,
      };
    } else {
      address = {
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "90483",
        country: "US",
      };
    }

    return address;
  }
}
