import { render, staticRenderFns } from "./TemplateView.vue?vue&type=template&id=460708d1"
import script from "./TemplateView.ts?vue&type=script&lang=ts&external"
export * from "./TemplateView.ts?vue&type=script&lang=ts&external"
import style0 from "./TemplateView.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports