export enum Direction {
    ASC = 'asc',
    DESC = 'desc'
}

export enum UsersSortableColumns {
    CREATED_AT = 'created_at',
    NAME = 'name',
    EMAIL = 'email',
    ROLE = 'role'
}

export enum AccessSortableColumns {
  STATUS = 'status',
  NAME = 'name',
  EMAIL = 'email',
}

export enum OrganisationsSortableColumns {
    CREATED_AT = 'created_at',
    TIMEZONE = 'timezone',
    NAME = 'name',
    USERS_LIMIT = 'users_limit',
    LOCATIONS_LIMIT = 'locations_limit',
    INSPECTIONS_LIMIT = 'inspections_limit',
    ACTIVE = 'active'
}

export enum LocationsSortableColumns {
    CREATED_AT = 'created_at',
    NAME = 'name',
}

export enum QRCodeSortableColumns {
    CREATED_AT = 'created_at',
    NAME = 'name',
}


export enum TemplatesSortableColumns {
    CREATED_AT = 'created_at',
    NAME = 'name',
    CREATOR_NAME = 'creator_name'
}

export enum InspectionsSortableColumns {
    CREATED_AT = 'created_at',
    NAME = 'name',
    DATE = 'date',
    START = 'start_datetime',
    END = 'end_datetime'
}

export enum IncidentsSortableColumns {
    CREATED_AT = 'created_at',
    ANSWER = 'answer',
    QUESTION = 'question_name',
    DATE = 'date',
    SOLVED = 'solved',
    SOLVED_DATE = 'solved_date',
    SOLVED_BY = 'solved_by'
}

export enum ReportsSortableColumns {
    CREATED_AT = 'created_at',
    LOCATION_NAME = 'location_name',
    INSPECTOR_NAME = 'inspector_name',
    SCORE = 'score'
}

export enum LogsSortableColumns {
    CREATED_AT = 'created_at',
    ACTION = 'action',
    USER_NAME = 'user_name',
    USER_ROLE = 'user_role'
}

export enum OrganisationRequestsSortableColumns {
    CREATED_AT = 'created_at',
    EMAIL = 'email'
}

export type SortHelper = (column: string, searchParams: any) => any;

const sort: SortHelper = function (column: string, searchParams: any): any {
    if (column === searchParams.column) {
        searchParams = setDirection(searchParams);
    } else {
        searchParams.column = column;
        searchParams.direction = Direction.ASC;
    }

    return searchParams;
}

function setDirection(searchParams: any): any {
    switch (searchParams.direction) {
        case null:
            searchParams.direction = Direction.ASC;
            break;
        case Direction.ASC:
            searchParams.direction = Direction.DESC;
            break;
        case Direction.DESC:
            searchParams.direction = null;
            searchParams.column = null;
    }

    return searchParams;
}

export default sort;
