import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import i18n from "@/lang/locale";

export const token = (): string | null => localStorage.getItem('token');

export const http = (config: AxiosRequestConfig = {}): AxiosInstance =>
  axios.create(Object.assign(
    config,
    {
      headers: {Authorization: "Bearer " + token(), 'Language': i18n.locale},
      baseURL: process.env.VUE_APP_API_BASE_URL
    }
  ));
