import {MutationTree} from "vuex";
import {MutationTypes} from "@/vuex/modules/languages-module/mutation-types";
import LanguagesState from "@/vuex/modules/languages-module/state";
import LanguageModel from "@/vuex/models/language/language";
import LanguageAvailable from "@/vuex/models/language/language-available";
import LanguageWord from "@/vuex/models/language/langauge-word";
import LanguageWithWords from "@/vuex/models/language/language-with-words";

const mutations: MutationTree<LanguagesState> = {
    [MutationTypes.SET_LANGUAGES](state: LanguagesState, languages: LanguageModel[]): void {
        state.languages = languages;
    },
    [MutationTypes.SET_AVAILABLE_LANGUAGES](state: LanguagesState, availableLanguages: LanguageAvailable[]): void {
        state.languagesAvailable = availableLanguages;
    },
    [MutationTypes.SET_LANGUAGE_WORDS](state: LanguagesState, languageWords: LanguageWord[]): void {
        state.languageWords = languageWords;
    },
    [MutationTypes.SET_LANGUAGE](state: LanguagesState, language: LanguageWithWords): void {
        state.language = language;
    },
    [MutationTypes.SET_DEFAULT_LANGUAGE](state: LanguagesState, language: LanguageWithWords): void {
        state.languageDefault = language;
    },


    [MutationTypes.SET_LOCALE_FROM_BROWSER](state: LanguagesState, locale: string): void {
        state.browserLocale = locale;
    },
    [MutationTypes.SET_TIMEZONE_FROM_BROWSER](state: LanguagesState, timezone: string): void {
        state.browserTimezone = timezone ? timezone : 'Europe/Warsaw';
    },
}

export default mutations;
