<template>
  <Modal :modal="!!id">
    <div class="header" slot="header">
      {{ `${$t("components.text.deleteTitle")} "${name}"` }}
    </div>
    <div class="content" slot="content">
      {{ $t("app.views.qrCodes.deleteNotification") }}
    </div>
    <div class="footer" slot="footer">
      <ButtonsSaveCancel
        @close="$emit('close', false)"
        @save="remove"
        :danger="true"
      >
        <span class="delete-button" slot="btn-save">
          {{ $t("components.delete") }}
        </span>
      </ButtonsSaveCancel>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/vuex/modules/qr-code-module/action-types";

export default {
  name: "DeleteLocationModal",
  emits: ["close"],
  components: { ButtonsSaveCancel, Modal },
  props: {
    id: {
      required: true,
      type: Number,
    },
    name: {
      required: true,
      type: String,
    },
  },
  methods: {
    ...mapActions({
      deleteQrCode: ActionTypes.DELETE_QR_CODE,
    }),
    async remove() {
      await this.deleteQrCode(this.id);
      await this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  font: normal normal 600 18px/42px Poppins;
  letter-spacing: 0;
  color: #ff5151;
}

.content {
  text-align: center;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0;
  color: #3d3d3d;
  margin: 0;
}

.footer {
  width: 100%;
  padding: 0;
  background: none;

  .ButtonsSaveCancel {
    justify-content: space-between;
  }

  .delete-button {
    min-width: 220px;
  }
}
</style>
