import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { PageMixin } from "@/mixins/pageMixin";
import { ActionTypes } from "@/vuex/modules/reports-module/action-types";
import { ReportsSearchParams } from "@/vuex/modules/reports-module/actions";
import { Debounce } from "vue-debounce-decorator";
import Report from "@/vuex/models/report/report";
import Meta from "@/vuex/models/meta";
import { ReportsSortableColumns } from "@/services/helpers/sort";
import DateToString from "@/services/dateToString/dateToString";
import i18n from "@/lang/locale";

@Component({
  methods: mapActions({
    fetchReports: ActionTypes.FETCH_REPORTS,
    fetchReportsForCsv: ActionTypes.FETCH_REPORTS_FOR_CSV,
  }),
  computed: mapGetters({
    reports: "reports",
    reportsForCsv: "reportsForCsv",
    meta: "reportsMeta",
  }),
})
export default class ReportsView extends Mixins(PageMixin) {
  protected readonly fetchReports!: (
    params: ReportsSearchParams
  ) => Promise<void>;
  protected readonly fetchReportsForCsv!: (
    params: ReportsSearchParams
  ) => Promise<void>;

  protected readonly reports!: Report[];
  protected readonly reportsForCsv!: Report[];
  protected readonly meta!: Meta;
  protected readonly sortableColumns = ReportsSortableColumns;
  protected readonly dateToString: DateToString = new DateToString();

  protected searchParams: ReportsSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
    location_id: null,
    is_mine: null,
  };

  protected async created(): Promise<void> {
    if (!!this.$route.query.page) {
      this.searchParams.page = Number(this.$route.query.page);
    }

    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchReports(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected async setPage(page: number): Promise<void> {
    this.searchParams.page = page;
    await this.$router.replace({ query: { page: page.toString() } });
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  @Debounce(350)
  protected searchReports(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  private generateHeaders() {
    return [
      i18n.t("components.table.name").toString(),
      i18n.t("components.table.date").toString(),
      i18n.t("components.table.location").toString(),
      i18n.t("components.table.inspector").toString(),
      i18n.t("components.table.score").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchReportsForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.reportsForCsv.map((el) => ({
        [i18n.t("components.table.name").toString()]: el.inspection_name,
        [i18n.t("components.table.date").toString()]:
          this.dateToString.getLocaleString(new Date(el.created_at), [
            this.dateToString.LONG_DATE,
          ]),
        [i18n.t("components.table.location").toString()]: el.location_name,
        [i18n.t("components.table.inspector").toString()]: el.inspector_name,
        [i18n.t("components.table.score").toString()]: el.score,
      })),
      this.generateHeaders(),
      "reports.csv"
    );
  }
}
