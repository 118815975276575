import {MutationTree} from "vuex";
import NotificationsState, {Notification} from "@/vuex/modules/notifications-module/state";
import {MutationTypes} from "./mutation-types";


const mutations: MutationTree<NotificationsState> = {
    [MutationTypes.ADD_NOTIFICATION](state: NotificationsState, notification: Notification) {
        state.notifications.push(notification);
    },

    [MutationTypes.REMOVE_NOTIFICATION](state: NotificationsState, notification: Notification) {
        state.notifications = state.notifications
            .filter((stateNotification: Notification) => stateNotification !== notification);
    },

    [MutationTypes.TOGGLE_FLUSH_NOTIFICATIONS](state: NotificationsState, toggle: boolean) {
        state.flushNotifications = toggle;
    }
}

export default mutations;
