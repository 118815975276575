import StylesButton from "@/components/Buttons/StylesButton.vue";
import { Component, Prop } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import { FormMixin } from "@/mixins/formMixin";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import {
  Direction,
  LocationsSortableColumns,
  UsersSortableColumns,
} from "@/services/helpers/sort";
import { Debounce } from "vue-debounce-decorator";
import { ActionTypes as LocationActions } from "@/vuex/modules/locations-module/action-types";
import { LocationSearchParams } from "@/vuex/modules/locations-module/actions";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as IncidentActions } from "@/vuex/modules/incidents-module/action-types";
import IncidentData from "@/vuex/models/incident/incident-data";
import { IncidentPriorityOption } from "@/vuex/models/incident/incident-priority-option";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import i18n from "@/lang/locale";
import store from "@/vuex/store";

@Component({
  methods: mapActions({
    fetchLocations: LocationActions.FETCH_LOCATIONS,
    fetchUsers: UserActions.FETCH_USERS,
    addIncident: IncidentActions.ADD_INCIDENT,
    fetchIncidentsTypes: IncidentActions.FETCH_INCIDENTS_TYPES,
  }),
  computed: mapGetters({
    locationOptions: "locationSelectOptions",
    userOptions: "userSelectOptions",
    incidentsTypesOption: "incidentsTypesOption",
  }),
  components: {
    InputSelectOrganisation,
    ButtonsSaveCancel,
    Field,
    StylesButton,
  },
})
export default class CreateIncidentModal extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public isActionIncidentModal!: boolean;

  protected readonly fetchLocations!: (
    params: LocationSearchParams
  ) => Promise<void>;
  protected readonly fetchIncidentsTypes!: () => Promise<void>;
  protected readonly fetchUsers!: (params: UserSearchParams) => Promise<void>;
  protected readonly addIncident!: (params: {
    data: IncidentData;
  }) => Promise<void>;
  protected innerWidth: number = window?.innerWidth;

  protected readonly incidentPriorityOptions: SelectOption[] = [
    {
      label: i18n.t("app.views.priority.defaultOption").toString(),
      value: IncidentPriorityOption.DEFAULT,
    },
    {
      label: i18n.t("app.views.priority.urgentOption").toString(),
      value: IncidentPriorityOption.URGENT,
    },
  ];
  protected readonly defaultTypeOptions = [
    {
      label: i18n.t("app.views.typeIncident.other").toString(),
      value: 0,
    },
  ];

  protected readonly locationOptions!: SelectOption[];
  protected userOptions!: SelectOption[];
  protected inspectorOptions: SelectOption[] = [];

  protected incidentData: IncidentData = {
    name: null,
    description: null,
    location_id: null,
    assigned_to: null,
    incident_level: null,
    incident_type_id: null,
    image: null,
  };

  protected locationSearchParams: LocationSearchParams = {
    page: 1,
    per_page: 100,
    column: LocationsSortableColumns.NAME,
    direction: Direction.ASC,
    search: null,
    organisation_id: null,
  };

  protected userSearchParams: UserSearchParams = {
    page: 1,
    per_page: 100,
    column: UsersSortableColumns.NAME,
    direction: Direction.DESC,
    search: null,
    locations_ids: [],
    organisation_id: null,
    role: null,
  };

  created() {
    if (!this.auth.isSystemAdmin(this.me)) {
      this.fetchIncidentsTypes();
    }
    if (this.auth.isInspector(this.me)) {
      this.inspectorOptions = [
        {
          label: `${this.me.name} ${this.me.surname}`,
          value: this.me.id,
        },
      ];
    } else {
      this.fetchUsers(this.userSearchParams);
    }
  }

  protected async selectLocation(locationId: number): Promise<void> {
    this.incidentData.location_id = +locationId;

    if (!this.auth.isInspector(this.me)) {
      this.userSearchParams.locations_ids = [locationId];
      await this.fetchUsers(this.userSearchParams);
    }
  }

  protected selectUser(userId: number) {
    this.incidentData.assigned_to = +userId;
  }

  protected selectIncidentType(incidentTypeId: number) {
    this.incidentData.incident_type_id = incidentTypeId;
  }

  protected selectPriorityOption(
    priorityValue:
      | IncidentPriorityOption.DEFAULT
      | IncidentPriorityOption.URGENT
  ) {
    this.incidentData.incident_level = priorityValue;
  }

  @Debounce(350)
  protected async searchLocations(search: string | null): Promise<void> {
    this.locationSearchParams.search = search;
    await this.fetchLocations(this.locationSearchParams);
  }

  @Debounce(350)
  protected async searchUsers(search: string | null): Promise<void> {
    this.userSearchParams.search = search;
    await this.fetchUsers(this.userSearchParams);
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");
    try {
      await this.createIncident();
      this.closeModal(true);
    } catch (exception: any) {
      await this.formErrors.setErrors(exception.response.data.errors);
    }
    this.$emit("loading");
  }

  protected addImage(event: any) {
    if (event.target.files[0].size > 8388608) {
      return store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: i18n
          .t(
            "app.views.inspection.execution.notificationMessage.isValidateImage"
          )
          .toString(),
        type: NotificationType.DANGER,
      });
    }
    this.incidentData.image = event.target.files[0];
    const imageInput: any = document.getElementById("imageInput");
    imageInput.value = null;
  }

  protected closeModal(submited: boolean) {
    this.$emit("close", submited);
    this.formErrors.clearErrors();
    this.incidentData = {
      name: null,
      description: null,
      location_id: null,
      assigned_to: null,
      incident_level: null,
      incident_type_id: null,
      image: null,
    };
  }

  protected async createIncident(): Promise<void> {
    await this.addIncident({ data: this.incidentData });
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: "Incident has been created.",
      type: NotificationType.SUCCESS,
    });
  }
}
