<template>
  <div class="billing">
    <BillingContainer
        :title="$tc('app.views.billing.subscription', 1).toString()"
        :show-actions="showSubscriptionAction"
        :has-action="subscriptionHasAction"
        :button-label="subscriptionButtonLabel"
        :help-text="subscriptionHelpText"
        @click="subscriptionAction"
    >
      <template v-slot:content>
        <SubscriptionTable @loading="$emit('loading')"/>
      </template>
    </BillingContainer>

    <BillingContainer
        :title="$t('app.views.billing.paymentMethods').toString()"
        :has-action="!!customer && ! customer.manual_payment"
        :button-label="$t('app.views.billing.update').toString()"
        :action-text="$t('app.views.billing.cantPayByCard').toString()"
        @click="$router.push({name: 'payment-method-form'})"
        @action="$router.push({name: 'cant-pay-by-card'})"
    >
      <template v-slot:content>
        <PaymentMethod @loading="$emit('loading')"/>
      </template>
    </BillingContainer>

    <BillingContainer
        :title="$t('app.views.billing.billingAddress').toString()"
        :has-action="addressHasAction"
        :button-label="$t('app.views.billing.update').toString()"
        @click="$router.push({name: 'billing-address-form'})"
    >
      <template v-slot:content>
        <BillingAddress @loading="$emit('loading')"/>
      </template>
    </BillingContainer>

    <BillingContainer :show-actions="false" :title="$t('app.views.billing.invoices').toString()">
      <template v-slot:content>
        <InvoicesTable @loading="$emit('loading')"/>
      </template>
    </BillingContainer>
  </div>
</template>

<script>
import BillingContainer from "@/views/UserProfile/components/BillingComponents/BillingContainer.vue";
import SubscriptionTable from "@/views/UserProfile/components/BillingComponents/SubscriptionTable.vue";
import PaymentMethod from "@/views/UserProfile/components/BillingComponents/PaymentMethodTable.vue";
import BillingAddress from "@/views/UserProfile/components/BillingComponents/BillingAddress.vue";
import InvoicesTable from "@/views/UserProfile/components/BillingComponents/InvoicesTable.vue";
import {mapGetters} from "vuex";
import {ActionTypes} from "@/vuex/modules/billing-module/action-types";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";
import {UserProfileTabs} from "@/vuex/models/userProfile";

export default {
  name: "BillingTab",
  emits: ['loading'],
  components: {
    InvoicesTable,
    BillingAddress,
    PaymentMethod,
    SubscriptionTable,
    BillingContainer
  },
  async created() {
    this.$emit('loading');

    if (!this.customer) {
      await this.$store.dispatch(ActionTypes.FETCH_CUSTOMER);
    }

    if (!this.subscription) {
      await this.$store.dispatch(ActionTypes.FETCH_SUBSCRIPTION);
    }

    if (!this.paymentMethod) {
      await this.$store.dispatch(ActionTypes.FETCH_PAYMENT_METHOD);
    }

    if (!this.address) {
      await this.$store.dispatch(ActionTypes.FETCH_ADDRESS);
    }

    if (!this.invoices) {
      await this.$store.dispatch(ActionTypes.FETCH_INVOICES);
    }

    if (!!this.$route.query.redirect_status && this.$route.query.redirect_status === 'succeeded') {
      await this.confirmPaymentMethod();
    }

    this.$emit('loading');
  },
  computed: {
    ...mapGetters(['me', 'customer', 'subscription', 'address', 'paymentMethod', 'invoices']),
    showSubscriptionAction() {
      if (!!this.subscription && !!this.subscription.cancel_at) {
        return true;
      }

      if (!!this.subscription && this.subscription.items.length === 1) {
        const item = this.subscription.items[0];
        return item.product.slug === 'pro' || item.product.slug === 'team';
      }

      if (!!this.subscription && this.subscription.items.length > 1) {
        return false;
      }

      return !(!!this.customer && this.customer.manual_payment);
    },
    subscriptionHasAction() {
      return !!this.customer
          && !!this.customer.tax_number
          && !this.customer.manual_payment
          && !!this.address
          && !!this.paymentMethod;
    },
    subscriptionButtonLabel() {
      return !!this.subscription && this.subscription.cancel_at
          ? this.$t('app.views.billing.resume')
          : this.$t('app.views.billing.changePlan');
    },
    subscriptionHelpText() {
      return !this.subscriptionHasAction
          ? this.$t('app.views.billing.updatePaymentAndAddress').toString()
          : '';
    },
    addressHasAction() {
      return this.paymentMethod || this.customer.manual_payment ;
    }
  },
  methods: {
    async confirmPaymentMethod() {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: 'Payment method has been added.',
        type: NotificationType.SUCCESS
      });

      await this.$router.replace({query: {tab: UserProfileTabs.BILLING}});
      await this.$store.dispatch(ActionTypes.FETCH_PAYMENT_METHOD);
    },
    async subscriptionAction() {
      this.$emit('loading');

      if (!!this.subscription && this.subscription.cancel_at) {
        await this.$store.dispatch(ActionTypes.RESUME_SUBSCRIPTION, this.subscription.id);
      } else {
        await this.$router.push({name: 'select-subscription'});
      }

      this.$emit('loading');
    }
  }
}
</script>

<style scoped lang="scss">
.billing {
  display: flex;
  flex-direction: column;
}
</style>
