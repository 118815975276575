<template>
  <div>
    <input
      :disabled="disabledButton"
      :placeholder="$t('app.views.user.placeholder.addType')"
      v-model="typeValue"
      ref="inputField"
      :class="{ error: error }"
    />
    <div class="actions__wrapper">
      <SvgIcon
        @click.stop="disabledButton = false"
        class="edit-type-icon"
        height="20"
        name="edit-type"
        width="20"
      />
      <SvgIcon
        @click="$emit('deleteType', incidentOption.value)"
        class="trash-icon"
        height="20"
        name="trash"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import { ActionTypes } from "@/vuex/modules/incidents-module/action-types";
import { mapActions } from "vuex";
export default {
  name: "IncidentItem",
  props: {
    incidentOption: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      disabledButton: true,
      typeValue: "",
      error: false,
    };
  },
  created() {
    this.typeValue = this.incidentOption.label;
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapActions({
      fetchIncidentsTypes: ActionTypes.FETCH_INCIDENTS_TYPES,
    }),
    handleClickOutside(event) {
      if (!this.typeValue.trim()) {
        return (this.error = true);
      }
      const inputField = this.$refs.inputField;
      if (
        this.incidentOption.label !== this.typeValue &&
        !this.disabledButton &&
        inputField instanceof HTMLElement &&
        !inputField.contains(event.target)
      ) {
        this.disabledButton = true;
        this.$emit("update", this.incidentOption.value, this.typeValue);
        this.error = false;
      } else if (
        !this.disabledButton &&
        inputField instanceof HTMLElement &&
        !inputField.contains(event.target)
      ) {
        this.disabledButton = true;
      }
    },
  },
};
</script>