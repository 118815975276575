import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import ExternalAccessState from "./state";
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const module: Module<ExternalAccessState, RootState> = {
    state: new ExternalAccessState(),
    actions,
    mutations,
    getters,
}

export default module
