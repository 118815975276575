import { MutationTree } from "vuex";
import LogsState from "./state";
import { MutationTypes } from "./mutation-types";
import Meta from "@/vuex/models/meta";
import Log from "@/vuex/models/log/log";

const mutations: MutationTree<LogsState> = {
  [MutationTypes.SET_LOGS](state: LogsState, logs: Log[]): void {
    state.logs = logs;
  },
  [MutationTypes.SET_LOGS_FOR_CSV](state: LogsState, logs: Log[]): void {
    state.logsForCsv = logs;
  },
  [MutationTypes.SET_LOGS_META](state: LogsState, meta: Meta): void {
    state.meta = meta;
  },
};

export default mutations;
