<template>
  <div>
    <div :class="{ DragActive: questionDragActive }">
      <draggable
        v-if="section.questions.length > 0"
        class="Questions"
        handle=".question-drag-handle"
        v-model="section.questions"
        @start="questionDragActive = true"
        @end="questionDragActive = false"
      >
        <div
          class="Question"
          v-for="(question, index) of section.questions"
          :key="index"
        >
          <div class="TopLine">
            <div class="Row">
              <SvgIcon
                name="list"
                width="30"
                height="30"
                :class="'ListIcon ' + (!disabled && 'question-drag-handle')"
              />
              <input
                class="question-input"
                :placeholder="$t('app.views.library.form.writeQuestion')"
                v-model="question.name"
                :class="{
                  'has-error': formErrors.hasErrors(
                    'sections.' + sectionIndex + '.questions.' + index + '.name'
                  ),
                }"
                v-on:keydown.enter.prevent="$event.preventDefault()"
                :disabled="disabled"
              />
              <SvgIcon
                name="trash"
                class="TrashIcon"
                width="24"
                height="24"
                @click="removeQuestion(index)"
                v-if="!disabled"
              />
            </div>

            <div class="Row displayRow">
              <div class="cell-1">
                <span class="cell-head">
                  {{ $t("app.views.library.form.answerType") }}</span
                >
                <InputSelect
                  class="question-select"
                  :options="questionTypes"
                  :value="question.type"
                  :isChagneStylesForTemplate="true"
                  @select="question.type = $event"
                  :disabled="disabled"
                  is-minimal
                  :has-error="
                    formErrors.hasErrors(
                      'sections.' +
                        sectionIndex +
                        '.questions.' +
                        index +
                        '.type'
                    )
                  "
                />
              </div>
              <div v-if="canHaveIncident(question.type)" class="cell-2">
                <span class="cell-head">{{
                  $t("app.views.library.form.incident")
                }}</span>
              </div>
              <div v-if="canHaveIncident(question.type)" class="cell-3"></div>
              <div v-if="canHaveIncident(question.type)" class="cell-4">
                <div class="cell-container">
                  <span class="cell-head">{{
                    $t("app.views.library.form.weight")
                  }}</span>
                  <input
                    v-if="!disabled && canHaveIncident(question.type)"
                    type="text"
                    placeholder="00"
                    v-model="question.weight"
                    class="weight-input"
                    :class="{
                      'has-error': formErrors.hasErrors(
                        'sections.' +
                          sectionIndex +
                          '.questions.' +
                          index +
                          '.weight'
                      ),
                    }"
                    :disabled="disabled"
                  />
                  <p v-else-if="canHaveIncident(question.type)" class="mx-auto">
                    {{ question.weight }}
                  </p>
                  <div v-else class="mx-auto"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="TopLine"
            v-if="
              formErrors.hasErrors(
                'sections.' +
                  sectionIndex +
                  '.questions.' +
                  index +
                  '.answer_options'
              )
            "
          >
            <p class="danger has-error">
              {{
                formErrors.get(
                  "sections." +
                    sectionIndex +
                    ".questions." +
                    index +
                    ".answer_options"
                )
              }}
            </p>
          </div>

          <AnswerOptions
            :question="question"
            :disabled="disabled"
            :form-errors="formErrors"
            :question-drag-active="questionDragActive"
            :section-drag-active="sectionDragActive"
            v-on:update:question="question = $event"
          ></AnswerOptions>

          <div class="TopLine">
            <div class="ExtraActions">
              <div
                v-if="
                  canHaveIncident(question.type) &&
                  TemplateQuestionType.SINGLE_CHOICE !== question.type
                "
                class="Action"
              >
                <InputCheckbox
                  v-model="question.reverse_score"
                  :disabled="disabled"
                  @input="setReverseScore(question, $event)"
                />
                <span>{{ $t("app.views.library.form.revertScore") }}</span>
              </div>
              <div class="Action">
                <InputCheckbox
                  v-model="question.required"
                  :disabled="disabled"
                  @input="setRequired(question, $event)"
                />
                <span>{{ $t("app.views.library.form.required") }}</span>
              </div>
              <div
                v-if="
                  canHaveIncident(question.type) &&
                  TemplateQuestionType.SINGLE_CHOICE !== question.type
                "
                class="Action"
              >
                <InputCheckbox
                  :value="
                    !!question.answer_options.find(
                      (option) =>
                        option.answer_option === NOT_APPLICABLE_OPTION()
                    )
                      ? 1
                      : 0
                  "
                  :disabled="disabled"
                  @input="setNotApplicableAnswer(question, $event)"
                />
                <span>{{ $t("app.views.library.form.addNotApplicable") }}</span>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>

    <div class="AddQuestion" @click="addQuestion()" v-if="!disabled">
      <img src="/images/icons/template/plus.svg" alt="plus-icon" />
      <p>{{ $t("app.views.library.form.addQuestion") }}</p>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "@/components/InputCheckbox/InputCheckbox.vue";
import AnswerOptions from "./AnswerOptions.vue";
import { TemplateQuestionType } from "@/vuex/models/template/template-question-type";
import { NOT_APPLICABLE_OPTION } from "@/vuex/models/template/template-answer-option";
import i18n from "@/lang/locale";

export default {
  name: "TemplateQuestionTable",
  emits: ["update:section"],
  components: {
    InputCheckbox,
    AnswerOptions,
  },
  data() {
    return {
      questionDragActive: false,
      questionTypes: [
        {
          label: i18n.t("app.views.library.questionTypes.yesNo").toString(),
          value: TemplateQuestionType.YES_NO,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.scaleUpTo5")
            .toString(),
          value: TemplateQuestionType.SCALE_1_TO_5,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.scaleUpTo10")
            .toString(),
          value: TemplateQuestionType.SCALE_1_TO_10,
        },
        {
          label: i18n.t("app.views.library.questionTypes.number").toString(),
          value: TemplateQuestionType.NUMBER,
        },
        {
          label: i18n.t("app.views.library.questionTypes.text").toString(),
          value: TemplateQuestionType.TEXT,
        },
        {
          label: i18n.t("app.views.library.questionTypes.textarea").toString(),
          value: TemplateQuestionType.COMMENT,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.singleChoice")
            .toString(),
          value: TemplateQuestionType.SINGLE_CHOICE,
        },
        {
          label: i18n.t("app.views.library.questionTypes.signature").toString(),
          value: TemplateQuestionType.SIGNATURE,
        },
        {
          label: i18n
            .t("app.views.library.questionTypes.geolocation")
            .toString(),
          value: TemplateQuestionType.GEOLOCATION,
        },
      ],
    };
  },
  computed: {
    TemplateQuestionType() {
      return TemplateQuestionType;
    },
    sectionIndex() {
      return this.section.order - 1;
    },
  },
  props: {
    section: {
      required: true,
    },
    formErrors: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    sectionDragActive: {
      required: true,
      type: Boolean,
    },
  },
  created() {
    this.mapQuestions();
  },
  methods: {
    NOT_APPLICABLE_OPTION() {
      return NOT_APPLICABLE_OPTION;
    },
    setIncident(question, value) {
      question.has_incident = value ? 1 : 0;

      if (!value) {
        question.answer_options.forEach((option) => {
          option.incident_level = null;
          option.has_incident = false;
        });
      }
    },
    setRequired(question, value) {
      question.required = value ? 1 : 0;
    },
    setReverseScore(question, value) {
      question.reverse_score = value ? 1 : 0;
    },
    setNotApplicableAnswer(question, value) {
      const notApplicableOption = {
        answer_option: this.NOT_APPLICABLE_OPTION(),
        has_incident: false,
        incident_level: null,
        order: question.answer_options.length + 1,
        score_points: 0,
      };

      if (value) {
        question.answer_options.push(notApplicableOption);
      } else {
        const index = question.answer_options.indexOf(notApplicableOption);
        question.answer_options.splice(index, 1);
      }
    },
    addQuestion() {
      this.section.questions.push({
        id: null,
        name: null,
        order: this.section.questions.length + 1,
        weight: 1,
        type: TemplateQuestionType.YES_NO,
        reverse_score: 0,
        answer_options: [],
        has_incident: 0,
        required: 1,
      });
    },
    removeQuestion(index) {
      this.section.questions.splice(index, 1);
      this.setQuestionOrder();
    },
    setQuestionOrder() {
      this.section.questions.forEach((question, i) => (question.order = i + 1));
    },
    canHaveIncident(type) {
      return (
        TemplateQuestionType.YES_NO === type ||
        TemplateQuestionType.SCALE_1_TO_5 === type ||
        TemplateQuestionType.SCALE_1_TO_10 === type ||
        TemplateQuestionType.SINGLE_CHOICE === type
      );
    },
    mapQuestions() {
      this.section.questions.forEach((question) => {
        question.type = !!question.type.id ? question.type.slug : question.type;
      });
    },
  },
  watch: {
    questionDragActive() {
      if (!this.questionDragActive && this.section.questions.length > 0) {
        this.setQuestionOrder();
      }
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";

$table-width: 1255;

#canvas-measure-text {
  display: none;
}

.CustomSelect {
  position: relative;
  height: 24px;
  margin-right: 25px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 24px;
    padding-right: 20px;
  }

  svg {
    position: absolute;
    top: calc((100% - 20px) / 2);
    right: 0;

    color: #6d6d6d;
  }
}

.InspectionTemplateQuestionsPseudoTable {
  width: 100%;

  .question-select {
    padding-right: 20px;
    font-weight: 400;
    width: 173px;
  }

  .input-select-content {
    margin-right: 20px;
  }

  .ListIcon {
    cursor: move;
    cursor: grab;

    color: #cecece;

    &:hover {
      color: #0d0e34;
    }
  }

  .DragActive {
    & > .Questions {
      .Question {
        &:not([draggable="true"]) {
          opacity: 0.5;
        }
      }
    }
  }

  .TrashIcon {
    color: #cecece;
    cursor: pointer;

    &:hover {
      color: #0d0e34;
    }
  }

  input,
  select {
    border: none;
    appearance: none;
    background: transparent;
    outline: 0;

    font-size: 12px;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font-weight: inherit;
      opacity: 1;
      color: #0d0e34;
    }

    &[type="text"] {
      width: 100%;
    }
  }

  .AddQuestion {
    cursor: pointer;
    color: #0d0e34;
    font-size: 12px;
    font-weight: bold;
  }

  .Section > .TopLine,
  .AddSection,
  .AddQuestion,
  .Question > .TopLine > :first-child {
    padding: 9px 39px 9px 19px;
  }

  .Section > .TopLine > .Title {
    width: 50%;
  }

  .Section > .TopLine > .Title,
  .AddSection,
  .AddQuestion,
  .Question > .TopLine > :first-child {
    display: flex;
    column-gap: 21px;
    align-items: center;
    font-weight: 600;
  }

  .Body {
    & > :first-child:not(.Sections),
    .Sections .Section:first-child .TopLine {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }

    & > :last-child {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    .Sections {
      .Section {
        &[draggable="true"] {
          box-shadow: 0px 0px 16px #00000029;

          .Questions,
          .AddQuestion {
            display: none;
          }
        }

        & > .TopLine {
          display: flex;
          align-items: center;
          justify-content: space-between;

          background: #f2f2f2;

          & > .Title {
            width: 100%;
          }
        }
      }
    }

    .Questions {
      & > .Question {
        border: 1px solid #e6e6e6;

        &:not(:last-child) {
          border-bottom: none;
        }

        &[draggable="true"] {
          box-shadow: 0px 0px 16px #00000029;
        }

        .TopLine {
          display: flex;
          flex-direction: column;

          width: 100%;

          .Row {
            display: flex;
            font: normal normal 600 12px/18px Poppins;
            letter-spacing: 0;
            color: #0d0e34;
            width: 100%;

            .question-input {
              margin-right: 5px;
              font-weight: 700;
              color: #0d0e34;
              width: 100%;
            }
          }

          .displayRow {
            width: 100%;
            padding: 0 24px 0 65px;
            align-items: center;
            justify-content: space-between;

            .cell-1 {
              width: 60%;
              display: flex;
              align-items: center;
              gap: 35px;

              margin-left: 7px;
            }
            .cell-2 {
              display: flex;
              justify-content: center;
              width: 10%;
            }
            .cell-3 {
              width: 10%;
            }
            .cell-4 {
              width: 20%;
              display: flex;
              justify-content: end;

              .cell-container {
                display: flex;
                align-items: center;
                gap: 15px;
              }
            }
            .cell-head {
              color: #6d6d6d;
              font-size: 12px;
            }
          }
        }
      }
    }

    .AddQuestion {
      border: 1px solid #e6e6e6;
      border-top: none;
    }
  }

  .Question > .TopLine,
  .AnswerOption {
    .ExtraActions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background: #f5f5f5 0 0 no-repeat padding-box;
      border-radius: 3px;
      margin: 15px 20px 15px 65px;

      .Action {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
      }
    }
  }

  .mx-auto {
    width: 51px;
    text-align: center;
  }

  .has-error {
    color: #e51d1d !important;
  }

  .has-error::placeholder {
    color: #e51d1d !important;
  }
}

.weight-input {
  border: 1px solid #cecece !important;
  border-radius: 3px;

  padding: 0 5px 0 5px;

  text-align: center;
  font-size: 12px;
  color: #6d6d6d;

  max-width: 51px;
  width: 100%;
  height: 24px;
}
</style>
