<template>
  <div>
    <loading-spinner :show="loading" v-if="loading" />

    <div class="login-form-credentials">
      <p class="Title">
        {{
          credentials.confirmed
            ? $t("auth.resetPassword.resetPasswordAction")
            : $t("auth.email.youAreLate")
        }}
      </p>
      <div v-if="credentials.confirmed" class="Inputs">
        <input-password
          :placeholder="$t('app.views.user.newPassword')"
          appearance="auth"
          v-model="credentials.password"
          :hasError="formErrors.hasErrors('password')"
          :error="formErrors.get('password')?.toString()"
        ></input-password>
        <input-password
          :placeholder="$t('app.views.user.repeatNewPassword')"
          appearance="auth"
          v-model="credentials.password_confirmation"
          :hasError="formErrors.hasErrors('password_confirmation')"
          :error="formErrors.get('password_confirmation')?.toString()"
        ></input-password>
      </div>
      <p v-else class="ConfirmAttentionText">
        {{ $t("auth.email.emailConfirmationLinkExpired") }}
      </p>

      <div class="Bottom">
        <div v-if="credentials.confirmed" class="Footer">
          <router-link :to="{ name: 'forgot-password' }">
            {{ $t("components.back") }}
          </router-link>
          <button type="submit" class="Button" @click="reset">
            {{ $t("components.send") }}
          </button>
        </div>
        <div v-else class="Footer center">
          <button class="Button" @click="resend">
            {{ $t("auth.login.resendEmail") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component } from "vue-property-decorator";
import { mapActions } from "vuex";
import { FormMixin } from "@/mixins/formMixin";
import { ActionTypes } from "@/vuex/modules/auth-module/action-types";
import { ResetPasswordCredentials } from "@/vuex/modules/auth-module/actions";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import { http } from "@/services/http/http";

@Component({
  methods: {
    ...mapActions({
      resetPassword: ActionTypes.RESET_PASSWORD,
    }),
  },
})
export default class AuthResetPassword extends FormMixin {
  protected readonly resetPassword!: (
    params: ResetPasswordCredentials
  ) => Promise<void>;

  protected loading: boolean = false;
  public credentials = {
    password: "",
    password_confirmation: "",
    code: "",
    confirmed: true,
  };

  async resend() {
    this.loading = true;

    try {
      await http().post(
        `/api/auth/resend-password-reset-code`,
        {},
        {
          params: {
            code: this.credentials.code,
            host: window.location.origin,
          },
        }
      );
      await this.$router.push({ name: "login" });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response?.data?.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  public async reset(): Promise<void> {
    this.loading = true;
    this.formErrors.clearErrors();

    if (this.credentials.password !== this.credentials.password_confirmation) {
      this.formErrors.setErrors({
        password_confirmation: [
          this.$t("auth.resetPassword.passwordNotMatch").toString(),
        ],
      });
    } else {
      try {
        this.credentials.code = this.$route.params.code;
        await this.resetPassword(this.credentials);
        await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: "Password has been reset.",
          type: NotificationType.SUCCESS,
        });
        await this.$router.push({ name: "login" });
      } catch (exception: any) {
        if (
          exception.response.data.message.includes(
            "Code is not valid, please receive new code."
          )
        ) {
          this.credentials.confirmed = false;
        }
        await this.formErrors.setErrors(exception.response.data.errors);
      }
    }
    this.loading = false;
  }
}
</script>

<style scoped>
.Footer {
  font-size: 14px;
}

.center {
  justify-content: center !important;
  margin-top: 30px;
}

.ConfirmAttentionText {
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0;
  font-size: 12px;
  color: #3d3d3d;
}
</style>