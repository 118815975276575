import { render, staticRenderFns } from "./InspectionStatusView.vue?vue&type=template&id=60267a7d&scoped=true"
import script from "./InspectionStatusView.ts?vue&type=script&lang=ts&external"
export * from "./InspectionStatusView.ts?vue&type=script&lang=ts&external"
import style0 from "./InspectionStatusView.scss?vue&type=style&index=0&id=60267a7d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60267a7d",
  null
  
)

export default component.exports