import {GetterTree} from "vuex";
import {RootState} from "@/vuex/store";
import Meta from "@/vuex/models/meta";
import OrganisationRequestsState from "@/vuex/modules/organisation-requests-module/state";
import OrganisationRequest from "@/vuex/models/org-request/org-request";

const getters: GetterTree<OrganisationRequestsState, RootState> = {
    organisationRequests(state: OrganisationRequestsState): OrganisationRequest[] {
        return state.requests;
    },
    organisationRequestsMeta(state: OrganisationRequestsState): Meta {
        return state.meta;
    },
}
export default getters