<template>
  <div class="billing_container">
    <div class="billing_container_header">
      <h2 class="billing_container_header_title">
        {{ title }}
      </h2>

      <div v-if="showActions" class="billing_container_header_action">
        <span v-if="!!actionText" @click="$emit('action')" class="billing_container_header_action_text">
          {{ actionText }}
        </span>
        <span v-if="helpText" class="billing_container_header_action_help">
          <AttentionSign/> {{ helpText }}
        </span>
        <button
            :class="{'disabled': !hasAction}"
            class="billing_container_header_action_button"
            @click="$emit('click')"
            :disabled="!hasAction"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>

    <div class="billing_container_content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import AttentionSign from "@/components/AttentionSign.vue";

export default {
  name: "BillingContainer",
  components: {AttentionSign},
  emits: ['action', 'click'],
  props: {
    title: {
      required: true,
      type: String
    },
    hasAction: {
      required: false,
      type: Boolean,
      default: false
    },
    buttonLabel: {
      required: false,
      type: String,
      default: null
    },
    actionText: {
      required: false,
      type: String,
      default: null
    },
    helpText: {
      required: false,
      type: String,
      default: null
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.billing_container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &_title {
      font: normal normal 600 26px/42px Poppins;
      letter-spacing: 0;
      color: #0D0E34;
    }

    &_action {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      &_text {
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0;
        color: #1DE591;
        cursor: pointer;
      }

      &_help {
        display: flex;
        align-items: center;
        gap: 10px;
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0;
        color: #737674;

        div {
          color: red;
        }
      }

      &_button {
        background: #1DE591 0 0 no-repeat padding-box;
        border-radius: 122px;
        cursor: pointer;
        height: 48px;
        padding: 0 40px;
        border: none;

        text-align: center;
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0;
        color: #FFFFFF;

        &:hover {
          box-shadow: 0 0 16px #00000029;
        }
      }
    }
  }
}

.disabled {
  opacity: .4;
}
</style>