import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import UserForm from "./components/UserForm/UserForm.vue";
import ExternalAccessForm from "./components/ExternalAccessForm/ExternalAccessForm.vue";
import NotificationAboutAddNewUser from "./components/NotificationAboutAddNewUser/NotificationAboutAddNewUser.vue";
import ButtonWithIcon from "@/components/ButtonWithIcon/ButtonWithIcon.vue";
import { PageMixin } from "@/mixins/pageMixin";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import DeleteUserModal from "@/views/UserProfile/components/DeleteUserModal/DeleteUserModal.vue";
import DeleteExternalAccessModal from "@/views/UserProfile/components/DeleteExternalAccessModal/DeleteExternalAccessModal.vue";
import User from "@/vuex/models/user/user";
import ExternalAccess from "@/vuex/models/external-access/external-access";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { ActionTypes as ExternalAccessActions } from "@/vuex/modules/external-access-module/action-types";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import { ExternalAccessSearchParams } from "@/vuex/modules/external-access-module/actions";
import Meta from "@/vuex/models/meta";
import { Debounce } from "vue-debounce-decorator";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import InputSelectAccess from "@/components/InputSelectAccess/InputSelectAccess.vue";
import { ActionTypes } from "@/vuex/modules/organisation-requests-module/action-types";
import { OrganisationRequestSearchParams } from "@/vuex/modules/organisation-requests-module/actions";
import OrganisationRequest from "@/vuex/models/org-request/org-request";
import UsersTable from "@/views/UsersView/components/DataTables/UsersTable.vue";
import OrganisationRequestsTable from "@/views/UsersView/components/DataTables/OrganisationRequestsTable.vue";
import UsersExternalAccessTable from "@/views/UsersView/components/DataTables/UsersExternalAccessTable.vue";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import UserData from "@/vuex/models/user/user-data";
import i18n from "@/lang/locale";
import { generateTranslationRole } from "@/services/helpers/generateTranslationRole";
import {Direction, UsersSortableColumns} from "@/services/helpers/sort";

@Component({
  components: {
    OrganisationRequestsTable,
    UsersExternalAccessTable,
    UsersTable,
    InputSelectAccess,
    AccessModal,
    ButtonsSaveCancel,
    DeleteUserModal,
    UserForm,
    ButtonWithIcon,
    NotificationAboutAddNewUser,
    ExternalAccessForm,
    DeleteExternalAccessModal,
  },
  computed: mapGetters({
    users: "users",
    usersForCsv: "usersForCsv",
    usersMeta: "usersMeta",
    requests: "organisationRequests",
    orgRequestsMeta: "organisationRequestsMeta",
    externalAccesses: "externalAccessItems",
    externalAccessMeta: "externalAccessMeta",
  }),
  methods: mapActions({
    fetchUsers: UserActions.FETCH_USERS,
    fetchUsersForCsv: UserActions.FETCH_USERS_FOR_CSV,
    fetchRequests: ActionTypes.FETCH_REQUESTS,
    allowUser: UserActions.ALLOW_USER,
    denyUser: UserActions.DENY_USER,
    fetchExternalAccess: ExternalAccessActions.FETCH_EXTERNAL_ACCESSES,
    createExternalAccess: ExternalAccessActions.CREATE_EXTERNAL_ACCESS,
    changeStatusAccess: ExternalAccessActions.CHANGE_STATUS_EXTERNAL_ACCESS,
    sendEmailAccess: ExternalAccessActions.SEND_EMAIL_EXTERNAL_ACCESS,
  }),
})
export default class UsersView extends Mixins(PageMixin) {
  private readonly fetchUsers!: (params: UserSearchParams) => Promise<void>;
  private readonly fetchExternalAccess!: (params: ExternalAccessSearchParams) => Promise<void>;
  private readonly fetchUsersForCsv!: (
    params: UserSearchParams
  ) => Promise<void>;
  private readonly fetchRequests!: (
    params: OrganisationRequestSearchParams
  ) => Promise<void>;
  private readonly allowUser!: (params: {
    userId: number;
    host: string;
  }) => Promise<void>;
  private readonly denyUser!: (params: {
    userId: number;
    host: string;
  }) => Promise<void>;
  private readonly sendEmailAccess!: (params: { externalAccessId: number | null; email: string | null }) => Promise<void>;

  protected readonly usersForCsv!: Array<User>;
  protected readonly users!: Array<User>;
  protected readonly requests!: OrganisationRequest[];
  protected readonly externalAccesses!: Array<ExternalAccess>;
  protected readonly usersMeta!: Meta;
  protected readonly orgRequestsMeta!: Meta;
  protected readonly externalAccessMeta!: Meta;

  protected notificationAboutAddNewUserData: null | UserData = null;
  protected typeModal: string = "";
  protected activeTab: number = 1;
  protected showAccessModal: boolean = false;
  protected showExternalAccessModal: boolean = false;
  protected selectedUser: User | null = null;
  protected isOpenNotificationModal: boolean = false;
  protected selectedExternalAccess: ExternalAccess | null = null;
  protected showExternalAccessForm: boolean = false;
  protected showExternalAccessDeleteModal: boolean = false;

  private userSearchParams: UserSearchParams = {
    page: 1,
    per_page: 25,
    column: null,
    direction: null,
    search: null,
    organisation_id: null,
    role: null,
    locations_ids: [],
  };

  protected orgRequestSearchParams: OrganisationRequestSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
  };

  private AccessSearchParams: ExternalAccessSearchParams = {
    page: 1,
    per_page: 25,
    column: null,
    direction: null,
    search: null,
    organisation_id: null,
  };

  protected get hasAccessToOrgRequests(): boolean {
    return (
      this.auth.isSystemAdmin(this.me) || this.auth.isOrganisationAdmin(this.me)
    );
  }

  protected setNewUserDataHandler(userData: UserData) {
    this.notificationAboutAddNewUserData = userData;
    this.showForm = false;
  }

  protected get tableIsEmpty(): boolean {
    switch (this.activeTab) {
      case 1:
        return this.users.length < 1;
      case 2:
        return this.externalAccesses.length < 1;
      case 3:
        return this.requests.length < 1;
      default:
        return false;
    }
  }

  protected get limitReached(): boolean {
    if (!this.auth.isSystemAdmin(this.me) && !!this.me.organisation) {
      return (
        null !== this.me.organisation.users_limit &&
        //@ts-ignore
        this.me.organisation.active_users_count >=
          this.me.organisation.users_limit
      );
    }

    return false;
  }

  protected get externalAccessLimitReached(): boolean {
    if (!this.auth.isSystemAdmin(this.me) && !!this.me.organisation) {
      // @ts-ignore
      return (
        null !== this.me.organisation.external_accesses_limit &&
        this.externalAccesses.length >= this.me.organisation.external_accesses_limit
      );
    }

    return false;
  }

  protected async created(): Promise<void> {
    await this.paginate();
    if (this.hasAccessToOrgRequests) {
      await this.fetchRequests(this.orgRequestSearchParams);
    }
  }

  protected async switchTab(tab: number): Promise<void> {
    this.activeTab = tab;
    await this.paginate();
  }

  protected openForm(): void {
    if (this.activeTab === 2 && (this.auth.isSystemAdmin(this.me) || this.auth.isOrganisationAdmin(this.me))) {
      if (!this.externalAccessLimitReached) {
        this.showExternalAccessForm = true;
      } else {
        this.showExternalAccessModal = true;
      }
    } else {
      if (this.limitReached) {
        if (this.me.organisation?.subscription_product_slug === "enterprise") {
          this.showAccessModal = true;
        } else {
          this.showForm = true;
          this.typeModal = "create";
        }
      } else {
        this.showForm = true;
        this.typeModal = "create";
      }
    }
  }

  @Watch("userSearchParams", { deep: true })
  private async paginate(): Promise<void> {
    this.loading = true;

    switch (this.activeTab) {
      case 1:
        this.removeNullValues();
        await this.fetchUsers(this.userSearchParams);
        this.pagination = this.usersMeta;
        break;
      case 2:
        await this.fetchExternalAccess(this.AccessSearchParams);
        this.pagination = this.externalAccessMeta;
        break;
      case 3:
        await this.fetchRequests(this.orgRequestSearchParams);
        this.pagination = this.orgRequestsMeta;
        break;
    }

    this.loading = false;
  }

  private generateHeaders() {
    let headers = [
      i18n.t("components.table.fullName").toString(),
      i18n.t("components.table.role").toString(),
      i18n.t("components.table.location").toString(),
      i18n.t("components.table.email").toString(),
    ];
    if (this.auth.isSystemAdmin(this.me)) {
      headers.splice(2, 0, i18n.t("components.table.organisation").toString());
    }
    if (
      this.auth.isSystemAdmin(this.me) ||
      this.auth.isOrganisationAdmin(this.me)
    ) {
      headers.push(i18n.t("components.table.status").toString());
    }
    return headers;
  }

  public async handleExportCsv() {
    await this.fetchUsersForCsv(this.userSearchParams);

    await this.exportCsv.handleExportCsv(
      this.usersForCsv.map((el) => ({
        [i18n
          .t("components.table.fullName")
          .toString()]: `${el.name} ${el.surname}`,
        [i18n.t("components.table.role").toString()]: i18n.t(
          generateTranslationRole(el.role)
        ),
        ...(this.auth.isSystemAdmin(this.me) && {
          [i18n.t("components.table.organisation").toString()]:
            !!el.organisation ? el.organisation.name : "-",
        }),
        [i18n.t("components.table.location").toString()]: !!el.locations.length
          ? el.locations.map((location) => location.name).join(", ")
          : "-",
        [i18n.t("components.table.email").toString()]: el.email,
        ...((this.auth.isSystemAdmin(this.me) ||
          this.auth.isOrganisationAdmin(this.me)) && {
          [i18n.t("components.table.status").toString()]: el.active
            ? i18n.t("app.views.userStatus.active").toString()
            : i18n.t("app.views.userStatus.inactive").toString(),
        }),
      })),
      this.generateHeaders(),
      "users.csv"
    );
  }

  private removeNullValues(): void {
    this.userSearchParams.locations_ids?.forEach(
      (id: number | null, i: number): void => {
        if (!id) {
          this.userSearchParams.locations_ids?.splice(i, 1);
        }
      }
    );
  }

  protected async setPage(page: number): Promise<void> {
    switch (this.activeTab) {
      case 1:
        this.userSearchParams.page = page;
        break;
      case 2:
        this.AccessSearchParams.page = page;
        break;
      case 3:
        this.orgRequestSearchParams.page = page;
        break;
    }

    await this.paginate();
  }

  protected async sortColumn(column: string): Promise<void> {
    switch (this.activeTab) {
      case 1:
        this.userSearchParams = this.sort(column, this.userSearchParams);
        break;
      case 2:
        this.AccessSearchParams = this.sort(
          column,
          this.AccessSearchParams
        );
        break;
      case 3:
        this.orgRequestSearchParams = this.sort(
          column,
          this.orgRequestSearchParams
        );
        break;
    }

    await this.paginate();
  }

  @Debounce(350)
  protected async searchRecords(search: string): Promise<void> {
    let searchParams;

    switch (this.activeTab) {
      case 1:
        searchParams = this.userSearchParams;
        break;
      case 2:
        searchParams = this.AccessSearchParams;
        break;
      case 3:
        searchParams = this.orgRequestSearchParams;
        break;
    }

    const params = this.search(search, searchParams);

    if (!!params) {
      switch (this.activeTab) {
        case 1:
          this.userSearchParams = params;
          break;
        case 2:
          this.AccessSearchParams = params;
          break;
        case 3:
          this.orgRequestSearchParams = params;
          break;
      }
    }

    await this.paginate();
  }

  protected async closeForm(submitted: boolean): Promise<void> {
    this.showForm = false;
    this.selectedUser = null;
    this.notificationAboutAddNewUserData = null;
    this.isOpenNotificationModal = false;

    if (submitted) {
      await this.paginate();
    }
  }

  protected async closeExternalAccessForm(): Promise<void> {
    this.showExternalAccessForm = false;
    this.selectedExternalAccess = null;
  }

  protected async closeModal(submitted: boolean): Promise<void> {
    this.showDeleteModal = false;
    this.selectedUser = null;

    if (submitted) {
      await this.paginate();
    }
  }

  protected update(user: User): void {
    this.selectedUser = user;
    this.showForm = true;
    this.typeModal = "update";
  }

  protected remove(user: User): void {
    this.selectedUser = user;
    this.showDeleteModal = true;
  }

  protected async allow(request: OrganisationRequest): Promise<void> {
    this.loading = true;

    try {
      await this.allowUser({
        userId: request.user_id,
        host: window.location.origin,
      });
      await this.paginate();
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: this.$t("app.views.users.userAllowed").toString(),
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  protected async deny(request: OrganisationRequest): Promise<void> {
    this.loading = true;

    try {
      await this.denyUser({
        userId: request.user_id,
        host: window.location.origin,
      });
      await this.paginate();
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: this.$t("app.views.users.userDenied").toString(),
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  protected async sendEmail(ExternalAccess: ExternalAccess): Promise<void> {
    this.loading = true;

    try {
      await this.sendEmailAccess({
        externalAccessId: ExternalAccess.id,
        email: ExternalAccess.email,
      });
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: this.$t("app.views.users.accessSend").toString(),
        type: NotificationType.SUCCESS,
      });
    } catch (exception: any) {
      await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }

    this.loading = false;
  }

  protected updateAccess(access: ExternalAccess): void {
    if (this.auth.isSystemAdmin(this.me) || this.auth.isOrganisationAdmin(this.me)) {
      this.showExternalAccessForm = true;
      this.selectedExternalAccess = access;
    }
  }

  protected removeAccess(access: ExternalAccess): void {
    this.selectedExternalAccess = access;
    this.showExternalAccessDeleteModal = true;
  }

  private async selectLocation(value: number): Promise<void> {
    this.userSearchParams.locations_ids = [value];
    // this.AccessSearchParams.organisation_id = value;

    await this.paginate();
  }

}
