import { ActionTypes } from "./action-types";
import { http } from "@/services/http/http";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/vuex/store";
import { BillingState } from "@/vuex/modules/billing-module/state";
import { appRoutes } from "@/config";
import { MutationTypes } from "@/vuex/modules/billing-module/mutation-types";
import { AxiosResponse } from "axios";
import StripeAddress from "@/vuex/models/billing/address";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import EnterpriseRequest from "@/vuex/models/billing/enterprise-request";

type BillingContext = ActionContext<BillingState, RootState>;

const actions: ActionTree<BillingState, RootState> = {
  async [ActionTypes.FETCH_CUSTOMER](store: BillingContext): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.billing.customer());
      store.commit(MutationTypes.SET_CUSTOMER, response.data);
    } catch (exception: any) {
      if (exception.response.status !== 403) {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    }
  },

  async [ActionTypes.FETCH_SUBSCRIPTION](store: BillingContext): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.billing.subscription());
      store.commit(MutationTypes.SET_SUBSCRIPTION, response.data);
    } catch (exception: any) {
      if (exception.response.status !== 403) {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    }
  },

  async [ActionTypes.SAVE_SUBSCRIPTION](
    store: BillingContext,
    productId: number
  ): Promise<void> {
    const response = await http().post(appRoutes.api.billing.subscription(), {
      stripe_product_id: productId,
    });
    store.commit(MutationTypes.SET_SUBSCRIPTION, response.data.data);
  },

  async [ActionTypes.CANCEL_SUBSCRIPTION](
    store: BillingContext,
    subscriptionId: number
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.billing.subscription() + "/cancel/" + subscriptionId
      );
      store.commit(MutationTypes.SET_SUBSCRIPTION, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.RESUME_SUBSCRIPTION](
    store: BillingContext,
    subscriptionId: number
  ): Promise<void> {
    try {
      const response = await http().get(
        appRoutes.api.billing.subscription() + "/resume/" + subscriptionId
      );
      store.commit(MutationTypes.SET_SUBSCRIPTION, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.REQUEST_ENTERPRISE](
    store: BillingContext,
    request: EnterpriseRequest
  ): Promise<void> {
    await http().post(appRoutes.api.billing.enterprise(), request);
  },

  async [ActionTypes.FETCH_PAYMENT_METHOD](
    store: BillingContext
  ): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.billing.paymentMethod());
      store.commit(MutationTypes.SET_PAYMENT_METHOD, response.data);
    } catch (exception: any) {
      if (exception.response.status !== 403) {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    }
  },

  async [ActionTypes.DELETE_PAYMENT_METHOD](
    store: BillingContext
  ): Promise<void> {
    try {
      await http().delete(appRoutes.api.billing.paymentMethod());
      store.commit(MutationTypes.SET_PAYMENT_METHOD, null);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_ADDRESS](store: BillingContext): Promise<void> {
    try {
      const response = await http().get(appRoutes.api.billing.address());
      store.commit(MutationTypes.SET_ADDRESS, response.data);
    } catch (exception: any) {
      if (exception.response.status !== 403) {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: exception.response.data.message,
          type: NotificationType.DANGER,
        });
      }
    }
  },

  async [ActionTypes.SAVE_ADDRESS](
    store: BillingContext,
    payload: {
      id: number;
      address: StripeAddress;
      tax: { tax_number: string; tax_type: string };
    }
  ): Promise<void> {
    const data = { ...payload.address, ...payload.tax };
    const response = !payload?.id
      ? await http().post(appRoutes.api.billing.address(), data)
      : await http().put(appRoutes.api.billing.address(), data);

    store.commit(MutationTypes.SET_ADDRESS, response.data);
  },

  async [ActionTypes.DELETE_ADDRESS](store: BillingContext): Promise<void> {
    try {
      await http().delete(appRoutes.api.billing.address());
      store.commit(MutationTypes.SET_ADDRESS, null);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_COUNTRIES_LIST](
    store: BillingContext
  ): Promise<AxiosResponse | void> {
    try {
      return await http().get(appRoutes.api.billing.countries());
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_TAX_TYPES](
    store: BillingContext
  ): Promise<void | AxiosResponse> {
    try {
      return await http().get(appRoutes.api.billing.taxTypes());
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_PRODUCTS](
    store: BillingContext
  ): Promise<AxiosResponse | void> {
    try {
      return await http().get(appRoutes.api.billing.products());
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.FETCH_INVOICES](
    store: BillingContext,
    payload: {
      page: number;
      per_page: number;
    } | null
  ): Promise<AxiosResponse | void> {
    try {
      if (!payload) {
        payload = {
          per_page: 5,
          page: 1,
        };
      }

      const response = await http().get(appRoutes.api.billing.invoices(), {
        params: { ...payload },
      });
      store.commit(MutationTypes.SET_INVOICES, response.data);
    } catch (exception: any) {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: exception.response.data.message,
        type: NotificationType.DANGER,
      });
    }
  },

  async [ActionTypes.CANNOT_PAY_REQUEST](store: BillingContext): Promise<void> {
    await http().post(
      appRoutes.api.billing.paymentMethod() + "/cannot-pay-request"
    );
  },

  async [ActionTypes.FETCH_SETUP_INTENT](): Promise<AxiosResponse> {
    try {
      return await http().get(appRoutes.api.billing.intent());
    } catch (exception: any) {
      throw exception;
    }
  },

  async [ActionTypes.FETCH_PUBLIC_KEY](): Promise<AxiosResponse> {
    try {
      return await http().get(appRoutes.api.billing.key());
    } catch (exception: any) {
      throw exception;
    }
  },
};

export default actions;
