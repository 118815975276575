var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.loading)?_c('loading-spinner',{attrs:{"show":_vm.loading}}):_vm._e(),_c('TableViewLayout',{attrs:{"title":_vm.$tc('app.views.language.language', 2),"is-empty":_vm.languages.length < 1},scopedSlots:_vm._u([{key:"header-items",fn:function(){return [_c('div',{staticClass:"Button IsTiny",on:{"click":function($event){_vm.showForm = !_vm.showForm}}},[_c('img',{staticClass:"Icon",attrs:{"src":"/images/icons/plus.svg","alt":"plus-icon"}})])]},proxy:true}])},[[_c('records-table',{attrs:{"rows":_vm.languages},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',{staticClass:"table_thead_tr"},[_c('th',{attrs:{"width":"240"}},[_vm._v(_vm._s(_vm.$t("components.table.langCode")))]),_c('th',[_vm._v(_vm._s(_vm.$t("components.table.translatedWords")))]),_c('th')])]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.languages),function(language){return _c('tr',[_c('td',[_c('div',{staticClass:"language-title",on:{"click":function($event){_vm.$router.push({
                    name: 'language-words',
                    params: { id: language.id.toString() },
                  })}}},[_vm._v(" "+_vm._s(_vm.generateTranslationsForLanguagesHandler(language.code))+" ")])]),_c('td',{staticClass:"words-count",class:{
                good: language.words_confirmed_count === language.words_count,
              }},[_vm._v(" "+_vm._s(language.words_confirmed_count + "/" + language.words_count)+" ")]),_c('td',[_c('table-row-drop-down-menu',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticClass:"controls-dropdown-button",on:{"click":function($event){_vm.$router.push({
                        name: 'language-words',
                        params: { id: language.id.toString() },
                      })}}},[_vm._v(" "+_vm._s(_vm.$t("components.show"))+" ")]),_c('span',{staticClass:"controls-dropdown-button",on:{"click":function($event){return _vm.remove(language)}}},[_vm._v(" "+_vm._s(_vm.$t("components.delete"))+" ")])]},proxy:true}],null,true)})],1)])})},proxy:true}])})]],2),(_vm.showForm)?_c('language-form',{attrs:{"is-visible":_vm.showForm},on:{"close":_vm.closeForm,"loading":function($event){_vm.loading = !_vm.loading}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }