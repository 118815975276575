import Vuex from "vuex";
import Vue from "vue";
import AuthModule from "./modules/auth-module/auth-module";
import LocationsModule from "./modules/locations-module/module";
import QRCodesModule from "./modules/qr-code-module/module";
import UsersModule from "./modules/users-module/module";
import TemplatesModule from "@/vuex/modules/templates-module/module";
import InspectionsModule from "@/vuex/modules/inspections-module/module";
import NotificationsModule from "@/vuex/modules/notifications-module/module";
import OrganisationsModule from "@/vuex/modules/organisations-module/module";
import ReportsModule from "@/vuex/modules/reports-module/module";
import LogsModule from "@/vuex/modules/logs-module/module";
import LanguagesModule from "./modules/languages-module/module";
import IncidentsModule from "./modules/incidents-module/module";
import StatisticsModule from "./modules/statistics-module/module";
import OrganisationRequestsModule from "./modules/organisation-requests-module/module";
import BrowserModule from "./modules/browser-module/module";
import ExternalAccessModule from "./modules/external-access-module/module";
import AuthState from "./modules/auth-module/state";
import UsersState from "./modules/users-module/state";
import LocationsState from "./modules/locations-module/state";
import TemplatesState from "@/vuex/modules/templates-module/state";
import InspectionsState from "@/vuex/modules/inspections-module/state";
import NotificationsState from "@/vuex/modules/notifications-module/state";
import ReportsState from "@/vuex/modules/reports-module/state";
import ProfileModule from "./modules/profile-module/module";
import { UserProfileState } from "./modules/profile-module/state";
import OrganisationsState from "@/vuex/modules/organisations-module/state";
import LanguagesState from "@/vuex/modules/languages-module/state";
import BillingModule from "./modules/billing-module/module";
import { BillingState } from "@/vuex/modules/billing-module/state";
import IncidentsState from "@/vuex/modules/incidents-module/state";
import StatisticsState from "@/vuex/modules/statistics-module/state";
import LogsState from "@/vuex/modules/logs-module/state";
import OrganisationRequestsState from "@/vuex/modules/organisation-requests-module/state";
import BrowserState from "@/vuex/modules/browser-module/state";
import QRCodeState from "@/vuex/modules/qr-code-module/state";
import ExternalAccessState from "@/vuex/modules/external-access-module/state";

Vue.use(Vuex);

export interface RootState {
  auth: AuthState;
  locations: LocationsState;
  qrCodes: QRCodeState;
  users: UsersState;
  inspectionTemplates: TemplatesState;
  profile: UserProfileState;
  inspectionEvents: InspectionsState;
  notifications: NotificationsState;
  organisations: OrganisationsState;
  reports: ReportsState;
  languages: LanguagesState;
  billing: BillingState;
  incidents: IncidentsState;
  statistics: StatisticsState;
  logs: LogsState;
  orgRequests: OrganisationRequestsState;
  browser: BrowserState;
  externalAccess: ExternalAccessState;
}

export default new Vuex.Store<RootState>({
  modules: {
    auth: AuthModule,
    locations: LocationsModule,
    qrCodes: QRCodesModule,
    users: UsersModule,
    inspectionTemplates: TemplatesModule,
    profile: ProfileModule,
    inspectionEvents: InspectionsModule,
    notifications: NotificationsModule,
    organisations: OrganisationsModule,
    reports: ReportsModule,
    logs: LogsModule,
    languages: LanguagesModule,
    billing: BillingModule,
    incidents: IncidentsModule,
    statistics: StatisticsModule,
    orgRequests: OrganisationRequestsModule,
    browser: BrowserModule,
    externalAccess: ExternalAccessModule,
  },
});
