import {Module} from "vuex";
import {RootState} from "@/vuex/store";
import InspectionTemplatesState from "./state";
import actions from './actions';
import mutations from "./muatations";
import getters from "./getters";


const module: Module<InspectionTemplatesState, RootState> = {
    state: new InspectionTemplatesState(),
    actions,
    mutations,
    getters,
}

export default module
