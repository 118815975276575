import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field/Field.vue";
import ButtonsSaveCancel from "@/components/Buttons/ButtonsSaveCancel.vue";
import AccessModal from "@/components/Modal/components/AccessModal.vue";
import LocationData from "@/vuex/models/location/location-data";
import LocationFull from "@/vuex/models/location/location-full";
import UserProfile from "@/vuex/models/user/user-profile";
import Authorization from "@/services/authorization/authorization";
import { ActionTypes as OrganisationActions } from "@/vuex/modules/organisations-module/action-types";
import { ActionTypes as UserActions } from "@/vuex/modules/users-module/action-types";
import { MutationTypes as OrganisationMutations } from "@/vuex/modules/organisations-module/mutation-types";
import { MutationTypes as UserMutations } from "@/vuex/modules/users-module/mutation-types";
import { UserSearchParams } from "@/vuex/modules/users-module/actions";
import { Direction, UsersSortableColumns } from "@/services/helpers/sort";
import { ActionTypes } from "@/vuex/modules/locations-module/action-types";
import store from "@/vuex/store";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import InputSelectOrganisation from "@/components/InputSelectOrganisation/InputSelectOrganisation.vue";
import { FormMixin } from "@/mixins/formMixin";
import { SelectOption } from "@/components/InputSelect/InputSelect";
import { http } from "@/services/http/http";
import { TLocationCurrentData } from "./types";

@Component({
  components: {
    InputSelectOrganisation,
    AccessModal,
    ButtonsSaveCancel,
    Field,
  },
  computed: {
    ...mapGetters({
      organisationOptions: "organisationSelectOptions",
      userOptions: "userSelectOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchOrganisationOptions: OrganisationActions.FETCH_ORGANISATIONS,
      fetchUserOptions: UserActions.FETCH_USERS,
      create: ActionTypes.CREATE_LOCATION,
      update: ActionTypes.UPDATE_LOCATION,
    }),
  },
})
export default class LocationForm extends FormMixin {
  @Prop({
    required: true,
    type: Boolean,
  })
  public showForm!: boolean;

  @Prop({
    required: false,
    type: Number,
  })
  public locationId!: number | null;

  @Prop({
    required: false,
    default: null,
  })
  public preSelectedOrganisation!: SelectOption | null;

  protected readonly fetchUserOptions!: (
    params: UserSearchParams
  ) => Promise<void>;
  protected readonly create!: (params: LocationData) => Promise<LocationFull>;
  protected readonly update!: (params: LocationData) => Promise<LocationFull>;

  protected readonly auth: Authorization = new Authorization();
  protected readonly userOptions!: SelectOption[];
  protected userFilteredOptions: SelectOption[] = [];
  protected userOrgOption: SelectOption = {
    label: "",
    value: null,
  };
  protected userLocationRoleOption: SelectOption = {
    label: "",
    value: null,
  };

  protected dataLocationWithResponse: null | TLocationCurrentData = null;
  protected userIds: number[] = [];
  protected locationData: LocationData = {
    id: null,
    organisation_id: null,
    name: null,
    users_ids: [],
  };

  protected async searchUserOptions(): Promise<void> {
    const searchParams: UserSearchParams = {
      page: 1,
      per_page: 100,
      direction: Direction.DESC,
      column: UsersSortableColumns.NAME,
      search: null,
      organisation_id: this.auth.isSystemAdmin(this.me)
        ? this.locationData?.organisation_id
        : null,
      role: null,
      locations_ids: [],
    };
    await this.fetchUserOptions(searchParams);
  }

  protected get label(): string {
    const action = !!this.locationId
      ? this.$t("components.form.update")
      : this.$t("components.form.create");

    return action + " " + this.$tc("app.views.location.location", 1);
  }

  protected get organisationName(): string | null {
    if (!!this.preSelectedOrganisation) {
      return this.preSelectedOrganisation.label;
    }
    return !!this.locationId
      ? this.dataLocationWithResponse?.organisation.name ?? ""
      : null;
  }

  protected async created(): Promise<void> {
    this.$emit("loading");

    if (this.locationId) {
      this.getCurrentLocation(this.locationId);
    }
    await this.searchUserOptions();
    await this.searchUserOptions();

    if (!this.locationId && !!this.preSelectedOrganisation) {
      this.selectOrganisation(Number(this.preSelectedOrganisation.value));
    }

    this.$emit("loading");
  }

  protected async selectOrganisation(organisationId: number): Promise<void> {
    this.locationData.organisation_id = organisationId;
    this.locationData.users_ids = [];
    await this.searchUserOptions();
  }

  protected close(): void {
    this.$emit("close", false);
    this.userOrgOption = {
      value: null,
      label: "",
    };
    this.userLocationRoleOption = {
      label: "",
      value: null,
    };
    this.$store.commit(UserMutations.SET_USERS, []);
    this.$store.commit(OrganisationMutations.SET_ORGANISATIONS, []);
  }

  protected setLocationUsers(userIds: number[]) {
    this.locationData.users_ids = userIds;
  }

  protected async submit(): Promise<void> {
    this.$emit("loading");

    if (!this.auth.isSystemAdmin(this.me)) {
      this.locationData.organisation_id = null;
    }

    try {
      if (!!this.locationId) {
        await this.updateLocation();
      } else {
        await this.createLocation();
      }

      this.$emit("close", true);
    } catch (exception: any) {
      await this.handleException(exception);
    }

    this.$emit("loading");
  }

  @Watch("showForm")
  async showFormHandler() {
    this.searchUserOptions();
  }

  sendUseIds() {
    const userOrgOptionValue = this.userOrgOption.value;
    const userLocationRoleOptionValue = this.userLocationRoleOption.value;

    const filteredLocationUsers = this.dataLocationWithResponse?.users
      .filter((el) => el.role === "LOC_ADMIN")
      .map((el) => el.id);

    const userIdsSet: any = new Set(
      userOrgOptionValue && userLocationRoleOptionValue
        ? [
            userOrgOptionValue,
            userLocationRoleOptionValue,
            ...(filteredLocationUsers as number[]),
            ...this.locationData.users_ids,
          ]
        : userOrgOptionValue
        ? [userOrgOptionValue, ...this.locationData.users_ids]
        : userLocationRoleOptionValue
        ? [userLocationRoleOptionValue, ...this.locationData.users_ids]
        : this.locationData.users_ids
    );

    return [...userIdsSet];
  }

  async getCurrentLocation(id: number) {
    try {
      const response = await http().get(`/api/locations/${id}`);

      const orgAdminRole = "ORG_ADMIN";
      const filteredOrdAdmin = response.data.users.find(
        (el: UserProfile) => el.role === orgAdminRole
      );

      this.locationData = {
        id: response.data.id,
        name: response.data.name,
        organisation_id: response.data.organisation.id,
        users_ids: response.data.users.map((user: UserProfile) => user.id),
      };

      this.dataLocationWithResponse = response.data;

      if (!this.auth.isSystemAdmin(this.me) && filteredOrdAdmin) {
        this.userOrgOption = {
          value: filteredOrdAdmin?.id ?? null,
          label: `${filteredOrdAdmin?.name} ${filteredOrdAdmin?.surname}` ?? "",
        };
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Watch("userOptions")
  filteredUsersOptions() {
    if (this.auth.isOrganisationAdmin(this.me)) {
      const orgAdminRole = "ORG_ADMIN";

      this.userFilteredOptions = this.userOptions.filter(
        (el) => el.roleUser !== orgAdminRole
      );

      const filteredOrdAdmin = this.userOptions.find(
        (el) => el.roleUser === orgAdminRole
      );

      if (!this.locationId) {
        this.userOrgOption = {
          value: filteredOrdAdmin?.value ?? null,
          label: filteredOrdAdmin?.label ?? "",
        };
      } else {
        if (
          this.dataLocationWithResponse?.users.some(
            (el) => el.id === filteredOrdAdmin?.value
          )
        ) {
          this.userOrgOption = {
            value: filteredOrdAdmin?.value ?? null,
            label: filteredOrdAdmin?.label ?? "",
          };
          this.locationData.users_ids = this.locationData.users_ids.filter(
            (el) => el !== filteredOrdAdmin?.value
          );
        }
      }
      return;
    }

    if (this.auth.isLocationAdmin(this.me)) {
      this.userFilteredOptions = this.userOptions.filter(
        (el) => el.value !== this.me.id
      );

      const currentLocationAdmin = this.userOptions.find(
        (el) => el.value === this.me.id
      );

      this.userLocationRoleOption = {
        value: currentLocationAdmin?.value ?? null,
        label: currentLocationAdmin?.label ?? "",
      };

      return;
    }

    this.userFilteredOptions = this.userOptions;
  }

  private async createLocation(): Promise<void> {
    await this.create({
      id: this.locationData.id,
      organisation_id: this.locationData.organisation_id,
      name: this.locationData.name,
      users_ids: this.sendUseIds(),
    });
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t('app.views.user.locationCreated'),
      type: NotificationType.SUCCESS,
    });
  }

  private async updateLocation(): Promise<void> {
    if (this.auth.isLocationAdmin(this.me)) {
      this.locationData.name = null;
    }

    await this.update({
      id: this.locationData.id,
      organisation_id: this.locationData.organisation_id,
      name: this.locationData.name,
      users_ids: this.sendUseIds(),
    });
    await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: this.$t('app.views.user.locationUpdated'),
      type: NotificationType.SUCCESS,
    });
  }
}
