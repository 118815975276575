import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class UserAvatar extends Vue {

  @Prop({
    required: true
  })
  public user!: { name: string, surname: string | null };

  private get initials(): string {
    let firstLetter = '';
    let secondLetter = '';

    if (this.user.name.length >= 1) {
      firstLetter = this.user.name.charAt(0);
    }

    if (this.user.surname && this.user.surname.length >= 1) {
      secondLetter = this.user.surname.charAt(0);
    }

    return firstLetter + secondLetter;
  }
}
