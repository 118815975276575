import { render, staticRenderFns } from "./OrganisationForm.vue?vue&type=template&id=6a592b1d&scoped=true"
import script from "./OrganisationForm.ts?vue&type=script&lang=ts&external"
export * from "./OrganisationForm.ts?vue&type=script&lang=ts&external"
import style0 from "./OrganisationForm.scss?vue&type=style&index=0&id=6a592b1d&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a592b1d",
  null
  
)

export default component.exports