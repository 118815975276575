import i18n from "@/lang/locale";

export const generateTranslationForTemplateTags = (name: string) => {
  let translatedTemplateTag;

  switch (true) {
    case name.endsWith("cleaning"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.cleaning")
        .toString();
      break;

    case name.endsWith("construction"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.construction")
        .toString();
      break;

    case name.endsWith("security"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.security")
        .toString();
      break;

    case name.endsWith("fireSafety"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.fireSafety")
        .toString();
      break;

    case name.endsWith("business"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.business")
        .toString();
      break;

    case name.endsWith("healthCare"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.healthCare")
        .toString();
      break;

    case name.endsWith("it"):
      translatedTemplateTag = i18n.t("app.views.template.tags.it").toString();
      break;

    case name.endsWith("production"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.production")
        .toString();
      break;

    case name.endsWith("qualityManagement"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.qualityManagement")
        .toString();
      break;

    case name.endsWith("retail"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.retail")
        .toString();
      break;

    case name.endsWith("services"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.services")
        .toString();
      break;

    case name.endsWith("isoStandards"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.isoStandards")
        .toString();
      break;

    case name.endsWith("logistics"):
      translatedTemplateTag = i18n
        .t("app.views.template.tags.logistics")
        .toString();
      break;

    default:
      translatedTemplateTag = "";
  }

  return translatedTemplateTag;
};
