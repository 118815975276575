export enum TemplateQuestionType {
    YES_NO = 'YES_NO',
    SCALE_1_TO_5 = 'SCALE_1_TO_5',
    SCALE_1_TO_10 = 'SCALE_1_TO_10',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    COMMENT = 'COMMENT',
    SIGNATURE = 'SIGNATURE',
    GEOLOCATION = 'GEOLOCATION',
    SINGLE_CHOICE = 'SINGLE_CHOICE'
}
