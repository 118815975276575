<template>
  <div class="alternative-empty-table">
    <AttentionSign style="color: red"/>
    <span>
      {{ message }}
    </span>
  </div>
</template>

<script>
import AttentionSign from "@/components/AttentionSign.vue";

export default {
  name: "AlternativeEmptyTable",
  components: {AttentionSign},
  props: {
    message: {
      required: false,
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
.alternative-empty-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;

  span {
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0;
    color: #0D0E34;
  }
}
</style>