import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

export enum ButtonStyles {
  BIG_CONFIRM = 'big-confirm',
  BIG_CANCEL = 'big-cancel',
  ROUND = 'round',
}

@Component
export default class ButtonWithIcon extends Vue {

  @Prop({
    required: false,
    type: String,
    default: ButtonStyles.BIG_CONFIRM
  })
  public appearance!: ButtonStyles;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public isDiv!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public isReverse!: boolean;

  private availableStyles = ButtonStyles;
}

