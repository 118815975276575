import QRCodeState from "./state";
import { GetterTree } from "vuex";
import { RootState } from "../../store";
import Meta from "@/vuex/models/meta";
import QRCodeFull from "@/vuex/models/qrCode/qrCode-full";

const getters: GetterTree<QRCodeState, RootState> = {
  listQRCodes(state: QRCodeState): Array<QRCodeFull> | [] {
    return state.listQRCodes;
  },
  qrCodesForCsv(state: QRCodeState): Array<QRCodeFull> | [] {
    return state.qrCodesForCsv;
  },
  qrCodesMeta(state: QRCodeState): Meta {
    return state.meta;
  },
};

export default getters;
