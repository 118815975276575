import i18n from "@/lang/locale";

export const generateTranslatinsForAccessLevel = (accessLevel: string) => {
  let result;

  switch (accessLevel) {
    case "PRIVATE":
      result = i18n.t("app.views.select.access.private").toString();
      break;
    case "PUBLIC":
      result = i18n.t("app.views.select.access.public").toString();
      break;
    case "ORGANISATION":
      result = i18n.t("app.views.select.access.organisation").toString();
      break;
    default:
      result = "-";
  }

  return result;
};
