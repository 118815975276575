import { Component, Vue } from "vue-property-decorator";
import { http } from "@/services/http/http";
import store from "@/vuex/store";
import { appRoutes } from "@/config";
import { MutationTypes } from "@/vuex/modules/inspections-module/mutation-types";
import {ActionTypes as NotificationActions} from "@/vuex/modules/notifications-module/action-types";
import {NotificationType} from "@/vuex/modules/notifications-module/state";

@Component
export default class PinCode extends Vue {
  pin: string[] = ["", "", "", ""];
  isInspection: boolean = window.location.pathname.indexOf('inspection') > -1;
  isInspectionExecution: boolean = this.$route.name == "external-inspection-execution";

  created() {
    const firstInputRef = this.$refs["input0"] as HTMLInputElement;
    if (firstInputRef) {
      firstInputRef.focus();
    }
    if (!this.$route.params.id) {
      this.$router.back();
    }
  }

  handleInput(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    this.$set(this.pin, index, value);

    if (value !== "" && index < 3) {
      const nextInputRef = this.$refs["input" + (index + 1)];
      if (nextInputRef) {
        const nextInput = Array.isArray(nextInputRef)
          ? nextInputRef[0]
          : nextInputRef;
        setTimeout(() => {
          (nextInput as HTMLInputElement).focus();
        }, 100);
      }
    }

    if (this.pin.join("").length === 4) {
      this.submitPin();
    }
  }

  handleFocus(event: Event) {
    const target = event.target as HTMLInputElement;
    target.select();
  }

  submitPin() {
    const pinCode = this.pin.join("");
    for (let i = 0; i < 4; i++) {
      const inputRef = this.$refs["input" + i];
      if (inputRef) {
        const input = Array.isArray(inputRef) ? inputRef[0] : inputRef;
        (input as HTMLInputElement).blur();
      }
    }

    if (this.isInspectionExecution) {
      this.sendPinInspection(pinCode);
    } else {
      this.sendPinQr(this.$route.params.id.toString(), pinCode);
    }
  }

  sendPinToEmail() {
    console.log("Send PIN to my email");
  }

  login() {
    this.$router.push({
      name: "login",
      query: { qr_token: this.$route.params.id.toString() },
    });
  }

  async handleException(exception :any) {
    await this.$store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
      text: exception.response.data.message ?? exception.response.data,
      type: NotificationType.DANGER,
    });
  }

  protected async sendPinQr(qrToken: string, pinCode: string) {
    try {
      const response = await http().get(this.isInspection
          ? appRoutes.api.inspections.startInspectionExternal(qrToken, pinCode)
          : appRoutes.api.incidents.startIncidentType(qrToken, pinCode)
      );
      if (response.data === "Unauthorized action.") {
        await this.handleException({response});
      } else {
        response.data.pin = pinCode;
        this.$emit("run", response.data);
      }
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }

  protected async sendPinInspection(pinCode: string) {
    try {
      const response = await http().get(appRoutes.api.inspections.startInspectionExternalPin(this.$route.params.id.toString(), pinCode));
      response.data.pin = pinCode;
      this.$emit("run", response.data);
    } catch (exception: any) {
      await this.handleException(exception);
    }
  }
}
