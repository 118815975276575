<template>
  <div class="billing_layout">
    <div class="billing_layout_header">
      <div class="billing_layout_header_back" @click="$router.back()">
        <svg-icon name="arrow"/>
      </div>
      <span class="billing_layout_header_title">
        {{ title }}
      </span>
    </div>
    <div class="billing_layout_container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingLayout",
  props: {
    bodyWithOutBox: Boolean,
  },
  data() {
    return {
      title: null
    }
  }
}
</script>

<style lang="scss">
@import "@/asesst/styles/mixins";

.billing_layout {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 65px 80px;
  background: #FFFFFF 0 0 no-repeat padding-box;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;

    &_back {
      border: 2px solid #1DE591;
      color: #1DE591;
      background-color: #FFF;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        color: #FFF;
        background-color: #1DE591;
      }
    }

    &_title {
      text-align: left;
      font: normal normal 600 26px/42px Poppins;
      letter-spacing: 0;
      color: #0D0E34;
    }
  }

  &_container {
    display: flex;
    justify-content: center;
  }
}
</style>