import { Component, Watch } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/vuex/modules/logs-module/action-types";
import { PageMixin } from "@/mixins/pageMixin";
import { LogsSearchParams } from "@/vuex/modules/logs-module/actions";
import Log from "@/vuex/models/log/log";
import Meta from "@/vuex/models/meta";
import { LogsSortableColumns } from "@/services/helpers/sort";
import { Debounce } from "vue-debounce-decorator";
import i18n from "@/lang/locale";
import { generateTranslationRole } from "@/services/helpers/generateTranslationRole";

@Component({
  methods: mapActions({
    fetchLogs: ActionTypes.FETCH_LOGS,
    fetchLogsForCsv: ActionTypes.FETCH_LOGS_FOR_CSV,
  }),
  computed: mapGetters({
    logs: "logs",
    logsForCsv: "logsForCsv",
    meta: "logsMeta",
  }),
})
export default class UserLogsView extends PageMixin {
  protected readonly fetchLogs!: (params: LogsSearchParams) => Promise<void>;
  protected readonly fetchLogsForCsv!: (
    params: LogsSearchParams
  ) => Promise<void>;

  protected readonly logs!: Log[];
  protected readonly logsForCsv!: Log[];
  protected readonly meta!: Meta;
  protected readonly sortableColumns = LogsSortableColumns;

  protected searchParams: LogsSearchParams = {
    page: 1,
    per_page: 25,
    direction: null,
    column: null,
    search: null,
  };

  protected async created(): Promise<void> {
    await this.paginate();
  }

  @Watch("searchParams", { deep: true })
  protected async paginate(): Promise<void> {
    this.loading = true;
    await this.fetchLogs(this.searchParams);
    this.loading = false;

    this.pagination = this.meta;
  }

  protected setPage(page: number): void {
    this.searchParams.page = page;
  }

  protected sortColumn(column: string): void {
    this.searchParams = this.sort(column, this.searchParams);
  }

  protected handleGenerateTranslationRole = (
    role: "INSPECTOR" | "ORG_ADMIN" | "LOC_ADMIN" | "SUP_ADMIN"
  ) => {
    return generateTranslationRole(role);
  };

  @Debounce(350)
  protected searchLogs(search: string): void {
    const params = this.search(search, this.searchParams);

    if (!!params) {
      this.searchParams = params;
    }
  }

  private generateHeaders() {
    return [
      i18n.t("components.table.action").toString(),
      i18n.t("components.table.organisation").toString(),
      i18n.t("components.table.user").toString(),
      i18n.t("components.table.role").toString(),
      i18n.t("components.table.date").toString(),
    ];
  }

  public async handleExportCsv() {
    await this.fetchLogsForCsv(this.searchParams);

    await this.exportCsv.handleExportCsv(
      this.logsForCsv.map((log) => ({
        [i18n.t("components.table.action").toString()]: log.description,
        [i18n.t("components.table.organisation").toString()]: !!log.organisation
          ? log.organisation.name
          : "-",
        [i18n.t("components.table.user").toString()]: log.user_name,
        [i18n.t("components.table.role").toString()]: i18n
          .t(generateTranslationRole(log.user_role))
          .toString(),
        [i18n.t("components.table.date").toString()]: log.created_at,
      })),
      this.generateHeaders(),
      "logs-history.csv"
    );
  }
}
