<template>
  <div class="attention">!</div>
</template>

<script>
export default {
  name: "AttentionSign"
}
</script>

<style scoped>
.attention {
  min-width: 30px;
  height: 30px;
  border: 2px solid;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 20px;
}
</style>