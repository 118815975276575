<template>
  <div class="WagaDetailIcon">
    <img
      src="/images/icons/waga.svg"
      width="20"
      height="20"
      @click="opened = !opened"
    />
    <div class="Tooltip" v-show="opened">
      {{ $t("app.views.library.wagaDetail.notificationText") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "WagaDetailIcon",
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<style scoped lang="scss">
@use "sass:math";

.WagaDetailIcon {
  position: relative;

  img {
    cursor: pointer;
  }

  & > .Tooltip {
    position: absolute;
    bottom: calc(100% + 14px);
    left: -85px; // (190/2) - (20/2)
    width: 190px;

    padding: 7px 10px 4px 10px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 300;
    color: #6d6d6d;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #00000029;
    //border: 1px solid #FFFFFF;
    border-radius: 5px;

    &:after {
      position: absolute;
      top: 100%;
      left: calc(50% - 5px);
      content: " ";

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: #f2f2f2 transparent transparent transparent;
    }
  }
}
</style>