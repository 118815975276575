<template>
  <div class="Form">
    <div class="Title">
      {{ $t("auth.register.register") }}
    </div>
    <p class="ConfirmAttentionText">
      {{ $t("auth.email.toAddress") }}
      <span v-text="$route.params.userEmail" />
      {{ $t("auth.email.notification") }}
    </p>

    <div class="Bottom">
      <div class="Footer">
        <router-link class="Button" to="/login">
          {{ $t("auth.login.logIn") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthConfirmAttention",

  created() {
    if (!this.$route.params.userEmail) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

<style scoped>
.ConfirmAttentionText {
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
}

.Footer {
  justify-content: center !important;
  font-size: 14px;
}
</style>