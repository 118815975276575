<template>
  <records-table :rows="requests">
    <template #thead>
      <tr class="table_thead_tr">
        <sortable-th
            width="25%"
            :direction="orgRequestSearchParams.direction"
            :sorted="sortableColumns.EMAIL === orgRequestSearchParams.column"
            @click="sortColumn(sortableColumns.EMAIL)"
        >
          {{ $t('components.table.email') }}
        </sortable-th>
        <th width="25%" v-if="auth.isSystemAdmin(me)">{{ $t('components.table.organisation') }}</th>
        <sortable-th
            width="25%"
            :direction="orgRequestSearchParams.direction"
            :sorted="sortableColumns.CREATED_AT === orgRequestSearchParams.column"
            @click="sortColumn(sortableColumns.CREATED_AT)"
        >
          {{ $t('components.table.date') }}
        </sortable-th>
        <th width="25%"></th>
      </tr>
    </template>

    <template #tbody>
      <tr v-for="request in requests">
        <td>{{ request.email }}</td>
        <td v-if="auth.isSystemAdmin(me)">{{ request.organisation_name }}</td>
        <td>{{ dateToString.getLocaleString(new Date(request.created_at), [dateToString.LONG_DATE]) }}</td>
        <td>
          <div class="actions">
            <button class="save" @click.prevent="allow(request)">
              {{ $t("app.views.user.allow") }}
            </button>
            <button class="cancel" @click.prevent="deny(request)">
              {{ $t("app.views.user.denied") }}
            </button>
          </div>
        </td>
      </tr>
    </template>
  </records-table>
</template>

<script>
import {OrganisationRequestsSortableColumns} from "@/services/helpers/sort";
import Authorization from "@/services/authorization/authorization";
import {mapGetters} from "vuex";
import DateToString from "@/services/dateToString/dateToString";

export default {
  name: 'OrganisationRequestsTable',
  emits: ['sortColumn', 'allowRequest', 'denyRequest'],
  props: {
    requests: {
      required: true
    },
    orgRequestSearchParams: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['me'])
  },
  data() {
    return {
      sortableColumns: OrganisationRequestsSortableColumns,
      auth: new Authorization(),
      dateToString: new DateToString()
    }
  },
  methods: {
    sortColumn(columnName) {
      this.$emit('sortColumn', columnName);
    },
    allow(request) {
      this.$emit('allowRequest', request);
    },
    deny(request) {
      this.$emit('denyRequest', request);
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  button {
    width: 120px;
    height: 35px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 46px;
    border: 2px solid;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #FFF;
    }
  }

  .save {
    border-color: #1DE591;
    color: #1DE591;

    &:hover {
      background: #1DE591 0 0 no-repeat padding-box;
    }
  }

  .cancel {
    border-color: #FF5151;
    color: #FF5151;

    &:hover {
      background: #FF5151 0 0 no-repeat padding-box;
    }
  }
}
</style>