export default function objectToFormData(dataObject: object): FormData {
    const formData = new FormData();

    function appendFormData(data: any, root: string = '') {
        root = root || '';

        if (typeof data === "string") {
            formData.append(root, data);
            return;
        }

        if (data instanceof File) {
            formData.append(root, data);
            return;
        }

        if (data instanceof Date) {
            formData.append(root, data.toISOString());
            return;
        }


        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                appendFormData(data[i], root + '[' + i + ']');
            }
            return;
        }

        if (typeof data === 'object' && data) {
            for (let property in data) {

                if (!data.hasOwnProperty(property)) {
                    continue;
                }

                if (root === '') {
                    appendFormData(data[property], property);
                } else {
                    appendFormData(data[property], root + '[' + property + ']');
                }
            }
            return;
        }

        if (data !== null && typeof data !== 'undefined') {
            formData.append(root, data);
            return;
        }
    }

    appendFormData(dataObject, '');

    return formData;
}
