import { GetterTree } from "vuex";
import { RootState } from "@/vuex/store";
import OrganisationsState from "@/vuex/modules/organisations-module/state";
import Organisation from "@/vuex/models/organisation/organisation";
import Meta from "@/vuex/models/meta";
import { SelectOption } from "@/components/InputSelect/InputSelect";

const getters: GetterTree<OrganisationsState, RootState> = {
  organisations(state: OrganisationsState): Organisation[] | [] {
    return state.organisations;
  },
  organisationsForCsv(state: OrganisationsState): Organisation[] | [] {
    return state.organisationsForCsv;
  },
  organisationsMeta(state: OrganisationsState): Meta {
    return state.meta;
  },
  organisationSelectOptions(state: OrganisationsState): SelectOption[] | [] {
    return state.organisations.map((organisation: Organisation) => {
      return {
        value: organisation.id,
        label: organisation.name,
      };
    });
  },
};
export default getters;
