import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class LoadingSpinner extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public show!: Boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  public fullScreen!: Boolean;
}
